import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import PssDocument from "../Model/PssDocument";
import {PssAction} from "../Model/PssEnums";

export default function Menu() {
    const navigate = useNavigate();
    const [state, setState] = useState(false);
    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);

    useEffect(() => {
        let subscriberId = documents.subscribeChange(_ => setState(!state));
        return () => {
            documents.unsubscribe(subscriberId);
        }
    });

    const getRouteForDocument = (doc: PssDocument): string => {
        switch(doc.step) {
            default:
            case PssAction.selections:
            case PssAction.classifications:
                return `/documents/${doc.id}`;
            case PssAction.descriptions:
                return `/documents/${doc.id}/descriptions`;
            case PssAction.download:
                return `/documents/${doc.id}/generator`;
        }
    }

    return (
        <Navbar className="mb-2 border-bottom" bg="light" expand="lg">
            <Container fluid>
                <Navbar.Brand href="#/">Právo na Spravedlivý proces</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse role="navigation" id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <NavDropdown align={"end"} title="Dokumenty" id="basic-nav-dropdown">
                            {documents
                                .getAllDocuments()
                                .sort((a, b) => {
                                    return a.type - b.type;
                                })
                                .map(function (doc, _) {
                                    return <NavDropdown.Item key={doc.id} onClick={() => {
                                        window.scrollTo(0, 0);
                                        navigate(getRouteForDocument(doc), {replace:true})}
                                    }>
                                        {doc.name}
                                    </NavDropdown.Item>;
                                })}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}