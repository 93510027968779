import { configureStore } from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import documentsReducer from './PssDocumentsSlice';

const PssStore = configureStore({
    reducer: {
        documents: documentsReducer
    }
});

export type PssStoreState = ReturnType<typeof PssStore.getState>
export type PssAppDispatch = typeof PssStore

export const useAppDispatch = () => useDispatch<PssAppDispatch>()
export const useAppSelector: TypedUseSelectorHook<PssStoreState> = useSelector

export default PssStore;