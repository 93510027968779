import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";

export default function DisclaimerPopup() {

    const [disclaimerShown, disclaimerSetShown] = useState(true);
    const handleClose = () => disclaimerSetShown(false);

    useEffect(() => {
        return () => {
            handleClose();
        };
    });

    return (
        <>
            <Modal size={"xl"} show={disclaimerShown} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Upozornění</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Výběr jednotlivých právních pravidel týkajících se práv na spravedlivý proces provádíte vlastním
                        jménem a jste za něj plně odpovědní stejně jako za pravdivost a srozumitelnost popisu
                        konkrétních
                        okolností souvisejících s porušením jednoho či více uvedených pravidel, jež můžete provést v
                        následujícím kroku poté.</p>

                    <p>
                        Vámi vybrané porušené právní pravidlo týkající se práva na spravedlivý proces se nicméně na Vámi
                        pravdivě a srozumitelné popsané skutkové okolnosti související s daným porušením nemusí
                        aplikovat z
                        důvodu odlišných skutkových okolností, v rámci kterých byl vydán rozsudek Evropského soudu pro
                        lidská práva, ve kterém bylo formulováno právní pravidlo, jež jste níže zaškrtli či
                        zaškrtnete.</p>

                    <p>Seznam právních pravidel týkajících se práv na spravedlivý proces podle čl. 6 § 1 Evropské úmluvy
                        pro lidská práva není vyčerpávající. Je možné, že ve Vašem případě právo na spravedlivý proces
                        bylo
                        porušeno, i když nenajdete v dále uvedeném seznamu právní pravidlo, které by se Vašeho případu
                        konkrétně týkalo. Detailní seznam práv na spravedlivý proces podle čl. 6 § 1 Evropské úmluvy pro
                        lidská práva naleznete na webových stránkách Evropského soudu pro lidská práva
                        (https://www.echr.coe.int/).</p>

                    <p>Asociace pro ochranu práv a občanů a podnikatelů (Asociace HYDRA) se tímto shrnutím právních
                        pravidel týkajících se práva na spravedlivý proces podle čl. 6 § 1 Evropské úmluvy pro lidská
                        práva
                        snaží zpřístupnit svým členům rozsah i obsah těchto práv, které mají jako občané České
                        republiky,
                        jež Evropskou úmluvu o lidských právech ratifikovala. Evropský soud pro lidská práva akceptoval,
                        že
                        asociace mají rovněž nárok na ochranu podle článku 6 § 1 Evropského soudu pro lidská práva,
                        pokud
                        usilují o uznání konkrétních práv a zájmů svých členů (Gorraiz Lizarraga a další v. Španělsko, §
                        45)
                        nebo zvláštních práv, na která mají nárok jako právnické osoby, jako je právo na přístup k
                        informacím.</p>

                    <p>Asociace pro ochranu práv a občanů a podnikatelů (Asociace HYDRA) nicméně nepřebírá odpovědnost
                        za
                        výběr jednotlivých právních pravidel týkajících se práv na spravedlivý proces, které podle byly
                        podle Vás ve Vaše porušeny. Odpovědnost za jejich výběr nese člen asociace, který tento výběr
                        provedl.</p>

                    <p>Asociace dále poskytuje shrnutí argumentů upravených do formy formulářového návrhu stížnosti či
                        formulářového návrhu trestního oznámení, prostřednictvím kterého si lze stěžovat na případná
                        porušení pravidel týkající se práv na spravedlivý proces, jehož se dopustily vybrané orgány
                        veřejné
                        moci či zástupci vybraných právních profesí. Asociace nicméně nepřebírá právní odpovědnost za
                        obsah
                        uvedeného formulářového návrhu doplněného o vybraná a popsaná porušení právních pravidel
                        týkajících
                        se práv na spravedlivý proces.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Porozuměl jsem a souhlasím
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}