import {useEffect, useRef, useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import GenerateDocumentSelections from "./Utils/GenerateDocumentSelections";
import DownloadRTF from "./Utils/DownloadRTF";

export default function SoudceTrestniOznameni() {

    let {documentId: str_doc_id} = useParams();
    let documentId: number = parseInt(str_doc_id as string);

    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);
    const storedDocument = documents.getDocument(documentId);
    const [state, setState] = useState({doc: storedDocument});

    useEffect(() => {
        let subscriberId = documents.subscribeChange(changedDoc => {
            if (state.doc.id === changedDoc.id) {
                setState({doc: changedDoc});
            }
        });

        return () => {
            documents.unsubscribe(subscriberId);
        };
    });

    const downloadContents = useRef(null);

    return <>
        <Card className={"mt-3"} id={"form-identifications"}>
            <Card.Header>
                <Card.Title>
                    Vyplňte prosím následující identifikace pro vybraný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Soudce či soudci, jenž se porušení práv na spravedlivý proces
                            dopustil(i):</Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.soudci}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.soudci = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Soud či soudy, na kterém tento soudce či soudci působí:</Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.soudy}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.soudy = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení podatele:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.podatel}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.podatel = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení dotčené osoby, pokud se neshodují s podatelem:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.dotceni}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.dotceni = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
        <Card className={"mt-3"} id={"document-preview"}>
            <Card.Header>
                <Card.Title>
                    Náhled na generovaný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body ref={downloadContents}>
                <h1>Trestní oznámení</h1>
                <p>Já níže uvedený podávám tímto trestní oznámení na neznámého pachatele, a to z níže uvedených
                    důvodů.</p>
                <h2>1. OBSAH</h2>
                <p>Žádám, aby níže uvedená mnou namítaná porušení zásad spravedlivého procesu níže uvedeným soudcem či
                    soudci byla posouzena ve světle podezření ze spáchání níže uvedených trestných činů či případně
                    jiných
                    trestných činů. Zároveň žádám, aby se příslušný orgán činný v trestním řízení argumentačně vypořádal
                    se
                    všemi níže uvedenými porušeními práva na spravedlivý proces, k nimž došlo, jež by mohla založit
                    důvodné
                    podezření z jednoho či více uvedených trestných činů. V této souvislosti podotýkám, že podle dále
                    uvedené judikatury Ústavního soudu (např. I. ÚS 403/03, N 160/38 SbNU 277), argumentuje-li podatel
                    názory judikatury, musí se veřejný orgán s těmito názory argumentačně vypořádat (případně i tak, že
                    vysvětlí, proč je nepovažuje pro danou věc relevantní).</p>
                <h2>2. PRÁVNÍ ZÁKLAD</h2>
                <p>Podle čl. 2 odst. 3 Ústavy ČR a čl. 2 odst. 3 Listiny základních práv a svobod „státní moc slouží
                    všem
                    občanům a lze ji uplatňovat jen v případech, v mezích a způsoby, které stanoví zákon.“</p>
                <p>Podle čl. 10 Ústavy ČR „vyhlášené mezinárodní smlouvy, k jejichž ratifikaci dal Parlament souhlas a
                    jimiž
                    je Česká republika vázána, jsou součástí právního řádu; stanoví-li mezinárodní smlouva něco jiného
                    než
                    zákon, použije se mezinárodní smlouva.“</p>
                <p>Podle čl. 6 odst. 1 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou
                    a
                    vyhlášené ve sdělení č. 209/1992 Sb., má „každý právo na to, aby jeho záležitost byla spravedlivě,
                    veřejně a v přiměřené lhůtě projednána nezávislým a nestranným soudem, zřízeným zákonem, který
                    rozhodne
                    o jeho občanských právech nebo závazcích nebo o oprávněnosti jakéhokoli trestního obvinění proti
                    němu.“
                    Obdobně podle čl. 36 odst. 1 Listiny základních práv a svobod se každý může domáhat stanoveným
                    postupem
                    svého práva u nezávislého a nestranného soudu a ve stanovených případech u jiného orgánu. Podle čl.
                    81
                    Ústavy ČR soudní moc vykonávají jménem republiky nezávislé soudy.</p>
                <p>Podle konstantní judikatury Ústavního soudu (např. N 160/38 SbNU 277) se nezávislost rozhodování
                    obecných
                    soudů uskutečňuje v ústavním a zákonném procesněprávním a hmotněprávním rámci, jenž je představován
                    především principy řádného a spravedlivého procesu, jak vyplývají z čl. 36 a násl. Listiny, jakož i
                    z
                    čl. 1 Ústavy České republiky a čl. 6 odst. 1 Evropské úmluvy o ochraně lidských práv a svobod.
                    Soudci
                    proto musí principy řádného a spravedlivého procesu, jak jsou vymezeny v uvedených ustanoveních a na
                    nich navazující judikatuře, při své činnosti dodržovat, aby se vyloučila libovůle při jejich
                    rozhodování.</p>
                <p>Podle konstantní judikatury Evropského soudu pro lidská práva (ESLP) mají vnitrostátní orgány, včetně
                    soudů, v každém jednotlivém případě povinnost zajistit účastníkům sporu spravedlivý proces (např.
                    ESLP,
                    Dombo Beheer B.V. v. Nizozemí); tento závěr je sdílen i českými soudci: „Někdy u soudu nejsme
                    schopni
                    nastolit skutečnou spravedlnost, ale vždycky Vám musíme dát férové zacházení (Kateřina Šimáčková,
                    bývalá
                    soudkyně Ústavního soudu ČR, Lze skrze právo dosáhnout spravedlnosti? In: Youtube [online].
                    Zveřejněno
                    10.11.2014 [cit. 1.6.2018]. Dostupné z: https://www.youtube.com/watch?v=QDSSW2PokIM)</p>
                <p>Podle konstantní judikatury Ústavního soudu České republiky je notorietou, tj. skutečností, jíž si
                    musí
                    být vědom každý orgán nadaný veřejnou moci a aplikující právo, včetně soudů, že „mechanická aplikace
                    [práva] abstrahující, resp. neuvědomující si, a to buď úmyslně, nebo v důsledku nevzdělanosti, smysl
                    a
                    účel právní normy, činí z práva nástroj odcizení a absurdity." (I.ÚS 2920/09 ze dne 8. 2. 2011, N
                    14/60
                    SbNU 141, Pl. ÚS 33/97 (N 163/9 SbNU 399; 30/1998 Sb.)), přičemž takový orgán, včetně soudů, „není
                    absolutně vázán doslovným zněním zákonného ustanovení, nýbrž se od něj smí a musí odchýlit v
                    případě,
                    kdy to vyžaduje ze závažných důvodů […] některý z principů, jež mají svůj základ v ústavně
                    konformním
                    právním řádu jako významovém celku" (I.ÚS 2920/09, N 14/60 SbNU 141).</p>
                <p>Podle čl. 13 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou a
                    vyhlášené
                    ve sdělení č. 209/1992 Sb., „každý, jehož práva a svobody přiznané touto Úmluvou byly porušeny, musí
                    mít
                    účinné právní prostředky nápravy před národním orgánem, i když se porušení dopustily osoby při
                    plnění
                    úředních povinností.“</p>
                <p>Soudce je, kromě jiného, vázán povinnostmi uvedenými v ustanoveních §§ 79 odst. 1, 80 odst. 1 – 4, 82
                    odst. 1 zákona č. 6/2002 Sb. o soudech a soudcích, v platném znění.</p>
                <h2>3. PORUŠENÍ PRÁV NA SPRAVEDLIVÝ PROCES</h2>
                <p><strong>Níže uvedený soudce či soudci, jako úřední osoby ve smyslu § 127 trestního zákoníku, se podle
                    mého názoru dopustili následujících porušení práv na spravedlivý proces podle čl. 36 Listiny
                    základních
                    práv a svobod a čl. 6 odst. 1 Evropské úmluvy pro lidská práva, a případně též odst. 2 a 3 v případě
                    trestních aspektů, tak jak jsou vymezena v příslušné judikatuře Evropského soudu pro lidská práva
                    vykládající toto ustanovení, která je u každého jednotlivého níže uvedeného porušení práva na
                    spravedlivý proces vždy zmíněna. Tato porušení podle mého názoru vyvolávají důvodné podezření z
                    naplnění
                    skutkové podstaty trestného činu zneužití pravomoci úřední osoby podle § 329 zákona č. 40/2009 Sb.,
                    trestního zákoníku, v platném znění:</strong></p>
                <p>„(1) Úřední osoba, která v úmyslu způsobit jinému škodu nebo jinou závažnou újmu anebo opatřit sobě
                    nebo
                    jinému neoprávněný prospěch</p>
                <p>a) vykonává svou pravomoc způsobem odporujícím jinému právnímu předpisu,</p>
                <p>b) překročí svou pravomoc nebo</p>
                <p>c) nesplní povinnost vyplývající z její pravomoci,</p>
                <p>bude potrestána odnětím svobody na jeden rok až pět let nebo zákazem činnosti.“</p>
                <p><strong>Uvedený soudce či soudci podle mého názoru v důsledku dále uvedených porušení vykonávali svou
                    pravomoc způsobem odporujícím právnímu předpisu, konkrétně čl. 6 odst. 1 Evropské úmluvy na ochranu
                    lidských práv a svobod, jež je v smyslu čl. 10 Ústavy ČR součástí právního řádu České republiky a má
                    přednost před zákonem či jiným právním předpisem, či nesplnil(i) svou povinnost vyplývající z jim
                    svěřené pravomoci, když v dále uvedených konkrétních případech nezajistil(i) spravedlivý proces, k
                    čemuž
                    je každý soudce v každém jednotlivém případě povinen na základě čl. 6 odst. 1 Evropské úmluvy na
                    ochranu
                    lidských práv a svobod (viz např. rozhodnutí ESLP, ve věci Dombo Beheer B.V. v. Nizozemí, série A,
                    č.
                    274) a na základě ustanovení §§ 79 odst. 1, 80 odst. 1 – 4, 82 odst. 1 zákona č. 6/2002 Sb. o
                    soudech a
                    soudcích, v platném znění.</strong></p>
                <p><strong>Tato důvodná podezření mne proto vedou k podání tohoto trestního oznámení pro podezření z
                    naplnění skutkových podstat výše uvedeného trestného činu či případně jiného trestného činu či
                    činů.</strong></p>

                {GenerateDocumentSelections(state.doc)}
                <p><strong>Vysvětlení: Očíslované věty či části vět představují právní pravidla týkající se práv na
                    spravedlivý proces vyjádřená v rozsudcích Evropského soudu pro lidská práva. Odkazy ve formátu
                    „jméno v.
                    (proti) název státu, § číslo či §§ čísla“, například „Cipolletta v. Italy §§ 33-37“ představují
                    odkazy
                    na rozsudky (judikáty) Evropského soudu pro lidská práva ve Štrasburku, ve kterých byla jednotlivá
                    právní pravidla formulována. Plný obsah těchto rozsudků lze dohledat zadáním jejich názvu v databázi
                    rozsudků Evropského soudu pro lidská práva ve Štrasburku HUDOC na internetové adrese:
                    https://hudoc.echr.coe.int/</strong></p>

                <h2>4. OSOBA ČI OSOBY, JEŽ SE UVEDENÝCH PORUŠENÍ DOPUSTILY</h2>
                <p><strong>Soudce či soudci, jenž se podle podatele výše uvedených porušení práv na spravedlivý proces
                    dopustil(i):</strong></p>
                <p>{state.doc.generatorSettings.soudci}</p>
                <p><strong>Soud či soudy, na kterém tento soudce či soudci působí: </strong></p>
                <p>{state.doc.generatorSettings.soudy}</p>
                <h2>5. IDENTIFIKACE PODATELE A DOTČENÝCH OSOB, JSOU-LI OD PODATELE ODLIŠNÉ</h2>
                <p><strong>Jméno a příjmení podatele:</strong></p>
                <p>{state.doc.generatorSettings.podatel}</p>
                <p><strong>Jméno a příjmení osoby či osob dotčené výše uvedenými porušeními, pokud jsou odlišné od
                    podatele:</strong></p>
                <p>{state.doc.generatorSettings.dotceni}</p>
                <h2>6. ADRESÁT A DALŠÍ DOTČENÉ INSTITUCE </h2>
                <p><strong>Adresát:</strong></p>
                <p>Vrchní státní zastupitelství v Praze</p>
                <p>nám. Hrdinů 1300,</p>
                <p>140 00 Praha 4-Nusle</p>
                <p>podatelna@vsz.pha.justice.cz</p>
                <br/>
                <p>Vzhledem k tomu, že orgány činné v trestním řízení, zejména pak státní zástupci, jež by byli místně
                    příslušní v případném řízení vyvolaným tímto trestním oznámením, mohou být v důsledku výkonu svých
                    funkcí v častém kontaktu se soudcem či soudci, jež jsou tímto trestním oznámením dotčeni, v důsledku
                    čehož by mohlo dojít je koluzi mezi uvedenými orgány činnými v trestním řízení a soudcem či soudci,
                    jež
                    jsou tímto trestním oznámením dotčeni, je toto trestní oznámení podáváno na Vrchní státní
                    zastupitelství
                    v Praze s žádostí, aby na základě svých znalostí fungování justiční a policejní soustavy přidělilo
                    vyřízení tohoto trestního oznámení takovému státnímu zástupci a policejnímu orgánu, u nichž podle
                    jeho
                    znalostí a zkušeností nehrozí nebezpečí koluze s uvedeným soudcem či soudci.</p>
                <p><strong>Na vědomí</strong></p>
                <p>Kancelář prezidenta republiky
                    <br/>Hrad I. nádvoří č. p. 1
                    <br/>Hradčany
                    <br/>119 08 Praha 1
                </p>
                <p>posta@hrad.cz</p><br/>
                <p>Vzhledem k ustanovení § 76 odst. 1 zákona o soudech a soudcích, podle kterého „pro činy spáchané při
                    výkonu funkce soudce nebo v souvislosti s výkonem této funkce lze soudce trestně stíhat nebo vzít do
                    vazby jen se souhlasem prezidenta republiky,“ přičemž orgány činné v trestním řízení opatřují
                    souhlas
                    přímo od prezidenta republiky a musí jej obdržet dříve, než vůbec dojde k prvnímu úkonu, kterým se
                    trestní řízení zahájí, je toto trestní oznámení dáváno i na vědomí prezidentu republiky
                    prostřednictvím
                    jeho kanceláře.</p>
            </Card.Body>
            <Card.Footer className={"text-end"} id={"download-buttons"}>
                <Button variant={"success"} className={"m-2"}
                        onClick={() => DownloadRTF(downloadContents, state.doc.id)}>Stáhnout jako Word</Button>
            </Card.Footer>
        </Card>
    </>
}