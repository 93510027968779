import {useEffect, useRef, useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import {useParams} from "react-router-dom";
import GenerateDocumentSelections from "./Utils/GenerateDocumentSelections";
import DownloadRTF from "./Utils/DownloadRTF";

export default function AdvokatKarnyPodnet() {

    let {documentId: str_doc_id} = useParams();
    let documentId: number = parseInt(str_doc_id as string);

    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);
    const storedDocument = documents.getDocument(documentId);
    const [state, setState] = useState({doc: storedDocument});

    useEffect(() => {
        let subscriberId = documents.subscribeChange(changedDoc => {
            if (state.doc.id === changedDoc.id) {
                setState({doc: changedDoc});
            }
        });

        return () => {
            documents.unsubscribe(subscriberId);
        };
    });

    const downloadContents = useRef(null);

    return <>
        <Card className={"mt-3"} id={"form-identifications"}>
            <Card.Header>
                <Card.Title>
                    Vyplňte prosím následující identifikace pro vybraný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Státní úředník či úředníci, jenž se podle podatele výše uvedených porušení práv na
                            spravedlivý proces dopustil(i), včetně jejich funkce:</Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.statniUrednik}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.statniUrednik = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Orgán veřejné moci, jehož součástí je uvedený úředník či úředníci: </Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.organVerejneMoci}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.organVerejneMoci = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení podatele:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.podatel}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.podatel = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení dotčené osoby, pokud se neshodují s podatelem:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.dotceni}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.dotceni = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
        <Card className={"mt-3"} id={"document-preview"}>
            <Card.Header>
                <Card.Title>
                    Náhled na generovaný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body ref={downloadContents}>
                <h1>Podnět ministru spravedlnosti na podání návrhu na zahájení kárného řízení proti advokátovi či
                    advokátům </h1>
                <p>Já níže uvedený podávám tímto podnět ministru spravedlnosti na podání návrhu na zahájení kárného
                    řízení proti advokátovi či advokátům, a to z níže uvedených důvodů.</p>
                <h2>1. OBSAH PODNĚTU</h2>
                <p>Žádám ministra spravedlnosti, aby níže uvedená mnou namítaná porušení práv spravedlivého procesu ze
                    strany níže uvedeného advokáta či advokátů posoudil a na základě tohoto posouzení podal návrh na
                    zahájení kárného řízení proti tomuto advokátovi či advokátům. </p>
                <p>Dále žádám ministra spravedlnosti, aby mě vyrozuměl o tom, jakým způsobem tento můj podnět
                    posoudil. </p>
                <p>Nedojde-li ze strany ministra spravedlnosti k podání návrhu na zahájení kárného řízení proti níže
                    uvedenému advokátovi či advokátům, žádám, aby se ve vyrozumění, jenž mi zašle, argumentačně
                    vypořádal se všemi níže uvedenými porušeními práva na spravedlivý proces, jichž se daný advokát či
                    advokáti dopustil či dopustili. V této souvislosti podotýkám, že podle dále uvedené judikatury
                    Ústavního soudu (např. I. ÚS 403/03, N 160/38 SbNU 277), argumentuje-li účastník názory judikatury,
                    musí se veřejný orgán s těmito názory argumentačně vypořádat (případně i tak, že vysvětlí, proč je
                    nepovažuje pro danou věc relevantní). </p>
                <p>Rovněž žádám, aby zároveň toto vyrozumění kromě mé osoby bylo zasláno rovněž na vědomí níže uvedeným
                    útvarům Evropské komise, Evropského parlamentu a Rady Evropy, jež jsou odpovědné za dohled nad
                    dodržováním práva na spravedlivý proces podle Evropské úmluvy na ochranu lidských práv a svobod v
                    členských státech Rady Evropy a Evropské unie. Rovněž žádám, aby toto vyrozumění bylo zasláno na
                    vědomí České advokátní komoře a Radě evropských advokátních komor.</p>
                <p>Konečně žádám, aby mi bylo zmíněné vyrozumění zasláno v zákonné lhůtě, tedy nejpozději do 30 dnů od
                    přijetí tohoto mého podnětu, nebo v případě, že se uvedené posouzení bude jevit jako složité, abych
                    byl v této zákonné lhůtě informován o prodloužení lhůty k posouzení na nejvýše 60 dnů.</p>
                <h2>2. PRÁVNÍ ZÁKLAD PODNĚTU</h2>
                <p>Podle čl. 2 odst. 3 Ústavy ČR a čl. 2 odst. 3 Listiny základních práv a svobod „státní moc slouží
                    všem občanům a lze ji uplatňovat jen v případech, v mezích a způsoby, které stanoví zákon.“</p>
                <p>Podle čl. 10 Ústavy ČR „vyhlášené mezinárodní smlouvy, k jejichž ratifikaci dal Parlament souhlas a
                    jimiž je Česká republika vázána, jsou součástí právního řádu; stanoví-li mezinárodní smlouva něco
                    jiného než zákon, použije se mezinárodní smlouva.“</p>
                <p>Podle čl. 6 odst. 1 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou
                    a vyhlášené ve sdělení č. 209/1992 Sb., má „každý právo na to, aby jeho záležitost byla spravedlivě,
                    veřejně a v přiměřené lhůtě projednána nezávislým a nestranným soudem, zřízeným zákonem, který
                    rozhodne o jeho občanských právech nebo závazcích nebo o oprávněnosti jakéhokoli trestního obvinění
                    proti němu.“ </p>
                <p>Každý občan členské země, jež přistoupila k Evropské úmluvě o ochraně základních práv a svobod, je
                    povinen respektovat základní práva a svobody jiných osob, jež jsou rovněž takovými občany, zejména
                    pak osoby, jež vykonávají profesi, jako je například profese advokáta, jež má přispět k naplňování
                    základních práv a svobod obsažených v uvedené Úmluvě, zejména pak práva na spravedlivý proces před
                    soudy a dalšími orgány v přímé působnosti čl. 6 odst. 1 této Úmluvy.</p>
                <p>Podle § 3 odst. 1 zákona č. 85/1996 Sb., o advokacii, v platném znění (zákon o advokacii) je advokát
                    při poskytování právních služeb nezávislý, přičemž je vázán právními předpisy a v jejich mezích
                    příkazy klienta. Podle § 16 odst. 1 zákona o advokacii je advokát povinen chránit a prosazovat práva
                    a oprávněné zájmy klienta a řídit se jeho pokyny, nicméně pokyny klienta však není vázán, jsou-li v
                    rozporu s právním nebo stavovským předpisem, o čemž je advokát povinen klienta přiměřeně poučit.
                    Podle odst. 2 téhož ustanovení je při výkonu advokacie advokát povinen jednat čestně a svědomitě a
                    zároveň je povinen využívat důsledně všechny zákonné prostředky a v jejich rámci uplatnit v zájmu
                    klienta vše, co podle svého přesvědčení pokládá za prospěšné. Podle § 17 téhož zákona advokát
                    postupuje zejména při výkonu advokacie tak, aby nesnižoval důstojnost advokátního stavu a za tím
                    účelem je zejména povinen dodržovat pravidla profesionální etiky a pravidla soutěže, které stanoví
                    stavovský předpis.</p>
                <p>Podle § 4 odst. 1 Usnesení představenstva České advokátní komory č. 1/1997 Věstníku, ze dne 31. října
                    1996, kterým se stanoví pravidla profesionální etiky a pravidla soutěže advokátů České republiky
                    (dále jen Etický kodex advokátů) je advokát je všeobecně povinen poctivým, čestným a slušným
                    chováním přispívat k důstojnosti a vážnosti advokátního stavu a podle odst. 3 téhož ustanovení jsou
                    projevy advokáta v souvislosti s výkonem advokacie jsou věcné, střízlivé, a nikoliv vědomě
                    nepravdivé. Dále podle čl. 17 odst. 2 téhož Etického kodexu advokátů nesmí advokát v řízení uvádět
                    údaje, ani navrhovat důkazy, o nichž ví, že jsou nepravdivé nebo klamavé, a to ani na příkaz
                    klienta. Podle odst. 3 téhož Etického kodexu advokátů je advokát povinen v řízení jednat poctivě,
                    respektovat zákonná práva ostatních účastníků řízení a chovat se k nim i k ostatním osobám
                    zúčastněným na řízení tak, aby nebyla snižována jejich důstojnost ani důstojnost advokátního stavu.
                    Podle čl. 18 Etického kodexu advokátů byl-li advokát k tomu vyzván, je povinen podílet se v
                    přiměřeném rozsahu na projektech směřujících k prosazování nebo obhajobě lidských práv a svobod, a
                    to i bez nároku na odměnu, ledaže mu v tom brání vážné důvody, a za stejných podmínek je povinen
                    podílet se na výzvu Komory na projektech, jejichž cílem je uskutečňování principů demokratického
                    právního státu nebo zdokonalení právního řádu České republiky.</p>
                <p>Krom uvedeného, Rada evropských advokátních komor (CCBE) přijala dva základní dokumenty, jednak
                    Chartu základních principů evropské advokacie, která byla přijata na plenárním zasedání v Bruselu
                    dne 24. listopadu 2006, a jednak Etický kodex advokátů Evropské unie přijatý na plenárním zasedání
                    CCBE 28. října 1988 a následně byl novelizován během plenárních zasedání CCBE 28. listopadu 1998, 6.
                    prosince 2002 a 19. května 2006.</p>
                <p>Charta základních principů evropské advokacie zahrnuje seznam deseti základních principů, které jsou
                    společné pro národní i mezinárodní pravidla regulující advokacii, přičemž jedním z tímto základních
                    principů, je, kromě jiného, […] písm. (i): respektování zásady právního státu a řádného výkonu
                    spravedlnosti. Komentář CCBE k této zásadě uvádí, že advokát může úspěšně zastupovat svého klienta,
                    jen pokud se na advokáta jako spolehlivého prostředníka při řádném výkonu spravedlnosti může
                    spolehnout jak soud, tak i třetí strana. Podle preambule této charty jsou evropští advokáti těmito
                    principy vázáni, neboť nezbytné pro řádný výkon spravedlnosti, přístup ke spravedlnosti a právo na
                    spravedlivý proces, tak jak to stanoví Evropská úmluva o lidských právech. </p>
                <p>Etický kodex advokátů Evropské unie (dále jen Etických kodex evropských advokátů CCBE) zavazuje v
                    členských státech EU všechny advokáty, jež jsou členy advokátních komor členských států Evropské
                    unie, včetně České republiky a českých advokátů, ve všech přeshraničních aktivitách v rámci EU,
                    Evropského hospodářského prostoru a Švýcarské konfederace, jakožto i v přidružených či
                    pozorovatelských zemích.</p>
                <p>Podle čl. 1.1 tohoto Etického kodexu evropských advokátů CCBE advokát musí sloužit zájmům
                    spravedlnosti, jakož i zájmům toho, jehož práva a svobody hájí a nejedná se jen o povinnost advokáta
                    hájit klientovy záležitosti, ale být také klientovým poradcem, přičemž respekt k profesním
                    advokátním pravidlům je základní podmínkou právního státu a demokracie ve společnosti.“ Tato úloha
                    tedy na advokáta klade celou řadu zákonných a morálních povinností, a to kromě jiného vůči (…)
                    veřejnosti, pro kterou je existence svobodné a nezávislé profese vázané dodržováním pravidel
                    stanovených v rámci advokacie zásadním prostředkem ochrany lidských práv vůči státní moci a jiným
                    zájmům ve společnosti. Podle čl. 4.2. (Čestné vedení řízení Etického kodexu evropských advokátů)
                    CCBE musí mít advokát vždy patřičně na zřeteli čestné vedení řízení, přičemž podle komentáře CCBE
                    toto ustanovení aplikuje obecnou zásadu, že ve sporném řízení se advokát nesmí pokoušet nečestně
                    oklamat svého protivníka.</p>
                <p>Z uvedeného vyplývá, že advokát jako osoba nadaná právními předpisy privilegiem bránit práva a
                    základní svobody jednotlivců jako jejich klientů, není oprávněn zároveň dopouštět se jednání, jež
                    navádějí soudy či soudní exekutory či jiné orgány v působnosti čl. 6 odst. 1 Evropské úmluvy na
                    ochranu lidských práv a svobod k tomu, aby porušili práva třetích osob, resp. protistrany, na
                    spravedlivý proces podle tohoto ustanovení nebo aby svým jednáním k takovému porušení významně
                    přispěli.</p>
                <p>Podle § 32 odst. 1 a 2 zákona o advokacii jsou advokát a advokátní koncipient kárně odpovědní za
                    kárné provinění, přičemž kárným proviněním je závažné nebo opětovné zaviněné porušení povinností
                    stanovených advokátovi nebo advokátnímu koncipientovi tímto nebo zvláštním zákonem s výjimkou zákona
                    o některých opatřeních proti legalizaci výnosů z trestné činnosti a financování terorismu nebo
                    stavovským předpisem. Podle § 51 odst. 1 téhož zákona je ministr spravedlnosti oprávněn vystupovat v
                    kárném řízení podle tohoto zákona jako kárný žalobce.</p>
                <h2>3. PORUŠENÍ ODŮVODŇUJÍCÍ PODÁNÍ NÁVRHU NA ZAHÁJENÍ KÁRNÉHO ŘÍZENÍ</h2>
                <p><strong>Níže uvedený advokát či advokáti se podle mého názoru dopustili u podatele či u níže uvedené
                    osoby či osob odlišných od podatele následujících porušení práv spravedlivý proces podle čl. 6 odst.
                    1 Evropské úmluvy na ochranu lidských práv a svobod, tak jak jsou vymezeny v příslušné judikatuře
                    Evropského soudu pro lidská práva vykládající toto ustanovení, která je u příslušného porušení vždy
                    zmíněna. Těmito porušeními se níže uvedený advokát či a advokáti dopustili porušení svých povinností
                    podle §§ 3 odst. 1 a 16 odst. 1 a 2 zákona o advokacii, jakož i možného porušení § 17 téhož zákona
                    ve spojení s porušením čl. § 4 odst. 1 a 3 českého Etického kodexu advokátů, v platném znění, a/nebo
                    čl. 17 odst. 2 a/nebo 3 tohoto kodexu, jakož i případně ve spojení s porušením čl. 1.1. a 4.2.
                    Etického kodexu advokátů EU v tom smyslu, že svým jednáním navedli soudní orgán či exekutora práva
                    podatele či níže uvedených dotčených osob na spravedlivý proces v rozporu s čl. 6 odst. 1 Evropské
                    úmluvy na ochranu lidských práv nebo významně přispěli k tomu, že došlo k dále popsaným porušením
                    práva podatele či níže uvedených dotčených osob na spravedlivý proces v rozporu s čl. 6 odst. 1
                    Evropské úmluvy na ochranu lidských práv a svobod. To je podle mého názoru důvodem pro podání na
                    zahájení kárného řízení proti níže uvedenému advokátovi či advokátům v důsledku podezření, že se
                    uvedený advokát či advokáti dopustili jednoho či více kárných provinění podle zákona o
                    advokacii.</strong></p>
                {GenerateDocumentSelections(state.doc)}
                <p><strong>Vysvětlení: Očíslované věty či části vět představují právní pravidla týkající se práv na
                    spravedlivý proces vyjádřená v rozsudcích Evropského soudu pro lidská práva. Odkazy ve formátu
                    „jméno v.
                    (proti) název státu, § číslo či §§ čísla“, například „Cipolletta v. Italy §§ 33-37“ představují
                    odkazy
                    na rozsudky (judikáty) Evropského soudu pro lidská práva ve Štrasburku, ve kterých byla jednotlivá
                    právní pravidla formulována. Plný obsah těchto rozsudků lze dohledat zadáním jejich názvu v databázi
                    rozsudků Evropského soudu pro lidská práva ve Štrasburku HUDOC na internetové adrese:
                    https://hudoc.echr.coe.int/</strong></p>
                <h2>4. OSOBA ČI OSOBY, JEŽ SE UVEDENÝCH PORUŠENÍ DOPUSTILI</h2>
                <p><strong>Advokát či advokáti, jenž se podle podatele výše uvedených porušení práv na spravedlivý
                    proces dopustil(i), včetně jejich registračního čísla u České advokátní komory:</strong></p>
                <p>{state.doc.generatorSettings.advokati}</p>
                <h2>5. IDENTIFIKACE PODATELE A DOTČENÝCH OSOB, JSOU-LI OD PODATELE ODLIŠNÉ</h2>
                <p><strong>Jméno a příjmení podatele:</strong></p>
                <p>{state.doc.generatorSettings.podatel}</p>
                <p><strong>Osoba či osoby dotčené výše uvedenými porušeními, pokud jsou odlišné od podatele:</strong>
                </p>
                <p>{state.doc.generatorSettings.dotceni}</p>
                <h2>6. ADRESÁT PODNĚTU A DALŠÍ INSTITUCE DOTČENÉ TÍMTO PODNĚTEM</h2>
                <p><strong>Adresát:</strong></p>
                <p>
                    Ministr spravedlnosti
                    <br/>Ministerstvo spravedlnosti ČR
                    <br/>Vyšehradská 16
                    <br/>128 10 Praha 2
                    <br/>posta@msp.justice.cz
                </p>
                <p><strong>Na vědomí</strong></p>
                <p>Evropské komisi, GŘ Spravedlnost a spotřebitelé, Ředitelství D - Základní práva a právní stát, Odbor
                    C2: Politiky v oblasti základních práv: JUST-C-DIR@ec.europa.eu, JUST-C-SEC@ec.europa.eu,,
                    JUST-C2@ec.europa.eu</p>
                <p>Výboru pro občanské svobody, spravedlnost a vnitřní věci Evropského parlamentu,
                    libe-secretariat@ep.europa.eu</p>
                <p>Řídicímu výboru pro lidská práva Výboru ministrů Rady Evropy: DGI-CDDH@coe.int,
                    DGI-CDDH-Reform@coe.int </p>
                <p>Česká advokátní komora, Národní 16, 110 00 Praha 1, sekretariat@cak.cz</p>
                <p>Rada evropských advokátních komor, Rue Joseph II, 40/8 - B-1000 Brussels, ccbe@ccbe.eu</p>
                <p>Podatel dává tento podnět na vědomí Evropské komisi a Evropskému parlamentu, neboť v podnětu popsané
                    porušení podle jeho názoru představuje rovněž porušení Českou republikou obecných zásad práva
                    Evropské unie tvořených podle ustanovení čl. 6 odst. 3 Smlouvy o Evropské unii základními právy,
                    která jsou zaručena Evropskou úmluvou o ochraně lidských práv a základních svobod: konkrétně pak v
                    tomto ohledu porušení základního práva na právní jistotu ohledně řádného výkonu spravedlnosti, jež
                    je jedním ze základních práv zaručených Evropskou úmluvou o ochraně lidských práv a základních
                    svobod v každé demokratické společnosti (viz např. ESLP, Beian proti Rumunsku (č. 1), § 39), ESLP,
                    Pretto a ostatní proti Itálii, § 21) a rovněž základní hodnotou Evropské unie zmíněnou v čl. 2
                    Smlouvy o Evropské unii; stejně tak představuje toto porušení porušení základních práv, která
                    vyplývají z ústavních tradic společných členským státům EU, včetně ČR: konkrétně pak v tomto ohledu
                    porušení základního práva vyplývajícího z ústavních ČR a ostatních členských států EU, na výslovné a
                    konkrétní zdůvodnění argumentu týkajícího se porušení konkrétního aspektu práva na spravedlivý
                    proces (viz např. Ústavní soud ČR, N 160/38 SbNU 277).</p>
                <p>Podatel dává tento podnět rovněž na vědomí Řídicímu výboru pro lidská práva Výboru ministrů Rady
                    Evropy, aby tento výbor upozornil na nedodržování Evropské úmluvy na ochranu lidských práv a svobod
                    ze strany České republiky jako státu, jenž se přesto k dodržování této Úmluvy zavázal, a umožnil
                    tímto případné spuštění příslušných dozorových či sankčních mechanismů Výboru ministrů Rady Evropy
                    či jiných orgánů Rady Evropy vůči České republice v důsledku popsaného nedodržování uvedené Úmluvy
                    Českou republikou.</p>
                <p>V případě, že ministr spravedlnosti nevyrozumí podatele o tom, jak naložil s touto jeho žádostí,
                    a/nebo toto vyrozumění nedá na vědomí Řídicímu výboru pro lidská práva Výboru ministrů Rady Evropy
                    ve výše uvedené lhůtě 30 dnů či ve složitějších případech 60 dnů, podatel zároveň žádá Řídicí výbor,
                    aby po uplynutí uvedené lhůty vyzval generálního tajemníka Rady Evropy, aby si podle čl. 52 Evropské
                    úmluvy pro lidská práva vyžádal u příslušných orgánů České republiky vysvětlení o způsobu, jakým
                    české právo zajišťuje účinné provádění ustanovení čl. 6 § 1 Úmluvy vzhledem k výše popsaným
                    porušením tohoto ustanovení, jichž se uvedený advokát či advokáti dopustil či dopustili; zejména pak
                    žádá Řídicí výbor, aby tlumočil generálnímu tajemníkovi Rady Evropy požadavek, aby si generální
                    tajemník Rady Evropy vyžádal vysvětlení, jaká opatření byla proti výše uvedenému advokátovi či
                    advokátům přijata, aby se taková porušení v budoucnu již v České republice neopakovala a bylo
                    zajištěno účinné provádění ustanovení čl. 6 § 1 Úmluvy ve vztahu nejen k dotčeným advokátům, ale i
                    všem advokátům působícím v České republice.</p>
                <p>Konečně, podatel dává tento podnět na vědomí Radě evropských advokátních komor, neboť konzistentní
                    aplikace povinnosti advokátů dodržovat právo na spravedlivý proces třetích osob obsaženého v čl. 6
                    odst. 1 Evropské úmluvy na ochranu základních práv a svobod v členských zemích Rady Evropy i
                    členských států Evropské unie, jakož i přehled o způsobech aplikace této povinnosti v těchto zemích
                    je předmětem aktivit Rady evropských advokátních komor bez ohledu na to, zda k uvedené aplikaci
                    dochází v čistě vnitrostátním kontextu nebo v kontextu přeshraničního poskytování advokátních služeb
                    v rámci Evropské unie.</p>
            </Card.Body>
            <Card.Footer className={"text-end"} id={"download-buttons"}>
                <Button variant={"success"} className={"m-2"}
                        onClick={() => DownloadRTF(downloadContents, state.doc.id)}>Stáhnout jako Word</Button>
            </Card.Footer>
        </Card>
    </>;
}