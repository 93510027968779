import PssDocument from "../../Model/PssDocument";
import GetTypeDatabase from "../../Database/FindDatabase";
import {FindChoiceById} from "../../Model/PssDatabase";

export default function GenerateDocumentSelections(storedDocument: PssDocument): JSX.Element {
    const typeDatabase = GetTypeDatabase(storedDocument.type);

    return <ul>
        {Array.from(storedDocument.choices.keys()).map((choice_id) => {
            const found_choice = FindChoiceById(choice_id, typeDatabase);
            return <li key={choice_id}>
                {found_choice?.label}
                <br/>
                {found_choice?.description}
                <br/><br/>
                <strong>Popis tohoto porušení:</strong>
                <br/>
                {storedDocument.getChoiceDescription(choice_id)}
                <br/>
                <br/>
            </li>;
        })}
    </ul>
}