import {useEffect, useRef, useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import {useParams} from "react-router-dom";
import GenerateDocumentSelections from "./Utils/GenerateDocumentSelections";
import DownloadRTF from "./Utils/DownloadRTF";

export default function UrednikSamospravyPodnet() {

    let {documentId: str_doc_id} = useParams();
    let documentId: number = parseInt(str_doc_id as string);

    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);
    const storedDocument = documents.getDocument(documentId);
    const [state, setState] = useState({doc: storedDocument});

    useEffect(() => {
        let subscriberId = documents.subscribeChange(changedDoc => {
            if (state.doc.id === changedDoc.id) {
                setState({doc: changedDoc});
            }
        });

        return () => {
            documents.unsubscribe(subscriberId);
        };
    });

    const downloadContents = useRef(null);

    return <>
        <Card className={"mt-3"} id={"form-identifications"}>
            <Card.Header>
                <Card.Title>
                    Vyplňte prosím následující identifikace pro vybraný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Úředník či úředníci územně samosprávného celku, jež se podle podatele výše uvedených
                            porušení práv na spravedlivý proces dopustil(i), včetně uvedení jejich funkce:</Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.urednikSamospravy}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.urednikSamospravy = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Název obce či kraje, jehož součástí je uvedený úředník či úředníci úředníkem či
                            úředníky územně samosprávného celku: </Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.uradSamosprava}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.uradSamosprava = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení podatele:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.podatel}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.podatel = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení dotčené osoby, pokud se neshodují s podatelem:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.dotceni}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.dotceni = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
        <Card className={"mt-3"} id={"document-preview"}>
            <Card.Header>
                <Card.Title>
                    Náhled na generovaný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body ref={downloadContents}>
                <h1>Podnět k zahájení řízení či přijetí příslušných opatření v rámci zaměstnaneckého či obdobného poměru
                    daného úředníka či úředníků vůči územně samosprávnému celku proti tomuto úředníkovi či úředníkům
                    územní správy za neřádný výkon veřejné správy</h1>
                <p>Já níže uvedený podávám tímto podnět k zahájení řízení či přijetí příslušných opatření v rámci
                    zaměstnaneckého či obdobného poměru daného úředníka či úředníků vůči územně samosprávnému celku
                    proti tomuto úředníkovi či úředníkům územní správy za neřádný výkon veřejné správy, a to z níže
                    uvedených důvodů.</p>
                <h2>1. OBSAH PODNĚTU</h2>
                <p>Žádám, aby níže uvedená mnou namítaná porušení zásad spravedlivého procesu níže uvedeným úředníkem či
                    úředníkům byla posouzena a na základě tohoto posouzení bylo případně zahájeno řízení nebo byla
                    přijata či přijetí příslušná opatření v rámci zaměstnaneckého či obdobného poměru daného úředníka či
                    úředníků vůči územně samosprávnému celku proti tomuto úředníkovi či úředníkům územní samosprávy za
                    neřádný výkon státní správy. </p>
                <p>Dále žádám, abych byl vyrozuměn o tom, jakým způsobem tento můj podnět byl posouzen. </p>
                <p>Nedojde-li k zahájení řízení či přijetí příslušných opatření v rámci zaměstnaneckého či obdobného
                    poměru daného úředníka či úředníků vůči územně samosprávnému celku proti tomuto úředníkovi či
                    úředníkům územní správy za neřádný výkon veřejné správy, žádám, aby vyrozumění, jenž mi bude
                    zasláno, obsahovalo argumenty vypořádávající se všemi níže uvedenými porušeními práva na spravedlivý
                    proces, jichž se uvedený úředník či úředníci dopustil či dopustili. V této souvislosti podotýkám, že
                    podle dále uvedené judikatury Ústavního soudu (např. I. ÚS 403/03, N 160/38 SbNU 277),
                    argumentuje-li účastník názory judikatury, musí se veřejný orgán s těmito názory argumentačně
                    vypořádat (případně i tak, že vysvětlí, proč je nepovažuje pro danou věc relevantní). </p>
                <p>Rovněž žádám, aby zároveň toto vyrozumění kromě mé osoby bylo zasláno rovněž na vědomí níže uvedeným
                    útvarům Evropské komise, Evropského parlamentu a Rady Evropy, jež jsou odpovědné za dohled nad
                    dodržováním práva na spravedlivý proces podle Evropské úmluvy na ochranu lidských práv a svobod v
                    členských státech Rady Evropy a Evropské unie.</p>
                <p>Konečně žádám, aby mi bylo zmíněné vyrozumění zasláno v zákonné lhůtě, tedy nejpozději do 30 dnů od
                    přijetí tohoto mého podnětu, nebo v případě, že se uvedené posouzení bude jevit jako složité, abych
                    byl v této zákonné lhůtě informován o prodloužení lhůty k posouzení na nejvýše 60 dnů.</p>
                <h2>2. PRÁVNÍ ZÁKLAD PODNĚTU</h2>
                <p>Podle čl. 2 odst. 3 Ústavy ČR a čl. 2 odst. 3 Listiny základních práv a svobod „státní moc slouží
                    všem občanům a lze ji uplatňovat jen v případech, v mezích a způsoby, které stanoví zákon.“</p>
                <p>Podle čl. 10 Ústavy ČR „vyhlášené mezinárodní smlouvy, k jejichž ratifikaci dal Parlament souhlas a
                    jimiž je Česká republika vázána, jsou součástí právního řádu; stanoví-li mezinárodní smlouva něco
                    jiného než zákon, použije se mezinárodní smlouva.“</p>
                <p>Podle čl. 6 odst. 1 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou
                    a vyhlášené ve sdělení č. 209/1992 Sb., má „každý právo na to, aby jeho záležitost byla spravedlivě,
                    veřejně a v přiměřené lhůtě projednána nezávislým a nestranným soudem, zřízeným zákonem, který
                    rozhodne o jeho občanských právech nebo závazcích nebo o oprávněnosti jakéhokoli trestního obvinění
                    proti němu,“ přičemž řízením se ve smyslu čl. 6 odst. 1 Evropské úmluvy o ochraně lidských práv a
                    svobod může rozumět i řízení před jiným orgánem než je soud. Úředník či úředníci územního
                    samosprávného celku, jež jednají jako součást takového jiného orgánu, proto musí principy řádného a
                    spravedlivého procesu, jak jsou vymezeny v uvedených ustanoveních Evropské úmluvy o ochraně lidských
                    práv a svobod a na nich navazující judikatuře Evropského soudu pro lidská práva, při své činnosti
                    dodržovat, aby se vyloučila libovůle při jejich rozhodování.</p>
                <p>Podle konstantní judikatury Evropského soudu pro lidská práva (ESLP) mají vnitrostátní orgány v
                    každém jednotlivém případě povinnost zajistit účastníkům sporu spravedlivý proces (např. ESLP, Dombo
                    Beheer B.V. v. Nizozemí). Podle konstantní judikatury Ústavního soudu České republiky je notorietou,
                    tj. skutečností, jíž si musí být vědom každý orgán nadaný veřejnou moci a aplikující právo, že
                    „mechanická aplikace [práva] abstrahující, resp. neuvědomující si, a to buď úmyslně, nebo v důsledku
                    nevzdělanosti, smysl a účel právní normy, činí z práva nástroj odcizení a absurdity." (I.ÚS 2920/09
                    ze dne 8. 2. 2011, N 14/60 SbNU 141, Pl. ÚS 33/97 (N 163/9 SbNU 399; 30/1998 Sb.)), přičemž takový
                    orgán „není absolutně vázán doslovným zněním zákonného ustanovení, nýbrž se od něj smí a musí
                    odchýlit v případě, kdy to vyžaduje ze závažných důvodů […] některý z principů, jež mají svůj základ
                    v ústavně konformním právním řádu jako významovém celku" (I.ÚS 2920/09, N 14/60 SbNU 141).</p>
                <p>Podle čl. 13 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou a
                    vyhlášené ve sdělení č. 209/1992 Sb., „každý, jehož práva a svobody přiznané touto Úmluvou byly
                    porušeny, musí mít účinné právní prostředky nápravy před národním orgánem, i když se porušení
                    dopustily osoby při plnění úředních povinností.“</p>
                <p>Podle § 16 odst. 1 zákona č. 312/2002 Sb. zákona o úřednících územních samosprávných celků, v platném
                    znění, je úředník územně samosprávného celku povinen, kromě jiného, (i) dodržovat ústavní pořádek
                    České republiky, jenž zahrnuje mezinárodní smlouvy, jimiž je Česká republika vázána, tedy i
                    Evropskou úmluvu o ochraně základních lidských práv a svobod, včetně jejího čl. 6 odst. 1 upravující
                    právo na spravedlivý proces, (ii) hájit při výkonu správních činností veřejný zájem, (iii) plnit
                    pokyny vedoucích úředníků, nejsou-li v rozporu s právními předpisy; má-li úředník za to, že vydaný
                    pokyn je v rozporu s právními předpisy, je povinen to bezodkladně oznámit osobě, která mu pokyn
                    vydala, a to písemně, nebo, hrozí-li nebezpečí z prodlení, ústně; poté je úředník povinen splnit
                    daný pokyn pouze tehdy, dostane-li od vedoucího úřadu písemný příkaz tak učinit; úředník nesmí
                    vykonat pokyn ani příkaz, pokud by se tím dopustil trestného činu, přestupku, popřípadě jiného
                    správního deliktu, a tuto skutečnost je povinen bez zbytečného prodlení písemně oznámit vedoucímu
                    úřadu, (iv) prohlubovat si kvalifikaci v rozsahu stanoveném tímto zákonem, (v) jednat a rozhodovat
                    nestranně bez ohledu na své přesvědčení a zdržet se při výkonu práce všeho, co by mohlo ohrozit
                    důvěru v nestrannost rozhodování, (vi) zdržet se jednání, jež by závažným způsobem narušilo
                    důvěryhodnost územního samosprávného celku, (h) zdržet se jednání, které by mohlo vést ke střetu
                    veřejného zájmu se zájmy osobními, zejména nezneužívat informací nabytých v souvislosti s výkonem
                    zaměstnání ve prospěch vlastní nebo někoho jiného. </p>
                <p>Podle odst. 1 ustanovení téhož paragrafu je úředníka dále povinen (i) pracovat svědomitě a řádně
                    podle svých sil, znalostí a schopností, (ii) plně využívat pracovní doby a pracovních prostředků k
                    vykonávání svěřených prací, plnit kvalitně, hospodárně a včas pracovní úkoly, (iii) řádně hospodařit
                    s prostředky svěřenými mu územním samosprávným celkem a střežit a ochraňovat jeho majetek před
                    poškozením, ztrátou, zničením a zneužitím a nejednat v rozporu s oprávněnými zájmy územního
                    samosprávného celku, (iv) zachovávat pravidla slušnosti při úředním jednání.</p>
                <p>Zákon o úřednících územních samosprávných celků předpokládá v rámci vstupního vzdělávání znalost
                    pravidel etiky úředníka. Vláda ČR svým usnesením ze dne 9. května 2012 č. 331 o Etickém kodexu
                    úředníků a zaměstnanců veřejné správy schválila vzorový etický kodex a doporučila hejtmanům,
                    primátorům a starostům využít jej pro vytvoření vlastního etického kodexu a tento vydat ve formě
                    vnitřního předpisu (dále jen „Vzorový etický kodex“). Etické kodexy jsou tak vydávány jednotlivými
                    územními samosprávnými celky, přičemž by měly vycházet z doporučujícího usnesení vlády, přičemž
                    zpravidla jsou etické kodexy územních samosprávných celků inkorporovány do jejich pracovních
                    řádů. </p>
                <p>Podle preambule tohoto Vzorového etického kodexu je každý úředník a zaměstnanec veřejné správy,
                    včetně úředníků územně samosprávných celků, povinen při rozhodování dodržovat a ctít zákonnost všech
                    postupů a rovný přístup ke všem fyzickým i právnickým osobám za účelem vytváření, udržování a
                    prohlubování důvěry veřejnosti ve veřejnou správu, jakož i za účelem vymezení a podporování
                    žádoucích standardů chování úředníka a zaměstnance veřejné správy ve vztahu k veřejnosti a
                    spolupracovníkům; takový úředník proto má zachovávat věrnost zásadám práva a spravedlnosti
                    vyplývajícím z evropského kulturního a historického dědictví a jednat v duchu nedotknutelných hodnot
                    lidské důstojnosti a svobody.</p>
                <p>Podle čl. 1 výše zmíněného Vzorového etického kodexu týkající se zákonnosti postupu úředníků územně
                    samosprávných celků má takový úředník plnit úkoly veřejné správy v souladu s ústavním pořádkem, se
                    zákony a ostatními právními předpisy a s právem Evropské unie, jakož i s mezinárodními smlouvami,
                    kterými je Česká republika vázána, tedy včetně Evropské úmluvy o ochraně základních lidských práv a
                    svobod, jež zahrnuje v čl. 6 odst. 1 právo na spravedlivý proces; při plnění úkolů veřejné správy má
                    úředník územně samosprávného celku jednat pouze v rozsahu zákonem svěřené pravomoci orgánu veřejné
                    správy a v souladu s jejím účelem.</p>
                <p>Podle čl. 2 výše zmíněného Vzorového etického kodexu týkající se rozhodování úředníků územně
                    samosprávných celků má takový úředník vždy volit nejvhodnější řešení s ohledem na veřejný zájem a na
                    rozhodné okolnosti konkrétního případu; má rovněž dbát na to, aby rozhodnutí nemohlo být z
                    objektivního hlediska vnímáno jako nespravedlivé, přičemž úředník územně samosprávného celku
                    zasahuje do práv osob jen za podmínek stanovených zákonem a v nezbytném rozsahu, nutném k dosažení
                    účelu sledovaného veřejným zájmem, k jehož ochraně mu byla pravomoc svěřena.</p>
                <p>Podle čl. 3 výše zmíněného Vzorového etického kodexu týkající se profesionality úředníků územně
                    samosprávných celků je výkon veřejné správy je službou veřejnosti. Úředník samosprávného celku proto
                    má vykonávat veřejnou správu na vysoké odborné úrovni, kterou si prohlubuje průběžným studiem, s
                    nejvyšší mírou slušnosti, porozumění a ochoty a bez jakýchkoli předsudků, v souladu se zásadou
                    rovných příležitostí bez ohledu na barvu pleti, pohlaví, národnost, náboženství, etnickou
                    příslušnost nebo jiné charakteristiky a nepřipouštět diskriminaci či obtěžování. Za kvalitu své
                    práce a za rozvíjení svých odborných znalostí je osobně odpovědný a své vzdělání si studiem průběžně
                    prohlubuje; ve vztahu k veřejnosti má takový úředník jednat s nejvyšší mírou zdvořilosti,
                    vstřícnosti a ochoty a bez jakýchkoli předsudků.</p>
                <p>Podle čl. 4 výše zmíněného Vzorového etického kodexu týkající se nestrannosti úředníků územně
                    samosprávných celků takový úředník má dbát na to, aby jeho rozhodování bylo objektivní, nestranné a
                    přijaté řešení bylo vždy v souladu s veřejným zájmem; při rozhodování nesmí úředník a zaměstnanec
                    veřejné správy preferovat osobní či skupinové zájmy ani se nechat ovlivnit pozitivními či
                    negativními vztahy ke konkrétním osobám a zdrží se také všeho, co by mohlo ohrozit důvěru v
                    nestrannost jeho rozhodování. Ve shodných nebo podobných případech má úředník samosprávného celku
                    jednat tak, aby mezi jednotlivými postupy nevznikaly rozdíly, jež není možno odůvodnit objektivními
                    skutečnostmi, zejména konkrétními okolnostmi daného případu, přičemž takový úředník vystupuje vůči
                    účastníkům právních vztahů objektivně tak, aby je neuváděl v omyl o jejich právech a povinnostech,
                    informuje je srozumitelně; veškerá hodnocení provádí profesionálně, objektivně, bez emocí a bez
                    sledování osobního prospěchu a v souladu s právem a spravedlností.</p>
                <p>Podle čl. 5 výše zmíněného Vzorového etického kodexu týkající se rychlosti a efektivity úředníků
                    územně samosprávných celků takový úředník má takový úředník vyřizovat pracovní záležitosti
                    zodpovědně, bez zbytečných průtahů, nejpozději v zákonem stanovených lhůtách, přičemž při plnění
                    jemu svěřených úkolů postupuje tak, aby stranám ani úřadu nevznikaly zbytečné náklady.</p>
                <p>Konečně, podle čl. 8 výše zmíněného Vzorového etického kodexu týkající se nakládání se svěřenými
                    prostředky úředníky územně samosprávných celků takový úředník vynakládá, v souladu s právními
                    předpisy, veškeré úsilí, aby zajistil maximálně efektivní a ekonomické spravování a využívání
                    finančních zdrojů a zařízení, které mu byly svěřeny, jakož i služeb, které mu byly poskytnuty, a s
                    těmito svěřenými prostředky nakládá efektivně a hospodárně.</p>
                <p>Pro efektivní fungování etických pravidel je zapotřebí, aby bylo možno případné porušení etických
                    pravidel vymáhat prostřednictvím kárného řízení či jiného obdobného mechanismu, přičemž povinnosti
                    dodržování etických pravidel lze stanovit a vymáhat například na základě existence příslušných
                    vnitřních předpisů územně samosprávného celku (SROVNÁVACÍ ANALÝZA různých druhů služebních poměrů
                    veřejných zaměstnanců v České republice, Ministerstvo vnitra České republiky, Sekce pro státní
                    službu, Praha, 2019, zpracována v rámci projektu Podpora profesionalizace a kvality státní služby a
                    státní správy, registrační číslo CZ.03.4.74/0.0/0.0/15_019/0006173, spolufinancovaného z Operačního
                    programu Zaměstnanost, str. 70).</p>
                <h2>3. PORUŠENÍ ODŮVODŇUJÍCÍ PODÁNÍ NÁVRHU NA ZAHÁJENÍ KÁRNÉHO ŘÍZENÍ</h2>
                <p><strong>Níže uvedený státní úředník či úředníci se podle mého názoru dopustili následujících porušení
                    práv spravedlivý proces podle čl. 6 odst. 1 Evropské úmluvy pro lidská práva, tak jak jsou vymezena
                    v příslušné judikatuře Evropského soudu pro lidská práva vykládající toto ustanovení, která je u
                    příslušného porušení vždy zmíněna. Těmito porušeními se níže uvedený úředník či úředníci dopustili
                    porušení svých povinností podle výše uvedených ustanovení § 16 odst. 1 a 2 zákona č. 312/2002 Sb.
                    zákona o úřednících územních samosprávných celků, a jakož i porušení ustanovení čl. 1 – 5 a 8
                    Etického kodexu úředníků a zaměstnanců veřejné správy, jenž byl vydán jako součást usnesení ze Vlády
                    ČR dne 9. května 2012 č. 331 a jenž byl podle mého názoru přejat do odpovídajícího závazného
                    etického kodexu daného územně samosprávného celku, jehož je dotčený úředník zaměstnancem, což je
                    podle mého názoru důvodem pro podání podnětu k zahájení kárného řízení proti níže uvedenému státnímu
                    úředníkovi či úředníkům. </strong></p>
                <p><strong>Nebyl-li uvedený Etický kodex daným územně samosprávným celkem, jehož je porušením dotčený
                    úředník územně samosprávného celku zaměstnancem, představují výše uvedená porušení, porušení
                    povinností úředníka veřejné správy vyplývajících z právních principů správního práva v rozsahu a
                    obsahu vymezeném v uvedeném Etickém kodexu. V takovém případě žádám souběžné zahájení kárného řízení
                    proti úředníkovi či úředníkům daného územně samosprávného celku za opomenutí zajistit převzetí výše
                    uvedeného Vzorového etického kodexu do závazných předpisů daného územně samosprávného celku
                    vztahujících se na jeho úředníky, což podle mého názoru je rovněž omisivním protiprávním aktem
                    představujícím příklad neřádného výkonu veřejné správy.</strong></p>
                <p><strong>Dále uvedené právní povinnosti týkající se práv spravedlivého procesu vztahující se na soudy
                    a soudce podle dále uvedené judikatury Evropského soudu pro lidská práva, jež podle mého názoru byly
                    porušeny ze strany úředníka či úředníků územně samosprávného celku, se použijí vedle soudů a soudců
                    obdobně (mutatis mutandis) i na úředníky územně samosprávných celků, jež jsou součástí orgánu
                    veřejné moci, jež vystupuje v řízení, na nějž se § 6 odst. 1 EÚLP vztahuje, v obdobném postavení
                    jako soud či soudce.</strong></p>

                {GenerateDocumentSelections(state.doc)}
                <p><strong>Vysvětlení: Očíslované věty či části vět představují právní pravidla týkající se práv na
                    spravedlivý proces vyjádřená v rozsudcích Evropského soudu pro lidská práva. Odkazy ve formátu
                    „jméno v.
                    (proti) název státu, § číslo či §§ čísla“, například „Cipolletta v. Italy §§ 33-37“ představují
                    odkazy
                    na rozsudky (judikáty) Evropského soudu pro lidská práva ve Štrasburku, ve kterých byla jednotlivá
                    právní pravidla formulována. Plný obsah těchto rozsudků lze dohledat zadáním jejich názvu v databázi
                    rozsudků Evropského soudu pro lidská práva ve Štrasburku HUDOC na internetové adrese:
                    https://hudoc.echr.coe.int/</strong></p>

                <h2>4. OSOBY, JEŽ SE UVEDENÝCH PORUŠENÍ DOPUSTILY</h2>
                <p><strong>Úředník či úředníci územně samosprávného celku, jež se podle podatele výše uvedených porušení
                    práv na spravedlivý proces dopustil(i), včetně uvedení jejich funkce:</strong></p>
                <p>{state.doc.generatorSettings.urednikSamospravy}</p>
                <p><strong>Název obce či kraje, jehož součástí je uvedený úředník či úředníci úředníkem či úředníky
                    územně samosprávného celku: </strong></p>
                <p>{state.doc.generatorSettings.uradSamosprava}</p>
                <h2>5. IDENTIFIKACE PODATELE A DOTČENÝCH OSOB, JSOU-LI OD PODATELE ODLIŠNÉ</h2>
                <p><strong>Jméno a příjmení podatele:</strong></p>
                <p>{state.doc.generatorSettings.podatel}</p>
                <p><strong>Jméno a příjmení osoby či osob dotčené výše uvedenými porušeními, pokud jsou odlišné od
                    podatele:</strong></p>
                <p>{state.doc.generatorSettings.dotceni}</p>
                <h2>6. DALŠÍ INSTITUCE DOTČENÉ TÍMTO PODNĚTEM</h2>
                <p><strong>Na vědomí</strong></p>
                <p>Evropské komisi, GŘ Spravedlnost a spotřebitelé, Ředitelství D - Základní práva a právní stát, Odbor
                    C2: Politiky v oblasti základních práv: JUST-C-DIR@ec.europa.eu, JUST-C-SEC@europa.eu,,
                    JUST-C2@ec.europa.eu</p>
                <p>Výboru pro občanské svobody, spravedlnost a vnitřní věci Evropského parlamentu,
                    libe-secretariat@ep.europa.eu</p>
                <p>Řídicímu výboru pro lidská práva Výboru ministrů Rady Evropy: DGI-CDDH@coe.int,
                    DGI-CDDH-Reform@coe.int </p>
                <p>Podatel dává tento podnět na vědomí Evropské komisi, neboť v podnětu popsané porušení podle jeho
                    názoru představuje rovněž porušení Českou republikou obecných zásad práva Evropské unie tvořených
                    podle ustanovení čl. 6 odst. 3 Smlouvy o Evropské unii základními právy, která jsou zaručena
                    Evropskou úmluvou o ochraně lidských práv a základních svobod: konkrétně pak v tomto ohledu porušení
                    základního práva na právní jistotu ohledně řádného výkonu spravedlnosti, jež je jedním ze základních
                    práv zaručených Evropskou úmluvou o ochraně lidských práv a základních svobod v každé demokratické
                    společnosti (viz např. ESLP, Beian proti Rumunsku (č. 1), § 39), ESLP, Pretto a ostatní proti
                    Itálii, § 21) a rovněž základní hodnotou Evropské unie zmíněnou v čl. 2 Smlouvy o Evropské unii;
                    stejně tak představuje toto porušení porušení základních práv, jež vyplývají z ústavních tradic
                    společných členským státům EU, včetně ČR: konkrétně pak v tomto ohledu porušení základního práva
                    vyplývajícího z ústavních ČR a ostatních členských států EU, na výslovné a konkrétní zdůvodnění
                    argumentu týkajícího se porušení konkrétního aspektu práva na spravedlivý proces (viz např. Ústavní
                    soud ČR, N 160/38 SbNU 277).</p>
                <p>Podatel dává tento podnět rovněž na vědomí Řídicímu výboru pro lidská práva Výboru ministrů Rady
                    Evropy, aby tento výbor upozornil na nedodržování Evropské úmluvy na ochranu lidských práv a svobod
                    ze strany České republiky jako státu, jenž se přesto k dodržování této Úmluvy zavázal, a umožnil
                    tímto případné spuštění příslušných dozorových či sankčních mechanismů Výboru ministrů Rady Evropy
                    či jiných orgánů Rady Evropy vůči České republice v důsledku popsaného nedodržování uvedené Úmluvy
                    Českou republikou. </p>
                <p>V případě, že příslušný orgán či odpovědná osoba v rámci příslušného orgánu řádně nevyrozumí podatele
                    o tom, jak naložil s touto jeho žádostí, a/nebo toto vyrozumění nedá na vědomí Řídicímu výboru pro
                    lidská práva Výboru ministrů Rady Evropy ve výše uvedené lhůtě 30 dnů či ve složitějších případech
                    60 dnů, podatel zároveň žádá Řídicí výbor, aby po uplynutí uvedené lhůty vyzval generálního
                    tajemníka Rady Evropy, aby si podle čl. 52 Evropské úmluvy pro lidská práva vyžádal u příslušných
                    orgánů České republiky vysvětlení o způsobu, jakým české právo zajišťuje účinné provádění ustanovení
                    čl. 6 § 1 Úmluvy vzhledem k výše popsaným porušením tohoto ustanovení, jichž se uvedený úředník či
                    úředníci dopustil či dopustili; zejména pak žádá Řídicí výbor, aby tlumočil generálnímu tajemníkovi
                    Rady Evropy požadavek, aby si generální tajemník Rady Evropy vyžádal vysvětlení, jaká opatření byla
                    proti výše uvedenému úředníkovi či úředníkům přijata, aby se taková porušení v budoucnu již v České
                    republice neopakovala a bylo zajištěno účinné provádění ustanovení čl. 6 § 1 Úmluvy ve vztahu nejen
                    k dotčeným úředníkům územně samosprávného celku, ale i všem úředníkům všech územně samosprávných
                    celků České republiky.</p>
            </Card.Body>
            <Card.Footer className={"text-end"} id={"download-buttons"}>
                <Button variant={"success"} className={"m-2"}
                        onClick={() => DownloadRTF(downloadContents, state.doc.id)}>Stáhnout jako Word</Button>
            </Card.Footer>
        </Card>
    </>;
}