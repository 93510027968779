import React, {useEffect, useState} from "react";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import {useNavigate, useParams} from "react-router-dom";
import {Alert, Breadcrumb, Button, Card, Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import DocumentSettingsDialog from "../Utilities/DocumentSettingsDialog";
import {PssAction, PssDocumentType, PssGeneratorDocument} from "../Model/PssEnums";
import SoudceKarnyPodnet from "../Documents/SoudceKarnyPodnet";
import SoudceTrestniOznameni from "../Documents/SoudceTrestniOznameni";
import StatniUrednikKarnyPodnet from "../Documents/StatniUrednikKarnyPodnet";
import StatniUrednikTrestniOznameni from "../Documents/StatniUrednikTrestniOznameni";
import UrednikSamospravyPodnet from "../Documents/UrednikSamospravyPodnet";
import UrednikSamospravyTrestniOznameni from "../Documents/UrednikSamospravyTrestniOznameni";
import ExekutorKarnyPodnet from "../Documents/ExekutorKarnyPodnet";
import ExekutorTrestniOznameni from "../Documents/ExekutorTrestniOznameni";
import AdvokatKarnyPodnet from "../Documents/AdvokatKarnyPodnet";
import StatniZastupceKarnyPodnet from "../Documents/StatniZastupceKarnyPodnet";
import StatniZastupceTrestniOznameni from "../Documents/StatniZastupceTrestniOznameni";
import PolicieStiznostGIBS from "../Documents/PolicieStiznostGIBS";
import PolicieTrestniOznameni from "../Documents/PolicieTrestniOznameni";

export default function DocumentGenerator() {
    const navigate = useNavigate();

    let {documentId: str_doc_id} = useParams();
    let documentId: number = parseInt(str_doc_id as string);

    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);
    const storedDocument = documents.getDocument(documentId);
    const [state, setState] = useState({doc: storedDocument});

    useEffect(() => {
        let subscriberId = documents.subscribeChange(changedDoc => {
            if (state.doc.id === changedDoc.id) {
                setState({doc: changedDoc});
            }
        });

        return () => {
            documents.unsubscribe(subscriberId);
        };
    });

    const handleBack = (): void => {
        state.doc.setCurrentStep(PssAction.descriptions);
        documents.setDocument(state.doc);
        window.scrollTo(0, 0);
        navigate(`/documents/${state.doc.id}/descriptions`, {replace: true});
    }

    const scrollToId = (id: string) => {
        document.querySelector(id)?.scrollIntoView();
    };

    const getGeneratorVariants = (type: PssDocumentType): { [key in PssGeneratorDocument]?: string } => {
        switch (type) {
            default:
                return {};
            case PssDocumentType.CivilSoudce:
                return {
                    [PssGeneratorDocument.SoudceKarnyPodnet]: "Soudce - Podnět na kárné řízení",
                    [PssGeneratorDocument.SoudceTrestniOznameni]: "Soudce - Trestní oznámení",
                    [PssGeneratorDocument.StatniUrednikKarnyPodnet]: "Státní úředník - Podnět na kárné řízení",
                    [PssGeneratorDocument.StatniUrednikTrestniOznameni]: "Státní úředník - Trestní oznámení",
                    [PssGeneratorDocument.UrednikSamospravyKarnyPodnet]: "Úředník územně samosprávného celku (kraje či obce) - Podnět na kárné řízení",
                    [PssGeneratorDocument.UrednikSamospravyTrestniOznameni]: "Úředník územně samosprávného celku (kraje či obce) - Trestní oznámení"
                };
            case PssDocumentType.CivilExekuce:
                return {
                    [PssGeneratorDocument.SoudceKarnyPodnet]: "Soudce exekučního soudu - Podnět na kárné řízení",
                    [PssGeneratorDocument.SoudceTrestniOznameni]: "Soudce exekučního soudu - Trestní oznámení",
                    [PssGeneratorDocument.ExekutorKarnyPodnet]: "Exekutor - Podnět na kárné řízení",
                    [PssGeneratorDocument.ExekutorTrestniOznameni]: "Exekutor - Trestní oznámení"
                };
            case PssDocumentType.CivilAdvokat:
                return {
                    [PssGeneratorDocument.AdvokatKarnyPodnet]: "Advokát (protistrany) - Podnět na kárné řízení"
                };
            case PssDocumentType.TrestSoudce:
                return {
                    [PssGeneratorDocument.SoudceKarnyPodnet]: "Soudce - Podnět na kárné řízení",
                    [PssGeneratorDocument.SoudceTrestniOznameni]: "Soudce - Trestní oznámení",
                };
            case PssDocumentType.TrestOrgany:
                return {
                    [PssGeneratorDocument.StatniZastupceKarnyPodnet]: "Státní zástupce - Podnět na kárné řízení",
                    [PssGeneratorDocument.StatniZastupceTrestniOznameni]: "Státní zástupce - Trestní oznámení",
                    [PssGeneratorDocument.PolicieStiznostGIBS]: "Policista - Stížnost ke GIBS (Generální inspekce bezpečnostních sborů)",
                    [PssGeneratorDocument.PolicieTrestniOznameni]: "Policista - Trestní oznámení",
                };
        }
    }
    const availableVariants = getGeneratorVariants(state.doc.type);
    const [selectedVariant, setSelectedVariant] = useState<PssGeneratorDocument>(Number(Object.keys(availableVariants)[0]));

    return (
        <>
            <Row className={"g-0 border-bottom mb-2"}>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#/">Hlavní stránka</Breadcrumb.Item>
                        <Breadcrumb.Item active>Dokumenty</Breadcrumb.Item>
                        <Breadcrumb.Item active> {state.doc.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xs={1} className={"text-end"}>
                    <DocumentSettingsDialog documentId={documentId}/>
                </Col>
            </Row>
            <Navbar sticky={"top"} variant={"dark"} bg={"dark"} className={"mb-2 rounded"}>
                <Container fluid className={"m-2"}>
                    <Navbar.Brand className={"me-auto"}>
                        Krok 5 - Vyberte si typ dokumentu ke stažení
                    </Navbar.Brand>
                    <Nav className={""}>
                        <Nav.Link
                            as={Button}
                            variant={"primary"}
                            className={"me-3 text-light"}
                            onClick={() => handleBack()}
                        >
                            Zpět
                        </Nav.Link>
                    </Nav>
                </Container>
            </Navbar>

            <div className={"bg-light border border-1 border-dark p-1 mb-2"}>
                <h3>Obsah</h3>
                <ol>
                    <li onClick={() => scrollToId('#typeselect')}>Vyberte si typ dokumentu</li>
                    <li onClick={() => scrollToId('#form-identifications')}>Vyplňte identifikační informace</li>
                    <li onClick={() => scrollToId('#document-preview')}>Prohlédněte si náhled na výsledný dokument</li>
                    <li onClick={() => scrollToId('#download-buttons')}>Stáhněte si dokument jako Word (RTF)
                    </li>
                    <li onClick={() => scrollToId('#informations')}>Informace co udělat s výsledným dokumentem</li>
                    <li onClick={() => scrollToId('#legal-disclaimer')}>Právní upozornění</li>
                </ol>
            </div>

            <Card className={"mt-3"} id={"typeselect"}>
                <Card.Header>
                    <Card.Title>
                        Vyberte si osobu a typ dokumentu, který chcete generovat
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <select className={"p-2"} value={selectedVariant} onChange={(e) => {
                        setSelectedVariant(Number(e.target.value))
                    }}>
                        {Object.keys(getGeneratorVariants(state.doc.type)).map(key => {
                            return <option key={key} value={key}>{getGeneratorVariants(state.doc.type)[Number(key) as PssGeneratorDocument]}</option>
                        })}
                    </select>
                </Card.Body>
            </Card>

            {{
                [PssGeneratorDocument.SoudceKarnyPodnet]: <SoudceKarnyPodnet/>,
                [PssGeneratorDocument.SoudceTrestniOznameni]: <SoudceTrestniOznameni/>,
                [PssGeneratorDocument.StatniUrednikKarnyPodnet]: <StatniUrednikKarnyPodnet/>,
                [PssGeneratorDocument.StatniUrednikTrestniOznameni]: <StatniUrednikTrestniOznameni/>,
                [PssGeneratorDocument.UrednikSamospravyKarnyPodnet]: <UrednikSamospravyPodnet/>,
                [PssGeneratorDocument.UrednikSamospravyTrestniOznameni]: <UrednikSamospravyTrestniOznameni/>,
                [PssGeneratorDocument.ExekutorKarnyPodnet]: <ExekutorKarnyPodnet/>,
                [PssGeneratorDocument.ExekutorTrestniOznameni]: <ExekutorTrestniOznameni/>,
                [PssGeneratorDocument.AdvokatKarnyPodnet]: <AdvokatKarnyPodnet/>,
                [PssGeneratorDocument.StatniZastupceKarnyPodnet]: <StatniZastupceKarnyPodnet/>,
                [PssGeneratorDocument.StatniZastupceTrestniOznameni]: <StatniZastupceTrestniOznameni/>,
                [PssGeneratorDocument.PolicieStiznostGIBS]: <PolicieStiznostGIBS/>,
                [PssGeneratorDocument.PolicieTrestniOznameni]: <PolicieTrestniOznameni/>,

            }[selectedVariant]}

            <Card className={"mt-2"} id={"informations"}>
                <Card.Header>
                    <Card.Title className={"fw-bold"}>
                        Co učinit s vygenerovaným dokumentem?
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <p>« Zašlete vygenerovaný dokument Adresátovi na jeho emailovou či poštovní adresu. Tato emailová či
                        poštovní adresa je uvedená v sekci 6. „Adresát podnětu“ vygenerovaného dokumentu. »</p>

                    <p>« Zasíláte-li dokument Adresátovi na jeho emailovou adresu, můžete dát do kopie emailové zprávy
                        též Evropský parlament, Evropskou komisi či Radu Evropy, pokud je chcete o vašem podání též
                        uvědomit. Emailové adresy těchto evropských institucí se nacházejí rovněž v sekci 6.
                        vygenerovaného dokumentu pod názvem „Na vědomí“.</p>
                </Card.Body>
                <Card.Header>
                    <Card.Title>
                        DŮLEŽITÁ INFORMACE, pokud posíláte podnět k zahájení řízení či udělení kárného opatření
                        úředníkovi územně samosprávného celku
                    </Card.Title>
                </Card.Header>
                <Card.Body>

                    <p>« U tohoto typu podnětu není možné identifikovat v rámci ČR centrální institucí, kam by tento
                        podnět bylo možné podat. Sekce 6. vygenerovaného dokumentu proto neobsahuje emailovou či
                        poštovní adresu adresát, kam tento podnět podat.

                        V případě podnětu na porušení povinností úředníka územního samosprávného celku (obce či kraje)
                        je nutné zaslat tento podnět na emailovou adresu či poštovní adresu obecního úřadu nebo na
                        krajského úřad, kde daný úředník pracuje. Tuto emailovou či poštovní adresu dané obce či kraje
                        si musíte dohledat sami. »</p>

                    <p>« Zasíláte-li dokument na Vámi vyhledanou emailovou adresu obce či kraje, kde daný úředník
                        působí, můžete dát do kopie této emailové zprávy též Evropský parlament, Evropskou komisi či
                        Radu Evropy, pokud je chcete o vašem podání též uvědomit. Emailové adresy těchto evropských
                        institucí se nacházejí v sekci 6. vygenerovaného dokumentu pod názvem „Na vědomí“. »</p>

                </Card.Body>
                <Card.Header>
                    <Card.Title>
                        Lze vygenerovaný dokument použít i jinak?
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    « Vygenerovaný dokument – disciplinární stížnost či trestní oznámení – můžete zaslat rovněž vašemu
                    právnímu zástupci. Ten může tento vygenerovaný dokument použít jako podklad k vypracování procesního
                    dokumentu, např. odvolání, dovolání, ústavní stížnost, stížnost k Evropskému soudu pro lidská práva,
                    za účelem uplatnění vašich zájmů u soudu nebo u jiného vybraného veřejného orgánu. »
                </Card.Body>
            </Card>

            <Card className={"mt-2"} id={"legal-disclaimer"}>
                <Card.Header>
                    <Card.Title className={"fw-bold"}>Právní upozornění</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Alert variant={"danger"}>
                        <p>
                            Prosím přečtěte si vygenerovaný dokument předtím, než se rozhodnete ho odeslat. Autorem
                            vygenerovaného dokumentu jste Vy sám/sama a jako jeho autor jste jedinou osobou odpovědnou
                            za obsah vygenerovaného dokumentu. Rovněž odeslání vygenerovaného dokumentu provádíte svým
                            vlastním jménem.
                        </p><p>
                        Orgány veřejné moci mají povinnost zabývat se porušením práv na spravedlivých proces podle čl. 6
                        Evropské úmluvy o lidských právech a stát má povinnost zajistit, aby jeho veřejné orgány tato
                        práva občanů na spravedlivý proces dodržovala.
                    </p><p>
                        Vámi namítané porušení jednoho či více práv na spravedlivý proces uvedených ve vygenerovaném
                        dokumentu příslušným orgánem veřejné moci či právním zástupcem protistrany nicméně nemusí vést k
                        zahájení disciplinárního řízení či trestního řízení proti osobě, jež se dopustila takového
                        porušení.
                    </p><p>
                        Nezahájení takového disciplinárního řízení či trestního řízení není porušením práva. Vámi podaný
                        podnět či trestní oznámení by mělo však být příslušným orgánem posouzeno, nicméně ani to není
                        možné zaručit. Případná absence takového posouzení je nicméně relevantním důvodem pro stížnost
                        proti orgánu, který se posouzení Vaší stížnosti vyhnul.
                    </p>
                    </Alert>
                </Card.Body>
            </Card>
        </>
    );
}