import Layout from './App/Layout/Layout';
import {HashRouter} from "react-router-dom";
import Menu from "./App/Menu/Menu";
import ReactDOM from "react-dom";
import React from "react";
import {Provider} from "react-redux";
import PssStore from "./App/Model/PssStore";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas);

ReactDOM.render(
    <React.StrictMode>
        <HashRouter>
            <Provider store={PssStore}>
                <Menu/>
                <Layout/>
            </Provider>
        </HashRouter>
    </React.StrictMode>,
    document.getElementById('root')
);