import {PssAction, PssDocumentType} from "./PssEnums";
import GeneratorSettings from "./GeneratorSettings";
import GetTypeDatabase from "../Database/FindDatabase";

class PssDocument {
    id: number;
    type: PssDocumentType;
    step: PssAction = PssAction.classifications;
    name: string = "";
    choices: Map<string, string> = new Map<string, string>();
    generatorSettings: GeneratorSettings = new GeneratorSettings();
    classifications: string[] = [];

    constructor(id: number, type: PssDocumentType) {
        this.id = id;
        this.type = type;
    }

    setGeneratorSettings = (settings: GeneratorSettings) => {
        this.generatorSettings = {
            ...settings
        } as GeneratorSettings;
    }

    handleChoice = (choice_key: string, choice_status: boolean) => {
        let hasChoiceAlready = this.choiceStatus(choice_key);
        if (choice_status) {
            this.choices.set(choice_key, "");
        } else if (!choice_status && hasChoiceAlready) {
            this.choices.delete(choice_key);
        }
    }

    handleClassification = (choice_key: string, choice_status: boolean) => {
        if (choice_status && !this.classifications.includes(choice_key)) {
            this.classifications.push(choice_key);
        } else if (!choice_status && this.classifications.includes(choice_key)) {
            this.classifications.splice(this.classifications.indexOf(choice_key, 1));
        }
    }

    setChoiceDescription = (choice_key: string, description: string): boolean => {
        if (this.choices.has(choice_key)) {
            this.choices.set(choice_key, description);
            return true;
        }
        return false;
    }

    hasClassifications = (): boolean => GetTypeDatabase(this.type).filter((t) => t.is_classification).length > 0;

    canSkipClassifications = (): boolean => GetTypeDatabase(this.type).filter((t) => t.is_classification && !t.is_classification_required).length > 0;

    canContinue = (): boolean => {
        if (this.step === PssAction.selections) {
            return this.choices.size > 0;
        }
        if (this.step === PssAction.classifications) {
            return !this.hasClassifications() || this.canSkipClassifications() || this.classifications.length > 0;
        }
        console.log("unknown step for PssDocument::canContinue", this.step);
        return false;
    }

    getChoiceDescription = (choice_key: string): string => {
        return this.choices.get(choice_key) as string;
    }

    choiceStatus = (choice_key: string): boolean => {
        return this.choices.has(choice_key);
    }

    setCurrentStep = (step: PssAction): PssDocument => {
        this.step = step;
        return this;
    }
}

export default PssDocument;