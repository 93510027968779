import {useEffect, useRef, useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import {useParams} from "react-router-dom";
import GenerateDocumentSelections from "./Utils/GenerateDocumentSelections";
import DownloadRTF from "./Utils/DownloadRTF";

export default function StatniZastupceKarnyPodnet() {

    let {documentId: str_doc_id} = useParams();
    let documentId: number = parseInt(str_doc_id as string);

    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);
    const storedDocument = documents.getDocument(documentId);
    const [state, setState] = useState({doc: storedDocument});

    useEffect(() => {
        let subscriberId = documents.subscribeChange(changedDoc => {
            if (state.doc.id === changedDoc.id) {
                setState({doc: changedDoc});
            }
        });

        return () => {
            documents.unsubscribe(subscriberId);
        };
    });

    const downloadContents = useRef(null);

    return <>
        <Card className={"mt-3"} id={"form-identifications"}>
            <Card.Header>
                <Card.Title>
                    Vyplňte prosím následující identifikace pro vybraný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Státní zástupce či zástupci, jenž se podle podatele výše uvedených porušení práv na
                            spravedlivý proces dopustil(i):</Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.statniZastupce}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.statniZastupce = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Státní zastupitelství, na kterém tento státní zástupce či zástupci
                            působí: </Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.statniZastupitelstvi}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.statniZastupitelstvi = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení podatele:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.podatel}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.podatel = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení dotčené osoby, pokud se neshodují s podatelem:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.dotceni}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.dotceni = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
        <Card className={"mt-3"} id={"document-preview"}>
            <Card.Header>
                <Card.Title>
                    Náhled na generovaný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body ref={downloadContents}>
                <h1>Podnět ministru spravedlnosti na podání návrhu na zahájení kárného řízení proti státnímu zástupci či
                    státním zástupcům</h1>
                <p>Já níže uvedený podávám tímto podnět ministru na podání návrhu na zahájení kárného řízení proti
                    státnímu zástupci či zástupcům, a to z níže uvedených důvodů.</p>
                <h2>1. OBSAH </h2>
                <p>Žádám ministra spravedlnosti, aby níže uvedená mnou namítaná porušení zásad spravedlivého procesu
                    níže uvedenému státnímu zástupci či zástupcům posoudil a na základě tohoto posouzení návrhu na
                    zahájení kárného řízení proti tomuto státnímu zástupci či zástupcům. </p>
                <p>Dále žádám ministra spravedlnosti, aby mě vyrozuměl o tom, jakým způsobem tento můj podnět
                    posoudil. </p>
                <p>Nedojde-li ze strany ministra spravedlnosti k podání návrhu na zahájení kárného řízení proti níže
                    uvedenému státnímu zástupci či zástupcům, žádám, aby se ve vyrozumění, jenž mi zašle, argumentačně
                    vypořádal se všemi níže uvedenými porušeními práva na spravedlivý proces, jichž se státní zástupce
                    či státní zástupci dopustil či dopustili. V této souvislosti podotýkám, že podle dále uvedené
                    judikatury Ústavního soudu (např. I. ÚS 403/03, N 160/38 SbNU 277), argumentuje-li účastník názory
                    judikatury, musí se veřejný orgán s těmito názory argumentačně vypořádat (případně i tak, že
                    vysvětlí, proč je nepovažuje pro danou věc relevantní). </p>
                <p>Rovněž žádám, aby zároveň toto vyrozumění kromě mé osoby bylo zasláno rovněž na vědomí níže uvedeným
                    útvarům Evropské komise, Evropského parlamentu a Rady Evropy, jež jsou odpovědné za dohled nad
                    dodržováním práva na spravedlivý proces podle Evropské úmluvy na ochranu lidských práv a svobod v
                    členských státech Rady Evropy a Evropské unie. </p>
                <p>Konečně žádám, aby mi bylo zmíněné vyrozumění zasláno v zákonné lhůtě, tedy nejpozději do 30 dnů od
                    přijetí tohoto mého podnětu, nebo v případě, že se uvedené posouzení bude jevit jako složité, abych
                    byl v této zákonné lhůtě informován o prodloužení lhůty k posouzení na nejvýše 60 dnů.</p>
                <h2>2. PRÁVNÍ ZÁKLAD</h2>
                <p>Podle čl. 2 odst. 3 Ústavy ČR a čl. 2 odst. 3 Listiny základních práv a svobod „státní moc slouží
                    všem občanům a lze ji uplatňovat jen v případech, v mezích a způsoby, které stanoví zákon.“</p>
                <p>Podle čl. 10 Ústavy ČR „vyhlášené mezinárodní smlouvy, k jejichž ratifikaci dal Parlament souhlas a
                    jimiž je Česká republika vázána, jsou součástí právního řádu; stanoví-li mezinárodní smlouva něco
                    jiného než zákon, použije se mezinárodní smlouva.“</p>
                <p>Podle čl. 6 odst. 1 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou
                    a vyhlášené ve sdělení č. 209/1992 Sb., má „každý právo na to, aby jeho záležitost byla spravedlivě,
                    veřejně a v přiměřené lhůtě projednána nezávislým a nestranným soudem, zřízeným zákonem, který
                    rozhodne o jeho občanských právech nebo závazcích nebo o oprávněnosti jakéhokoli trestního obvinění
                    proti němu.“ </p>
                <p>Podle konstantní judikatury Evropského soudu pro lidská práva (ESLP) mají vnitrostátní orgány, včetně
                    orgánů činných v trestním řízení zahrnujících státní zástupce, v každém jednotlivém případě
                    povinnost zajistit účastníkům sporu spravedlivý proces (např. ESLP, Dombo Beheer B.V. v. Nizozemí).
                    Státní zástupci proto musí principy řádného a spravedlivého procesu, jak jsou vymezeny v uvedených
                    ustanoveních a na nich navazující judikatuře, při své činnosti dodržovat, aby se vyloučila libovůle
                    při jejich rozhodování.</p>
                <p>Podle § 2 odst. 4 zákona č. 141/1961 Sb., o trestním řízení soudním (trestní řád) projednávají orgány
                    činné v trestním řízení, jež zahrnují státní zástupce i příslušníky policie ČR s plným šetřením práv
                    a svobod zaručených Listinou základních práv a svobod a mezinárodními smlouvami o lidských právech a
                    základních svobodách, jimiž je Česká republika vázána, přičemž při provádění úkonů trestního řízení
                    lze do těchto práv osob, jichž se takové úkony dotýkají, zasahovat jen v odůvodněných případech na
                    základě zákona a v nezbytné míře pro zajištění účelu trestního řízení.</p>
                <p>Čl. 6 odst. 1 Evropské úmluvy na ochranu lidských práv a svobod se vztahuje na všechny fáze řízení,
                    jimiž se „rozhoduje… o oprávněnosti jakéhokoli trestního obvinění proti jednotlivci“. Podle čl. 6
                    odst. 2 téže Úmluvy každý, kdo je obviněn z trestného činu, se považuje za nevinného, dokud jeho
                    vina nebyla prokázána zákonným způsobem. Podle čl. 6 odst. 3 téže Úmluvy každý, kdo je obviněn z
                    trestného činu, má tato minimální práva:</p>
                <p>a) být neprodleně a v jazyce, jemuž rozumí, podrobně seznámen s povahou a důvodem obvinění proti
                    němu;</p>
                <p>b) mít přiměřený čas a možnosti k přípravě své obhajoby;</p>
                <p>c) obhajovat se osobně nebo za pomoci obhájce podle vlastního výběru nebo, pokud nemá prostředky na
                    zaplacení obhájce, aby mu byl poskytnut bezplatně, jestliže to zájmy spravedlnosti vyžadují;</p>
                <p>d) vyslýchat nebo dát vyslýchat svědky proti sobě a dosáhnout předvolání a výslech svědků ve svůj
                    prospěch za stejných podmínek, jako svědků proti sobě;</p>
                <p>e) mít bezplatnou pomoc tlumočníka, jestliže nerozumí jazyku používanému před soudem nebo tímto
                    jazykem nemluví.</p>
                <p>Podle konstantní judikatury Ústavního soudu České republiky je notorietou, tj. skutečností, jíž si
                    musí být vědom každý orgán nadaný veřejnou moci a aplikující právo, včetně státních zástupců, že
                    „mechanická aplikace [práva] abstrahující, resp. neuvědomující si, a to buď úmyslně, nebo v důsledku
                    nevzdělanosti, smysl a účel právní normy, činí z práva nástroj odcizení a absurdity." (I.ÚS 2920/09
                    ze dne 8. 2. 2011, N 14/60 SbNU 141, Pl. ÚS 33/97 (N 163/9 SbNU 399; 30/1998 Sb.)), přičemž takový
                    orgán, včetně státního zástupce, „není absolutně vázán doslovným zněním zákonného ustanovení, nýbrž
                    se od něj smí a musí odchýlit v případě, kdy to vyžaduje ze závažných důvodů […] některý z principů,
                    jež mají svůj základ v ústavně konformním právním řádu jako významovém celku" (I.ÚS 2920/09, N 14/60
                    SbNU 141).</p>
                <p>Podle čl. 13 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou a
                    vyhlášené ve sdělení č. 209/1992 Sb., „každý, jehož práva a svobody přiznané touto Úmluvou byly
                    porušeny, musí mít účinné právní prostředky nápravy před národním orgánem, i když se porušení
                    dopustily osoby při plnění úředních povinností.“</p>
                <p>Podle § 2 odst. 2 zákona č. 283/1993 Sb. o státním zastupitelství, v platném znění, státní
                    zastupitelství při výkonu své působnosti dbá, aby každý jeho postup byl v souladu se zákonem,
                    rychlý, odborný a účinný; svoji působnost vykonává nestranně, respektuje a chrání přitom lidskou
                    důstojnost, rovnost všech před zákonem a dbá na ochranu základních lidských práv a svobod. </p>
                <p>Podle § 24 odst. 1 téhož zákona je státní zástupce při výkonu své funkce povinen odpovědně plnit své
                    úkoly a respektovat přitom zásady, které zákon pro činnost státního zastupitelství stanoví; zejména
                    je povinen postupovat odborně, svědomitě, odpovědně, nestranně, spravedlivě a bez zbytečných
                    průtahů. Jakýkoli vnější zásah nebo jiný vliv, jehož důsledkem by mohlo být porušení některé z
                    těchto povinností, musí odmítnout. Dále podle § 24 odst. 2 téhož zákona, v platném znění, je státní
                    zástupce je při výkonu své funkce, ve svém osobním životě i při výkonu svých politických práv
                    povinen vystříhat se všeho, co by mohlo vzbuzovat důvodné pochybnosti o dodržování povinností
                    uvedených v odstavci 1, ohrozit vážnost funkce státního zástupce nebo vážnost státního
                    zastupitelství anebo ohrozit důvěru v nestranný a odborný výkon působnosti státního zastupitelství
                    nebo státního zástupce. Státní zástupce za tímto účelem musí, kromě jiného, vykonávat svoji funkci
                    musí nezaujatě a bez ekonomických, sociálních, rasových, etnických, sexuálních, náboženských nebo
                    jiných předsudků a ve vztahu k osobám, s nimiž při výkonu své funkce jedná, se musí zdržet projevů
                    osobních sympatií, náklonnosti nebo negativních postojů, a zároveň nesmí umožnit, aby funkce
                    státního zástupce byla zneužita k prosazování soukromých zájmů.</p>
                <p>Konečně, podle § 24 odst. 3 zákona č. 283/1993 Sb. o státním zastupitelství, v platném znění, je
                    státní zástupce povinen dbát soustavným vzděláváním o prohlubování svých odborných právních a
                    dalších znalostí potřebných pro řádný výkon funkce, přičemž k tomu vedle samostatného studia využívá
                    zejména vzdělávací akce, organizované Justiční akademií, případně státním zastupitelstvím a vysokými
                    školami.</p>
                <p>Etický kodex státního zástupce přijatý vedoucími státními zástupci s personální pravomocí přijatého
                    dne 16.4.2019 v Brně a účinného od 1. 5. 2019 (dále jen „etický kodex“), a to s účinností od 1. 5.
                    2019. slouží tento kodex jako společné vyjádření základních hodnot, na kterých stojí důvěryhodnost,
                    vážnost a důstojnost státního zastupitelství, přičemž toto vyjádření stvrdili vedoucí státní
                    zástupci s personální pravomocí. Podle sekce I. tohoto etického kodexu státní zástupce (Zákonnost a
                    nezávislost) vykonává působnost státního zastupitelství důsledně podle zákona a svého svědomí,
                    nezávisle na jiných orgánech i na místních, politických, soukromých nebo jiných vlivech a zájmech.
                    Podle sekce II. téhož kodexu (Nestrannost) se státní zástupce se vyvaruje jakéhokoli neoprávněného
                    zvýhodňování nebo znevýhodňování osob při výkonu své funkce, což zahrnuje i takovou činnost, která
                    by mohla i jen zavdat důvodnou pochybnost o jeho nestrannosti. Konečně, podle sekce III. tohoto
                    kodexu (Odbornost) státní zástupce vykonává svou funkci na nejvyšší odborné úrovni, přičemž za tímto
                    účelem se soustavně vzdělává a prohlubuje svoje právní znalosti a při výkonu své funkce postupuje
                    státní zástupce iniciativně tak, aby rychle a spolehlivě zjistil skutečný stav věci a podle výsledku
                    učinil odpovídající rozhodnutí nebo opatření.</p>
                <p>Podle § 16b odst. 1 zákona č. 283/1993 Sb. o státním zastupitelství, v platném znění, je každý
                    oprávněn podat stížnost na průtahy při plnění úkolů státního zastupitelství nebo na nevhodné chování
                    státních zástupců, vyšších úředníků a ostatních zaměstnanců státního zastupitelství; proti
                    evropskému pověřenému žalobci lze podat pouze stížnost na nevhodné chování.</p>
                <h2>3. PORUŠENÍ ODŮVODŇUJÍCÍ PODÁNÍ NÁVRHU NA ZAHÁJENÍ KÁRNÉHO ŘÍZENÍ</h2>
                <p><strong>Níže uvedený státní zástupce či zástupci se podle mého názoru dopustili následujících
                    porušení práv spravedlivý proces podle čl. 6 odst. 1 Evropské úmluvy pro lidská práva, tak jak jsou
                    vymezeny v příslušné judikatuře Evropského soudu pro lidská práva vykládající toto ustanovení, která
                    je u příslušného porušení vždy zmíněna. Těmito porušeními se níže uvedený státní zástupce či
                    zástupci dopustili porušení svých povinností podle § 2 odst. 4 věta třetí trestního řádu, dále
                    porušení §§ 2 odst. 2 a 24 odst. 1 – 3, 82 odst. 1 zákona č. 283/1993 Sb. o státním zastupitelství,
                    v platném znění, jakož i porušení sekcí I. – III. etického kodexu státních zástupců což je podle
                    mého názoru důvodem pro podání na zahájení kárného řízení proti níže uvedenému státnímu zástupci či
                    zástupcům.</strong></p>

                {GenerateDocumentSelections(state.doc)}
                <p><strong>Vysvětlení: Očíslované věty či části vět představují právní pravidla týkající se práv na
                    spravedlivý proces vyjádřená v rozsudcích Evropského soudu pro lidská práva. Odkazy ve formátu
                    „jméno v.
                    (proti) název státu, § číslo či §§ čísla“, například „Cipolletta v. Italy §§ 33-37“ představují
                    odkazy
                    na rozsudky (judikáty) Evropského soudu pro lidská práva ve Štrasburku, ve kterých byla jednotlivá
                    právní pravidla formulována. Plný obsah těchto rozsudků lze dohledat zadáním jejich názvu v databázi
                    rozsudků Evropského soudu pro lidská práva ve Štrasburku HUDOC na internetové adrese:
                    https://hudoc.echr.coe.int/</strong></p>

                <h2>4. OSOBA ČI OSOBY, JEŽ SE UVEDENÝCH PORUŠENÍ DOPUSTILY</h2>
                <p><strong>Státní zástupce či zástupci, jenž se podle podatele výše uvedených porušení práv na
                    spravedlivý proces dopustil(i):</strong></p>
                <p>{state.doc.generatorSettings.statniZastupce}</p>
                <p><strong>Státní zastupitelství, na kterém tento státní zástupce či zástupci působí: </strong></p>
                <p>{state.doc.generatorSettings.statniZastupitelstvi}</p>
                <h2>5. IDENTIFIKACE PODATELE A DOTČENÝCH OSOB, JSOU-LI OD PODATELE ODLIŠNÉ</h2>
                <p><strong>Jméno a příjmení podatele:</strong></p>
                <p>{state.doc.generatorSettings.podatel}</p>
                <p><strong>Osoba či osoby dotčené výše uvedenými porušeními, pokud jsou odlišné od podatele:</strong>
                </p>
                <p>{state.doc.generatorSettings.dotceni}</p>
                <h2>6. ADRESÁT PODNĚTU A DALŠÍ INSTITUCE DOTČENÉ TÍMTO PODNĚTEM</h2>
                <p><strong>Adresát:</strong></p>
                <p>
                    Ministr spravedlnosti
                    <br/>Ministerstvo spravedlnosti ČR
                    <br/>Vyšehradská 16
                    <br/>128 10 Praha 2
                    <br/>posta@msp.justice.cz
                </p>
                <p><strong>Na vědomí</strong></p>
                <p>Evropské komisi, GŘ Spravedlnost a spotřebitelé, Ředitelství D - Základní práva a právní stát, Odbor
                    C2: Politiky v oblasti základních práv: JUST-C-DIR@ec.europa.eu, JUST-C-SEC@ec.europa.eu,,
                    JUST-C2@ec.europa.eu</p>
                <p>Výboru pro občanské svobody, spravedlnost a vnitřní věci Evropského parlamentu,
                    libe-secretariat@ep.europa.eu</p>
                <p>Řídicímu výboru pro lidská práva Výboru ministrů Rady Evropy: DGI-CDDH@coe.int,
                    DGI-CDDH-Reform@coe.int</p>
                <p>Podatel dává tento podnět na vědomí Evropské komisi a Evropskému parlamentu, neboť v podnětu popsané
                    porušení podle jeho názoru představuje rovněž porušení Českou republikou obecných zásad práva
                    Evropské unie tvořených podle ustanovení čl. 6 odst. 3 Smlouvy o Evropské unii základními právy,
                    která jsou zaručena Evropskou úmluvou o ochraně lidských práv a základních svobod: konkrétně pak v
                    tomto ohledu porušení základního práva na právní jistotu ohledně řádného výkonu spravedlnosti, jež
                    je jedním ze základních práv zaručených Evropskou úmluvou o ochraně lidských práv a základních
                    svobod v každé demokratické společnosti (viz např. ESLP, Beian proti Rumunsku (č. 1), § 39), ESLP,
                    Pretto a ostatní proti Itálii, § 21) a rovněž základní hodnotou Evropské unie zmíněnou v čl. 2
                    Smlouvy o Evropské unii; toto porušení je rovněž porušením základních práv, která vyplývají z
                    ústavních tradic společných členským státům EU, včetně ČR – konkrétně pak v tomto ohledu porušení
                    základního práva vyplývajícího z ústavních ČR a ostatních členských států EU, na výslovné a
                    konkrétní zdůvodnění argumentu týkajícího se porušení konkrétního aspektu práva na spravedlivý
                    proces (viz např. Ústavní soud ČR, N 160/38 SbNU 277).</p>
                <p>Podatel dává tento podnět rovněž na vědomí Řídicímu výboru pro lidská práva Výboru ministrů Rady
                    Evropy, aby tento výbor upozornil na nedodržování Evropské úmluvy na ochranu lidských práv a svobod
                    ze strany České republiky jako státu, jenž se přesto k dodržování této Úmluvy zavázal, a umožnil
                    tímto případné spuštění příslušných dozorových či sankčních mechanismů Výboru ministrů Rady Evropy
                    či jiných orgánů Rady Evropy vůči České republice v důsledku popsaného nedodržování uvedené Úmluvy
                    Českou republikou.</p>
                <p>V případě, že ministr spravedlnosti nevyrozumí podatele o tom, jak naložil s touto jeho žádostí,
                    a/nebo toto vyrozumění nedá na vědomí Řídicímu výboru pro lidská práva Výboru ministrů Rady Evropy
                    ve výše uvedené lhůtě 30 dnů či ve složitějších případech 60 dnů, podatel zároveň žádá Řídicí výbor,
                    aby po uplynutí uvedené lhůty vyzval generálního tajemníka Rady Evropy, aby si podle čl. 52 Evropské
                    úmluvy pro lidská práva vyžádal u příslušných orgánů České republiky vysvětlení o způsobu, jakým
                    české právo zajišťuje účinné provádění ustanovení čl. 6 § 1 Úmluvy vzhledem k výše popsaným
                    porušením tohoto ustanovení, jichž se uvedený státní zástupce či zástupce dopustil či dopustili;
                    zejména pak žádá Řídicí výbor, aby tlumočil generálnímu tajemníkovi Rady Evropy požadavek, aby si
                    generální tajemník Rady Evropy vyžádal vysvětlení, jaká opatření byla proti výše uvedenému státnímu
                    zástupci či zástupcům přijata, aby se taková porušení v budoucnu již v České republice neopakovala a
                    bylo zajištěno účinné provádění ustanovení čl. 6 § 1 Úmluvy ve vztahu nejen k dotčeným státním
                    zástupcům, ale i všem státním zástupcům působícím v České republice.</p>
            </Card.Body>
            <Card.Footer className={"text-end"} id={"download-buttons"}>
                <Button variant={"success"} className={"m-2"}
                        onClick={() => DownloadRTF(downloadContents, state.doc.id)}>Stáhnout jako Word</Button>
            </Card.Footer>
        </Card>
    </>;
}