import {PssChoice, PssSection, PssSectionPart} from "../Model/PssDatabase";

export const CivilExekuceDb: PssSection[] = [
    new PssSection(
        "I.     PORUŠENÍ NEZÁVISLOSTI A NESTRANNOSTI EXEKUTORA NEBO EXEKUČNÍHO SOUDU",
        "Níže uvedené požadavky se vztahující se na soudy se použijí i na exekutory, neboť podle § 28 zákona č. 120/2001 Sb." +
        " o soudních exekutorech a exekuční činnosti (exekuční řád), v platném znění (věta druhá) se úkony exekutora " +
        "se považují za úkony exekučního soudu." +
        "\n" +
        "\n" +
        "Spravedlnost nemůže být pouze vykonávána, ale veřejnost musí rovněž vidět, že je spravedlnost vykonávána. " +
        "Viditelnost výkonu spravedlnosti posiluje důvěru veřejnosti v soudy, v demokratické společnosti [1]." +
        " Soudci ani exekutoři proto nesmí být vůči stranám podjatí, a to ani funkčně, tedy v rámci jednotlivých fází procesu, ani osobně [2]," +
        " což zahrnuje I nezávislost vůči ostatním složkám veřejné moci (výkonné i zákonodárné) [3]. " +
        "\n" +
        "\n" +
        "\"Nezávislost\" označuje nezbytnou osobní a institucionální nezávislost," +
        " která je nezbytná pro nestranné rozhodování, a je tedy předpokladem nestrannosti. " +
        "Charakterizuje jak stav mysli, který označuje odolnost soudce a exekutora" +
        " vůči vnějšímu tlaku jako součást jeho morální integrity [4].",
        "[1] (Micallef v. Malta [GC]); [2] (Micallef v. Malta [GC], §§ 97-98); " +
        "[3] (De Haan v. the Netherlands, §§ 52-55; Helle v. Finland, § 46; Crompton v. the United Kingdom, § 79; " +
        "Denisov v. Ukraine [GC], §§ 65, 67 and 72); [4] (Guðmundur Andri Ástráðsson v. Island [GC], § 234)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na spravedlivý proces, muselo dojít alespoň k jedné" +
                " z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ce.1.1", "1. Soudce exekučního soudu nebo exekutor, ohledně něhož existuje legitimní důvod se bát, že není nestranný, se musí z projednávání věci vyloučit. "," (Micallef v. Malta [GC], § 98), což ovšem neučinil"),
                    new PssChoice("ce.1.2", "2. Exekuční soud či exekutor, jemuž byla doručena námitka podjatosti, se nevyjádřil k argumentům uvedeným v této námitce nebo v souvislosti s ní, porušil právo stran na nezávislý a nestranný exekuční soud či exekutora. "," (Harabin v. Slovakia, § 136)"),
                    new PssChoice("ce.1.3", "3. Je porušením práva na nezávislost a nestrannost soudce exekučního soudu či exekutora, pokud se soudce exekučního soudu či exekutor vyhne posouzení námitky podjatosti, ačkoli je zřejmé, že není bezpředmětná či zjevně nedůvodná, a nepřijme v důsledku toho příslušná opatření zajišťující nestrannost a nezávislost exekučního soudu či exekutora. "," (Cosmos Maritime Trading and Shipping Agency v. Ukraine, §§ 78-82)"),
                    new PssChoice("ce.1.4", "4. Soudce exekučního soudu či exekutor porušil svou nestrannost a nezávislost, pokud vyjadřoval nepřátelství vůči jedné ze stran. ","(Buscemi v. Italy, §§ 67-68)"),
                    new PssChoice("ce.1.5", "5. Osobní nesnášenlivost soudce exekučního soudu či exekutora k jedné ze stran je přesvědčivým důvodem pro jeho vyloučení. "," (Rustavi 2 Broadcasting Company Ltd and Others v. Georgia, § 359)"),
                    new PssChoice("ce.1.6", "6. Je porušením nestrannosti soudce exekučního soudu či exekutora a práva na spravedlivý proces, pokud soudce exekučního soudu či exekutor vyjadřoval předsudky či předpojatost vůči některé ze stran nebo vůči určitému způsobu řešení sporu. "," (Wettstein proti Švýcarsku, § 43; Micallef proti Maltě [velký senát]"),
                    new PssChoice("ce.1.7", "7. Soudce exekučního soudu či exekutor porušil svou nestrannost a nezávislost, pokud poskytoval poradenství jedné ze stran v téže věci, ohledně téhož rozhodnutí nebo ohledně analogických záležitostí. "," (Kleyn and Others v. the Netherlands [GC], § 200)"),
                    new PssChoice("ce.1.8", "8. Jakákoli přímá účast na přijetí zákona či prováděcích opatření, včetně například územního či rozvojového plánu určitého území je zpravidla dostatečným důvodem pro vyloučení soudce exekučního soudu či exekutora z důvodu absence jeho nestrannosti a nezávislosti, pokud tento soudce exekučního soudu či exekutor následně daný předpis vykládá v rámci řízení, v němž rozhoduje. "," (McGonnell v. the United Kingdom, §§ 55-58)"),
                    new PssChoice("ce.1.9", "9. Pokud jsou vedena dvě souběžná řízení mezi stejnými stranami, přičemž v jednom z nich určitá osoba vystupuje jako soudce exekučního soudu či exekutor a v druhé jako protistrana, představuje to porušení práva na nestranného a nezávislého soudce. "," (Wettstein v. Switzerland, §§ 44-47)"),
                    new PssChoice("ce.1.10", "10. Soudce exekučního soudu či exekutor porušil svou nestrannost a nezávislost, pokud v předchozích řízeních, jež vyústily v řízení před tímto soudem, zastupoval jednu ze stran. "," (Mežnarić v. Croatia, § 36, Švarc and Kavnik v. Slovenia, § 44)"),
                    new PssChoice("ce.1.11", "11. Pokud existuje úzký vztah soudce exekučního soudu či exekutora s jeho faktickou přítomností v jiném postavení, než je postavení soudce exekučního soudu či exekutora, v záležitostech či skutečnostech, jichž se týkají různé fáze řízení, jehož se jako soudce exekučního soudu či exekutor účastní, jedná se o porušení jeho nestrannosti."," (Toziczka v. Poland, § 36)"),
                    new PssChoice("ce.1.12", "12. Soudce exekučního soudu či exekutor porušuje svou nestrannost a nezávislost, pokud rozhoduje o tom, zda se dopustil nesprávného právního posouzení v rozhodnutí, jehož vydání se v předchozím stupni účastnil. "," (Driza v. Albania, §§ 78-83)"),
                    new PssChoice("ce.1.13", "13. Soudce exekučního soudu či exekutor porušuje svou nestrannost a nezávislost v případě, kdy se účastní dvou různých řízení týkajících se stejných skutkových okolností. "," (Indra proti Slovensku, § 51–53)"),
                    new PssChoice("ce.1.14", "14. Jeden ze soudců exekučního soudu či exekutor rozhodující o právním posouzení věci na odvolacím soudě, rozhodoval dříve o téže věci jako soudce soudu první instance, čímž se dopustil porušení své nestrannosti a nezávislosti.","(De Haan proti Nizozemsku, § 51)"),
                    new PssChoice("ce.1.15", "15. Představuje porušení nestrannosti a nezávislosti, pokud soudce exekučního soudu či exekutor rozhoduje o věci, jež se týká dřívějšího rozhodnutí správního orgánu, jehož byl v té době předsedou. "," (Ramos Nunes de Carvalho e Sá v. Portugal [GC] (§§ 153-156)"),
                    new PssChoice("ce.1.16", "16. Je porušením nezávislosti a nestrannosti soudu, pokud asistent jednoho z ústavních soudců byl dříve součástí týmu právníků, jež zastupovali jednu ze stran v řízení na nižším stupni, jež poté vyústila v řízení před ústavním soudem. "," (Bellizzi v. Malta (§§ 60-61)"),
                    new PssChoice("ce.1.17", "17. Soudce exekučního soudu či exekutor porušuje svou povinnost nestrannosti, pokud má osobní zájem na výsledku sporu ve věci, již soudí. "," (Langborger v. Sweden, § 35; Gautrin and Others v. France, § 59)"),
                    new PssChoice("ce.1.18", "18. Profesní, majetkové nebo osobní vztahy mezi soudcem exekučního soudu či exekutorem a stranou sporu nebo advokátem jedné ze stran sporu jsou důvodem pro porušení nestrannosti a nezávislosti . "," (Pescador Valero v. Spain, § 27; Tocono and Profesorii Prometeişti v. Moldova, § 31; Micallef v. Malta [GC], § 102; Wettstein v. Switzerland, § 47)"),
                    new PssChoice("ce.1.19", "19. Vyjádření či vztahy mezi rodinnými příslušníky soudce exekučního soudu či exekutora na straně jedné, a jedné ze stran sporu nebo jejího právního zástupce či právní kanceláře, jejímž jménem tento právní zástupce ve sporu vystupuje, na straně druhé, mohou být důvodem pro vyloučení soudce exekučního soudu či exekutora z důvodu jeho nestrannosti či nezávislosti v závislosti na jejich intenzitě, úzkému charakteru majetkových či osobních vztahů, vlivu a postavení dané osoby v dotčené právní kanceláři, významu sporu pro danou právní kancelář atd. ","(Rustavi 2 Broadcasting Company Ltd and Others v. Georgia, §§ 342 et seq., § 344, Nicholas v. Cyprus, § 62)"),
                    new PssChoice("ce.1.20", "20. Pokud je exekuční soudce či exekutora nebo jeho asistent podílející se na dané věci zároveň v podřízeném postavení vůči jedné ze stran sporu, představuje taková situace porušení nezávislosti a nestrannosti exekučního soudu či exekutora. "," (Sramek v. Austria, § 42)"),
                    new PssChoice("ce.1.21", "21. Zasáhla-li moc výkonná do případů projednávaných před exekučním soudem či exekutorem s cílem ovlivnit jejich výsledek, je tím ohrožena nezávislost exekučního soudu či exekutora, a došlo k porušení práva na spravedlivý proces. "," (Sovtransavto Holding proti Ukrajině, § 80; Mosteanu a ostatní proti Rumunsku, § 42)"),
                    //new PssChoice("ce.1.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Exekuční soud i jeho soudci stejně jako exekutor jsou považováni za nestranné a nezávislé, dokud jejich nestrannost či nezávislost není zpochybněna. ", "(Le Compte, Van Leuven and De Meyere v. Belgium, § 58; Driza v. Albania, § 75; Micallef v. Malta [GC], § 94)", false),
                    new PssChoice("", "2. Skutečnost, že jsou soudci exekučního soudu jmenování představitelem výkonné moci a jsou odvolatelní, není dostatečným důvodem k pochybnostem o jejich nestrannosti či nezávislosti. ", "(Clarke v. the United Kingdom (dec.))",false),
                    new PssChoice("", "3. Skutečnost, že se soudce exekučního soudu nebo exekutor v určité části své procesní dráhy může nacházet v obdobném postavení jako některá ze stran řízení, není sama o sobě bez dalšího skutečností, jež by vedla k porušení nestrannosti a nezávislosti soudce.", "(Ramos Nunes de Carvalho e Sá v. Portugal [GC], § 163)", false),
                    new PssChoice("", "4. Není porušením práva na nestranný a nezávislý exekuční soud či exekutora, pokud exekuční soud odmítne námitku podjatosti exekučního soudce či exekutora, protože tato námitka nesplňuje formální náležitosti. ", "(Mikhail Mironov v. Russia, §§ 34-40)", false),
                    new PssChoice("", "5. Není porušením práva na nestranný a nezávislý exekuční soud či exekutora, pokud exekuční soud či exekutor posoudí námitku podjatosti „očima nezávislého pozorovatele“ a námitku podjatosti zamítne. ", "(Clarke v. the United Kingdom (dec.))", false),
                    new PssChoice("", "6. Není porušením práva na nestranný a nezávislý exekuční soud či exekutora, pokud zamítnutá námitka podjatosti, byla následně posouzena v plném rozsahu soudem vyššího stupně, který se vypořádal s problémem, na nějž tato námitka mířila.", "(De Haan v. the Netherlands, §§ 52-55; Helle v. Finland, § 46; Crompton v. the United Kingdom, § 79; Denisov v. Ukraine [GC], §§ 65, 67 and 72)", false),
                    new PssChoice("", "7. Není porušením práva na nestranný a nezávislý exekuční soud či exekutora, pokud předchozí vztah soudce exekučního soudu či exekutora k dané věci byl marginální a uplynula od něj již dlouhá doba.", "(McGonnell v. the United Kingdom, §§ 52-57)", false),
                    new PssChoice("", "8. Skutečnost, že soudce exekučního soudu či exekutor má pokrevní pouto se členem advokátní kanceláře, jejíž člen je právním zástupcem jiné strany exekučního řízení, jehož se účastní rovněž uvedený soudce či exekutora, neznamená automaticky porušení nezávislosti či nestrannosti tohoto soudce exekučního soudu či exekutora.", "(Ramljak v. Croatia, §§ 29, Koulias v. Cyprus, §§ 62-64)", false),
                    new PssChoice("", "9. Skutečnost, že soudci exekučního soudu a exekutoři se znají jako kolegové, mohou se potkávat v budově soudu nebo dokonce sdílejí své kanceláře, není sám o sobě nezvratným důkazem o porušení jejich nezávislosti či nestrannosti.", "(Steck-Risch and Others v. Liechtenstein, § 48)", false),
                    new PssChoice("", "10. Skutečnost, že má soudce exekučního soudu či exekutor detailní znalost projednávané věci i z jiných zdrojů, než jsou tvrzení a důkazy účastníků, není porušením jeho nestrannosti, pokud je výsledné rozhodnutí založeno na tvrzeních a důkazech předložených účastníky. ", "(Morel v. France, § 45)", false),
                    new PssChoice("", "11. Skutečnost, že se soudce odvolacího soudu nevyloučil z projednávání věci v situaci, kdy se účastnil jiných souvisejících exekučních řízení jako soudce exekučního soudu, není porušením jeho nestrannosti.", "(Golubović v. Croatia, § 52)", false),
                    new PssChoice("", "12. Není porušením nestrannosti exekučního soudu, pokud předsedové soudů vykonávali pouze správní (manažerské a organizační) funkce, které byly striktně odděleny od funkce soudní.", "(Parlov-Tkalčić proti Chorvatsku, § 88–95)", false),
                    new PssChoice("", "13. Není porušením nestrannosti soudce exekučního soudu či exekutora, a tudíž ani práva na spravedlivý proces to, že se soudce exekučního soudu či exekutor nevyloučil z projednání odvolání v občanskoprávní věci, ačkoli se předtím účastnil řady jiných podobných řízení, nebyla důkazem postačujícím pro vyvrácení domněnky nestrannosti. ", "(Golubović proti Chorvatsku, § 52)", false),
                    new PssChoice("", "14. Z požadavku na nestrannost exekučního soudu či exekutora nevyplývá pro nadřízený soud, který zrušil správní nebo soudní rozhodnutí exekučního soudu či exekutora, povinnost vrátit věc k projednání jinému jurisdikčnímu orgánu nebo jinak obsazenému útvaru téhož orgánu, tzn. že není porušením práva na spravedlivý proces, je-li nadřízeným soudem, vrácena zrušená věc témuž exekučnímu soudu či exekutorovi, který dříve vydal rozhodnutí, jež bylo nadřízeným soudem zrušeno.", "(Ringeisen proti Rakousku, § 97 in fine)", false),
                    new PssChoice("", "15. S požadavkem nestrannosti není neslučitelné, jestliže se tentýž soudce exekučního soudu účastní nejprve rozhodování o meritu věci a následně řízení, v němž je posuzována přípustnost opravného prostředku proti tomuto rozhodnutí. ", "(Warsicka proti Polsku, § 38–47)", false),
                ],
                false
            )
        ],
        false,
        false
    ),
    new PssSection(
        "II.\tABSENCE FÉROVOSTI PROCESU",
        "Níže uvedené požadavky se vztahující se na soudy se použijí i na exekutory, neboť Podle § 28 zákona č. 120/2001 Sb." +
        " o soudních exekutorech a exekuční činnosti (exekuční řád), v platném znění (věta druhá) " +
        "se úkony exekutora se považují za úkony exekučního soudu." +
        "\n" +
        "\n" +
        "Právo na férovost soudního procesu, zahrnujícího i exekuční řízení, je význačnou vlastností " +
        "a jedním ze základních principů demokratické společnosti [1]. " +
        " Instituce státu musí zajistit v každém individuálním případě, že požadavky férového procesu byly dodrženy [2]." +
        " Tyto požadavky se vztahují na doručení informace o existenci soudního řízení, včetně exekučního řízení, " +
        "zajištění rovnosti procesních zbraní účastníků " +
        "soudního řízení, povinnosti soudu i exekutora posoudit a zhodnotit argumenty účastníků řízení. " +
        "\n"+
        "\n" +
        "Stížnosti účastníků soudního řízení i exekučního řízení" +
        " na to, že tyto požadavky spravedlivého procesu byly dodrženy, jsou soudy či exekutoři podle situace " +
        "povinny zkoumat se zvláštní pečlivostí a opatrností [3]." +
        " Podstata práva na přístup k soudu je narušena, když pravidla přestanou sloužit cílům „právní jistoty“ [4] " +
        "a „řádného výkonu spravedlnosti“ [5].",
        "[1] (Airey v. Ireland, § 24; Stanev v. Bulgaria [GC], § 231, Pretto and Others v. Italy, § 21)" +
        "[2] (Dombo Beheer B.V. v. the Netherlands, § 33 in fine); [3] (Fabris v. Francie [GC], § 72 in fine; Wagner a JMWL v. Lucembursko, § 96); "+
        "[4] (Nideröst-Huber v. Švýcarsko, § 30); [5] (Beian proti Rumunsku (č. 1), § 39)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ce.2.1", "1. Exekuční soud či exekutor neumožnil „praktický a účinný“ přístup do soudního řízení. Přístup k exekučnímu soudu byl jen teoretický nebo iluzorní. "," (Airey v. Irsko, § 24; Perez v. Francie [GC], § 80)"),
                    new PssChoice("ce.2.2", "2. Exekuční soud či exekutor neumožnil účastnit se řízení, které bylo zahájeno protistranou a hájit v tomto řízení zájmy účastníka. "," (Dilipak a Karakaya v. Turecko, § 85-88)"),
                    new PssChoice("ce.2.3", "3. Exekuční soud či exekutor vytvořil jednu či více překážek, která zabránila v tom, aby byl případ projednán věcně příslušným exekučním soudem. "," (Zubac v. Chorvatsko [GC], § 98)"),
                    new PssChoice("ce.2.4", "4. Exekuční soud či exekutor neposoudil námitky týkající se práv a svobod zaručených Úmluvou a jejími Protokoly, přesto, že je účastník řízení v řízení předložil, a vnitrostátní soudy je nezkoumali obzvláště přísně a pečlivě přesto, že jsou k tomu povinny. "," (Wagner a J.M.W.L. proti Lucembursku, § 96)"),
                    new PssChoice("ce.2.5", "5. Jako příslušníkovi mezinárodní organizace imunita této mezinárodní organizace před exekučním soudem či exekutorem nebyla respektována. "," (Stichting Mothers of Srebrenica and Others v. the Netherlands (dec.), § 139)"),
                    new PssChoice("ce.2.6", "6. Exekuční soud či exekutor neučinil nezbytná opatření, aby informoval účastníka o řízení, která se ho týkají. "," (Dilipak a Karakaya v. Turecko, § 85-88)"),
                    new PssChoice("ce.2.7", "7. Exekuční soud či exekutor znemožnil, účastníkovi, aby měl jasnou a praktickou příležitost napadnout čin, který je zásahem do jeho práv. "," (Bellet v. Francie, § 36; Nunes Dias v. Portugalsko (dec.))"),
                    new PssChoice("ce.2.8", "8. Exekuční soud s náležitou péčí nezajistil účastníkovi, aby byl informován o řízení či řízeních, která se ho týkají, aby se mohl k exekučnímu soudu dostavit a bránit se a aby bylo zajištěno jeho právo na přístup k soudu. "," (Schmidt v Latvia, §§ 86-90, 92 a 94-95)"),
                    new PssChoice("ce.2.9", "9. Účastník nebyl s dostatečným předstihem informován o datu a místě jednání exekučního soudu, protože exekuční soud před prvním soudním jednání neověřil, že účastník byl s dostatečným předstihem informován o datu a místě jednání soudního jednání. Exekuční soud musí jít nad rámec pouhého odkazu na zaslání soudního předvolání a musí co nejlépe využít dostupné důkazy, aby bylo možné zjistit, zda byla nepřítomná strana skutečně o jednání informována v dostatečném předstihu, což však soud neučinil.", "(Gankin a další v. Rusko, §§ 34 a 48,"),
                    new PssChoice("ce.2.11", "10. Exekuční soud či exekutor neodročil jednání přesto, že zjistil, že nepřítomný účastník neobdržel předvolání k soudu či k provedení úkonu včas.", "(Gankin a další v. Rusko, § 39 a 42)"),
                    new PssChoice("ce.2.12", "11. Odvolání proti rozhodnutí podané jedním účastníkem řízení nebylo druhému účastníkovi doručeno, takže posléze uvedený účastník neměl možnost se k tomuto odvolání vyjádřit. "," (Beer v. Austria, § 19)"),
                    new PssChoice("ce.2.13", "12. K řádné účasti odvolatele na odvolacím řízení je nezbytné, aby mu soud z vlastního podnětu poskytl dokumenty, které má k dispozici. Není tudíž podstatné, že stěžovatel proti neposkytnutí příslušných dokumentů nic nenamítal nebo že se pokusil získat přístup ke spisu. "," (Kerojärvi proti Finsku, § 42)"),
                    new PssChoice("ce.2.14", "13. Je porušením práva na spravedlivý proces účastníka řízení, pokud zákonodárce za situace, kdy již devět let probíhalo řízení, jehož účastníkem byl stát a stěžovatelé měli proti němu k dispozici pravomocný vykonatelný rozsudek, zasáhl legislativně do probíhajícího řízení, přičemž cílem zásahu bylo ovlivnit blížící se výsledek řízení ve prospěch státu. "," (Stran Greek Refineries a Stratis Andreadis proti Řecku, § 49–50)"),
                    new PssChoice("ce.2.15", "14. Je porušením práva na spravedlivý proces, pokud došlo k přijetí zákona, který pro účely praxe vyřešil hmotněprávní otázky a učinil tak bezpředmětným další pokračování sporu před kasačním soudem, jenž se v té době nacházel v rozhodující fázi. "," (Papageorgiou proti Řecku)"),
                    new PssChoice("ce.2.16", "15. Je porušením práva na spravedlivý proces, pokud stát, ačkoli sám není účastníkem řízení, prostřednictvím svých legislativních pravomocí exekuční řízení zmaří. "," (Ducret proti Francii, § 33–42)"),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Pouhá skutečnost, že výsledek exekučního řízení nedopadl ve vás prospěch, neznamená, že vaše práva na spravedlivý proces byla porušena. ", "(Andronicou and Constantinou v. Cyprus, § 201)", false),
                    new PssChoice("", "2. Pochybení exekučního soudu či exekutora ohledně férovosti procesu byla napravena týmž exekučním soudem či exekutorem nebo soudem vyššího stupně v pozdější fázi řízení.", "(Helle v. Finland, §§ 46 and 54, Schuler-Zgraggen v. Switzerland, § 52)", false),
                    new PssChoice("", "3. Účastník řízení nevyužil možnosti napadnout předchozí procesní pochybení či rozhodnutí exekutora před exekučním soudem, i když k tomu měl příležitost. ", "(Oerlemans v. the Netherlands, §§ 53-58; British-American Tobacco Company Ltd v. the Netherlands, § 78, Air Canada v. the United Kingdom, § 62)", false),
                    new PssChoice("", "4. Přijetí obecných právních předpisů se může pro účastníky řízení ukázat jako nepříznivé, aniž by bylo skutečně zacíleno na probíhající soudní řízení a jeho účelem bylo obejití zásady zákonnosti. Právní předpis, na němž je výsledek řízení závislý, může být v průběhu soudního řízení prohlášen za neústavní, aniž by bylo úmyslem jakkoli toto soudní řízení ovlivnit, a bylo tím ovlivněno právo na spravedlivý proces.", "(Gorraiz Lizarraga a ostatní proti Španělsku, § 72; Dolca proti Rumunsku (rozh.))", false),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "III.\tPORUŠENÍ PRÁVA NA KONTRADIKTORNÍ PROCES",
        "Níže uvedené požadavky se vztahující se na soudy se použijí i na exekutory, neboť podle § 28 zákona č. 120/2001 Sb. " +
        "o soudních exekutorech a exekuční činnosti (exekuční řád), v platném znění (věta druhá) se úkony exekutora " +
        "se považují za úkony exekučního soudu." +
        "\n" +
        "\n" +
        "Požadavky vyplývající z práva na kontradiktorní řízení jsou v zásadě shodné jak v občanskoprávních, tak v trestních věcech [1]." +
        " Snaha ušetřit čas a urychlit řízení ze strany soudu či exekutora neodůvodňuje nerespektování základní zásady" +
        "rovnosti procesních zbraní v řízení [2]. " +
        "Právo na kontradiktorní řízení v zásadě znamená, že účastníci trestního nebo občanskoprávního řízení, včetně exekučního, " +
        "musejí mít možnost seznámit " +
        "se se všemi důkazy nebo stanovisky předloženými s cílem ovlivnit rozhodnutí soudu či exekutora podle situace" +
        " a vyjádřit se k nim [3]. Úkolem podle Úmluvy je zajistit, zda je exekuční řízení" +
        " jako celek bylo spravedlivé, a to včetně způsobu dokazování [4]. " +
        "\n" +
        "\n" +
        "Exekuční soud nebo exekutor podle situace musí tudíž zjistit, zda byly důkazy " +
        "předloženy způsobem, který poskytoval záruku spravedlivého řízení [5]. Úmluva jako taková ovšem nestanoví žádná " +
        "pravidla týkající se důkazů jako takových [6]. Přípustnost důkazů a způsob jejich hodnocení jsou primárně předmětem " +
        "vnitrostátní právní úpravy a jejího výkladu vnitrostátními soudy, včetně exekučních soudů i exekutorů [7]. " +
        "Totéž platí i pro důkazní hodnotu a důkazní břemeno [8].",
        "[1] (Werner proti Rakousku, § 66); [2] (Nideröst-Huber v. Švýcarsko, § 30); " +
        "[3] (Ruiz-Mateos proti Španělsku, § 63; McMichael proti Spojenému království, § 80); " +
        "[4] (Elsholz proti Německu [velký senát], § 66); [5] (Blücher proti České republice, § 65); " +
        "[6] (Mantovanelli proti Francii, § 34); [7] (García Ruiz proti Španělsku [velký senát], § 28); " +
        "[8] (Tiemann proti Francii a Německu (rozh.))",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ce.3.1", "1. Účastníkovi bylo znemožněno předložit exekučnímu soudu či exekutorovi argumenty a důkazy s potřebnou účinností. ","(H. v. Belgie, § 53, Dombo Beheer BV v. Nizozemsko, § 33 in fine)"),
                    new PssChoice("ce.3.2", "2. Účastníkovi poskytnuta přiměřená příležitost předložit jeho případ exekučnímu soudu či exekutorovi - včetně jeho důkazů - za podmínek, které ho neznevýhodňují vůči druhé straně.", "(Regner v. Česká republika [GC], § 146; Dombo Beheer BV v. Nizozemsko, § 33)"),
                    new PssChoice("ce.3.3", "3. Účastník neměl možnost získat dostatečné znalosti o průběhu exekučního řízení a komentovat všechny předložené důkazy nebo vyjádření podaná s cílem ovlivnit rozhodnutí exekučního soudu či exekutora.", "(Ruiz-Mateos v. Španělsko, § 63; McMichael v. Spojené království, § 80; Vermeulen v. Belgie, § 33; Lobo Machado v. Portugalsko, § 31; Kress v. Francie [GC], § 74)"),
                    new PssChoice("ce.3.4", "4. Účastník neměl možnost seznámit se s důkazy před exekučním soudem či exekutorovi nebo možnost vyjádřit se k jejich existenci, obsahu a autentičnosti vhodnou formou a ve vhodnou dobu.", "(Krčmář a další v. Česká republika, § 42; Immeubles Groupe Kosser v. Francie, § 26)"),
                    new PssChoice("ce.3.5", "5. Jako zranitelnému jednotlivci, například v důsledku zdravotní poruchy, se účastníkovi v průběhu exekučního řízení nedostalo potřebné ochrany či pomoci tak, aby byla zachována jeho důstojnost a právo na soukromý život. "," (Evers v. Germany, §§ 82-84)"),
                    new PssChoice("ce.3.6", "6. Zákonná a podzákonná pravidla týkající se přípustnosti znaleckých posudků a dokumentů neposkytla účastníkovi možnost tyto znalecké posudky a důkazy účinně napadnout. "," (Letinčić v. Croatia, § 50)"),
                    new PssChoice("ce.3.7", "7. Pokud byl soudem ustanoven znalec, nebylo účastníkovi umožněno, aby se účastnil výslechů, které soudní znalec uskutečnil nebo, jež uvádí ve svých dokumentech, že je uskutečnil, v důsledku čehož se účastník nemohl účinně účastnit exekučního řízení. "," (Letinčić v. Croatia, § 50; Devinar v. Slovenia, § 46)"),
                    new PssChoice("ce.3.8", "8. Exekuční soud neodročil soudní jednání, přesto, že účastník o to žádal z důvodu, že neměl možnost seznámit se s důkazy před soudem nebo možnost vyjádřit se k jejich existenci, obsahu a autentičnosti vhodnou formou a ve vhodnou dobu. ", "(Yvon v. Francie, § 39)"),
                    new PssChoice("ce.3.9", "9. Účastník nemohl předložit argumenty nebo důkazy, protože nevěděl o existenci práva nebo existenci souvisejících řízeních. ", "(Schmidt v. Lotyšsko, bod 96)"),
                    new PssChoice("ce.3.10", "10. Účastník soudního řízení neměl možnost seznámit se s důkazy předloženými soudu, ani možnost vyjádřit se přiměřenou formou a v přiměřené lhůtě k jejich existenci, obsahu a pravosti, v případě potřeby i docílením odročení jednání. "," (Krčmář a ostatní proti České republice, § 42; Immeubles Groupe Kosser proti Francii, § 26; Yvon proti Francii § 39)"),
                    new PssChoice("ce.3.11", "11. Účastník řízení neměl příležitost navrhnout důkaz potřebný k tomu, aby se svým nárokem uspěl. "," (Clinique des Acacias a ostatní proti Francii, § 37)"),
                    //new PssChoice("ce.3.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Bylo porušeno procesní právo účastníka řízení, avšak důsledek tohoto porušení neměl reálně možnost ovlivnit výsledek exekučního řízení, který byl v souladu s právem. ", "(Stepinska v. France, § 18)", false),
                    new PssChoice("", "2. Procesní pochybení, kterého se dopustil soud, je zcela marginální. ","(Liga Portuguesa de Futebol Profissional v. Portugal (déc.), §§ 36-40; Kılıç and Others v. Turkey (dec.); Holub v. the Czech Republic (dec); Cavajda v. the Czech Republic (dec.)", false),
                    new PssChoice("", "3. Procesní pochybení bylo pouze izolované a nemohlo způsobit neférovost exekučního řízení jako takového. ", "(Miroļubovs and Others v. Latvia, § 103)", false),
                    new PssChoice("", "4. Pokud byl důkaz předložený exekučnímu soudu či exekutorovi získán nezákonně, je pouze na exekučním soudu, zda určí, zda takový důkaz je v sexekučním řízení přípustný. ", "(López Ribalda and Others v. Spain [GC], § 150)", false),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "IV.\tPORUŠENÍ PRÁVA NA ROVNOST PROCESNÍCH ZBRANÍ",
        "Níže uvedené požadavky se vztahující se na soudy se použijí i na exekutory, neboť podle § 28 zákona č. 120/2001 Sb." +
        " o soudních exekutorech a exekuční činnosti (exekuční řád), v platném znění (věta druhá) se úkony exekutora " +
        "se považují za úkony exekučního soudu." +
        "\n" +
        "\n" +
        "Zásada „rovnosti procesních zbraní“ je nedílnou součástí pojmu spravedlivého řízení v širším slova smyslu. " +
        "Požadavek „rovnosti procesních zbraní“" +
        " ve smyslu „spravedlivé rovnováhy“ mezi účastníky řízení se v zásadě uplatní v civilních i trestních věcech [1]. " +
        "Zachování „spravedlivé rovnováhy“ mezi účastníky řízení znamená, že každému účastníku řízení " +
        "musí být poskytnuta přiměřená možnost předestřít svoji věc – včetně důkazů – za podmínek, které jej podstatným " +
        "způsobem neznevýhodňují vůči protistraně [2]. " +
        "\n" +
        "\n" +
        "Vnitrostátní soudy, včetně exekučních soudů i exekutorů, jsou povinny " +
        "řádně posoudit podání, argumenty a důkazy," +
        " předložené účastníky řízení [3]. Vnitrostátním soudům, včetně exekučních soudů a exekutorů " +
        "přísluší také posouzení relevance navrhovaných důkazů [4].",
        "[1] (Feldbrugge proti Nizozemsku, § 44); [2] (Dombo Beheer B.V. proti Nizozemsku, § 33); " +
        "[3] (Van de Hurk proti Nizozemsku, § 59); [4] (Centro Europa 7 S.r.l. a Di Stefano proti Itálii [velký senát], § 198)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ce.4.1", "1. Exekučním soud či exekutor upřel účastníkovi mít exekučním soudem či exekutorem posouzeny jeho tvrzení a důkazy, které byly předloženy exekučnímu soudu či exekutorovi před vydáním předmětného rozhodnutí exekučního soudu či exekutora, pro nějž taková tvrzení a důkazy byly relevantní. ", "(Kraska v. Switzerland, § 30; Van de Hurk v. the Netherlands, § 59; Perez v. France [GC], § 80)"),
                    new PssChoice("ce.4.2", "2. Exekuční soud či exekutor porušil právo účastníka řízení na to, aby vyjádření, které předložil/a, byla skutečně „vyslechnuta“, tj. aby je exekuční soud či exekutor řádně zvážil. ", "(Donadze v. Georgia, § 35)"),
                    new PssChoice("ce.4.3", "3. Účastník exekučního řízení neměl příležitost vyjádřit svůj názor na určitý dokument ve spisu, včetně dokumentů, které si exekuční soud či exekutor opatřil z vlastního podnětu. "," K.S. proti Finsku, § 22) (Nideröst-Huber proti Švýcarsku, § 29; Pellegrini proti Itálii, § 45)"),
                    new PssChoice("ce.4.4", "4. Běh lhůt byl přerušen jen jednomu z účastníků exekučního řízení, čímž se druhý účastník ocitl v podstatné nevýhodě. "," (Platakou proti Řecku, § 48; Wynen proti Belgii § 32)"),
                    new PssChoice("ce.4.5", "5. Účastník exekučního řízení nebyl informován o podání k exekučnímu soudu, jež učinil jiný účastník exekučního řízení a nemohl se k němu vyjádřit. "," (APEH Üldözötteinek Szövetsége a ostatní proti Maďarsku, § 42)"),
                    new PssChoice("ce.4.6", "6. Exekuční soud či exekutor se konkrétně a výslovně nevyjádřil k tvrzení účastníka, které bylo rozhodné pro výsledek řízení. "," (Ruiz Torija v. Spain, § 30; Hiro Balani v. Spain, § 28; and compare Petrović and Others v. Montenegro, § 43)"),
                    new PssChoice("ce.4.7", "7. Nedostatek neutrality znalce spolu s jeho postavením a úlohou v exekučním řízení vychýlily rovnováhu ve prospěch jednoho účastníka řízení na úkor druhého. "," (Sara Lind Eggertsdottir proti Islandu, § 53)"),
                    new PssChoice("ce.4.8", "8. Protistrana měla oproti účastníkovi exekučního řízení významná zvýhodnění, co se týče přístupu k relevantním informacím, v důsledku čehož měla dominantní postavení v procesu a významně ovlivňovala posouzení exekučním soudem či exekutorem. "," (Yvon v. France, § 37)"),
                    new PssChoice("ce.4.9", "9. Účastníkovi exekučního řízení nebyl exekučním soudem či exekutorem umožněn přístup k relevantním dokumentům, jež byly v držení orgánu veřejné moci a jež byly významné pro výsledek exekučního řízení. "," (McGinley and Egan v. the United Kingdom, §§ 86 and 90)"),
                    new PssChoice("ce.4.10", "10. Exekuční soudce odmítl odročit jednání, i když účastník řízení musel být z urgentních důvodů převezen do nemocnice, což způsobilo, že ani právní zástupce účastníka řízení nebyl schopen zastupovat tohoto účastníka při jednání exekučního soudu. "," (Vardanyan and Nanushyan v. Armenia, §§ 88-90)"),
                    new PssChoice("ce.4.11", "11. Exekuční soud či exekutor nezvážil všechny hlavní argumenty a nepřezkoumal hlavní argumenty účastníka řízení, které v řízení předložil.", "(Buzescu v. Rumunsko, § 67; Donadze v. Gruzie, § 35)"),
                    new PssChoice("ce.4.12", "12. Exekučním soudem či exekutorem bylo opomenuto obecné skutkové a právní pozadí situace relevantní pro exekuční řízení, přesto, že se ho účastník snažil soudu vylíčit.", "(Stankiewicz v. Polsko, § 70)"),
                    new PssChoice("ce.4.13", "13. Exekuční soud či exekutor nevzal v úvahu důkazy předložené účastníkem řízení, a nerespektoval jeho právo na to předložit důkazy takovým způsobem, který zaručuje spravedlivý proces.", "(Blücher v. Česká republika, § 65; Van de Hurk v. Nizozemsko, § 59)"),
                    new PssChoice("ce.4.14", "14. Přítomnost členů právního odboru orgánu veřejné moci jako věřitele na poradě exekučního soudu či při provádění úkonů exekutora, ať již „aktivní“ nebo „pasivní“, poté, co veřejně vyjádřili svůj názor na projednávanou věc, je nepřípustná, přesto k ní však došlo. "," (Vermeulen proti Belgii, § 34; Lobo Machado proti Portugalsku, § 32; Kress proti Francii, § 87)"),
                    //new PssChoice("ce.4.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. " +
                "Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, " +
                "pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Exekuční soud či exekutor se vyjádřil k vaším hlavním argumentům, ale nevyjádřil se úplně ke všem vašim argumentům. "," (Van de Hurk v. the Netherlands, § 61; García Ruiz v. Spain [GC], § 26; Jahnke and Lenoble v. France (déc.); Perez v. France [GC], § 81)", false),
                    new PssChoice("", "2. Vaše právo na férový proces jako účastníka řízení nemohlo být porušeno, pokud jste nepředložil relevantní a dostatečné důkazy o porušení vašich hmotněprávních nároků. ", "(Fleischner v. Germany, §§ 40-41)", false),
                    new PssChoice("", "3. Porušení vašich práv na spravedlivý proces je důsledkem významných chyb v soudním řízení z vaší strany, které jsou vám objektivně přičitatelné.", "(Zubac v. Croatia [GC], §§ 90 and 121)", false),
                    new PssChoice("", "4. Porušení vašich práv na spravedlivý proces je důsledkem významné nečinnosti či nedbalosti z vaší strany. ", "(Avotiņš v. Latvia [GC], §§ 123-24; Barik Edidi v. Spain (dec.), § 45)", false),
                    new PssChoice("", "5. Právo na férovost procesu nezajišťuje, že týž exekuční soud nebo odlišné soudy či exekutoři nemohou dojít k odlišným závěrům a vydat odlišná rozhodnutí.", "(Santos Pinto v. Portugal, § 41)", false),
                    new PssChoice("", "6. Není pochybením exekučního soudu či exekutora, pokud vydal odlišná rozhodnutí v situacích, jež byly objektivně skutkově odlišné.", "(Uçar v. Turkey (dec.))", false),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "V.\tPORUŠENÍ POVINNOSTI ŘÁDNÉHO ODŮVODNĚNÍ",
        "Níže uvedené požadavky se vztahující se na soudy se použijí i na exekutory, neboť podle § 28 zákona č. 120/2001 Sb." +
        " o soudních exekutorech a exekuční činnosti (exekuční řád), v platném znění (věta druhá) se úkony " +
        "exekutora se považují za úkony exekučního soudu." +
        "\n" +
        "\n" +
        "Záruky zakotvené v článku 6 odst. 1 zahrnují i povinnost soudů, včetně exekučních soudů a exekutorů, řádně odůvodňovat svá rozhodnutí [1]. " +
        "Odůvodněné rozhodnutí je pro účastníky řízení důkazem o tom, že jejich věc byla skutečně projednána." +
        " Ačkoli má exekuční soud i exekutor určitý prostor pro uvážení při volbě argumentů a připouštění důkazů," +
        " je povinen vysvětlit svoji činnost tím, že svá rozhodnutí odůvodní [2]. Odůvodnění musí účastníkům řízení " +
        "umožňovat účinné využití případného práva na odvolání [3]. " +
        "\n" +
        "\n" +
        "Rozsah, v jakém se tato povinnost odůvodnit rozhodnutí" +
        " uplatní, se může lišit podle povahy rozhodnutí exekučního soudu či exekutora [4] a může být určen pouze ve světle okolností daného " +
        "případu: je nezbytné vzít v úvahu mimo jiné různorodost podání, jež může účastník řízení činit, " +
        "a existující rozdíly mezi smluvními státy, pokud jde o zákonná ustanovení, obyčejová pravidla, " +
        "právní názory a vyhlašování a vyhotovování rozsudků [5].",
        "[1] (H. proti Belgii, § 53); [2] (Suominen proti Finsku, § 36); [3] (Hirvisaari proti Finsku, § 30 in fine); " +
        "[4] (Ruiz Torija proti Španělsku, § 29; Hiro Balani proti Španělsku, § 27); [5] (Ruiz Torija proti Španělsku, § 29; " +
        "Hiro Balani proti Španělsku, § 27)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z " +
                "následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ce.5.1", "1. Odůvodnění poskytnuté exekutorem bylo příliš stručné a obecné, takže dotčenému účastníku řízení neumožnilo řádně odůvodněnou žalobou napadnout jeho závěry, a exekuční soud rozhodující o skutkových otázkách účastníku neumožnil předložit argumenty na podporu jeho tvrzení. "," (Hentrich proti Francii, § 56)"),
                    new PssChoice("ce.5.2", "2. Exekuční soud při zkoumání přiměřenosti omezení přístupu k němu nevzal v úvahu procesní chyby, které se udály v řízení před exekutorem a které vám jako účastníkovi řízení bránily v přístupu do exekučního řízení a účastník řízení byl proto nucen nést nadměrné břemeno z důvodu těchto chyb exekutora. ","(Zubac v. Croatia [GC], [GC], §§ 90-95, § 119)"),
                    new PssChoice("ce.5.3", "3. Odůvodnění rozhodnutí exekučního soudu či exekutora musí účastníkům řízení umožňovat účinné využití případného práva na odvolání, což se však nestalo. "," (Hirvisaari v. Finland, § 30 in fine)"),
                    new PssChoice("ce.5.4", "4. Exekuční soud či exekutor nereagoval výslovným a konkrétním odůvodněním na hlavní argumenty účastníka řízení předložené exekučního soudu či exekutora. "," (Buzescu proti Rumunsku, § 67; Donadze proti Gruzii, § 35)"),
                    new PssChoice("ce.5.5", "5. Dovolací soud odmítl dovolání účastníka jako nepřípustné, aniž by nepřípustnost takového odvolání podrobně zdůvodnil. ","(Kukkonen v. Finland (no. 2), § 24; Bufferne v. France (dec.))"),
                    new PssChoice("ce.5.6", "6. Existuje dlouhodobě ustálená judikatura, avšak nejvyšší soud se od ní odklonil, což však podrobně neodůvodnil, čímž porušil právo účastníka řízení na řádně odůvodněné rozhodnutí. ","(Petko Petkov proti Bulharsku, § 32–34)"),
                    new PssChoice("ce.5.7", "7. Je porušením práva na spravedlivý proces, pokud se rozhodnutí odvolacího soudu opíralo, byť jen podpůrně, o zákon, který byl přijat v průběhu řízení a měl vliv na jeho výsledek. "," (Anagnostopoulos a ostatní proti Řecku, § 20–21)"),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Článek 6 odst. 1 nevyžaduje, aby odvolací soud podrobněji odůvodnil rozhodnutí, jímž na základě konkrétního zákonného ustanovení nepřipustí dovolání pro jeho nedostatečné vyhlídky na úspěch, aniž by podal bližší vysvětlení. ", "(Burg a ostatní proti Francii, (rozh.); Gorou proti Řecku (č. 2) [velký senát], § 41)", false),
                    new PssChoice("", "2. V případě návrhu na připuštění odvolání, které je podmínkou pro projednání věci nadřízeným soudem a případné vynesení rozsudku, nelze článek 6 odst. 1 vykládat tak, že vyžaduje, aby se i na samotné zamítnutí návrhu vztahoval požadavek podrobného odůvodnění. "," (Kukkonen proti Finsku č. 2, § 24; viz též Bufferne proti Francii (rozh.))", false),
                    new PssChoice("", "3. Při zamítnutí odvolání může odvolací soud v zásadě jen potvrdit odůvodnění rozhodnutí nižšího soudu, aniž by tím bylo porušeno právo na spravedlivý proces.", "(Garcia Ruiz proti Španělsku [velký senát], § 26 – naopak viz Tatishvili proti Rusku, § 62)", false),
                    new PssChoice("", "4. Požadavky právní jistoty a ochrany legitimních očekávání v sobě nezahrnují právo na ustálenou judikaturu. Vývoj judikatury sám o sobě není v rozporu s řádným výkonem spravedlnosti, neboť nemožnost uplatňovat dynamický a flexibilní přístup by bránila jakýmkoli reformám či zlepšením. Sjednocení judikatury si však může vyžádat jistý čas, a lze tedy tolerovat určitá období, kdy je judikatura rozkolísaná, aniž by tím byla ohrožena právní jistota. "," (Unédic proti Francii, § 74; Atanasovski proti „Bývalé jugoslávské republice Makedonii“, § 38; Nejdet Şahin a Perihan Şahin proti Turecku [velký senát], § 83; Albu a ostatní proti Rumunsku, § 36 a 40–43)", false),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "VI. NEPŘIMĚŘENÁ DÉLKA EXEKUČNÍHO ŘÍZENÍ NEBO PŘEKÁŽKY JEHO ŘÁDNÉMU PROVEDENÍ",
        "Níže uvedené požadavky se vztahující se na soudy se použijí i na exekutory, neboť podle § 28 zákona č. 120/2001 Sb." +
        " o soudních exekutorech a exekuční činnosti (exekuční řád), v platném znění (věta druhá) se úkony exekutora " +
        "se považují za úkony exekučního soudu." +
        "\n" +
        "\n" +
        "Článek 6 odst. 1 chrání výkon pravomocných a závazných soudních rozhodnutí (na rozdíl od výkonu rozhodnutí," +
        " která mohou být přezkoumána soudem vyššího stupně) [1]. " +
        "Právo na výkon takovýchto rozhodnutí vydaných kterýmkoli soudem tvoří nedílnou součást „práva na soud“ [2]. " +
        "Právo na spravedlivý proces zaručuje také právo na přístup k vykonávacímu řízení, tj. právo na zahájení vykonávacího řízení [3]." +
        " Jinak by bylo ustanovení článku 6 odst. 1 zbaveno veškerých užitečných účinků [4]. " +
        "\n" +
        "\n" +
        "Účinná ochrana účastníka řízení a obnovení zákonného stavu předpokládají povinnost orgánů veřejné moci orgánů respektovat rozsudek [5]." +
        " Nepřiměřené prodlení při výkonu závazného rozsudku může být porušením Úmluvy [6]. " +
        "Opatření učiněná vnitrostátními orgány pro zajištění výkonu rozhodnutí musí být přiměřená a dostatečná pro daný účel [7]" +
        " vzhledem k povinnostem těchto orgánů při výkonu rozhodnutí, neboť právě ony jsou tím, kdo vykonává veřejnou moc [8]. " +
        "V tomto smyslu musí být výkon rozhodnutí úplný a vyčerpávající, nikoli pouze částečný [9]. " +
        "Stát proto může nést odpovědnost za to, že orgány veřejné moci provádějící výkon rozhodnutí nevyvíjejí dostatečnou aktivitu anebo dokonce výkonu brání [10].",
        "[1] (Ouzounis a ostatní proti Řecku, § 21); [2] (Hornsby proti Řecku, § 40; Scordino proti Itálii (č. 1) [velký senát], § 196)" +
        "[3] (Apostol proti Gruzii, § 56); [4] (Burdov proti Rusku, § 34 a 37); [5] (Hornsby proti Řecku, § 41; Kyrtatos proti Řecku, § 31–32);" +
        "[6] (Raylyan proti Rusku, § 31); [7] (Ruianu proti Rumunsku, § 66); [8] (Ruianu proti Rumunsku, § 72–73); " +
        "[9] (Matheus proti Francii, § 58; Sabin Popescu proti Rumunsku, § 68–76); [10] (Fuklev proti Ukrajině, § 67)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z " +
                "následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je",
                [],
                [
                    new PssChoice("ce.6.1", "1. Exekuce nesmí být bezdůvodně oddalována, jinak je porušeno právo oprávněného na spravedlivý proces. "," (Immobiliare Saffi proti Itálii [velký senát], § 74)"),
                    new PssChoice("ce.6.2", "2. Odmítá-li exekuční soud či exekutor respektovat rozhodnutí nadřízeného soudu – což může vést k situaci, kdy je v jednom řízení vydáno několik rozsudků opakovaně rušících vydané rozhodnutí –, je takový postup porušením vašeho práva na spravedlivý proces. "," (Turczanik proti Polsku, § 49–51)"),
                    new PssChoice("ce.6.3", "3. Pokud státní orgány, včetně exekutora, více než pět let neučinily potřebná opatření pro splnění pravomocného a vykonatelného soudního rozhodnutí, zbavily tím ustanovení článku 6 odst. 1 veškerých užitečných účinků, a porušily právo věřitele na spravedlivý proces. ","(Hornsby proti Řecku, § 45)"),
                    new PssChoice("ce.6.4", "4. Právo na spravedlivý proces je porušeno v případě, že úřady zhruba čtyři roky odmítaly využít policejní asistence při výkonu soudního rozhodnutí o vyklizení vydaného proti nájemci, nebo více než šest let trvajícím odkladem výkonu rozhodnutí způsobeným zásahem zákonodárce, jímž bylo zpochybněno soudní rozhodnutí o vyklizení, které tak v důsledku napadených zákonných ustanovení pozbylo veškerých užitečných účinků. "," (Lunari proti Itálii, § 38–42; Immobiliare Saffi proti Itálii [velký senát], § 70 a 74)"),
                    new PssChoice("ce.6.5", "5. Povinnost zajistit splnění povinností uložených státu rozsudkem nesou státní orgány, a to ode dne, kdy se rozsudek stává závazným a vykonatelným. Pokud tak neučinily, a musel jste proti státu zahájit exekuci, bylo porušeno vaše právo na spravedlivý proces ze strany státu, neboť po osobě, která v soudním řízení dosáhla vydání rozsudku proti státu, nelze požadovat, aby zahájila samostatné vykonávací řízení. ","((Yavorivskaya proti Rusku, § 25; Burdov proti Rusku (č. 2), §§ 68-69))"),
                    new PssChoice("ce.6.6", "6. Státní orgán nemůže omlouvat neplacení soudem přiznaného dluhu nedostatkem financí nebo jiných zdrojů. Pokud tak přesto činí, jde o porušení vašeho práva jako věřitele na spravedlivý proces. ","(Burdov proti Rusku, § 35; Amat-G Ltd a Mebaghishvili proti Gruzii, § 47; Scordino proti Itálii (č. 1) [velký senát], § 199)"),
                    new PssChoice("ce.6.7", "7. V případě výkonu rozhodnutí týkajícího se zajištění náhradního ubytování státní orgán nemůže omlouvat nesplnění rozsudku nedostatkem náhradního ubytování. Pokud tak přesto činí, jde o porušení vašeho práva na spravedlivý proces. ","(Prodan proti Moldavsku, § 53)"),
                    new PssChoice("ce.6.8", "8. Je porušením práva na spravedlivý proces, pokud nebyla veřejnými orgány uplatněna sankce vůči třetí (soukromé) osobě za to, že nespolupracovala s orgány pověřenými výkonem pravomocných vykonatelných rozhodnutí, například když soukromé zařízení, v němž žily dvě děti, více než tři roky bránilo výkonu rozhodnutí o jejich osvojení. ","(Pini a ostatní proti Rumunsku, § 186–188)"),
                    new PssChoice("ce.6.9", "9. Pokud však stát učinil veškeré zákonem stanovené kroky k tomu, aby donutil soukromou osobu podrobit se soudnímu rozhodnutí, nemůže nést odpovědnost za to, že dlužník odmítl své závazky splnit. "," (Fociac proti Rumunsku, § 74 a 78)"),
                    //new PssChoice("ce.6.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. " +
                "Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, " +
                "pak práva na spravedlivý proces nebyla porušena",
                [],
                [
                    new PssChoice("", "10. Celková doba výkonu rozhodnutí v délce devíti měsíců, nebyla vzhledem k okolnostem nepřiměřená. ", "(Moroko proti Rusku, § 43–45)", false),
                    new PssChoice("", "11. Není porušením práva na spravedlivý proces, pokud jako oprávněný musíte činit nikoli nepřiměřené úkony, abyste v exekučním řízení vymohl splnění rozsudku. ", "(Burdov proti Rusku (č. 2), § 69)", false),
                    new PssChoice("", "12. Stát nemůže odpovídat za nezaplacení vykonatelné pohledávky způsobené platební neschopnosti „soukromého“ dlužníka. ", "(Sanglier proti Francii, § 39)", false),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    )
];