import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import PssDocument from "../Model/PssDocument";

function DocumentSettingsDialog({documentId}: { documentId: number }) {
    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);
    const [document, setDocument] = useState({d: documents.getDocument(documentId)});

    useEffect(() => {
        let subscriberId = documents.subscribeChange((changedDoc: PssDocument) => {
            if (changedDoc.id === document.d.id) {
                setDocument({d: changedDoc});
            }
        });

        return () => {
            documents.unsubscribe(subscriberId);
        };
    });

    const ref_name = React.createRef<HTMLInputElement>();

    const [dialogShowStatus, dialogSetShow] = useState(false);
    const handleClose = () => dialogSetShow(false);
    const handleShow = () => dialogSetShow(true);
    const handleChange = () => {
        document.d.name = ref_name.current?.value as string;
    };
    const handleSave = () => {
        handleChange();
        documents.setDocument(document.d);
        handleClose();
    };

    return (
        <>
            <FontAwesomeIcon onClick={() => handleShow()} icon="cogs" className={"ml-2"}/>
            <Modal show={dialogShowStatus} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Nastavení dokumentu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label htmlFor={"doc_name"}>Název dokumentu</label>
                    <input type={"text"} ref={ref_name} placeholder={"Název dokumentu"} defaultValue={document.d.name}
                           onChange={handleChange} name={"doc_name"}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Neukládat
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Uložit a Zavřít
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DocumentSettingsDialog;