import {useEffect, useRef, useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import {useParams} from "react-router-dom";
import GenerateDocumentSelections from "./Utils/GenerateDocumentSelections";
import DownloadRTF from "./Utils/DownloadRTF";

export default function SoudceKarnyPodnet() {

    let {documentId: str_doc_id} = useParams();
    let documentId: number = parseInt(str_doc_id as string);

    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);
    const storedDocument = documents.getDocument(documentId);
    const [state, setState] = useState({doc: storedDocument});

    useEffect(() => {
        let subscriberId = documents.subscribeChange(changedDoc => {
            if (state.doc.id === changedDoc.id) {
                setState({doc: changedDoc});
            }
        });

        return () => {
            documents.unsubscribe(subscriberId);
        };
    });

    const downloadContents = useRef(null);

    return <>
        <Card className={"mt-3"} id={"form-identifications"}>
            <Card.Header>
                <Card.Title>
                    Vyplňte prosím následující identifikace pro vybraný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Soudce či soudci, jenž se porušení práv na spravedlivý proces
                            dopustil(i):</Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.soudci}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.soudci = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Soud či soudy, na kterém tento soudce či soudci působí:</Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.soudy}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.soudy = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení podatele:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.podatel}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.podatel = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení dotčené osoby, pokud se neshodují s podatelem:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.dotceni}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.dotceni = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
        <Card className={"mt-3"} id={"document-preview"}>
            <Card.Header>
                <Card.Title>
                    Náhled na generovaný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body ref={downloadContents}>
                <h1>Podnět ministru spravedlnosti na podání návrhu na zahájení kárného řízení proti soudci či
                    soudcům</h1>
                <p>Já níže uvedený podávám tímto podnět ministru spravedlnosti na podání návrhu na zahájení kárného
                    řízení
                    proti soudci, a to z níže uvedených důvodů.</p>
                <h2>1. OBSAH PODNĚTU</h2>
                <p>Žádám ministra spravedlnosti, aby níže uvedená mnou namítaná porušení zásad spravedlivého procesu
                    níže
                    uvedeným soudcem či soudcům posoudil a na základě tohoto posouzení případně podal návrh na zahájení
                    kárného řízení proti tomuto soudci či soudcům.</p>
                <p>Dále žádám ministra spravedlnosti, aby mě vyrozuměl o tom, jakým způsobem tento můj podnět
                    posoudil. </p>
                <p>Nedojde-li ze strany ministra spravedlnosti k podání návrhu na zahájení kárného řízení proti níže
                    uvedenému soudci či soudcům, žádám, aby se ve vyrozumění, jenž mi zašle, argumentačně vypořádal se
                    všemi
                    níže uvedenými porušeními práva na spravedlivý proces, jichž se uvedený soudce či soudci dopustil či
                    dopustili. V této souvislosti podotýkám, že podle dále uvedené judikatury Ústavního soudu (např. I.
                    ÚS
                    403/03, N 160/38 SbNU 277), argumentuje-li účastník názory judikatury, musí se veřejný orgán s
                    těmito
                    názory argumentačně vypořádat (případně i tak, že vysvětlí, proč je nepovažuje pro danou věc
                    relevantní). </p>
                <p>Rovněž žádám, aby zároveň toto vyrozumění kromě mé osoby bylo zasláno rovněž na vědomí níže uvedeným
                    útvarům Evropské komise, Evropského parlamentu a Rady Evropy, jež jsou odpovědné za dohled nad
                    dodržováním práva na spravedlivý proces podle Evropské úmluvy na ochranu lidských práv a svobod v
                    členských státech Rady Evropy a Evropské unie.</p>
                <p>Konečně žádám, aby mi bylo zmíněné vyrozumění zasláno v zákonné lhůtě, tedy nejpozději do 30 dnů od
                    přijetí tohoto mého podnětu, nebo v případě, že se uvedené posouzení bude jevit jako složité, abych
                    byl
                    v této zákonné lhůtě informován o prodloužení lhůty k posouzení na nejvýše 60 dnů.</p>
                <h2>2. PRÁVNÍ ZÁKLAD PODNĚTU</h2>
                <p>Podle čl. 2 odst. 3 Ústavy ČR a čl. 2 odst. 3 Listiny základních práv a svobod „státní moc slouží
                    všem
                    občanům a lze ji uplatňovat jen v případech, v mezích a způsoby, které stanoví zákon.“</p>
                <p>Podle čl. 10 Ústavy ČR „vyhlášené mezinárodní smlouvy, k jejichž ratifikaci dal Parlament souhlas a
                    jimiž
                    je Česká republika vázána, jsou součástí právního řádu; stanoví-li mezinárodní smlouva něco jiného
                    než
                    zákon, použije se mezinárodní smlouva.“</p>
                <p>Podle čl. 6 odst. 1 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou
                    a
                    vyhlášené ve sdělení č. 209/1992 Sb., má „každý právo na to, aby jeho záležitost byla spravedlivě,
                    veřejně a v přiměřené lhůtě projednána nezávislým a nestranným soudem, zřízeným zákonem, který
                    rozhodne
                    o jeho občanských právech nebo závazcích nebo o oprávněnosti jakéhokoli trestního obvinění proti
                    němu.“</p>
                <p>Podle konstantní judikatury Ústavního soudu (např. N 160/38 SbNU 277) se nezávislost rozhodování
                    obecných
                    soudů uskutečňuje v ústavním a zákonném procesněprávním a hmotněprávním rámci, jenž je představován
                    především principy řádného a spravedlivého procesu, jak vyplývají z čl. 36 a násl. Listiny
                    základních
                    práv a svobod, jakož i z čl. 1 Ústavy České republiky a čl. 6 odst. 1 Evropské úmluvy o ochraně
                    lidských
                    práv a svobod. Soudci proto musí principy řádného a spravedlivého procesu, jak jsou vymezeny v
                    uvedených
                    ustanoveních a na nich navazující judikatuře, při své činnosti dodržovat, aby se vyloučila libovůle
                    při
                    jejich rozhodování.</p>
                <p>Podle konstantní judikatury Evropského soudu pro lidská práva (ESLP) mají vnitrostátní orgány, včetně
                    soudů, v každém jednotlivém případě povinnost zajistit účastníkům sporu spravedlivý proces (např.
                    ESLP,
                    Dombo Beheer B.V. v. Nizozemí); tento závěr je sdílen i českými soudci: „Někdy u soudu nejsme
                    schopni
                    nastolit skutečnou spravedlnost, ale vždycky Vám musíme dát férové zacházení (Kateřina Šimáčková,
                    bývalá
                    soudkyně Ústavního soudu ČR, Lze skrze právo dosáhnout spravedlnosti? In: Youtube [online].
                    Zveřejněno
                    10.11.2014 [cit. 1.6.2018]. Dostupné z: https://www.youtube.com/watch?v=QDSSW2PokIM)</p>
                <p>Podle konstantní judikatury Ústavního soudu České republiky je notorietou, tj. skutečností, jíž si
                    musí
                    být vědom každý orgán nadaný veřejnou moci a aplikující právo, že „mechanická aplikace [práva]
                    abstrahující, resp. neuvědomující si, a to buď úmyslně, nebo v důsledku nevzdělanosti, smysl a účel
                    právní normy, činí z práva nástroj odcizení a absurdity." (I.ÚS 2920/09 ze dne 8. 2. 2011, N 14/60
                    SbNU
                    141, Pl. ÚS 33/97 (N 163/9 SbNU 399; 30/1998 Sb.)), přičemž takový orgán „není absolutně vázán
                    doslovným
                    zněním zákonného ustanovení, nýbrž se od něj smí a musí odchýlit v případě, kdy to vyžaduje ze
                    závažných
                    důvodů […] některý z principů, jež mají svůj základ v ústavně konformním právním řádu jako
                    významovém
                    celku" (I.ÚS 2920/09, N 14/60 SbNU 141).</p>
                <p>Podle čl. 13 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou a
                    vyhlášené
                    ve sdělení č. 209/1992 Sb., „každý, jehož práva a svobody přiznané touto Úmluvou byly porušeny, musí
                    mít
                    účinné právní prostředky nápravy před národním orgánem, i když se porušení dopustily osoby při
                    plnění
                    úředních povinností.“</p>
                <p>Podle § 79 odst. 1 zákona č. 6/2002 Sb. o soudech a soudcích, v platném znění, soudci a přísedící
                    jsou
                    při výkonu své funkce nezávislí a jsou vázáni pouze zákonem. Jsou povinni vykládat jej podle svého
                    nejlepšího vědomí a svědomí a rozhodovat v přiměřených lhůtách bez průtahů, nestranně a spravedlivě
                    a na
                    základě skutečností zjištěných v souladu se zákonem.</p>
                <p>Podle § 80 odst. 1 – 4 zákona č. 6/2002 Sb. o soudech a soudcích, v platném znění, soudce a přísedící
                    je
                    povinen vykonávat svědomitě svou funkci a při výkonu funkce a v občanském životě se zdržet všeho, co
                    by
                    mohlo narušit důstojnost soudcovské funkce nebo ohrozit důvěru v nezávislé, nestranné a spravedlivé
                    rozhodování soudů. Dále je soudce v zájmu záruk nezávislosti a nestrannosti výkonu soudcovské funkce
                    soudce povinen (i) prosazovat a obhajovat nezávislost soudnictví a jeho dobrou pověst, (ii) chovat
                    se
                    tak, aby nezavdal příčinu ke snížení důvěry v soudnictví a důstojnosti soudcovské funkce, (iii)
                    odmítnout jakýkoliv zásah, nátlak, vliv, přání nebo žádost, jejichž důsledkem by mohlo být ohrožení
                    nezávislosti soudnictví, (iv) vystupovat nezaujatě a ke stranám nebo účastníkům řízení přistupovat
                    bez
                    ekonomických, sociálních, rasových, etnických, sexuálních, náboženských nebo jiných předsudků, a též
                    dbá
                    svým chováním o to, aby jeho nestrannost nebyla důvodně zpochybňována. </p>
                <p>Krom toho je soudce je povinen vykonávat svou funkci v souladu se zájmem na řádném výkonu soudnictví.
                    Při
                    své rozhodovací činnosti je soudce zejména povinen poskytnout každému účastníku řízení nebo straně
                    soudního řízení anebo jejich zástupcům plnou možnost k uplatnění jejich práv; nesmí však od nich, s
                    výjimkou případů stanovených zákony o řízení před soudy, jednostranně přijímat nebo jim podávat
                    informace nebo s nimi jednat o skutkové podstatě projednávané věci nebo o procesních otázkách, které
                    na
                    ni mohou mít vliv, a rovněž dbá o to, aby jeho rozhodnutí bylo srozumitelně a pečlivě odůvodněno.
                    Konečně, soudce je povinen při své činnosti mimo výkon funkce soudce a při výkonu svých politických
                    práv
                    si počínat tak, aby tato činnost neohrožovala nebo nenarušovala důvěru v nezávislé, nestranné a
                    spravedlivé rozhodování soudu nebo nenarušovala důstojnost soudcovské funkce anebo mu nebránila v
                    řádném
                    výkonu funkce soudce.</p>
                <p>Podle § 82 odst. 1 zákona č. 6/2002 Sb. o soudech a soudcích, v platném znění, soudce nese
                    odpovědnost za
                    svou odbornou úroveň při výkonu soudcovské funkce.</p>
                <h2>3. PORUŠENÍ ODŮVODŇUJÍCÍ PODÁNÍ NÁVRHU NA ZAHÁJENÍ KÁRNÉHO ŘÍZENÍ</h2>
                <p><strong>Níže uvedený soudce či soudci se podle mého názoru dopustili následujících porušení práv
                    spravedlivý proces podle čl. 6 odst. 1, Evropské úmluvy pro lidská práva a případně též odst. 2 a 3
                    v
                    případě trestních aspektů, tak jak jsou vymezena v příslušné judikatuře Evropského soudu pro lidská
                    práva vykládající toto ustanovení, která je u příslušného porušení vždy zmíněna. Těmito porušeními
                    se
                    níže uvedený soudce či soudci dopustili porušení svých povinností podle výše uvedených §§ 79 odst.
                    1, 80
                    odst. 1 – 4, 82 odst. 1 zákona č. 6/2002 Sb. o soudech a soudcích, v platném znění, což je podle
                    mého
                    názoru důvodem pro podání podnětu k zahájení kárného řízení proti níže uvedenému soudci či
                    soudcům.</strong></p>
                {GenerateDocumentSelections(state.doc)}
                <p><strong>Vysvětlení: Očíslované věty či části vět představují právní pravidla týkající se práv na
                    spravedlivý proces vyjádřená v rozsudcích Evropského soudu pro lidská práva. Odkazy ve formátu
                    „jméno v.
                    (proti) název státu, § číslo či §§ čísla“, například „Cipolletta v. Italy §§ 33-37“ představují
                    odkazy
                    na rozsudky (judikáty) Evropského soudu pro lidská práva ve Štrasburku, ve kterých byla jednotlivá
                    právní pravidla formulována. Plný obsah těchto rozsudků lze dohledat zadáním jejich názvu v databázi
                    rozsudků Evropského soudu pro lidská práva ve Štrasburku HUDOC na internetové adrese:
                    https://hudoc.echr.coe.int/</strong></p>

                <h2>4. OSOBY, JEŽ SE UVEDENÝCH PORUŠENÍ DOPUSTILY</h2>
                <p><strong>Soudce či soudci, jenž se podle podatele výše uvedených porušení práv na spravedlivý proces
                    dopustil(i):</strong></p>
                <p>{state.doc.generatorSettings.soudci}</p>
                <p><strong>Soud či soudy, na kterém tento soudce či soudci působí: </strong></p>
                <p>{state.doc.generatorSettings.soudy}</p>
                <h2>5. IDENTIFIKACE PODATELE A DOTČENÝCH OSOB, JSOU-LI OD PODATELE ODLIŠNÉ</h2>
                <p><strong>Jméno a příjmení podatele:</strong></p>
                <p>{state.doc.generatorSettings.podatel}</p>
                <p><strong>Jméno a příjmení osoby či osob dotčené výše uvedenými porušeními, pokud jsou odlišné od
                    podatele:</strong></p>
                <p>{state.doc.generatorSettings.dotceni}</p>
                <h2>6. ADRESÁT PODNĚTU A DALŠÍ INSTITUCE DOTČENÉ TÍMTO PODNĚTEM</h2>
                <p><strong>Adresát:</strong></p>
                <p>Ministr spravedlnosti
                    <br/>Ministerstvo spravedlnosti ČR
                    <br/>Vyšehradská 16
                    <br/>128 10 Praha 2
                </p>
                <p>posta@msp.justice.cz</p>
                <p><strong>Na vědomí</strong></p>
                <p>Evropské komisi, GŘ Spravedlnost a spotřebitelé, Ředitelství D - Základní práva a právní stát, Odbor
                    C2:
                    Politiky v oblasti základních práv: JUST-C-DIR@ec.europa.eu, JUST-C-SEC@ec.europa.eu,,
                    JUST-C2@ec.europa.eu</p>
                <p>Výboru pro občanské svobody, spravedlnost a vnitřní věci Evropského parlamentu,
                    libe-secretariat@ep.europa.eu</p>
                <p>Řídicímu výboru pro lidská práva Výboru ministrů Rady Evropy: DGI-CDDH@coe.int,
                    DGI-CDDH-Reform@coe.int </p>
                <p>Podatel dává tento podnět na vědomí Evropské komisi, neboť v podnětu popsané porušení podle jeho
                    názoru
                    představuje rovněž porušení Českou republikou obecných zásad práva Evropské unie tvořených podle
                    ustanovení čl. 6 odst. 3 Smlouvy o Evropské unii základními právy, která jsou zaručena Evropskou
                    úmluvou
                    o ochraně lidských práv a základních svobod: konkrétně pak v tomto ohledu porušení základního práva
                    na
                    právní jistotu ohledně řádného výkonu spravedlnosti, jež je jedním ze základních práv zaručených
                    Evropskou úmluvou o ochraně lidských práv a základních svobod v každé demokratické společnosti (viz
                    např. ESLP, Beian proti Rumunsku (č. 1), § 39), ESLP, Pretto a ostatní proti Itálii, § 21) a rovněž
                    základní hodnotou Evropské unie zmíněnou v čl. 2 Smlouvy o Evropské unii; stejně tak představuje
                    toto
                    porušení porušení základních práv, jež vyplývají z ústavních tradic společných členským státům EU,
                    včetně ČR: konkrétně pak v tomto ohledu porušení základního práva vyplývajícího z ústavních ČR a
                    ostatních členských států EU, na výslovné a konkrétní zdůvodnění argumentu týkajícího se porušení
                    konkrétního aspektu práva na spravedlivý proces (viz např. Ústavní soud ČR, N 160/38 SbNU 277).</p>
                <p>Podatel dává tento podnět rovněž na vědomí Řídicímu výboru pro lidská práva Výboru ministrů Rady
                    Evropy,
                    aby tento výbor upozornil na nedodržování Evropské úmluvy na ochranu lidských práv a svobod ze
                    strany
                    České republiky jako státu, jenž se přesto k dodržování této Úmluvy zavázal, a umožnil tímto
                    případné
                    spuštění příslušných dozorových či sankčních mechanismů Výboru ministrů Rady Evropy či jiných orgánů
                    Rady Evropy vůči České republice v důsledku popsaného nedodržování uvedené Úmluvy Českou
                    republikou. </p>
                <p>V případě, že ministr spravedlnosti řádně nevyrozumí podatele o tom, jak naložil s touto jeho
                    žádostí,
                    a/nebo toto vyrozumění nedá na vědomí Řídicímu výboru pro lidská práva Výboru ministrů Rady Evropy
                    ve
                    výše uvedené lhůtě 30 dnů či ve složitějších případech 60 dnů, podatel zároveň žádá Řídicí výbor,
                    aby po
                    uplynutí uvedené lhůty vyzval generálního tajemníka Rady Evropy, aby si podle čl. 52 Evropské úmluvy
                    pro
                    lidská práva vyžádal u příslušných orgánů České republiky vysvětlení o způsobu, jakým české právo
                    zajišťuje účinné provádění ustanovení čl. 6 § 1 Úmluvy ve vztahu k soudcům v rámci civilního procesu
                    vzhledem k výše popsaným porušením tohoto ustanovení, jichž se uvedený soudce či soudce dopustil či
                    dopustili; zejména pak žádá Řídicí výbor, aby tlumočil generálnímu tajemníkovi Rady Evropy
                    požadavek,
                    aby si generální tajemník Rady Evropy vyžádal vysvětlení, jaká opatření byla proti výše uvedenému
                    soudci
                    či soudcům přijata, aby se taková porušení v budoucnu již v České republice neopakovala a bylo
                    zajištěno
                    účinné provádění ustanovení čl. 6 § 1 Úmluvy ve vztahu nejen k dotčeným soudcům, ale i všem soudcům
                    České republiky.</p>
            </Card.Body>
            <Card.Footer className={"text-end"} id={"download-buttons"}>
                <Button variant={"success"} className={"m-2"} onClick={() => DownloadRTF(downloadContents, state.doc.id)}>Stáhnout jako Word</Button>
            </Card.Footer>
        </Card>
    </>;
}