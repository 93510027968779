import './Layout.scss';
import {Container} from "react-bootstrap";
import {Route, Routes} from "react-router-dom";
import Documents from '../Views/Documents';
import DocumentDetail from "../Views/DocumentDetail";
import DocumentDescriptions from '../Views/DocumentDescriptions';
import DocumentGenerator from "../Views/DocumentGenerator";

function Layout() {
    return (
        <Container className="p-2" fluid>
            <Routes>
                <Route path="/" element={<Documents/>}/>
                <Route path="/documents" element={<Documents/>}/>
                <Route path="/documents/:documentId" element={<DocumentDetail/>}/>
                <Route path="/documents/:documentId/descriptions" element={<DocumentDescriptions/>}/>
                <Route path="/documents/:documentId/generator" element={<DocumentGenerator/>}/>
            </Routes>
        </Container>
    );
}

export default Layout;
