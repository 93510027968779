import {useEffect, useRef, useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import {useParams} from "react-router-dom";
import GenerateDocumentSelections from "./Utils/GenerateDocumentSelections";
import DownloadRTF from "./Utils/DownloadRTF";

export default function StatniUrednikKarnyPodnet() {

    let {documentId: str_doc_id} = useParams();
    let documentId: number = parseInt(str_doc_id as string);

    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);
    const storedDocument = documents.getDocument(documentId);
    const [state, setState] = useState({doc: storedDocument});

    useEffect(() => {
        let subscriberId = documents.subscribeChange(changedDoc => {
            if (state.doc.id === changedDoc.id) {
                setState({doc: changedDoc});
            }
        });

        return () => {
            documents.unsubscribe(subscriberId);
        };
    });

    const downloadContents = useRef(null);

    return <>
        <Card className={"mt-3"} id={"form-identifications"}>
            <Card.Header>
                <Card.Title>
                    Vyplňte prosím následující identifikace pro vybraný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Státní úředník či úředníci, jenž se podle podatele výše uvedených porušení práv na
                            spravedlivý proces dopustil(i), včetně jejich funkce:</Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.statniUrednik}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.statniUrednik = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Orgán veřejné moci, jehož součástí je uvedený úředník či úředníci: </Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.organVerejneMoci}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.organVerejneMoci = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení podatele:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.podatel}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.podatel = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení dotčené osoby, pokud se neshodují s podatelem:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.dotceni}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.dotceni = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
        <Card className={"mt-3"} id={"document-preview"}>
            <Card.Header>
                <Card.Title>
                    Náhled na generovaný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body ref={downloadContents}>
                <h1>Podnět náměstkovi ministra vnitra pro státní službu na podání návrhu na zahájení kárného řízení
                    proti státnímu úředníkovi či úředníkům</h1>
                <p>Já níže uvedený podávám tímto podnět náměstkovi ministra vnitra pro státní službu na podání návrhu na
                    zahájení kárného řízení proti státnímu úředníkovi či úředníkům, a to z níže uvedených důvodů.</p>
                <h2>1. OBSAH PODNĚTU</h2>
                <p>Žádám náměstka ministra vnitra pro státní službu, aby níže uvedená mnou namítaná porušení zásad
                    spravedlivého procesu níže uvedeným úředníkem či úředníkům posoudil a na základě tohoto posouzení
                    případně podal návrh na zahájení kárného řízení proti tomuto úředníkovi či úředníkům. </p>
                <p>Dále žádám náměstka ministra vnitra pro státní službu, aby mě vyrozuměl o tom, jakým způsobem tento
                    můj podnět posoudil. </p>
                <p>Nedojde-li ze strany náměstka ministra vnitra pro státní službu spravedlnosti k podání návrhu na
                    zahájení kárného řízení proti níže uvedenému úředníkovi či úředníkům, žádám, aby se ve vyrozumění,
                    jenž mi zašle, argumentačně vypořádal se všemi níže uvedenými porušeními práva na spravedlivý
                    proces, jichž se uvedený státní úředník či úředníci dopustil či dopustili. V této souvislosti
                    podotýkám, že podle dále uvedené judikatury Ústavního soudu (např. I. ÚS 403/03, N 160/38 SbNU 277),
                    argumentuje-li účastník názory judikatury, musí se veřejný orgán s těmito názory argumentačně
                    vypořádat (případně i tak, že vysvětlí, proč je nepovažuje pro danou věc relevantní). </p>
                <p>Rovněž žádám, aby zároveň toto vyrozumění kromě mé osoby bylo zasláno rovněž na vědomí níže uvedeným
                    útvarům Evropské komise, Evropského parlamentu a Rady Evropy, jež jsou odpovědné za dohled nad
                    dodržováním práva na spravedlivý proces podle Evropské úmluvy na ochranu lidských práv a svobod v
                    členských státech Rady Evropy a Evropské unie.</p>
                <p>Konečně žádám, aby mi bylo zmíněné vyrozumění zasláno v zákonné lhůtě, tedy nejpozději do 30 dnů od
                    přijetí tohoto mého podnětu, nebo v případě, že se uvedené posouzení bude jevit jako složité, abych
                    byl v této zákonné lhůtě informován o prodloužení lhůty k posouzení na nejvýše 60 dnů.</p>
                <h2>2. PRÁVNÍ ZÁKLAD PODNĚTU</h2>
                <p>Podle čl. 2 odst. 3 Ústavy ČR a čl. 2 odst. 3 Listiny základních práv a svobod „státní moc slouží
                    všem občanům a lze ji uplatňovat jen v případech, v mezích a způsoby, které stanoví zákon.“</p>
                <p>Podle čl. 10 Ústavy ČR „vyhlášené mezinárodní smlouvy, k jejichž ratifikaci dal Parlament souhlas a
                    jimiž je Česká republika vázána, jsou součástí právního řádu; stanoví-li mezinárodní smlouva něco
                    jiného než zákon, použije se mezinárodní smlouva.“</p>
                <p>Podle čl. 6 odst. 1 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou
                    a vyhlášené ve sdělení č. 209/1992 Sb., má „každý právo na to, aby jeho záležitost byla spravedlivě,
                    veřejně a v přiměřené lhůtě projednána nezávislým a nestranným soudem, zřízeným zákonem, který
                    rozhodne o jeho občanských právech nebo závazcích nebo o oprávněnosti jakéhokoli trestního obvinění
                    proti němu,“ přičemž řízením se ve smyslu čl. 6 odst. 1 Evropské úmluvy o ochraně lidských práv a
                    svobod může rozumět i řízení před jiným orgánem než je soud. Státní úředník či úředníci, jež jednají
                    jako součást takového jiného orgánu, proto musí principy řádného a spravedlivého procesu, jak jsou
                    vymezeny v uvedených ustanoveních a na nich navazující judikatuře, při své činnosti dodržovat, aby
                    se vyloučila libovůle při jejich rozhodování.</p>
                <p>Podle konstantní judikatury Ústavního soudu České republiky je notorietou, tj. skutečností, jíž si
                    musí být vědom každý orgán nadaný veřejnou moci a aplikující právo, že „mechanická aplikace [práva]
                    abstrahující, resp. neuvědomující si, a to buď úmyslně, nebo v důsledku nevzdělanosti, smysl a účel
                    právní normy, činí z práva nástroj odcizení a absurdity." (I.ÚS 2920/09 ze dne 8. 2. 2011, N 14/60
                    SbNU 141, Pl. ÚS 33/97 (N 163/9 SbNU 399; 30/1998 Sb.)), přičemž takový orgán „není absolutně vázán
                    doslovným zněním zákonného ustanovení, nýbrž se od něj smí a musí odchýlit v případě, kdy to
                    vyžaduje ze závažných důvodů […] některý z principů, jež mají svůj základ v ústavně konformním
                    právním řádu jako významovém celku" (I.ÚS 2920/09, N 14/60 SbNU 141).</p>
                <p>Podle čl. 13 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou a
                    vyhlášené ve sdělení č. 209/1992 Sb., „každý, jehož práva a svobody přiznané touto Úmluvou byly
                    porušeny, musí mít účinné právní prostředky nápravy před národním orgánem, i když se porušení
                    dopustily osoby při plnění úředních povinností.“</p>
                <p>Podle § 77 odst. 1 zákona č. 234/2014 Sb. o státní službě, v platném znění, je státní zaměstnanec je
                    povinen, kromě jiného, (i) vykonávat službu nestranně, v mezích svého oprávnění a zdržet se při
                    výkonu služby všeho, co by mohlo ohrozit důvěru v jeho nestrannost, (ii) při výkonu služby dodržovat
                    právní předpisy vztahující se k jejímu výkonu, služební předpisy a příkazy k výkonu služby, (iii)
                    prohlubovat si vzdělání podle pokynů služebního orgánu, (iv) zdržet se jednání, které by mohlo vést
                    ke střetu veřejného zájmu se zájmy osobními, zejména nezneužívat informací nabytých v souvislosti s
                    výkonem služby ve prospěch vlastní nebo jiného, jakož i nezneužívat postavení státního zaměstnance,
                    (v) zachovávat pravidla slušnosti při úředním jednání, (vi) plně využívat služební dobu k výkonu
                    služby, (vii) řádně hospodařit s prostředky svěřenými mu služebním úřadem a střežit a ochraňovat
                    majetek, který mu byl svěřen, před poškozením, ztrátou, zničením a zneužitím, (viii) dodržovat
                    pravidla etiky státního zaměstnance vydaná služebním předpisem.</p>
                <p>Státní zaměstnanec je dále povinen dodržovat Pravidla etiky státního zaměstnance, jež jsou stanovena
                    služebním předpisem náměstka ministra vnitra pro státní službu č. 13/2015, a jež upravují a
                    podporují dodržování žádoucích standardů chování státního zaměstnance ve vztahu k veřejnosti a
                    spolupracovníkům s cílem zvyšovat důvěru veřejnosti ve státní správu.</p>
                <p>Podle čl. 3 těchto Pravidel etiky státní zaměstnanec má vykonávat službu v souladu s ústavním
                    pořádkem, zákonem o státní službě, dalšími zákony a ostatními právními předpisy, právem Evropské
                    unie, jakož i s mezinárodními smlouvami, které jsou součástí právního řádu České republiky, tedy
                    včetně Evropské úmluvy o ochraně lidských práv a svobod, i jejího čl. 6 odst. 1 ve světle příslušné
                    judikatury Evropského soudu pro lidská práva. Podle téhož článku státní zaměstnanec má jednat pouze
                    v rozsahu pravomoci svěřené služebnímu úřadu zákonem nebo na základě zákona a v souladu s jejím
                    účelem a v mezích svého oprávnění.</p>
                <p>Podle čl. 2 týchž Pravidel etiky státní zaměstnanec má jednat s vědomím, že veřejnost oprávněně
                    očekává otevřenou, přístupnou, řádně vedenou a vykonávanou státní správu; má proto jednat v souladu
                    se zásadami práva a spravedlnosti vyplývajícími z principů demokracie a právního státu, které jsou
                    obsaženy v Ústavě České republiky a v Listině základních práv a svobod, v duchu nedotknutelných
                    hodnot svobody, rovnosti a lidské důstojnosti atd. Státní zaměstnanec má ctít zákonnost výkonu
                    služby a uplatňovat rovný přístup ke všem osobám, jichž se činnost služebního úřadu v jednotlivém
                    případě dotýká.</p>
                <p>Podle čl. 4 těchto Pravidel etiky má státní zaměstnanec jednat přiměřeně, což znamená, že při plnění
                    služebních úkolů volí státní zaměstnanec řešení, které je v souladu s veřejným zájmem, je nestranné,
                    nezávislé a odpovídá okolnostem daného případu, a má dbát na to, aby při rozhodování skutkově
                    shodných nebo podobných případů nevznikaly nedůvodné rozdíly. Do práv a oprávněných zájmů dotčených
                    osob státní zaměstnanec má zasahovat jen za podmínek stanovených zákonem a v nezbytném rozsahu.</p>
                <p>Konečně, podle čl. 5 Pravidel etiky má státní zaměstnanec jednat profesionálně, což znamená, že má
                    vykonávat službu na vysoké odborné úrovni, přičemž je osobně odpovědný za kvalitu služby a rozvíjení
                    své odbornosti a své vzdělání si průběžně doplňuje, jakož i sleduje vývoj ve svém oboru nebo oborech
                    služby. Státní zaměstnanec má jednat profesionálně, nestranně a nezávisle, objektivně, čestně, bez
                    nepřiměřeného projevu emocí a bez sledování osobního prospěchu tak, aby neohrozil dobrou pověst,
                    vážnost a důvěryhodnost služebního úřadu.</p>
                <h2>3. PORUŠENÍ ODŮVODŇUJÍCÍ PODÁNÍ NÁVRHU NA ZAHÁJENÍ KÁRNÉHO ŘÍZENÍ</h2>
                <p><strong>Níže uvedený státní úředník či úředníci se podle mého názoru dopustili následujících porušení
                    práv spravedlivý proces podle čl. 6 odst. 1 Evropské úmluvy pro lidská práva, tak jak jsou vymezena
                    v příslušné judikatuře Evropského soudu pro lidská práva vykládající toto ustanovení, která je u
                    příslušného porušení vždy zmíněna. Těmito porušeními se níže uvedený úředník či úředníci dopustili
                    porušení svých povinností podle výše uvedených ustanovení § 77 odst. 1 zákona č. 234/2014 Sb. o
                    státní službě, jakož i porušení ustanovení čl. 2 – 5 Pravidel etiky stanovených služebním předpisem
                    náměstka ministra vnitra pro státní službu č. 13/2015, což je podle mého názoru důvodem pro podání
                    podnětu k zahájení kárného řízení proti níže uvedenému státnímu úředníkovi či úředníkům.</strong>
                </p>
                <p><strong>Dále uvedené právní povinnosti týkající se práv spravedlivého procesu vztahující se na soudy
                    a soudce podle dále uvedené judikatury Evropského soudu pro lidská práva, jež podle mého názoru byly
                    porušeny ze strany státního úředníka či úředníků, se použijí vedle soudů a soudců obdobně (mutatis
                    mutandis) i na státní úředníky, jež jsou součástí orgánu veřejné moci, jež vystupuje v řízení, na
                    nějž se § 6 odst. 1 EÚLP vztahuje, v obdobném postavení jako soud či soudce.</strong></p>

                {GenerateDocumentSelections(state.doc)}
                <p><strong>Vysvětlení: Očíslované věty či části vět představují právní pravidla týkající se práv na
                    spravedlivý proces vyjádřená v rozsudcích Evropského soudu pro lidská práva. Odkazy ve formátu
                    „jméno v.
                    (proti) název státu, § číslo či §§ čísla“, například „Cipolletta v. Italy §§ 33-37“ představují
                    odkazy
                    na rozsudky (judikáty) Evropského soudu pro lidská práva ve Štrasburku, ve kterých byla jednotlivá
                    právní pravidla formulována. Plný obsah těchto rozsudků lze dohledat zadáním jejich názvu v databázi
                    rozsudků Evropského soudu pro lidská práva ve Štrasburku HUDOC na internetové adrese:
                    https://hudoc.echr.coe.int/</strong></p>

                <h2>4. OSOBY, JEŽ SE UVEDENÝCH PORUŠENÍ DOPUSTILY</h2>
                <p><strong>Státní úředník či úředníci, jenž se podle podatele výše uvedených porušení práv na
                    spravedlivý proces dopustil(i), včetně jejich funkce:</strong></p>
                <p>{state.doc.generatorSettings.statniUrednik}</p>
                <p><strong>Orgán veřejné moci, jehož součástí je uvedený úředník či úředníci: </strong></p>
                <p>{state.doc.generatorSettings.organVerejneMoci}</p>
                <h2>5. IDENTIFIKACE PODATELE A DOTČENÝCH OSOB, JSOU-LI OD PODATELE ODLIŠNÉ</h2>
                <p><strong>Jméno a příjmení podatele</strong></p>
                <p>{state.doc.generatorSettings.podatel}</p>
                <p><strong>Jméno a příjmení osoby či osob dotčené výše uvedenými porušeními, pokud jsou odlišné od
                    podatele:</strong></p>
                <p>{state.doc.generatorSettings.dotceni}</p>
                <h2>6. ADRESÁT PODNĚTU A DALŠÍ INSTITUCE DOTČENÉ TÍMTO PODNĚTEM</h2>
                <p><strong>Adresát:</strong></p>
                <p>
                    Náměstek ministra vnitra pro státní službu
                    <br/>Ministerstvo vnitra,
                    <br/>Jindřišská 34, 110 00 Praha 1
                    <br/>statnisluzba@mvcr.cz
                </p>
                <p><strong>Na vědomí</strong></p>
                <p>
                    Evropské komisi, GŘ Spravedlnost a spotřebitelé, Ředitelství D - Základní práva a právní stát, Odbor
                    C2: Politiky v oblasti základních práv: JUST-C-DIR@ec.europa.eu, JUST-C-SEC@ec.europa.eu,,
                    JUST-C2@ec.europa.eu
                </p>
                <p>Výboru pro občanské svobody, spravedlnost a vnitřní věci Evropského parlamentu,
                    libe-secretariat@ep.europa.eu</p>
                <p>Řídicímu výboru pro lidská práva Výboru ministrů Rady Evropy: DGI-CDDH@coe.int,
                    DGI-CDDH-Reform@coe.int </p>
                <p>Podatel dává tento podnět na vědomí Evropské komisi, neboť v podnětu popsané porušení podle jeho
                    názoru představuje rovněž porušení Českou republikou obecných zásad práva Evropské unie tvořených
                    podle ustanovení čl. 6 odst. 3 Smlouvy o Evropské unii základními právy, která jsou zaručena
                    Evropskou úmluvou o ochraně lidských práv a základních svobod: konkrétně pak v tomto ohledu porušení
                    základního práva na právní jistotu ohledně řádného výkonu spravedlnosti, jež je jedním ze základních
                    práv zaručených Evropskou úmluvou o ochraně lidských práv a základních svobod v každé demokratické
                    společnosti (viz např. ESLP, Beian proti Rumunsku (č. 1), § 39), ESLP, Pretto a ostatní proti
                    Itálii, § 21) a rovněž základní hodnotou Evropské unie zmíněnou v čl. 2 Smlouvy o Evropské unii;
                    stejně tak představuje toto porušení porušení základních práv, jež vyplývají z ústavních tradic
                    společných členským státům EU, včetně ČR: konkrétně pak v tomto ohledu porušení základního práva
                    vyplývajícího z ústavních ČR a ostatních členských států EU, na výslovné a konkrétní zdůvodnění
                    argumentu týkajícího se porušení konkrétního aspektu práva na spravedlivý proces (viz např. Ústavní
                    soud ČR, N 160/38 SbNU 277).</p>
                <p>Podatel dává tento podnět rovněž na vědomí Řídicímu výboru pro lidská práva Výboru ministrů Rady
                    Evropy, aby tento výbor upozornil na nedodržování Evropské úmluvy na ochranu lidských práv a svobod
                    ze strany České republiky jako státu, jenž se přesto k dodržování této Úmluvy zavázal, a umožnil
                    tímto případné spuštění příslušných dozorových či sankčních mechanismů Výboru ministrů Rady Evropy
                    či jiných orgánů Rady Evropy vůči České republice v důsledku popsaného nedodržování uvedené Úmluvy
                    Českou republikou. </p>
                <p>V případě, že náměstek ministra vnitra pro státní službu řádně nevyrozumí podatele o tom, jak naložil
                    s touto jeho žádostí, a/nebo toto vyrozumění nedá na vědomí Řídicímu výboru pro lidská práva Výboru
                    ministrů Rady Evropy ve výše uvedené lhůtě 30 dnů či ve složitějších případech 60 dnů, podatel
                    zároveň žádá Řídicí výbor, aby po uplynutí uvedené lhůty vyzval generálního tajemníka Rady Evropy,
                    aby si podle čl. 52 Evropské úmluvy pro lidská práva vyžádal u příslušných orgánů České republiky
                    vysvětlení o způsobu, jakým české právo zajišťuje účinné provádění ustanovení čl. 6 § 1 Úmluvy
                    vzhledem k výše popsaným porušením tohoto ustanovení, jichž se uvedený státní úředník či úředníci
                    dopustil či dopustili; zejména pak žádá Řídicí výbor, aby tlumočil generálnímu tajemníkovi Rady
                    Evropy požadavek, aby si generální tajemník Rady Evropy vyžádal vysvětlení, jaká opatření byla proti
                    výše uvedenému státnímu úředníkovi či státním úředníkům přijata, aby se taková porušení v budoucnu
                    již v České republice neopakovala a bylo zajištěno účinné provádění ustanovení čl. 6 § 1 Úmluvy ve
                    vztahu nejen k dotčeným státním úředníkům, ale i všem státním úředníkům České republiky.</p>
            </Card.Body>
            <Card.Footer className={"text-end"} id={"download-buttons"}>
                <Button variant={"success"} className={"m-2"}
                        onClick={() => DownloadRTF(downloadContents, state.doc.id)}>Stáhnout jako Word</Button>
            </Card.Footer>
        </Card>
    </>;
}