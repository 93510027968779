import React, {ChangeEvent, useEffect, useState} from "react";
import {FindChoiceById, PssSection} from "../Model/PssDatabase";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import {useNavigate, useParams} from "react-router-dom";
import {Breadcrumb, Button, Card, Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import DocumentSettingsDialog from "../Utilities/DocumentSettingsDialog";
import GetTypeDatabase from "../Database/FindDatabase";
import {PssAction} from "../Model/PssEnums";

export default function DocumentDescriptions() {
    const navigate = useNavigate();

    let {documentId: str_doc_id} = useParams();
    let documentId: number = parseInt(str_doc_id as string);

    const ref_doc_name = React.createRef<HTMLElement>();

    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);
    const _pssdocument = documents.getDocument(documentId);
    const [state, setState] = useState({doc: _pssdocument});

    const typeDatabase: PssSection[] = GetTypeDatabase(state.doc.type);

    useEffect(() => {
        let subscriberId = documents.subscribeChange(changedDoc => {
            if (state.doc.id === changedDoc.id) {
                setState({doc: changedDoc});
                if (ref_doc_name.current) {
                    ref_doc_name.current.textContent = state.doc.name;
                }
            }
        });

        return () => {
            documents.unsubscribe(subscriberId);
        };
    });

    const handleContinue = function (): void {
        state.doc.setCurrentStep(PssAction.download);
        documents.setDocument(state.doc);
        window.scrollTo(0, 0);
        navigate(`/documents/${state.doc.id}/generator`, {replace:true});
    };

    const handleBack = function (): void {
        state.doc.setCurrentStep(PssAction.selections);
        documents.setDocument(state.doc);
        window.scrollTo(0, 0);
        navigate(`/documents/${state.doc.id}`, {replace: true});
    }

    const handleChange = function(e: ChangeEvent<HTMLTextAreaElement>): void {
        state.doc.setChoiceDescription(e.target.id, e.target.value)
        documents.setDocument(state.doc);
    }

    return (
        <>
            <Row className={"g-0 border-bottom mb-2"}>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#/">Hlavní stránka</Breadcrumb.Item>
                        <Breadcrumb.Item active>Dokumenty</Breadcrumb.Item>
                        <Breadcrumb.Item active> <span ref={ref_doc_name}>{state.doc.name}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xs={1} className={"text-end"}>
                    <DocumentSettingsDialog documentId={documentId}/>
                </Col>
            </Row>
            <Navbar sticky={"top"} variant={"dark"} bg={"dark"} className={"mb-2 rounded"}>
                <Container fluid className={"m-2"}>
                    <Navbar.Brand className={"me-auto"}>
                        Krok 4 - Popište vybraná porušení vašich práv
                    </Navbar.Brand>
                    <Nav className={""}>
                        <Nav.Link
                            as={Button}
                            variant={"primary"}
                            className={"me-3 text-light"}
                            onClick={() => handleBack()}
                        >
                            Zpět
                        </Nav.Link>
                        <Nav.Link
                            as={Button}
                            variant={"success"}
                            className={"ms-3 text-light"}
                            onClick={() => handleContinue()}
                        >
                            Pokračovat
                        </Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            {Array.from(state.doc.choices.keys()).map((choice_key) => {
                let choice = FindChoiceById(choice_key, typeDatabase);
                let stored = state.doc.choices.get(choice_key);
                return <Card key={choice_key} className={"mt-2 mb-2"}>
                    <Card.Header>
                        <Card.Title>{choice?.label}</Card.Title>
                        <small>{choice?.description}</small>
                    </Card.Header>
                    <Card.Body>
                        <small>Volitelně popište toto konkrétní porušení, max. 1000 znaků</small>
                        <textarea
                            id={choice_key}
                            defaultValue={stored}
                            className={"p-3 w-100"}
                            maxLength={1000}
                            onChange={(e) => handleChange(e)}
                        >
                        </textarea>
                    </Card.Body>
                </Card>
            })}
        </>
    );
}