export const enum PssDocumentType {
    CivilSoudce = 10,
    CivilExekuce = 11,
    CivilAdvokat = 12,
    TrestSoudce = 20,
    TrestOrgany = 21
}

export const PssDefaultDocumentNames: { [key: number]: string } = {
    10: "Civil: Soudce",
    11: "Civil: Exekuce",
    12: "Civil: Advokát",
    20: "Trest: Soudce",
    21: "Trest: OČTŘ"
}

export const enum PssAction {
    classifications = 1,
    selections = 2,
    descriptions = 3,
    download = 4
}

export const enum PssGeneratorDocument {
    SoudceKarnyPodnet,
    SoudceTrestniOznameni,
    StatniUrednikKarnyPodnet,
    StatniUrednikTrestniOznameni,
    UrednikSamospravyKarnyPodnet,
    UrednikSamospravyTrestniOznameni,
    ExekutorKarnyPodnet,
    ExekutorTrestniOznameni,
    AdvokatKarnyPodnet,
    StatniZastupceKarnyPodnet,
    StatniZastupceTrestniOznameni,
    PolicieStiznostGIBS,
    PolicieTrestniOznameni,
}