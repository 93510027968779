import {Alert, Breadcrumb, Button, Card, ListGroup, Modal, NavLink} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {PssDocumentType} from "../Model/PssEnums";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import React, {useEffect, useState} from "react";
import PssDocument from "../Model/PssDocument";

export default function Documents() {
    const navigate = useNavigate();
    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);

    const [showModal, showModalSet] = useState(false);
    const handleClose = () => showModalSet(false);
    const handleShow = () => showModalSet(true);
    const [newDocumentType, setNewDocumentType] = useState({
        t: PssDocumentType.CivilSoudce,
        force: false,
        click: false
    });
    const existing_documents: PssDocument[] = [];
    const [existingDocs, existingDocsSet] = useState(existing_documents);

    useEffect(() => {
        if (newDocumentType.click) {
            if (newDocumentType.force || existingDocs.length === 0) {
                const pss_document = documents.createNewDocument(newDocumentType.t);
                window.scrollTo(0, 0);
                navigate(`/documents/${pss_document.id}`, {replace: true});
            } else {
                handleShow();
            }
            setNewDocumentType({t: newDocumentType.t, click: false, force: false})
        }
    }, [documents, navigate, newDocumentType, existingDocs]);

    const forceCreateNewDocument = () => {
        const pss_document = documents.createNewDocument(newDocumentType.t);
        window.scrollTo(0, 0);
        navigate(`/documents/${pss_document.id}`, {replace: true});
    };

    const createNewDocument = async (type: PssDocumentType, force: boolean = false) => {
        // cache for modal
        let exists = documents.getDocumentsByType(type);
        existingDocsSet(exists);
        setNewDocumentType({t: type, force: force, click: true});
    };

    return (
        <>
            <Breadcrumb className={"border-bottom mb-2"}>
                <Breadcrumb.Item active>Hlavní stránka</Breadcrumb.Item>
            </Breadcrumb>

            <Card className={"mt-2"}>
                <Card.Header>
                    <h1>Jak používat tento nástroj</h1>
                </Card.Header>
                <ListGroup>
                    <ListGroup.Item onClick={_ => {
                        document.querySelector('#step1')?.scrollIntoView();
                    }}>
                        Krok 1 - Vyberte si toho, o kom se domníváte, že porušil Vaše práva na spravedlivý proces
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Krok 2 - Identifikujte řízení, ve kterém se práva na spravedlivý proces použijí
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Krok 3 – Vyberte jedno či více práv, které Vám bylo upřeno
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Krok 4 – Popište, jak fakticky bylo vybrané porušené právo či práva porušena
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Krok 5 – Vyberte typ dokumentu (kárná stížnost, trestní oznámení), kterým porušení vašeho práva
                        na spravedlivý proces uplatníte
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Krok 6 – Vygeneruje se vám vámi vybraný dokument obsahující popis porušení vašich práv, který
                        můžete odeslat
                    </ListGroup.Item>
                </ListGroup>
            </Card>

            <Card className={"mt-3"}>
                <Card.Header>
                    <h2>Právní základy</h2>
                </Card.Header>
                <Card.Header>
                    <h3>Evropská úmluva o ochraně lidských práv</h3>
                </Card.Header>
                <Card.Body>

                    <strong>Relevantní pro civilní i trestní proces:</strong>

                    <blockquote className="blockquote text-muted">
                        <p>
                            1. Každý má právo na to, aby jeho záležitost byla spravedlivě, veřejně a v přiměřené lhůtě
                            projednána
                            nezávislým a nestranným soudem zřízeným zákonem, který rozhodne o jeho občanských právech
                            nebo
                            závazcích nebo o oprávněnosti jakéhokoli trestního obvinění proti němu.
                        </p>
                        <p>
                            Rozsudek musí být vyhlášen veřejně, avšak tisk a veřejnost mohou být vyloučeny buď po dobu
                            celého
                            nebo části procesu v zájmu
                            mravnosti, veřejného pořádku nebo národní bezpečnosti v demokratické společnosti, nebo když
                            to
                            vyžadují zájmy nezletilých nebo ochrana soukromého života účastníků, anebo v rozsahu
                            považovaném
                            soudem za zcela nezbytný, pokud by, vzhledem ke zvláštním okolnostem, veřejnost řízení mohla
                            být na
                            újmu zájmům spravedlnosti.
                        </p>
                        <footer className={"blockquote-footer text-end"}>
                            <cite>
                                <a href="https://www.echr.coe.int/documents/convention_ces.pdf" target="_blank"
                                   rel="noreferrer">
                                    Článek 6, odst. 1, Evropská úmluva o ochraně lidských práv
                                </a>
                            </cite>
                        </footer>
                    </blockquote>
                </Card.Body>
                <Card.Header>
                    &nbsp;
                </Card.Header>
                <Card.Body>
                    <Alert variant={"info mt-3"}>
                        <strong>Proč se musí veřejné orgány porušeními práva na spravedlivý proces zabývat?</strong>
                        <hr/>
                        Argumentuje-li účastník názory judikatury, musí se veřejný orgán s těmito názory argumentačně
                        vypořádat
                        (případně i tak, že vysvětlí, proč je nepovažuje pro danou věc relevantní). (Ústavní soud, N
                        160/38 SbNU
                        277).
                    </Alert>

                    <Alert variant={"warning mt-3"}>
                        <strong>Proč má právo na spravedlivý proces podle Evropské úmluvy o lidských právech
                            přednost?</strong>
                        <hr/>
                        Z čl. 10 Ústavy ČR vyplývá, že práva na spravedlivý proces podle čl. 6 Evropské úmluvy o
                        lidských
                        právech mají přednost před českým zákonem a občan je může přímo uplatnit. Pokud orgán veřejné
                        moci,
                        zejména soud, ale i jiný veřejný orgán, právo jednotlivce na spravedlivý proces podle čl. 6 této
                        Úmluvy
                        poruší, pak musí podle čl. 13 uvedené Úmluvy jiný orgán veřejné moci toto porušení práva na
                        spravedlivý
                        proces napravit.
                    </Alert>

                </Card.Body>
            </Card>
            <hr/>
            <h2 id={"step1"}>Krok 1 - Vyberte si prosím typ procesu</h2>
            <div className={"lead mb-2"}>
                V následujících sekcích vám umožňujeme zjistit, zda nebyla porušena vaše práva na spravedlivý proces, a
                to v konkrétních případech podle judikatury Evropského soudu pro lidská práva.
            </div>

            <Card className={"mt-3"}>
                <Card.Header>
                    <h2>Civilní proces</h2>
                </Card.Header>
                <ListGroup variant={"flush"}>
                    <ListGroup.Item variant={"dark"}>Vyberte si prosím, která z následující osob porušila Vaše práva na
                        spravedlivý proces:</ListGroup.Item>
                    <ListGroup.Item action={true} onClick={() => createNewDocument(PssDocumentType.CivilSoudce)}>
                        Soudce nebo jiný vybraný veřejný orgán
                        <br/>
                        <small>(vedle soudů, též např. správní orgány, celní orgány, orgány s působností pro regulované
                            profese, státní službu či veřejné vzdělávání, orgány sociálního zabezpečení, bydlení či
                            ochrany dětí atd.)</small>
                    </ListGroup.Item>
                    <ListGroup.Item action={true} onClick={() => createNewDocument(PssDocumentType.CivilExekuce)}>
                        Exekuční soud / exekutor
                    </ListGroup.Item>
                    <ListGroup.Item action={true} onClick={() => createNewDocument(PssDocumentType.CivilAdvokat)}>
                        Ostatní subjekty
                    </ListGroup.Item>
                </ListGroup>
            </Card>


            <Card className={"mt-3"}>
                <Card.Header>
                    <h2>Trestní proces</h2>
                </Card.Header>
                <ListGroup variant={"flush"}>
                    <ListGroup.Item variant={"dark"}>Vyberte si prosím, která z následující osob porušila Vaše práva na
                        spravedlivý proces:</ListGroup.Item>
                    <ListGroup.Item action={true} onClick={() => createNewDocument(PssDocumentType.TrestSoudce)}>
                        Soudce v trestním řízení
                    </ListGroup.Item>
                    <ListGroup.Item action={true} onClick={() => createNewDocument(PssDocumentType.TrestOrgany)}>
                        Státní zástupce / Policista (orgány činné v trestním řízení)
                    </ListGroup.Item>
                </ListGroup>
                <Card.Body>
                    <strong>Relevantní pro trestní proces:</strong>
                    <blockquote className="blockquote">
                        <ul className="text-muted list-unstyled">
                            <li>2. Každý, kdo je obviněn z trestného činu, se považuje za nevinného, dokud jeho vina
                                nebyla prokázána zákonným způsobem.
                            </li>
                            <li>3. Každý, kdo je obviněn z trestného činu, má tato minimální práva:
                                <ol type="a">
                                    <li>být neprodleně a v jazyce, jemuž rozumí, podrobně seznámen s povahou a důvodem
                                        obvinění proti němu
                                    </li>
                                    <li>mít přiměřený čas a možnost k přípravě své obhajoby</li>
                                    <li>obhajovat se osobně nebo za pomoci obhájce podle vlastního výběru nebo, pokud
                                        nemá prostředky na zaplacení obhájce, aby mu byl poskytnut bezplatně, jestliže
                                        to zájmy spravedlnosti vyžadují
                                    </li>
                                    <li>vyslýchat nebo dát vyslýchat svědky svědčící v jeho neprospěch a dosáhnout
                                        předvolání a výslechu svědků svědčících v jeho prospěch za stejných podmínek,
                                        jako svědků svědčících v jeho neprospěch
                                    </li>
                                    <li>mít bezplatnou pomoc tlumočníka, jestliže nerozumí jazyku používanému před
                                        soudem nebo tímto jazykem nemluví
                                    </li>
                                </ol>
                            </li>
                        </ul>

                        <footer className={"text-end"}>
                            <cite className={"small"}>
                                <a href="https://www.echr.coe.int/documents/convention_ces.pdf" target="_blank"
                                   rel="noreferrer">
                                    Článek 6, odst. 2-3, Evropská úmluva o ochraně lidských práv
                                </a>
                            </cite>
                        </footer>
                    </blockquote>
                </Card.Body>
            </Card>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Vyberte si existující dokument nebo zvolte "Vytvořit nový"</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup id={"existing_list"}>
                        {existingDocs.map(doc => {
                            return (
                                <ListGroup.Item key={'doc-' + doc.id}>
                                    <NavLink onClick={_ => {
                                        window.scrollTo(0, 0);
                                        navigate(`/documents/${doc.id}`, {replace: true})}
                                    }>
                                        {doc.name}
                                    </NavLink>
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={handleClose}>
                        Zrušit
                    </Button>
                    <Button variant={"success"} onClick={() => forceCreateNewDocument()}>
                        Vytvořit nový
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};