import {PssChoice, PssSection, PssSectionPart} from "../Model/PssDatabase";

export const TrestOCTRDb: PssSection[] = [
    new PssSection(
        "VZHLEDEM KE STÁTNÍMU ZÁSTUPCI  A PŘÍSLUŠNÍKŮM POLICIE ČR",
        "Právo na spravedlivý proces musí proto dodržovat i orgány činné v trestním řízení, včetně státního zástupce a Policie ČR, " +
        "neboť podle čl. 2 odst. (4) věta třetí trestního řádu projednávají orgány činné v trestním řízení trestní věci s" +
        "plným šetřením práv a svobod zaručených Listinou základních práv a svobod a mezinárodními smlouvami o " +
        "lidských právech a základních svobodách, jimiž je Česká republika vázána; při provádění úkonů trestního řízení" +
        " lze do těchto práv osob, jichž se takové úkony dotýkají, zasahovat jen v odůvodněných případech na " +
        "základě zákona a v nezbytné míře pro zajištění účelu trestního řízení. Jelikož právo na spravedlivý " +
        "proces zahrnuje podle Evropské úmluvy o základních právech zásadu rovnosti procesních zbraní, " +
        "má státní zástupce povinnost nečinit kroky či úkony, které by zásadu rovnosti procesních zbraní porušovaly." +
        "\n" +
        "\n" +
        "Článek 6 odst. 1 je použitelný na celé řízení o oprávněnosti „trestního obvinění“, včetně procesu odsouzení [1]. " +
        "Požadavky spravedlivého procesu uvedené čl. 6, odst. 1 – 3 Úmluvy se vztahují k celému trestnímu řízení, " +
        "včetně přípravného řízení [2]. Pokud jde o předsoudní stadium řízení (pátrání, vyšetřování), " +
        "Evropský soud pro lidská práva pokládá trestní řízení za jeden celek. Proto mohou být některé požadavky článku 6 Úmluvy, " +
        "jako například požadavek na přiměřenou délku řízení nebo právo na obhajobu, důležité také v tomto předsoudním stadiu řízení v rozsahu, " +
        "v němž by férovost řízení mohla vážně utrpět jejich počátečním nedodržením [3]." +
        "\n" +
        "\n " +
        "Fáze vyšetřování může mít zvláštní význam pro přípravu trestního řízení: důkazy získané v této fázi často určují rámec, " +
        "v němž bude předmětný trestný čin posuzován při soudním řízení, a vnitrostátní právní předpisy mohou klást důsledky na postoj " +
        "obviněného v počátečních fázích policejního výslechu, které jsou rozhodující pro úspěšnost obhajoby " +
        "v jakémkoli následném trestním řízení. Obviněný se tak může v této fázi řízení ocitnout ve zvláště zranitelném postavení, " +
        "jehož účinek může být zesílen stále složitějšími právními předpisy v trestním řízení, zejména pokud jde o pravidla " +
        "upravující shromažďování a používání důkazů [4].",
        "[1] (Dvorski v. Chorvatsko), [2] (Phillips proti Spojenému království, § 39); [3] (Imbrioscia proti Švýcarsku, § 36), " +
        "[4] (§ 76, Salduz, §§ 52 a 54; Dvorski, § 77), " +
        "\n" +
        "\n",
        [],
        true
    ),
    new PssSection(
        "I. Férovost procesu",

        "Klíčovou zásadou uplatňování článku 6 je férovost procesu [1]. To, co představuje férovost, však není předmětem jediného neměnného " +
        "pravidla, ale závisí na okolnostech konkrétního případu [2]. Článek 6 jako celek zaručuje právo obviněného účinně se obhajovat a vystupovat " +
        "v rámci trestního řízení [3]. Obecně to zahrnuje, mimo jiné, nejen jeho právo být přítomen, ale také vyslechnout a sledovat řízení [4]." +
        "Způsob, jakým mají být články 6 § 1 a 3 použity během fáze vyšetřování, závisí na zvláštnostech daného řízení a na okolnostech případu [5]. " +
        "\n" +
        "\n" +
        "Při určování, zda je řízení jako celek spravedlivé, lze vzít v úvahu váhu veřejného zájmu na vyšetřování a " +
        "potrestání konkrétního dotčeného trestného činu. Kromě toho by článek 6 neměl být uplatňován způsobem, který " +
        "by policejním orgánům způsoboval nepřiměřené obtíže při přijímání účinných opatření pro boj proti terorismu nebo " +
        "jiným závažným trestným činům při plnění jejich povinností podle článků 2, 3 a 5 § 1 Úmluvy na ochranu práva na život a " +
        "práva na tělesnou bezpečnost jednotlivců. Obavy obecného zájmu však nemohou odůvodnit opatření, která zanikají samotnou " +
        "podstatou práva obviněného na obhajobu [6]." +
        "\n" +
        "\n" +
        "Rovnost procesních zbraní je neodmyslitelnou součástí spravedlivého procesu. Vyžaduje, aby každá strana měla přiměřenou příležitost " +
        "předložit svůj případ za podmínek, které ho neznevýhodňují vůči jeho oponentovi [7]. Rovnost zbraní vyžaduje dosažení spravedlivé " +
        "rovnováhy, tj., aby se účastníci řízení dozvěděli o všech předložených důkazech nebo návrzích či argumentech podaných za účelem " +
        "ovlivnění rozhodnutí soudu a vyjádřili se k němu za týmž účelem [8]. V trestních věcech se článek 6 § 1 překrývá se zvláštními zárukami " +
        "článku 6 § 3, i když se neomezuje na minimální práva v něm uvedená. Záruky obsažené v článku 6 § 3 jsou totiž základními prvky, " +
        "mimo jiné pojmu spravedlivý proces stanovený v článku 6 § 1 [9]. ",
        "[1] (Gregačević v. Chorvatsko, § 49), [2] (Ibrahim a další v. Spojené království [GC], " +
        "§ 250), [3] (Murtazaliyeva v. Rusko [GC], § 91), [4] (Stanford v. Spojené království, § 26), [5] (Imbrioscia, § 38; Ibrahim a další. v. the United Kingdom [GC], § 253)) " +
        "[6] (Ibrahim a další. v. the United Kingdom [GC], § 252), " +
        "[7] (Öcalan v. Turecko [GC], § 140; Foucher v. Francie, § 34; Bulut v. Rakousko; Faig Mammadov v. Ázerbájdžán, § 19) " +
        "[8] (Brandstetter v. Rakousko, § 67); [9] (Ibrahim a další v. Spojené království [GC], § 251)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na spravedlivý proces, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("to.1.1", "1. Účinná účast obviněného či obžalovaného na jeho trestním řízení zahrnuje rovněž jeho právo sestavovat si poznámky s cílem usnadnit provádění jeho obhajoby bez ohledu na to, zda má právního zástupce či nikoli.", "(Pullicino v. Malta  (prosinec); Moisejev v. Rusko, § 214)",),
                    new PssChoice("to.1.2", "2. Soud odmítl vyslechnout jakéhokoli svědka nebo provést jakýkoli důkaz navržený účastníkem řízení, zatímco vyslechl všechny svědky obžaloby a provedl všechny důkazy, které navrhovala, čímž se dopustil porušením práva na spravedlivý proces a práva na rovnost zbraní. ", " (Borisova v. Bulgaria, §§ 47-48; Topić v. Croatia, § 48; Kasparov and Others v. Russia, §§ 64-65)",),
                    new PssChoice("to.1.3", "3. Účastníkovi řízení nebylo umožněno účinně napadnout znalecký posudek, zejména připuštěním revizního znaleckého posudku, čímž došlo k porušení práva na spravedlivý proces. ", " (Stoimenov v. the former Yugoslav Republic of Macedonia, § 38; Matytsina v. Russia, § 169)",),
                    new PssChoice("to.1.4", "4. Neomezený přístup ke spisu a neomezené používání jakýchkoli poznámek, v případě potřeby včetně možnosti získat kopie příslušných dokumentů, jsou důležitými zárukami spravedlivého procesu. Neposkytnutím takového přístupu byla porušena zásada rovnosti zbraní a právo na spravedlivý proces účastníka řízení. ", "(Beraru v. Romania, § 70)",),
                    new PssChoice("to.1.5", "5. Článek 6 § 1 Úmluvy vyžaduje, aby orgány státních zastupitelství sdělily obhajobě veškeré podstatné důkazy, které mají k dispozici pro obviněného nebo proti němu, což se nestalo, čímž bylo porušeno právo na spravedlivý proces. ", " (Rowe and Davis v. The United Kingdom [GC], § 60)",),
                    new PssChoice("to.1.6", "6. Účastníkovi řízení nebylo umožněno, aby se vyjádřil k dodatečné policejní zprávě, čímž bylo porušeno právo na spravedlivý proces. ", " (Kuopila v. Finland, § 38)"),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Podle článku 6 § 1 Úmluvy jsou přípustná opatření omezující práva na obhajobu, která jsou nezbytně nutná z důvodu národní bezpečnosti nebo potřeby chránit ohrožené svědky, kterým hrozí odveta, nebo utajit metody používané policií k vyšetřování trestné činnosti. ", " (Van Mechelen a další v. Nizozemsko, § 58; Paci v. Belgie, § 85)",false),
                    new PssChoice("", "2. Omezení práv účastníka řízení a obhajoby byla dostatečně vyvážena jinými opatřeními ve prospěch tohoto účastníka a obhajoby v rámci daného řízení. ", " (Rowe and Davis v. The United Kingdom [GC], § 61; Doorson v. The Netherlands, § 72)",false),
                    new PssChoice("", "3. Není porušením práva na spravedlivý proces, pokud informace, se kterými nebylo umožněno účastníkovi řízení se seznámit, neměly žádný vliv na práva obhajoby a účastníka řízení. ", " (M v. The Netherlands, § 69)",false),
                    new PssChoice("", "4. Obhajobě sice nebylo umožněno učinit výslech svědka, nicméně žádost o výslech svědka nebyla ze strany obhajoby dostatečně odůvodněná a relevantní pro předmět obvinění nebo soud poskytl dostatečné důvody pro své rozhodnutí nevyslechnout svědka u soudu nebo rozhodnutí soudu nevyslechnout svědka nenarušilo celkovou spravedlnost řízení. ", " (Abdullayev v. Azerbaijan, §§ 59-60)",false),
                ],
                false
            )
        ],
        true
    ),
    new PssSection(
        "II. Právo nevypovídat a neobviňovat sebe samého",
        "Každý, kdo je obviněn z trestného činu, má právo nevypovídat a nesmí být nucen k obviňování sebe sama [1]. " +
        "Ačkoli to není výslovně uvedeno v článku 6 Úmluvy, právo nevypovídat a zásada spočívající v nepřispívání " +
        "k vlastnímu obvinění jsou obecně uznávány mezinárodními normami, které jsou jádrem pojmu spravedlivého řízení " +
        "podle článku 6 Úmluvy. Tím, že poskytují obviněnému ochranu před nepřípustným nátlakem ze strany orgánů činných " +
        "v trestním řízení, přispívají tyto zásady také k předcházení justičním omylům a k zajištění cílů článku 6. " +
        "\n" +
        "\n" +
        "Právo neobviňovat sebe samého nechrání před podáním usvědčujícího prohlášení jako takového, ale před získáním důkazů " +
        "donucením nebo nátlakem. Právě existence donucení vyvolává obavy, zda bylo respektováno právo neobviňovat sebe samého. " +
        "Z tohoto důvodu musí být nejprve posouzena povaha a stupeň donucení použitého k získání důkazů. [3] " +
        "Právo neobviňování sebe samého se vztahuje na trestní řízení ve vztahu ke všem druhům trestných činů, od těch méně závažných " +
        "až po ty nejzávažnější [4]. Právo nevypovídat platí od okamžiku, kdy je podezřelý " +
        "vyslýchán policií [5]. Právo nevypovídat ovšem není absolutní [6].",
        "[1] (O‘Halloran a Francis v. Spojené království [GC], § 45; Funke v. Francie, § 44), " +
        "[2] (John Murray v. Spojené království [GC], § 45; Bykov v. Rusko [GC], § 92), [3] (Ibrahim a další v. Spojené království [GC], § 267) " +
        "[4] (Saunders v. Spojené království [GC] § 74), [5] (John Murray v. Spojené království [GC], § 45), " +
        "[6] (John Murray v. Spojené království [GC], § 47; Ibrahim a další v. Spojené království  [GC], § 269) ",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na spravedlivý proces, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("to.2.1", "1. Zejména osoba „obviněná z trestného činu“ ve smyslu článku 6 Úmluvy má právo být informována o právu neobviňovat sama sebe, k čemuž však nedošlo, čímž bylo porušeno jeho právo na spravedlivý proces. ", " (Ibrahim and Others v. The United Kingdom [GC], § 272)",),
                    new PssChoice("to.2.2", "2. Podezřelý byl povinen vypovídat pod hrozbou sankcí, a skutečně tak učinil nebo tak neučinil, a byl za to sankcionován a nebyl předtím informována o právu neobviňovat sama sebe, čímž bylo porušeno jeho právo na spravedlivý proces.", "(Saunders v. The United Kingdom [GC], Brusco v. France)",),
                    new PssChoice("to.2.3", "3. Právo účastníka řízení nevypovídat bylo porušeno v důsledku fyzického nebo psychologického nátlaku, jež porušuje ustanovení článku 3 Úmluvy o zákazu mučení a nelidského zacházení, a tento nátlak byl použit za účelem získání důkazů či výpovědi.", " (Jalloh v. Germany [GC]; Gäfgen v. Germany [GC])",),
                    new PssChoice("to.2.4", "4. Právo účastníka řízení nevypovídat, a tudíž jeho právo na spravedlivý proces bylo porušeno v důsledku jednání orgánů činných v trestním řízení, které se pokusily získat informace od účastníka řízení lstí poté, co se jim nepodařilo získat dotčenou informaci výslechem. ", " (Allan v. The United Kingdom)",),
                    new PssChoice("to.2.5", "5. Policií zadržené osoby nemohou být v žádném případě zbaveny práva na právní pomoc, pokud nebyly informovány o jejich právu nevypovídat, k čemuž však došlo, čímž byly zbaveny práva na spravedlivý proces.", " (Brusco v. France, § 54; Navone and Others v. Monaco, § 74)",),
                    new PssChoice("to.2.6", "6. Osobám, jež jsou “obviněné z trestného činu“ ve smyslu Úmluvy, nesmí být dáno na výběr mezi jejich právem nevypovídat a neobviňovat sebe samého, na straně jedné, a právem na právní pomoc na straně druhé, k čemuž však došlo, čímž bylo porušeno jeho právo na spravedlivý proces.", " (Ibrahim and Others v. The United Kingdom [GC], § 272)",),
                    new PssChoice("to.2.7", "7. Sdělení poučení o právu nevypovídat a neobviňovat sebe samého dotčené osobě musí orgány činné v trestním řízení být schopny dokumentovat, přičemž případné vzdání se tohoto práva, pokud k tomu došlo, musí být učiněno v přítomnosti právního zástupce dotčené osoby, jinak se jedná o porušení práva na spravedlivý proces. ", " (Stojkovic v. France and Belgium, § 54)",),
                    new PssChoice("to.2.8", "8. Odsouzení obžalovaného nesmí být založeno pouze na skutečnosti, že obžalovaný odmítl vypovídat nebo poskytnout důkazy o jeho nevině. ", " (John Murray v. The United Kingdom [GC], § 47)"),
                    //new PssChoice("to.2.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Svědectví získané na základě jinak nepřípustného nátlaku, které nepřispívají k zesílení obvinění dotčeného účastníka řízení, jako jsou poznámky ve prospěch této osoby nebo pouhé informace o skutkových otázkách, mohou být použity v trestním řízení obžalobou například za účelem rozporování nebo zpochybnění jiných prohlášení obviněného nebo důkazů, které poskytl během hlavního líčení, nebo ke zpochybnění jeho důvěryhodnosti. Takový postup je přípustný a není porušením spravedlivého procesu.", " (Ibrahim and Others v. the United Kingdom [GC], § 268)",false),
                    new PssChoice("", "2. Právo neobviňovat sebe samého není porušeno získáním od dotčené osoby vzorku krve, DNA nebo jiných tělesných vzorků nebo získáním dokumentů, které mohou orgány činné v trestním řízení získat ohledně dotčené osoby na základě svých pravomocí z jiných zdrojů. ", " (Saunders v. the United Kingdom [GC], § 69; O’Halloran and Francis v. the United Kingdom [GC], § 47)",false),
                    new PssChoice("", "3. Konfrontace obviněného či obžalovaného v trestním řízení s jeho vyjádřeními, které učinil v jiných typech řízení, například azylovém řízení, nepředstavuje porušení práva nevypovídat či neobviňovat sebe samého. ", " (H. and J. v. the Netherlands (dec.)) ",false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "III. Právo na to, aby předložené důkazy byly získány řádně a férově",
        "Článek 6 sice zaručuje právo na spravedlivý proces, ale nestanoví žádná pravidla pro přípustnost " +
        "důkazů jako takových, což je především předmětem úpravy vnitrostátních norem [1]" +
        "Zejména je třeba přezkoumat, zda účastníkovi řízená byla dána příležitost zpochybnit věrohodnost důkazů a" +
        "namítnout jejich nepřípustnost [2]. Kromě toho je třeba vzít v úvahu kvalitu důkazů, stejně jako okolnosti, " +
        "za kterých byly získány, a zda tyto okolnosti zpochybňují jejich spolehlivost nebo přesnost [3]" +
        ". Právo na to, aby předložené důkazy byly získány řádně a férově se neomezuje na důkazy, které jsou rozhodné pro výsledek " +
        "trestního řízení [4].",
        "[1] (Schenk v. Švýcarsko, §§ 45–46; Moreira Ferreira v. Portugalsko (č. 2) [GC], § 83; Heglas v. Česká republika, § 84), " +
        "[2] (Bykov v. Rusko  [GC], § 89; Jalloh v. Německo  [GC], § 96), [3] (Bykov v. Rusko [GC], § 89; Jalloh v. Německo  [GC], § 96) " +
        "[4] (Gäfgen v. Germany [GC], § 164)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na spravedlivý proces, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("to.3.1", "1. Právo na to, aby předložené důkazy byly získány, je vždy porušeno, pokud předložené důkazy v trestním řízení byly získány jako výsledek porušení zákazu mučení či nelidského či ponižujícího zacházení podle čl. 3 Úmluvy, k čemuž v daném případě došlo. ", " (Jalloh v. Germany [GC], §§ 99 and 105; Harutyunyan v. Armenia, § 63, Gäfgen v. Germany [GC], § 166; Ibrahim and Others v. The United Kingdom [GC], § 254; El Haski v. Belgium, § 85; Cēsnieks v. Latvia, §§ 67-70)"),
                    new PssChoice("to.3.2", "2. Právo na to, aby předložené důkazy byly získány, je porušeno, pokud předložené důkazy v trestním řízení byly získány jako výsledek porušení zákazu mučení či nelidského či ponižujícího zacházení podle čl. 3 Úmluvy, i když se posléze uvedeného porušení dopustila fyzická osoba, k čemuž v daném případě došlo.", " (Ćwik v. Poland)",),
                    new PssChoice("to.3.3", "3. Právo na to, aby předložené důkazy byly získány řádně a férově, je porušeno, pokud předložené důkazy v trestním řízení byly získány v přímé souvislosti s porušením zákazu mučení či nelidského či ponižujícího zacházení podle čl. 3 Úmluvy, k čemuž v daném případě došlo.", " (Gäfgen v. Germany [GC], § 167; Jalloh v. Germany [GC], § 105)",),
                    new PssChoice("to.3.4", "4. Právo na to, aby předložené důkazy byly získány řádně a férově, je porušeno i v případě, pokud předložené důkazy v trestním řízení byly získány jako výsledek porušení zákazu mučení či nelidského či ponižujícího zacházení podle čl. 3 Úmluvy uplatněného vůči třetí osobě a nikoli účastníkovi řízení, proti kterému je takový předložený důkaz použit, k čemuž v daném případě došlo.", " (Urazbayev v. Russia, § 73)",),
                    //new PssChoice("to.3.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Důkazy proti obviněnému, resp. obžalovanému, byly získány jako výsledek nelidského či ponižujícího zacházení, jež však nedosáhlo intenzity mučení ve smyslu čl. 3 Úmluvy. Pokud takto získané důkazy neměly žádný vliv na výsledek řízení, nejedná se o porušení práva na spravedlivý proces. ", " (Gäfgen v. Germany [GC], § 178; El Haski v. Belgium, § 85; Zličić v. Serbia, § 119)", false)
                ],
                false
            )
        ]
    ),
    new PssSection(
        "IV. Průtahy v trestním řízení",
        "V trestních věcech je cílem článku 6 § 1 Úmluvy, díky kterému má každý právo na slyšení v přiměřené lhůtě zajistit, " +
        "aby dotčený účastník řízení nebyl nepřiměřeně dlouho v pozici obviněného či obžalovaného a aby bylo rozhodnuto o obvinění " +
        "či obžalobě v přiměřené lhůtě bez průtahů [1]. Pojem obvinění a obžaloby musí být chápán v autonomním smyslu ve světle článku 6 § 1 [2]. " +
        "Přiměřenost lhůty řízení je třeba určit s ohledem na okolnosti případu, které vyžadují celkové posouzení [3]." +
        "Článek 6 Úmluvy vyžaduje, aby soudní řízení bylo rychlé, ale rovněž stanoví obecnější zásadu řádného výkonu spravedlnosti, " +
        "kdy je třeba nalézt spravedlivou rovnováhu mezi různými aspekty tohoto základního požadavku." +
        "\n" +
        "\n" +
        "Přiměřená lhůta může začít běžet před tím, než se věc dostala před soud [5], například od okamžiku zatčení [6], " +
        "nebo od okamžiku, kdy je osoba obviněna [7], nebo od momentu započetí předběžného vyšetřování [8] " +
        "nebo od okamžiku výslechu žalobce jako svědka podezřelého ze spáchání trestného činu [9]. " +
        "V každém případě je však relevantním okamžikem ten okamžik, kdy se obviněný dozvěděl o svém obvinění nebo " +
        "když byl podstatně dotčen opatřeními přijatými v rámci vyšetřování vedoucímu k zahájení trestního řízení [11] " +
        "„Přiměřená lhůta“ podle čl. 6 Úmluvy se vztahuje na celé trestní řízení, včetně odvolacího řízení " +
        " [12]. Tato lhůta, která má být vzata v úvahu, trvá nejméně do zproštění obžaloby nebo do vydání odsuzujícího rozsudku [13]. ",
        "[1] (Wemhoff v. Německo, § 18; Kart v. Turecko [GC], § 68), [2] (McFarlane v. Irsko [GC], § 143), " +
        "[3] (Boddaert v. Belgie, §36), [4] (Boddaert v. Belgie, §39), [5] (Deweer v. Belgie, § 42), [6] (Wemhoff v. Německo, § 19)," +
        "[7] (Neumeister v. Rakousko, § 18), [8] (Ringeisen v. Rakousko, § 110; Šubinski v. Slovinsko, §§ 65-68), [9] (Kalēja v. Lotyšsko, § 40)," +
        "[10] (Mamič v. Slovinsko (č. 2), §§ 23-24); Liblík a další v. Estonsko, § 94), [11] (König v. Německo, § 98), " +
        "[12] (Delcourt v. Belgie, §§ 25–26; König v. Německo, § 98; V. V. Spojené království [GC], § 109), [13] (Wemhoff v. Německo, § 18). " +

        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na spravedlivý proces, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("to.4.1", "1. I když určité části trestního řízení byly samy o sobě vedeny přiměřenou rychlostí, celková délka trestního řízení však přesáhla přiměřenou dobu. ", " (Dobbertin v. France, § 44) ",),
                    new PssChoice("to.4.2", "2. Bez ohledu na to, zda je projednávaná věc komplexní či nikoli, došlo ke zdlouhavým obdobím neaktivity, jež nejsou souladu s požadavkem přiměřenosti délky řízení a právem na spravedlivý proces. ", " (Adiletta v. Italy, § 17) ",),
                    new PssChoice("to.4.3", "3. Skutečnost, že věc je složitá, neomlouvá vždy nepřiměřenou délku řízení. ", " (Rutkowski and Others v. Poland, § 137) ",),
                    new PssChoice("to.4.4", "4. Zaneprázdněnost orgánů činných v trestním řízení nikdy způsobilým důvodem pro omluvu nepřiměřenosti délky řízení a porušení práva na spravedlivý proces v tomto ohledu. ", " (Eckle v. Germany, § 92), neboť čl. 6 Úmluvy vyžaduje, aby si její členské státy zorganizovaly své justiční systémy tak, aby dostály požadavku přiměřené délky řízení (Abdoella v. the Netherlands, § 24; Dobbertin v. France, § 44)",),
                    //new PssChoice("to.4.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Není porušením přiměřenosti délky řízení a práva na spravedlivý proces, pokud je dlouhá délka řízení důsledkem složitosti dané věci, jež může vyplývat z jednoho z následujících faktorů: vysoký počet obvinění, vysoký počet osob, jež jsou součástí projednávané věci, jako například vysoký počet obžalovaných nebo svědků, mezinárodní rozměr věci, například v situaci, kdy se dotčené komplexní transakce se odehrávaly v řadě zemí mezi velkým množstvím právnických osob, což vyžadovalo využití pomoci Interpolu a úmluv o mezinárodní soudní spolupráci (Neumeister v. Austria, § 20) nebo, v situaci, kdy se jednalo o otázky mezinárodní legalizace výnosů z trestné činnosti (C.P. and Others v. France, § 30).", "", false),
                    new PssChoice("", "2. Není porušením přiměřenosti délky řízení a práva na spravedlivý proces, pokud je dlouhá délka řízení důsledkem chování účastníka řízení dané věci, jež může vyplývat z jednoho z následujících faktorů: účastník řízení úmyslně zdržoval vyšetřování, což je objektivně zřejmé ze spisu (I.A. v. France, § 121); účastník řízení zdržoval soudní řízení systematicky podávanými námitkami ohledně podjatosti soudců (Eckle v. Germany, § 82); účastník řízení se vyhýbal trestnímu řízení pobytem mimo zemi, kde proti němu bylo trestní řízení vedeno (Vayiç v. Turkey, § 44); zdravotní stav účastníka řízení vedl k průtahům v řízení (Yaikov v. Russia, § 76).", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "V. Presumpce neviny",
        "Presumpce neviny může být porušena nejen soudcem nebo soudem, ale i jinými orgány veřejné moci " +
        "[1]. To platí například pro policisty a policejní úředníky [2]; státního zástupce [3] a další osoby, jež jsou orgány činnými " +
        "v trestním řízení, nebo pro ně pracují [4]. Rovněž to platí pro soudce, s tím, že vyjádření soudců podléhají přísnější kontrole " +
        "než výroky orgánů činných v trestním řízení [5]. " +
        "\n" +
        "\n" +
        "Presumpce neviny se vztahuje na celé trestní řízení, nikoli však na jiná než " +
        "trestní řízení, které trestnímu řízení nohou předcházet. Presumpce nepůsobí již poté, co nabyl právní moci odsuzující rozsudek. " +
        "Presumpce neviny se vztahuje se pouze na trestné činy, za které byla osoba odsouzena a z důvodů, za které byla odsouzena. " +
        "Presumpce neviny se vztahuje i na odvolací či dovolací řízení a platí i posmrtně. Konečně, presumpce neviny se vztahuje i na osobu, " +
        "která se k trestnému činu přiznala [6]. Presumpce neviny se použije vedle trestního řízení jako takového i na věcně související " +
        "řízení (souběžná řízení) a časově související řízení (následná řízení). Aby se presumpce neviny použila i na řízení související " +
        "s trestním řízením, je nutné prokázat souvislost takového řízení s trestním řízením [7]. " +
        "\n" +
        "\n" +
        "Presumpce neviny rovněž chrání osoby, které byly zproštěny obžaloby nebo v souvislosti s nimiž bylo trestní řízení " +
        "zastaveno před tím, aby s nimi veřejní činitelé a orgány v následných jiných řízeních zacházely, " +
        "jako by se ve skutečnosti dopustily trestného činu, z něhož byly obviněny. Bez ochrany, která by zajistila " +
        "respektování osvobozujícího rozsudku nebo rozhodnutí o zastavení trestního řízení, by se záruky spravedlivého " +
        "procesu podle článku 6 § 2 mohly stát jen teoretickými a iluzorními. Po skončení trestního řízení může mít absence " +
        "uvedených záruk spravedlivého procesu negativní dopad na pověst osoby a způsob, jakým je osoba dříve dotčená " +
        "trestním řízením vnímána veřejností [8].",
        "[1] (Allenet de Ribemont v. Francie, §36; Daktaras v. Litva, § 42; Petyo Petkov v. Bulharsko, § 91)," +
        "[2] (Allenet de Ribemont v. Francie, §§ 37 a 41), [3] (Daktaras v. Litva, § 42), " +
        "[4] (Khuzhin a další v. Rusko, § 96), [5] (Pandy v. Belgie, § 43), [6] (Axel Springer SE and RTL Television GmbH v. Germany, § 40)" +
        "[7] (Kemal Coşkun v. Turecko, § 44; viz také Istrate v. Rumunsko, §§ 63-66), (Allen v. The United Kingdom [GC], § 94).",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na spravedlivý proces, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("to.5.1", "1. Jasné prohlášení soudce nebo orgánu činného v trestním řízení či osoby vykonávající veřejnou moc před okamžikem nabytí právní moci odsuzujícího rozsudku, že dotyčná osoba spáchala určitý trestný čin, je porušením presumpce neviny a zároveň porušením práva na spravedlivý proces podle Úmluvy. ", " (Ismoilov and Others v. Russia, § 166; Nešťák v. Slovakia, § 89), a to i v případě, že: \n" +
                        "\n" +
                        "- takové prohlášení bylo učiněno neúmyslně; \n" +
                        "(Avaz Zeynalov v. Azerbaijan, § 69),  \n" +
                        "\n" +
                        "- bylo učiněno neobratně či nešťastně; \n" +
                        "(Allen v. The United Kingdom [GC], § 126; Lähteenmäki v. Estonia, § 45), nebo \n" +
                        "\n" +
                        "- je-li takové prohlášení v doslovném znění v pořádku, ale jeho skutečný význam presumpci neviny porušuje. \n" +
                        "(Lavents v. Latvia, § 126) \n" +
                        "\n" +
                        "K takovému porušení v daném případě došlo."),
                    new PssChoice("to.5.2", "2. Vyjádření „pevného přesvědčení, že se určitá osoba opět dopustila trestného činu“ v řízení o pozastavení trestu odnětí svobody ve zkušební době porušilo článek 6 § 2 Úmluvy, tedy je porušením práva na spravedlivý proces.", " (El Kaada v. Germany, §§ 61-63)",),
                    new PssChoice("to.5.3", "3. Je porušením presumpce neviny a zároveň porušením práva na spravedlivý proces podle Úmluvy, pokud soudce nebo orgán činný v trestním řízení či osoby vykonávající veřejnou moc učiní jasné prohlášení, že dotyčná osoba spáchala daný trestný čin poté, co nabylo právní moci rozhodnutí, jímž se daná osoba zprošťuje viny. ", " (Rushiti v. Austria, § 31; O. V. Norway, § 39; Geerings v. The Netherlands, § 49; Paraponiaris v. Greece, § 32) ",),
                    new PssChoice("to.5.4", "4. Je porušením presumpce neviny a zároveň porušením práva na spravedlivý proces podle Úmluvy, pokud soudce nebo orgán činný v trestním řízení či osoby vykonávající veřejnou moc učiní jasné prohlášení, že dotyčná osoba spáchala daný trestný čin předtím, než nabylo právní moci rozhodnutí, jímž se daná osoba uznává vinnou z trestného činu, za který byla odsouzena. ", " (Matijašević v. Serbia, § 49; Nešťák v. Slovakia, § 90] ",),
                    new PssChoice("to.5.5", "5. Je porušením presumpce neviny a zároveň porušením práva na spravedlivý proces podle článku 6 § 2 Úmluvy, pokud ministr vnitra po zatčení dotčené osoby, ale před jejím slyšením před soudcem, učiní prohlášení publikované v časopise, ve kterém zdůrazňuje, že to, co dotčená osoba udělala, představovalo propracovaný systém machinací fungující po řadu let. ", " (Gutsanovi v. Bulgaria (§§ 195-201)",),
                    //new PssChoice("to.5.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Není porušením presumpce neviny a zároveň není porušením práva na spravedlivý proces, že určitá osoba je pouze podezřelá z toho, že se dopustila trestného činu. ", " (Ismoilov and Others v. Russia, § 166; Nešťák v. Slovakia, § 89)",false),
                    new PssChoice("", "2. Prohlášení porušující presumpci neviny neporušuje právo na spravedlivý proces, pokud se netýká stejného trestného činu, u něhož se uplatňuje ochrana presumpce neviny v rámci trestního řízení ohledně uvedeného trestného činu. ", " (Larrañaga Arando and Others v. Spain (dec.), § 48)",false),
                    new PssChoice("", "3. Právo na presumpci neviny se neuplatní v situaci, kdy ohledně dotčené osoby doposud nebylo vzneseno “obvinění z trestného činu” ve smyslu čl. 6 Úmluvy a je učiněno soukromou osobou, například novináři. ", " (Gogitidze and Others v. Georgia, §§ 125-126; Larrañaga Arando and Others v. Spain (dec.), §§ 45-46; Khodorkovskiy and Lebedev v. Russia (no.2), § 543)",false),
                    new PssChoice("", "4. Právo na presumpci neviny a spravedlivý proces nebrání organům veřejné moci, aby informovaly veřejnost obezřetně a citlivě, jakož i v souladu s vnitrostátními pravidly o probíhajících trestních vyšetřováních a řízeních. ", " (Fatullayev v. Azerbaijan, § 159; Allenet de Ribemont v. France, § 38; Garycki v. Poland, § 69)",false),
                    new PssChoice("", "5. Porušení práva presumpce neviny nemusí soud posuzovat z úřední povinnosti, tedy v situaci, kdy námitka porušení práva presumpce neviny nebyla dotčeným účastníkem trestního řízení vznesena řádným způsobem v rámci takového řízení, ať už v první instanci či odvolacím řízení. ", " (Czajkowski v. Poland (dec.))",false),
                    new PssChoice("", "6. Není porušením presumpce neviny práva na spravedlivý proces, pokud předseda vlády oznámí v televizi bez dalšího fakt, že určitá osoba byla zadržena či vzata do vazby. ", " (Gutsanovi v. Bulgaria (§§ 195-201)",false),
                    new PssChoice("", "7. Prohlášení předsedy politické strany, která je právně a finančně nezávislá na státu v kontextu vzrušené politické debaty, nelze považovat za prohlášení veřejného činitele jednajícího ve veřejném zájmu podle článku 6 § 2 Úmluvy, a tudíž ani za porušení práva na spravedlivý proces. ", " (Mulosmani v. Albania, § 141)",false)
                ],
                false
            )
        ]
    ),
    new PssSection(
        "VI. Práva obhajoby – čl. 6, odst. 3, písm. (a) Úmluvy: právo na včasné seznámení se s povahou a důvodem obvinění v jazyce, kterému obviněný rozumí",
        "",
        "",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na spravedlivý proces, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("to.6.1", "1. Obvinění z trestného činu, které bylo účastníkovi řízení sděleno, neobsahuje podrobné informace o trestných činech, z nichž je obžalovaný obviněn, nebo neobsahuje dostatečnou identifikaci skutku uvedením místa a data spáchání trestného činu, případně identifikaci oběti, pokud existuje. Tím došlo k porušení práva na spravedlivý proces. ", "(Brozicek v. Itálie, § 42)"),
                    new PssChoice("to.6.2", "2. Obvinění z trestného činu, které bylo účastníkovi řízení sděleno, neobsahuje odkaz na příslušná ustanovení trestního zákoníku či jiného zákonného předpisu vymezující skutkovou podstatu trestného činu, a ani neobsahuje právní kvalifikaci skutků, jež jsou obviněném kladeny za vinu ve světle uvedených ustanovení trestní zákonných předpisů. ", " (Mattoccia v. Italy, § 59; Penev v. Bulgaria, §§ 33 and 42)"),
                    new PssChoice("to.6.3", "3. Povinnost informovat obviněného spočívá výhradně na obžalobě a nelze ji pasivně dodržet zpřístupněním informací, aniž by na to byla upozorněna obhajoba, k čemuž však došlo, čímž zároveň došlo k porušení práva na spravedlivý proces. ", "(Mattoccia v. Itálie, §65; Chichlian a Ekindjian v. Francie, Zpráva Komise, § 71)"),
                    new PssChoice("to.6.4", "4. O tom, že obviněný skutečně obdržel informace o povaze a důvodu obvinění v jazyce, kterému obviněný rozumí neexistuje důkaz; doručení informace o povaze a důvodu obviněnému je založeno pouze na fikci doručení či obdobné právní domněnce doručení (C. V. Italy, Commission decision), což se v daném případě stalo, čímž zároveň došlo k porušení práva na spravedlivý proces."),
                    new PssChoice("to.6.5", "5. V případě osoby s duševními obtížemi jsou orgány povinny přijmout další opatření, aby byla osoba podrobně informována o povaze a příčině obvinění proti němu, což se v daném případě nestalo, čímž zároveň došlo k porušení práva na spravedlivý proces.", " (Vaudelle v. France, § 65)"),
                    new PssChoice("to.6.6", "6. Obviněný nebyl řádně a plně informován o změně v jeho obvinění, včetně důvodu a právní kvalifikace, tak aby měl dostatečný čas a dostatečnou možnost připravit svou obhajobu, čímž došlo k porušení práva na spravedlivý proces. ", " (Mattoccia v. Italy, §§ 60 nebo 61; Bäckström and Andersson v. Sweden (dec.); Varela Geis v. Spain, § 54)"),
                    new PssChoice("to.6.7", "7. Je-li prokázáno nebo existují-li důvody se domnívat, že obviněný nemá dostatečné znalosti jazyka, v němž jsou informace o jeho obvinění poskytnuty, musí mu orgány poskytnout překlad obvinění, a to zdarma. ", " (Brozicek v. Italy, § 41; Tabaï v. France (dec.), Luedicke, Belkacem and Koç v. Germany, § 45)"),
                    //new PssChoice("to.6.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Informace o obvinění nemusí nutně uvádět důkazy, na nichž je obvinění založeno. ", " (X. V. Belgie, rozhodnutí Komise; Collozza a Rubinat v. Itálie, zpráva Komise)", false),
                    new PssChoice("", "2. Není porušením práva na spravedlivý proces, pokud se skutečnost, že se obviněný nemohl včas seznámit s povahou a důvodem obvinění v jazyce, kterému obviněný rozumí, je přičitatelná chování obviněného. ", " (Erdogan v. Turkey, Commission decision; Campbell and Fell v. The United Kingdom, § 96)", false),
                    new PssChoice("", "3. Skutkové podrobnosti trestného činu mohou být doplněny po sdělení obvinění během následného řízení. ", " (Sampech v. Italy (dec.), § 110; Pereira Cruz and Others v. Portugal, § 198)", false),
                    new PssChoice("", "4. Nedochází k porušení práva na včasné seznámení se s povahou a důvodem obvinění v jazyce, kterému obviněný rozumí, a tedy ani k porušení práva na spravedlivý proces, pokud vady ve sdělení obvinění či obžaloby byly v odvolacím řízení napraveny, pokud měl obžalovaný před vyššími soudy předložit svou obhajobu ve vztahu k přeformulovanému obvinění a zpochybnit jeho odsouzení ve všech relevantních právních a skutkových aspektech. ", " (Dallos v. Hungary, §§ 49-52; Sipavičius v. Lithuania, §§ 30-33; Zhupnik v. Ukraine, §§ 39-43; I.H. and Others v. Austria, §§ 36-38; Gelenidze v. Georgia, § 30)", false),
                    new PssChoice("", "5. Dostatečné informace o obvinění však mohou být rovněž poskytnuty ústním překladem obžaloby, pokud to obviněnému, resp. obžalovanému umožní připravit svou obhajobu. ", " (Husain v. Italy (dec.)", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "VII. Práva obhajoby – Čl. 6, odst. 3, písm. (b) Úmluvy: právo na přiměřený čas na přípravu obhajoby ",
        "Článek 6 § 3 (b) Úmluvy chrání obviněného před nepřiměřeně rychlým soudním řízením [1]." +
        "Ačkoli je důležité vést řízení přiměřenou rychlostí, nemělo by to být provedeno na úkor procesních práv jedné ze stran " +
        "[2]. Článek 6 § 3 (b) Úmluvy se týká dvou prvků řádné obhajoby, a sice otázky, zda měl obviněný dostatečné možnosti k tomu, " +
        "aby si připravil svou obhajobu, a zda měl k této přípravě přiměřený čas. Otázka přiměřenosti času a možností se hájit " +
        "poskytnutých obviněnému musí být posouzena s ohledem na okolnosti každého konkrétního případu [3]." +
        "\n" +
        "\n" +
        "Obhajoba jménem obviněného může zahrnovat vše, co je „nezbytné“ pro přípravu soudního řízení. " +
        "Obviněný musí mít možnost zorganizovat svou obhajobu vhodným způsobem a bez omezení, pokud jde o schopnost předložit " +
        "soudu v trestním řízení všechny relevantní argumenty obhajoby, a ovlivnit tak výsledek tohoto řízení [4]; " +
        "každý obviněný z trestného činu musí mít rovněž možnost seznámit se pro účely přípravy své obhajoby s výsledky vyšetřování " +
        "prováděných v průběhu trestního řízení [5]. Při posuzování, zda měl obviněný dostatek času na přípravu své obhajoby, je třeba " +
        "vzít zvláštní ohled na povahu řízení, jakož i na složitost věci a fázi řízení [6].",
        "[1] (Kröcher a Möller v. Švýcarsko, rozhodnutí Komise; Bonzi v. Švýcarsko, rozhodnutí Komise; Borisova v. Bulharsko)" +
        "§ 40; Malofeyeva v. Rusko, § 115; Gafgaz Mammadov v. Ázerbájdžán, § 76–82), [2] (OAO Neftyanaya Kompaniya Yukos v. Rusko, § 540)," +
        "[3] (Iglin v. Ukrajina, § 65; Galstyan v. Arménie, § 84), [4] (Can v. Rakousko, zpráva Komise, § 53; Gregačević v. Chorvatsko, § 51)," +
        "[5] (Huseyn a další v. Ázerbájdžán, §175; OAO Neftyanaya Kompaniya Yukos v. Rusko, § 538), [6] (Gregačević v. Chorvatsko, § 51) ",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na spravedlivý proces, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("to.7.1", "1. Právo na přípravu obhajoby v přiměřené době, a též právo na spravedlivý proces je porušeno, když je obviněnému bráněno v získávání kopií příslušných dokumentů ze spisu a sestavování a používání jakýchkoli pořízených poznámek či návrhů, k čemuž v daném případě došlo. ", "(Rasmussen v. Polsko, §§ 48-49; Moisejev v. Rusko, §§ 213-218; Matyjek v. Polsko, § 59; Seleznev v. Rusko, §§ 64-69)"),
                    new PssChoice("to.7.2", "2. Právo na přípravu obhajoby v přiměřené době, a též právo na spravedlivý proces bylo porušeno, pokud účastník řízení a jeho obhájce měli pouhých 5 dní na přípravu obhajoby, kdy spis čítal cca 1500 stran. ", " (Lambin v. Russia, §§ 43-48)"),
                    new PssChoice("to.7.3", "3. Právo na přípravu obhajoby v přiměřené době, a též právo na spravedlivý proces bylo porušeno, pokud obhájce a účastník řízení měl pouze velmi krátký čas ke studiu spisu nebo mezi sdělením obvinění a ústním jednáním u soudu uplynula pouze velmi krátká doba. ", " (Huseyn and Others v. Azerbaijan, § 174-178; Iglin v. Ukraine, §§ 70-73, Vyerentsov v. Ukraine, §§ 75-77)"),
                    new PssChoice("to.7.4", "4. Obhajobě musí být poskytnuta dodatečná lhůta poté, co nastaly určité události v řízení, aby obhajoba mohla reagovat na takové události, například úpravou obhajoby, přípravy žádostí, podání odvolání atd. Tyto „události“ mohou zahrnovat změnu v obžalobě, předložení nových důkazů obžalobou nebo náhlou a drastickou změnou názoru znalce během hlavního líčení. K takovému poskytnutí dodatečné lhůty ovšem nedošlo, čímž došlo k porušením práva na spravedlivý proces.", "(Miminoshvili v. Rusko, § 141, Pélissier a Sassi v. Francie  [GC], § 62, G.B. v. Francie,§§ 60-62, 69-70)"),
                    new PssChoice("to.7.5", "5. Není-li plně odůvodněný rozsudek k dispozici před uplynutím lhůty pro podání opravného prostředku, musí být obviněnému poskytnuty dodatečně veškeré informace a čas, aby mohl podat informované odvolání. Takové informace nebo čas však poskytnuty nebyly, čímž došlo k porušení práva na spravedlivý proces. ", "(Zoon v. the Netherlands, §§ 40-50; Baucher v. France, §§ 46-51)"),
                    new PssChoice("to.7.6", "6. Je zásadní, aby se obviněný i jeho obhájce mohli účastnit řízení a přednést obhajobu, aniž by trpěli nadměrnou únavou, k čemuž však došlo a čímž bylo porušeno jeho právo na spravedlivý proces. ", "(Barberà, Messegué and Jabardo v. Spain, § 70; Makhfi v. France, § 40; Fakailo (Safoka) and Others v. France, § 50)"),
                    new PssChoice("to.7.7", "7. Schopnost obviněného či obžalovaného soustředit se a uplatňovat mentální dovednosti při své obhajobě byly narušeny, nikoli jeho vinou, čímž bylo porušeno jeho právo na spravedlivý proces. ", " (Mayzit v. Russia, § 81)"),
                    new PssChoice("to.7.8", "8. Podmínky zadržení neumožnily obviněnému či obžalovanému číst a psát s přiměřeným stupněm koncentrace, včetně instruování svého právního zástupce. ", " (Moiseyev v. Russia, § 221)"),
                    new PssChoice("to.7.9", "9. Kumulativní účinek vyčerpání způsobeného zdlouhavými převozy mezi věznicemi – ve špatných podmínkách a s méně než osmi hodinami odpočinku, opakovaných po dobu čtyř dnů v týdnu po dobu delší než čtyři měsíce – vážně narušil schopnost obviněného, resp. obžalovaného sledovat řízení, předkládat tvrzení, dělat si poznámky a instruovat své právníky. Tím bylo porušeno právo na spravedlivý proces. ", "(Razvozzhayev v. Russia and Ukraine and Udaltsov v. Russia, §§ 253-254)"),
                    new PssChoice("to.7.10", "10. Umístění obžalovaného do skleněné kabiny během soudního jednání brání jeho účinné konzultaci s advokátem, čímž došlo k porušení práva na spravedlivý proces. ", " (Yaroslav Belousov v. Russia, §§ 148-153)"),
                    new PssChoice("to.7.11", "11. Soud z vlastní iniciativy neodložil soudní jednání, aby umožnil přípravu obhajoby v přiměřené době, v případě, že ho o to účastník řízení požádal a měl k tomu reálné důvody.", " (Sadak and Others v. Turkey (no. 1), § 57; Sakhnovskiy v. Russia [GC], §§ 103 and 106)"),
                    //new PssChoice("to.7.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Neměl-li účastník dostatečný čas na přípravu obhajoby, musí tento účastník požádat soud o odložení či odročení soudního jednání. Pokud tak neučinil, nejedná se o porušení práva na spravedlivý proces.", "(Campbell and Fell v. the United Kingdom, § 98; Bäckström and Andersson v. Sweden (dec.); Craxi v. Italy (no. 1), § 72)", false),
                    new PssChoice("", "2. Obviněnému nemusí být umožněn přímý přístup ke spisu, stačí, aby byl svými zástupci informován o materiálu ve spise, pokud obviněnému nebylo umožněno vést vlastní obhajobu, odepření přístupu ke spisu představuje porušení práva na obhajobu, a též porušení práva na spravedlivý proces.", "(Kremzow v. Rakousko, § 52, Foucher v. Francie, §§ 33-36)", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "VIII. Práva obhajoby – Čl. 6, odst. 3, písm. (c) Úmluvy: právo hájit se osobně nebo prostřednictvím obhájce vlastního výběru ",
        "Právo každého obviněného z trestného činu účinně se hájit prostřednictvím advokáta – obhájce, je jedním ze " +
        "základních rysů spravedlivého procesu [1]. Každá osoba, vůči níž bylo vzneseno trestní obvinění, musí být chráněna článkem 6 § 3 písm. c) " +
        "Úmluvy v každé fázi řízení [2]. Zatímco článek 6 § 3 b) je vázán na přípravu hlavního líčení, článek 6 § 3 písm. c) Úmluvy " +
        "dává obviněnému obecnější právo na pomoc a podporu obhájcem po celou dobu trestního řízení [3]. " +
        "\n" +
        "\n" +
        "Stejně tak způsob, jakým má být článek 6 § 3 písm. c) Úmluvy uplatňován ve vztahu k odvolacím nebo dovolacím soudům, " +
        "závisí na zvláštních rysech řízení před těmito soudy [4]. Předmět a účel článku 6 Úmluvy jako celku ukazuje, že osoba obviněná " +
        "z trestného činu je oprávněna účastnit se soudního jednání [5]. Právo na právní pomoc zahrnuje celou řadu služeb spojených " +
        "s poskytování právní pomocí: prodiskutování případu, organizace obhajoby, shromažďování důkazů ve prospěch obviněného či " +
        "obžalovaného, příprava k výslechu, podpora obviněného v tísni a ověření podmínek zadržení [6]." +
        "\n" +
        "\n" +
        "Podezřelému by měl být umožněn přístup k právní pomoci od okamžiku, kdy je proti němu podáno „trestní obvinění“ " +
        "v autonomním významu Úmluvy [7]. V této souvislosti osoba nabývá postavení podezřelé osoby, " +
        "což vyvolává použití záruk podle článku 6 Úmluvy, nikoli tehdy, je-li jí tento status podezřelé osoby formálně přidělen, " +
        "ale pokud mají vnitrostátní orgány hodnověrné důvody pro podezření, že se tato osoba podílela na trestném činu [8]. Právo na přístup " +
        "k obhájci tak například vzniká nejen tehdy, je-li osoba vzata do vazby nebo vyslýchána policií [9], " +
        "ale i při procesních úkonech, jako jsou identifikace, rekognice či rekonstrukce událostí na místě [10]" +
        ", jakož i při pátracích a zajišťovacích operacích [11]. " +
        "\n" +
        "\n" +
        "Kromě toho právo obviněného účinně se účastnit trestního řízení zahrnuje obecně nejen právo být přítomen " +
        "průběhu tohoto řízení, ale také právo na právní pomoc, v případě potřeby [12] " +
        "zahrnuje právo na „praktickou a účinnou“ právní pomoc. Pouhé ustanovení obhájce za účelem poskytnutí právní pomoci ještě nezajišťuje, " +
        "že právní pomoc bude účinně poskytnuta, protože ustanovený obhájce může zemřít, vážně onemocnět, či mu může být " +
        "znemožněno právní pomoc poskytovat nebo se sám může vyhýbat svým povinnostem [13]. " +
        "Jakékoli omezení ovlivňující vztah mezi klientem a obhájcem, ať už faktické nebo výslovné, nesmí popřít " +
        "charakter účinnosti právní pomoci, na kterou má obhajovaný nárok [14].",
        "[1] (Salduz v. Turecko  [GC], § 51; Ibrahim a další v. Spojené království  [GC], § 255; " +
        "Simeonovi v. Bulharsko  [GC], § 112; Beuze v. Belgie  [GC], § 123); [2] (Imbrioscia v. Švýcarsko, § 37), " +
        "[3] (Can v. Rakousko, Zpráva Komise, § 54), [4] (Meftah a další v. Francie  [GC], § 41), " +
        "[5] (Zana v. Turecko  [GC], § 68; Monnell a Morris v. Spojené království, § 58), " +
        "[6] (Ayetullah Ay v. Turecko, § 136), [7] (Simeonovi v. Bulharsko [GC], § 110), [8] (Truten v. Ukrajina, § 66; Knox v. Itálie, § 152)," +
        "[9] (Simeonovi v. Bulharsko [GC], § 111; Sîrghi v. Rumunsko, § 44), [10] (Simeonovi v. Bulharsko [GC], § 111; Sîrghi v. Rumunsko, § 44)" +
        "[11] (Ayetullah Ay v. Turecko, §§ 135 a 163), [12] (Lagerblom v. Švédsko, § 49; Galstyan v. Arménie, § 89)," +
        "[13] (Artico v. Itálie, § 33; Vamvakas v. Řecko (č. 2), § 36), [14] (Sakhnovskij v. Rusko [GC], § 102).",

        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na spravedlivý proces, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("to.8.1", "1. Přístup k obhájci by měl být zpravidla poskytován od prvního výslechu podezřelého policií, pokud není s ohledem na konkrétní okolnosti každého případu prokázáno, že existují přesvědčivé důvody k omezení tohoto práva, což se však nestalo a existence žádných přesvědčivých důvodů nebyla prokázána. ", "(Salduz v. Turkey [GC], §§ 54-55; Ibrahim and Others v. The United Kingdom [GC], § 256)"),
                    new PssChoice("to.8.2", "2. Stejně tak pouhá přítomnost obhájce nemůže vykompenzovat nepřítomnost obviněného. Není-li právní zástupce schopen se poradit se svým klientem a obdržet od něj důvěrné pokyny bez dohledu, ztrácí jeho pomoc velkou část své užitečnosti. Užitečná pomoc obhájce tak nebyla jeho klientovi poskytnuta, tudíž došlo k porušení práva na spravedlivý proces. ", "(Zana v. Turecko [GC], § 72, S. V. Švýcarsko, §48; Brennanová v. Spojené království, § 58)"),
                    new PssChoice("to.8.3", "3. Dotčená osoba neměla právo poradit se přímo se svým právním zástupcem před úvodním policejním výslechem nebo kdykoli poté, čímž došlo k porušení práva na spravedlivý proces. ", "(Beuze v. Belgium, §§ 133-134)"),
                    new PssChoice("to.8.4", "4. Dotčené osobě nebylo zajištěno právo, aby její právní zástupce byl přítomen úvodnímu policejnímu výslechu nebo kdykoli poté, čímž došlo k porušení práva na spravedlivý proces. ", " (Beuze v. Belgium, §§ 133-134)"),
                    new PssChoice("to.8.5", "5. Dotčené osobě nebylo zajištěno právo, aby její právní zástupce při úvodním policejním výslechu jí mohl aktivně asistovat nebo kdykoli poté, čímž došlo k porušení práva na spravedlivý proces. ", " (Beuze v. Belgium, §§ 133-134, Soytemiz v. Turkey, (§§ 44-46, 27)"),
                    new PssChoice("to.8.6", "6. Policejní orgán neodložil výslech přesto, že dotčená vyslýchaná osoba požádala před podpisem výslechového protokolu o to, aby mohla být zastupována právním zástupcem, čímž došlo k porušení práva na spravedlivý proces. ", "(Soytemiz v. Turkey, (§§ 44-46, 27)"),
                    new PssChoice("to.8.7", "7. Právní zástupce účastníka řízení měl obtíže při přístupu do spisu, a to kdykoli během soudní části trestního řízení nebo předtím od okamžiku prvního kontaktu s orgány činnými v trestním řízení. ", "(Beuze v. Belgium [GC], (§ 135)"),
                    new PssChoice("to.8.8", "8. Právní zástupce účastníka řízení měl obtíže účastnit se úkonů trestního řízení týkajících se účastníka, kterého zastupuje či zastupoval, například při identifikaci, rekonstrukci nebo rekognici. ", "(Beuze v. Belgium [GC], (§ 135)"),
                    new PssChoice("to.8.9", "9. Právo na právní zastoupení nezávisí na přítomnosti obviněného. Skutečnost, že obviněný, přestože byl řádně předvolán, se nedostaví, nemůže – ani při neexistenci omluvy – odůvodnit zbavení jeho práva na právního zástupce, k čemuž však přesto došlo, čímž bylo porušeno právo na spravedlivý proces.", "(Van Geyseghem v. Belgie  [GC], § 34; Campbell a Fell v. Spojené království, § 99; Poitrimol v. Francie, § 34; (Van Geyseghem v. Belgie  [GC], § 34; Pelladoah v. Nizozemsko, § 40; Krombach v. Francie, § 89; Galstyan v. Arménie, § 89)"),
                    new PssChoice("to.8.10", "10. Aby bylo právo na právní pomoc praktické a účinné, a nikoli pouze teoretické, nesmí být jeho výkon podmiňován splněním nepřiměřeně formalistických podmínek: je na soudech, aby zajistily spravedlivé řízení, a tedy i obhájce, který se účastní soudního řízení za zjevným účelem obhajoby obviněného v jeho nepřítomnosti k tomu měl příležitost. K uvedenému praktickému a účinnému zajištění práva na právní pomoc však nedošlo, čímž došlo k porušení práva na spravedlivý proces. ", "(Van Geyseghem v. Belgium [GC], § 33; Pelladoah v. The Netherlands, § 41)"),
                    new PssChoice("to.8.11", "11. Je porušením práva na spravedlivý proces, pokud právo na právní pomoc bylo účastníkovi řízení omezeno z důvodu obavy o vynášení informací účastníkem řízení či jeho právním zástupcem na veřejnost nebo v důsledku toho, že omezení práva účastníka řízení na právní pomoc je běžnou zvyklostí orgánů veřejné moci. ", "(Ibrahim and Others v. The United Kingdom [GC], § 259, Simeonovi v. Bulgaria [GC], § 130)."),
                    new PssChoice("to.8.12", "12. Nebyl-li účastník trestního řízení informován o svém právu na právní zastoupení, nemůže se tohoto práva vzdát. Účastník trestního řízení nicméně informován nebyl, ale přesto se tohoto práva vzdal, čímž bylo porušeno právo na spravedlivý proces.", "(Simeonovi v. Bulgaria [GC], § 118)"),
                    new PssChoice("to.8.13", "13. Vzdání se práva na právní zastoupení bylo později dotčenou osobou, která tak učinila, výslovně odvoláno jejím vyjádřením, ve kterém žádala o právní zastoupení, což jí však bylo odepřeno, čímž došlo k porušení práva na spravedlivý proces. ", "(Artur Parkhomenko v. Ukraine, § 81)"),
                    new PssChoice("to.8.14", "14. Právo na právního zástupce, kterého si dotčená osoba žádá, by mělo být až na výjimečné situace respektováno, k čemuž však nedošlo a uvedená výjimečná situace nenastala, čímž došlo k porušení práva na spravedlivý proces. ", "(Lagerblom v. Sweden, § 54)"),
                    new PssChoice("to.8.15", "15. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces, pokud byla jeho komunikace s jeho právním zástupcem odposlouchávána, k čemuž v daném případě došlo. ", "(Zagaria v. Italy, § 36)."),
                    new PssChoice("to.8.16", "16. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces, pokud bylo dohlíženo na jeho komunikaci s jeho právním zástupcem, k čemuž v daném případě došlo. ", " (Rybacki v. Poland, § 58, Lanz v. Austria, § 52)"),
                    new PssChoice("to.8.17", "17. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces, pokud byly soustavně omezovány návštěvy právního zástupce u daného účastníka. ", "(Öcalan v. Turkey [GC], § 135)."),
                    new PssChoice("to.8.18", "18. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces, pokud mu nebyla umožněna soukromá komunikace s jeho právním zástupcem v rámci videokonference. ", " (Sakhnovskiy v. Russia [GC], § 104; Gorbunov and Gorbachev v. Russia, § 37)"),
                    new PssChoice("to.8.19", "19. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces, pokud mu nebyla umožněna komunikace s jeho právním zástupcem v soudní síni. ", " (Khodorkovskiy and Lebedev v. Russia, §§ 642-647)"),
                    new PssChoice("to.8.20", "20. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces, pokud mu bylo vyhrožováno sankcí v případě, že bude vyžadovat soukromou komunikaci s jeho právním zástupcem ", "(M v. The Netherlands, § 92)"),
                    new PssChoice("to.8.21", "21. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces v situaci, kdy “ex offo” ustanovený obhájce k zastupování tohoto účastníka v trestním řízení pro tohoto účastníka řízení nečinil žádné úkony. ", " (Artico v. Italy, §§ 33 and 36)"),
                    new PssChoice("to.8.22", "22. Došlo k porušení práva účastníka trestního řízení na spravedlivý process v situaci, kdy “ex offo” ustanovený obhájce k zastupování tohoto účastníka v trestním řízení svou nečinností zavinil nesplnění zásadní procesní povinnosti tohoto účastníka řízení, přičemž takové nesplnění není pouhou vadou v argumentaci nebo chybou ve vedení obhajoby. ", " (Czekalla v. Portugal, §§ 65 and 71, Czekalla v. Portugal, §§ 65 and 71)"),
                    new PssChoice("to.8.23", "23. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces, pokud “ex offo” ustanovený obhájce k zastupování tohoto účastníka v trestním řízení se opakovaně nedostavil na soudní jednání a soud nezajistil takovému účastníkovi řízení jiným způsobem účinné právní zastoupení v tomto trestním řízení. ", " (Güveç v. Turkey (§ 131)."),
                    new PssChoice("to.8.24", "24. Pokud se účastník trestního řízení vzdal práva na to být zastupován právním zástupcem, má soud povinnost zkoumat okolnosti, za jakých k takovému vzdání se došlo. Pokud tak neučinil jako v daném případě, došlo k porušení práva účastníka na spravedlivý proces. ", "(Türk v. Turkey, §§ 53-54; Rodionov v. Russia, § 167)"),
                    //new PssChoice("to.8.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Není porušením práva na spravedlivý proces, pokud se účastník trestního řízení rozhodne hájit sám a zájmy spravedlnosti nevyžadují, aby mu byl ustanoven obhájce “ex-offo”. ", " (Galstyan v. Armenia, § 91)", false),
                    new PssChoice("", "2. Není porušením práva na spravedlivý proces, pokud se účastník trestního řízení rozhodne hájit sám a při své obhajobě nepostupuje s dostatečnou procesní pečlivostí. ", " (Melin v. France, § 25)", false),
                    new PssChoice("", "3. Není porušením práva na spravedlivý proces, pokud je právo na právního zástupce odepřeno účastníkovi řízení v postavení svědka, jenž učinil dobrovolnou výpověď. ", " (Bandaletov v. Ukraine, §§ 61-66)", false),
                    new PssChoice("", "4. Není porušením práva na spravedlivý proces, pokud je právo na právního zástupce odepřeno účastníkovi dopravní nehody při odběru krevního vzorku. ", " (Sršen v. Croatia (dec.), §§ 43-45)", false),
                    new PssChoice("", "5. Není porušením práva na spravedlivý proces, pokud je právo na právního zástupce ve výjimečných případech poskytnuto se zpožděním. Takovém omezení na včasný přístup účastníka trestního řízení ke svému právnímu zástupci musí být založeno na “přesvědčivých důvodech”: existence přesvědčivých důvodů se může zakládat na individuálních důvodech daného případu a musí být časově omezené. I pokud splňují tyto podmínky, nesmí tyto přesvědčivé důvody vest k celkové “neférovosti” trestního řízení, zejména nesmí vést k nenapravitelným poškozením práva na spravedlivý proces dotčeného účastníka trestního řízení. ", " (Ibrahim and Others v. The United Kingdom [GC], §§ 257, 258, 264-265; Dimitar Mitev v. Bulgaria, § 71)", false),
                    new PssChoice("", "6. Byla prokázána existence přesvědčivých důvodů, proč by právo na důvěrnou komunikaci účastníka trestního řízení s advokátem mohlo být omezeno, konkrétně riziko ovlivňování svědků nebo porušování etických pravidel advokátem nebo protiprávní jednání advokáta (S. V. Switzerland, § 49; Rybacki v. Poland, § 59) nebo zneužití důvěrnosti či bezpečnostní riziko. V takovém případě nejde o porušení práva na spravedlivý proces.", "(Khodorkovskiy and Lebedev v. Russia, § 641)", false),
                    new PssChoice("", "7. Účastník trestního řízení se vzdal práva na přístup k právnímu zástupci a toto vzdání se splňuje podmínky “vědomého a s rozmyslem učiněného rozhodnutí”, přičemž soud přezkoumal okolnosti učinění takového “vědomého a s rozmyslem učiněného rozhodnutí”. Pokud vzdání se práva na přístup k právnímu zástupci splňuje podmínky “vědomého a s rozmyslem učiněného rozhodnutí” a soud to přezkoumal, není možné zpochybňovat spravedlivý proces. ", "(Türk v. Turkey, §§ 53-54; Rodionov v. Russia, § 167; Ibrahim and Others v. The United Kingdom [GC], § 272; Pishchalnikov v. Russia, § 77; Šarkienė v. Lithuania (dec.), § 38; Sklyar v. Russia, § 26)", false),
                    new PssChoice("", "8. Omezení možnosti zastupování vybranými advokáty v určitých typech řízení v situaci, kdy si účastník trestního řízení přeje být zastupován jiným advokátem, jenž není některým z uvedených vybraných advokátů, není porušením práva na spravedlivý proces.", "(Meftah and Others v. France [GC], § 47)", false),
                    new PssChoice("", "9. V případě, že bylo omezeno právo účastníka trestního řízení na výběr právního zástupce podle jeho vlastního uvážení, není porušením práva na spravedlivý proces, pokud účastník trestního řízení ve své stížnosti proti takovému omezení nezdůvodní, jakým způsobem toto omezení mohlo mít vliv na celkovou férovost tohoto projevu. ", " (Stevan Petrović v. Serbia, §§ 171-172)", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "IX. Práva obhajoby – Čl. 6, odst. 3, písm. (d) Úmluvy: právo vyslýchat nebo dát vyslýchat svědky proti sobě a dosáhnout předvolání a výslechu svědků ve svůj prospěch za stejných podmínek jako svědků proti sobě",
        "Před tím, než může být obžalovaný odsouzen, musí být všechny důkazy proti němu předloženy v jeho přítomnosti " +
        "na veřejném slyšení, aby se k nim mohl vyjádřit a případně je rozporovat. Článek 6 § 3 písm. (d) Úmluvy vyžaduje, aby " +
        "obviněnému byla poskytnuta přiměřená a řádná příležitost napadnout a vyslechnout svědka proti němu, a to buď v okamžiku, " +
        "kdy svědek učiní svou svědeckou výpověď, nebo v pozdější fázi řízení [1]. Pojem „svědek“ má autonomní význam ve smyslu " +
        "Úmluvy bez ohledu na klasifikaci podle vnitrostátního práva [2]. " +
        "\n" +
        "\n" +
        "Pokud výpověď svědka může sloužit v podstatné míře jako základ pro odsouzení, představuje důkaz pro trestní stíhání, " +
        "na které se vztahují záruky stanovené článkem 6 § 1 a § 3 písm. (d) Úmluvy, " +
        "přičemž takové důkazy mohou zahrnovat rovněž důkazy vyplývající z rekognice nebo osobní konfrontace s podezřelým [4], " +
        "jež se týkají spoluobžalovaného, oběti [6], znalce [7] či policisty [8]. " +
        "Článek 6 § 3 (d) lze použít také na listinné důkazy [9], včetně zpráv " +
        "vypracovaných orgánem činným v trestním řízení, jež provedl zatčení [10].",
        "[1] (Al-Khawaja a Tahery v. Spojené království [GC], § 118; Hümmer v. Německo, § 38; Lucà v. Itálie, § 39; " +
        "Solakov v. Bývalá jugoslávská republika Makedonie, § 57), [2] (Damir Sibgatullin v. Rusko, § 45; S.N. v. Švédsko, § 45)" +
        "[3] (Kaste a Mathisen v. Norsko, § 53; Lucà v. Itálie, § 41); [4] (Vanfuli v. Rusko, § 110);" +
        "[5] (Trofimov v. Rusko, § 37; Oddone a Pecci v. San Marino; §§ 94-95), [6] (Vladimir Romanov v. Rusko, § 97);" +
        "[7] (Doorson v. Nizozemsko, §§ 81-82); [8] (Ürek a Ürek v. Turecko, § 50);" +
        "[9] (Mirilashvili v. Rusko, §§ 158-159; Chap Ltd v. Arménie, § 48); [10] (Butkevich v. Rusko, §§ 98-99)." +
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na spravedlivý proces, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("to.9.1", "1. Účastníkovi řízení nebylo umožněno zpochybnit bezúhonnost a věrohodnost výpovědí svědka, včetně její pravdivosti a spolehlivosti tím, že by svědek byl ústně vyslechnut v jeho přítomnosti, a to ani v době, kdy svědek činil výpověď, nebo v jiné fázi řízení, nebo pokud se svědek dostavil k soudu, avšak účastníkovi řízení nebylo umožněno z důvodu procesních nesrovnalostí účastníka řízení vyslechnout. Tím došlo k porušení práva účastníka řízení na výslech svědků a zároveň k porušení práva na spravedlivý proces. ", "(Chernika v. Ukraine, § 46)"),
                    new PssChoice("to.9.2", "2. Článek 6 § 1 společně s čl. 6 § 3 Úmluvy požaduje, aby soud či jiné orgány veřejné moci učinily aktivní kroky k tomu, aby umožnily účastníkovi řízení vyslechnout svědky, jejichž svědectví svědčí proti němu. Takové aktivní kroky však učiněny nebyly, čímž došlo k porušení práva na spravedlivý proces. ", "(Trofimov v. Russia, § 33; Sadak and Others v. Turkey (no. 1), § 67; Cafagna v. Italy, § 42)"),
                    new PssChoice("to.9.3", "3. Je porušením práva na spravedlivý proces, pokud nebyly ze strany orgánů veřejné moci učiněny přiměřené kroky k tomu, aby zajistily při soudním jednání osobní účast svědků, jejichž svědectví svědčí proti účastníkovi řízení v postavení obviněného či obžalovaného, například v případě svědka pobývajícího v zahraničí nedošlo k vyžádání si jeho výslechu prostřednictvím mechanismů mezinárodní právní pomoci. Žádné takové kroky v daném případě však nebyly učiněny. ", "(Karpenko v. Russia, § 62; Damir Sibgatullin v. Russia, § 51; Pello v. Estonia, § 35; Bonev v. Bulgaria, § 43; Tseber v. The Czech Republic, § 48; Lučić v. Croatia, §§ 79-80; (Schatschaschwili v. Germany [GC], §§ 121-122)"),
                    new PssChoice("to.9.4", "4. Je porušením práva na spravedlivý proces, pokud u svědka, jehož totožnost není účastníkovi řízení známa, nejsou prokázány relevantní a dostatečné důvody pro utajení jeho totožnosti, tedy pokud není důkazně prokázána existence objektivně důvodných obav na straně svědka s utajenou totožností. Takové důvody však v daném případě nebyly prokázány. ", "(Doorson v. The Netherlands, § 69; Van Mechelen and Others v. The Netherlands, § 52; Krasniki v. The Czech Republic § 76; Visser v. The Netherlands, § 47; Sapunarescu v. Germany (dec.); Dzelili v. Germany (dec.); Scholer v. Germany, §§ 53-56; (Al-Khawaja and Tahery v. The United Kingdom [GC], § 124; Balta and Demir v. Turkey, § 44)"),
                    new PssChoice("to.9.5", "5. Pokud soud umožní výslech svědka, má povinnost přijmout účinná opatření k zajištění jeho přítomnosti při soudním jednání, například nařízením jeho předvedení orgány policie. Taková opatření však přijata nebyla, čímž došlo k porušení práv na spravedlivý proces. ", "(Polufakin and Chernyshev v. Russia, § 207; (Murtazaliyeva v. Russia [GC], § 147)"),
                    new PssChoice("to.9.6", "6. Je porušením práva na spravedlivý proces, pokud soud připustí pouze písemné vyjádření svědka místo jeho osobního výslechu při soudním jednání v situaci, kdy existovala reálná možnost, jak umožnit osobní výslech svědka, avšak nebyla využita, a takový svědek nebyl vyslechnut dříve v rámci daného trestního řízení. K takovému porušení v daném případě došlo. ", "(Seton v. the United Kingdom, §§ 58-59; Dimović v. Serbia, §§ 36-40; T.K. v. Lithuania, §§ 95-96)"),
                    new PssChoice("to.9.7", "7. Pokud je odsuzující rozsudek založen pouze a jen na vyjádření svědka, jemuž účastník řízení v postavení obviněného či obžalovaného nemohl, kdykoli během trestního řízení či předtím, klást otázky, jedná se o porušení práva na spravedlivý proces, ke kterému v daném případě došlo. ", " (Seton v. the United Kingdom, §§ 58-59; Dimović v. Serbia, §§ 36-40; T.K. v. Lithuania, §§ 95-96)"),
                    //new PssChoice("to.9.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Není porušením práva na spravedlivý proces, pokud žádost o výslech svědka není dostatečně zdůvodněna, a proto je soudem zamítnuta.", "(Al-Khawaja and Tahery v. the United Kingdom [GC] (§§ 119-147)", false),
                    new PssChoice("", "2. Není porušením práva na spravedlivý proces, pokud žádost o výslech svědka je dostatečně zdůvodněna, avšak je přesto zamítnuta v případě, že je její zamítnutí řádně odůvodněno a uvedeno, proč není pro obhajobu relevantní, a zároveň je zamítnutí výslechu svědka navrženého obhajobou nenarušuje celkovou férovost procesu.", "(Al-Khawaja and Tahery v. the United Kingdom [GC] (§§ 119-147)", false),
                    new PssChoice("", "3. Nejedná se o porušení práva na spravedlivý proces, pokud je odsuzující rozsudek založen pouze a jen na vyjádření svědka, jehož totožnost byla účastníkovi řízení v postavení obviněného či obžalovaného utajeno, a soud s maximální obezřetností ověřil, že nebylo možné pro obžalobu získat důkazy proti uvedenému účastníkovi řízení jiným způsobem.", "(Seton v. the United Kingdom, §§ 58-59; Dimović v. Serbia, §§ 36-40; T.K. v. Lithuania, §§ 95-96)", false),
                    new PssChoice("", "4. Není porušením práva na spravedlivý proces, pokud soud nepřeruší projednávání věci v situaci, kdy se zjistilo, že není možné zajistit přítomnost požadovaného svědka a byla za tím účelem přijata aktivní opatření ovšem bez výsledku.", "(Gossa v. Poland, § 55; Haas v. Germany (dec.); Calabrò v. Italy and Germany (dec.); Ubach Mortes v. Andorra (dec.); Gani v. Spain, § 39)", false),
                    new PssChoice("", "5. Není porušením práva na spravedlivý proces, pokud je místo pobytu svědka neznámé, protože se skrývá před spravedlností a účastník řízení dostl příležitost vyjádřit se k aktivním opatřením, které byy přijaty k zajištění jeho přítoknosti, byť bez výsledku.", "(Lobarev and Others v. Russia, §§ 33-34)", false),
                    new PssChoice("", "6. Není porušením práva na spravedlivý proces připuštění písemné výpovědi svědka místo jeho osobní účasti na soudním projednávání věci, pokud je místo pobytu takového svědka neznámé z důvodu, že se skrývá před spravedlností (Schatschaschwili v. Germany [GC], § 119).", "", false),
                    new PssChoice("", "7. Není porušením práva na spravedlivý proces, pokud se svědek nedostavil k soudnímu projednání věci z některého z následujících důvodů: smrt svědka nebo důvodné obavy o jeho bezpečnost (Mika v. Sweden (dec.), § 37; Ferrantelli and Santangelo v. Italy, § 52; Al-Khawaja and Tahery v. the United Kingdom [GC], §§ 120-125), zdravotní důvody (Bobeş v. Romania, §§ 39-40; Vronchenko v. Estonia, § 58) nebo jeho zadržení v jiné zemi (Štefančič v. Slovenia, § 39).", "", false),
                    new PssChoice("", "8. Není porušením práva na spravedlivý proces, pokud není připuštěn svědek, jehož svědecká výpověď se týká pouze způsobu, jakým bylo prováděno vyšetřování, a ve vztahu k meritu věci je taková jeho svědecká výpověď redundantní (Murtazaliyeva v. Russia [GC], § 136).", "", false),
                    new PssChoice("", "9. Není porušením práva na spravedlivý proces, pokud účastník řízení v postavení obviněného či obžalovaného si stěžuje, že nemohl vyslýchat některé svědky, aniž by zdůvodnil, proč bylo důležité, aby takový svědek byl vyslechnut a proč svědecká výpověď takového svědka je důležitá pro jeho obhajobu. ", "(Perna v. Italy [GC], § 29; Băcanu and SC « R » S.A. v. Romania, § 75)", false),
                    new PssChoice("", "10. Není porušením práva na spravedlivý proces, pokud svědecká výpověď svědka navrženého účastníkem řízení v postavení obviněného či obžalovaného byla zamítnuta, protože nemohla obvinit výsledek trestního řízení, což bylo soudem řádně zdůvodněno.", "(Kapustyak v. Ukraine, §§ 94-95)", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "X. Práva obhajoby – Čl. 6, odst. 3, písm. (e) Úmluvy: právo na bezplatnou právní pomoc a tlumočníka",
        "Článek 6 § 3 písm. (e) Úmluvy se vztahuje nejen na ústní prohlášení učiněná při hlavním líčení, " +
        "ale také na listinné materiály a přípravné řízení [1]. " +
        "Požadavky odstavce 3 (e) článku 6 Úmluvy je třeba považovat za zvláštní aspekty práva na spravedlivý proces zaručeného odstavcem 1 " +
        "téhož článku [2]. Skutečnost, že žalovaný má základní znalost jazyka řízení nebo, jak je tomu ve věci, " +
        "třetího jazyka, překlad do nějž je snadno dostupný, by sama o sobě neměla bránit této osobě v tom, aby měl prospěch z tlumočení " +
        "do jazyka, kterému rozumí dostatečně dobře, aby mohla plně uplatnit své právo na obhajobu [3]. " +
        "Článek 6 § 3 (e) Úmluvy se nevztahuje na vztahy mezi obviněným a jeho právním zástupcem; vztahuje se pouze na vztahy " +
        "mezi obviněným, resp. Obžalovaným a soudcem [4]. " +
        "\n" +
        "\n" +
        "Článek 6 § 3 (e) zaručuje právo na bezplatnou pomoc tlumočníka při překladu nebo výkladu všech dokumentů nebo prohlášení " +
        "v řízení, kde je nezbytné, aby obviněný či obžalovaný rozuměl jeho obsahu a mohl využít svého práva na spravedlivý proces [5]" +
        "Stručně řečeno, poskytnutá tlumočnická pomoc by měla být taková, aby žalovanému umožnila " +
        "seznámit se s případem, který je proti němu veden, a bránit se zejména tím, že bude moci předložit soudu svou verzi " +
        "událostí [6].",
        "[1] (Kamasinski v. Rakousko, §74; Hermi v. Itálie [GC], § 70; Baytar v. Turecko, § 49); [2] (Baytar v. Turecko, § 48)" +
        "[3] (Vizgirda v. Slovinsko, § 83); [4] (X. V. Rakousko, rozhodnutí Komise)" +
        "[5] (Luedicke, Belkacem a Koç v. Německo, § 48; Ucak v. Spojené království (dec.); Hermi v. Itálie [GC], § 69; " +
        "Lagerblom v. Švédsko, § 61); [6] (Hermi v. Itálie [GC]; Kamasinski v. Rakousko, § 74; Güngör v. Německo (prosinec); " +
        "Protopapa v. Turecko, § 80; Vizgirda v. Slovinsko, § 79)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na spravedlivý proces, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("to.10.1", "1. Povinnost zajistit tlumočníka není omezena na situace, kdy podezřelý či obviněný či obžalovaný je cizím státním příslušníkem a učiní výslovnou žádost o tlumočníka. Je důležité, aby si podezřelý byl vědom práva na tlumočníka, což znamená, že o takovém právu musí být informován, pokud je ve smyslu Úmluvy „obviněn z trestného činu, přičemž oznámení podezřelému o právu na tlumočníka mu musí být sděleno v jazyce, kterému rozumí, k čemuž nedošlo. ", " (Vizgirda v. Slovenia, §§ 86-87)"),
                    new PssChoice("to.10.2", "2. Počáteční vady v tlumočení proto mohou mít dopad na jiná práva a mohou ohrozit férovost řízení jako celku. Stejně jako pomoc právního zástupce musí být pomoc tlumočníka poskytována již od prvotní fáze vyšetřování, k čemuž nedošlo, pokud není prokázáno, že existují přesvědčivé důvody k omezení tohoto práva, jež nebyly přítomny. ", " (Baytar v. Turkey, §§ 50, 54-55)"),
                    new PssChoice("to.10.3", "3. Obviněný či obžalovaný nerozuměl jednacímu jazyku soudu, což je v rozporu s právem na spravedlivý proces, a to i kdyby jeho právní zástupce jednací jazyk soudu ovládal. ", " (Kamasinski v. Austria, § 74; Cuscani v. The United Kingdom, § 38)"),
                    new PssChoice("to.10.4", "4. Náklady tlumočení nemohou být zpětně požadovány po obviněném či obžalovaném, což se však stalo.", "(Luedicke, Belkacem and Koç v. Germany, § 46)"),
                    new PssChoice("to.10.5", "5. Soud neprověřil tvrzení obviněného či obžalovaného, že služby poskytnuté tlumočníkem byly nekvalitní, čímž došlo k porušení práva na spravedlivý proces podle čl. 6 § 3 (e) Úmluvy. ", " (Knox v. Italy, §§ 182-187)"),
                    new PssChoice("to.10.6", "6. Soud má povinnost I bez návrhu se přesvědčit, že obviněný či obžalovaný nepotřebuje tlumočníka, pokud se od právního zástupce dozví, že má potíže s obviněným či obžalovaným komunikovat v daném jazyce. O takových potížích se soud dozvěděl, nicméně potřebu tlumočníka pro obviněného či obžalovaného z úřední povinnosti nepřezkoumal, čímž se dopustil porušení práva na spravedlivý proces. ", "(Cuscani v. The United Kingdom, § 38)"),
                    //new PssChoice("to.10.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Právo na bezplatnou pomoc tlumočníka platí výlučně v situacích, kdy obviněný či obžalovaný nerozumí jazyku používanému u soudu nebo jím nemluví. ", "(K. v. France, Commission decision Baytar v. Turkey, § 49)", false),
                    new PssChoice("", "2. Obviněný či obžalovaný, který rozumí jednací řeči soudu, nemůže trvat na službách tlumočníka, aby mu umožnil vést svou obhajobu v jiném jazyce, včetně jazyka etnické menšiny, jejíž je členem.", "(Bideault v. France, Commission decision; Lagerblom v. Sweden, § 62)", false),
                    new PssChoice("", "3. Práva na tlumočníka se lze vzdát, avšak musí to být rozhodnutí obviněného, resp. obžalovaného, nikoli rozhodnutí jeho právního zástupce .", "(Kamasinski v. Austria, § 80)", false),
                    new PssChoice("", "4. Po obviněném, resp. Obžalovaném, mohou být požadovány náklady za tlumočení za slyšení, jehož se měl zúčastnit, avšak se nezúčastnil. ", " (Fedele v. Germany (dec.))", false),
                ],
                false
            )
        ]
    )
];