import {useEffect, useRef, useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import {useParams} from "react-router-dom";
import GenerateDocumentSelections from "./Utils/GenerateDocumentSelections";
import DownloadRTF from "./Utils/DownloadRTF";

export default function ExekutorTrestniOznameni() {

    let {documentId: str_doc_id} = useParams();
    let documentId: number = parseInt(str_doc_id as string);

    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);
    const storedDocument = documents.getDocument(documentId);
    const [state, setState] = useState({doc: storedDocument});

    useEffect(() => {
        let subscriberId = documents.subscribeChange(changedDoc => {
            if (state.doc.id === changedDoc.id) {
                setState({doc: changedDoc});
            }
        });

        return () => {
            documents.unsubscribe(subscriberId);
        };
    });

    const downloadContents = useRef(null);

    return <>
        <Card className={"mt-3"} id={"form-identifications"}>
            <Card.Header>
                <Card.Title>
                    Vyplňte prosím následující identifikace pro vybraný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Exekutor či exekutoři, jenž se podle podatele výše uvedených porušení práv na
                            spravedlivý proces dopustil(i):</Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.exekutori}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.exekutori = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Exekuční úřad, na kterém tento exekutor či exekutoři působí: </Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.exekutorskyUrad}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.exekutorskyUrad = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení podatele:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.podatel}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.podatel = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení dotčené osoby, pokud se neshodují s podatelem:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.dotceni}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.dotceni = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
        <Card className={"mt-3"} id={"document-preview"}>
            <Card.Header>
                <Card.Title>
                    Náhled na generovaný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body ref={downloadContents}>
                <h1>Trestní oznámení</h1>
                <p>Já níže uvedený podávám tímto trestní oznámení na neznámého pachatele, a to z níže uvedených
                    důvodů.</p>
                <h2>1. OBSAH </h2>
                <p>Žádám, aby níže uvedená mnou namítaná porušení zásad spravedlivého procesu níže uvedeným exekutorem
                    či exekutory byla posouzena ve světle podezření ze spáchání níže uvedených trestných činů či
                    případně jiných trestných činů. Zároveň žádám, aby se příslušný orgán činný v trestním řízení
                    argumentačně vypořádal se všemi níže uvedenými porušeními práva na spravedlivý proces, k nimž došlo,
                    jež by mohla založit důvodné podezření z jednoho či více uvedených trestných činů. V této
                    souvislosti podotýkám, že podle dále uvedené judikatury Ústavního soudu (např. I. ÚS 403/03, N
                    160/38 SbNU 277), argumentuje-li podatel názory judikatury, musí se veřejný orgán s těmito názory
                    argumentačně vypořádat (případně i tak, že vysvětlí, proč je nepovažuje pro danou věc
                    relevantní). </p>
                <h2>2. PRÁVNÍ ZÁKLAD</h2>
                <p>Podle čl. 2 odst. 3 Ústavy ČR a čl. 2 odst. 3 Listiny základních práv a svobod „státní moc slouží
                    všem občanům a lze ji uplatňovat jen v případech, v mezích a způsoby, které stanoví zákon.“</p>
                <p>Podle čl. 10 Ústavy ČR „vyhlášené mezinárodní smlouvy, k jejichž ratifikaci dal Parlament souhlas a
                    jimiž je Česká republika vázána, jsou součástí právního řádu; stanoví-li mezinárodní smlouva něco
                    jiného než zákon, použije se mezinárodní smlouva.“</p>
                <p>Podle čl. 6 odst. 1 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou
                    a vyhlášené ve sdělení č. 209/1992 Sb., má „každý právo na to, aby jeho záležitost byla spravedlivě,
                    veřejně a v přiměřené lhůtě projednána nezávislým a nestranným soudem, zřízeným zákonem, který
                    rozhodne o jeho občanských právech nebo závazcích nebo o oprávněnosti jakéhokoli trestního obvinění
                    proti němu.“ Obdobně podle čl. 36 odst. 1 se každý může domáhat stanoveným postupem svého práva u
                    nezávislého a nestranného soudu a ve stanovených případech u jiného orgánu. Podle čl. 81 Ústavy ČR
                    soudní moc vykonávají jménem republiky nezávislé soudy.</p>
                <p>Podle konstantní judikatury Ústavního soudu (např. N 160/38 SbNU 277) se nezávislost rozhodování
                    obecných soudů uskutečňuje v ústavním a zákonném procesněprávním a hmotněprávním rámci, jenž je
                    představován především principy řádného a spravedlivého procesu, jak vyplývají z čl. 36 a násl.
                    Listiny, jakož i z čl. 1 Ústavy České republiky a čl. 6 odst. 1 Evropské úmluvy o ochraně lidských
                    práv a svobod. Soudci i exekutoři proto musí principy řádného a spravedlivého procesu, jak jsou
                    vymezeny v uvedených ustanoveních a na nich navazující judikatuře, při své činnosti dodržovat, aby
                    se vyloučila libovůle při jejich rozhodování.</p>
                <p>Podle konstantní judikatury Evropského soudu pro lidská práva (ESLP) mají vnitrostátní orgány, včetně
                    soudů i exekutorů, v každém jednotlivém případě povinnost zajistit účastníkům sporu spravedlivý
                    proces (např. ESLP, Dombo Beheer B.V. v. Nizozemí); tento závěr je sdílen i českými soudci: „Někdy u
                    soudu nejsme schopni nastolit skutečnou spravedlnost, ale vždycky Vám musíme dát férové zacházení
                    (Kateřina Šimáčková, bývalá soudkyně Ústavního soudu ČR,
                    https://www.youtube.com/watch?v=QDSSW2PokIM)</p>
                <p>Podle § 28 zákona č. 120/2001 Sb. o soudních exekutorech a exekuční činnosti (exekuční řád), v
                    platném znění (věta druhá) se úkony exekutora se považují za úkony exekučního soudu. Podle § 55b
                    odst. 1 exekučního řádu, exekutor při svém rozhodování činí úkony, které v řízení o výkonu
                    rozhodnutí přísluší soudu prvního stupně. Podle § 2 odst. 1 exekučního řádu exekutor vykonává
                    exekuční činnost nezávisle, přičemž při výkonu exekuční činnosti je vázaný jen Ústavou České
                    republiky, zákony, jinými právními předpisy a rozhodnutími soudu vydanými v řízení o výkonu
                    rozhodnutí a exekučním řízení. Podle § 2 odst. 2 je exekutor je povinen vykonávat svědomitě své
                    povolání a při jeho výkonu a v občanském životě se zdržet všeho, co by mohlo narušit důstojnost
                    exekutorského povolání nebo ohrozit důvěru v nezávislý, nestranný a spravedlivý výkon exekuční
                    činnosti.</p>
                <p>Podle čl. 3 odst. 1 Usnesení sněmu Exekutorské komory České republiky ze dne 28. února 2006, kterým
                    byl přijat stavovský předpis, kterým se stanoví Pravidla profesionální etiky a pravidla soutěže
                    soudních exekutorů je exekutor je všeobecně povinen přispívat poctivým, čestným a slušným chováním k
                    důstojnosti a vážnosti exekutorského stavu. Podle odst. 3 téhož článku jsou projevy exekutora v
                    souvislosti s výkonem exekuční činnosti a další činnosti soudního exekutora věcné, střízlivé a
                    nikoliv vědomě nepravdivé. Podle čl. 5 odst. 1 téhož stavovského předpisu ve věcech, v nichž byl
                    soudem pověřen provedením exekuce nebo při výkonu další činnosti postupuje exekutor vždy nezávisle,
                    svědomitě a pečlivě.</p>
                <p>Podle čl. 12 odst. 3 zmíněného stavovského předpisu „Pravidla profesionální etiky a pravidla soutěže
                    soudních exekutorů“ je exekutor povinen v exekučním řízení jednat poctivě, respektovat zákonná práva
                    účastníků tohoto řízení a chovat se k nim i k ostatním osobám zúčastněným na řízení tak, aby nebyla
                    snižována jejich důstojnost ani důstojnost exekutorského stavu. Dále podle čl. 13 (Veřejně prospěšná
                    činnost) je exekutor, byl-li k tomu vyzván Exekutorskou komorou, je povinen podílet se v přiměřeném
                    rozsahu na projektech směřujících k prosazování nebo obhajobě lidských práv a svobod, a to i bez
                    nároku na odměnu, ledaže mu v tom brání vážné důvody, za stejných podmínek je exekutor povinen
                    podílet se na výzvu Exekutorské komory na projektech, jejichž cílem je uskutečňování principů
                    demokratického právního státu nebo zdokonalení právního řádu České republiky.</p>
                <p>Podle čl. 6 odst. 1 Evropské úmluvy na ochranu lidských práv a svobod se vztahuje na všechny fáze
                    soudního řízení, jimiž se „rozhod[uj]e… o… občanských právech nebo závazcích“, včetně fází
                    následujících po vydání rozsudku ve věci samé. Výkon rozsudku kteréhokoli soudu tedy musí být
                    považován za nedílnou součást „řízení“ ve smyslu článku 6 (ESLP, Hornsby proti Řecku, § 40;
                    Romańczyk proti Francii, § 53), přičemž použitelnost článku 6 na prvotní řízení není rozhodující,
                    neboť exekuční titul, kterým bylo rozhodnuto o občanských právech, nemusí nutně vzejít z řízení, na
                    které je použitelný článek 6 (ESLP, Buj proti Chorvatsku, § 19).</p>
                <p>Podle konstantní judikatury Ústavního soudu České republiky je notorietou, tj. skutečností, jíž si
                    musí být vědom každý orgán nadaný veřejnou moci a aplikující právo, včetně exekutorů, že „mechanická
                    aplikace [práva] abstrahující, resp. neuvědomující si, a to buď úmyslně, nebo v důsledku
                    nevzdělanosti, smysl a účel právní normy, činí z práva nástroj odcizení a absurdity." (I.ÚS 2920/09
                    ze dne 8. 2. 2011, N 14/60 SbNU 141, Pl. ÚS 33/97 (N 163/9 SbNU 399; 30/1998 Sb.)), přičemž takový
                    orgán, včetně exekutora, „není absolutně vázán doslovným zněním zákonného ustanovení, nýbrž se od
                    něj smí a musí odchýlit v případě, kdy to vyžaduje ze závažných důvodů […] některý z principů, jež
                    mají svůj základ v ústavně konformním právním řádu jako významovém celku" (I.ÚS 2920/09, N 14/60
                    SbNU 141).</p>
                <p>Podle čl. 13 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou a
                    vyhlášené ve sdělení č. 209/1992 Sb., „každý, jehož práva a svobody přiznané touto Úmluvou byly
                    porušeny, musí mít účinné právní prostředky nápravy před národním orgánem, i když se porušení
                    dopustily osoby při plnění úředních povinností.“</p>
                <h2>3. PORUŠENÍ PRÁV SPRAVEDLIVÉHO PROCESU</h2>
                <p><strong>Níže uvedený soudce či soudci, jako úřední osoby ve smyslu § 127 trestního zákoníku, se podle
                    mého názoru dopustili následujících porušení mých práv na spravedlivý proces podle čl. 36 Listiny
                    základních práv a svobod a čl. 6 odst. 1 Evropské úmluvy pro lidská práva, tak jak jsou vymezeny v
                    příslušné judikatuře Evropského soudu pro lidská práva vykládající toto ustanovení, která je u
                    každého jednotlivého níže uvedeného porušení práva na spravedlivý proces vždy zmíněna. Tato porušení
                    podle mého názoru vyvolávají důvodné podezření z naplnění skutkové podstaty trestného činu zneužití
                    pravomoci úřední osoby podle § 329 zákona č. 40/2009 Sb., trestního zákoníku, v platném
                    znění:</strong></p>
                <p>„(1) Úřední osoba, která v úmyslu způsobit jinému škodu nebo jinou závažnou újmu anebo opatřit sobě
                    nebo jinému neoprávněný prospěch</p>
                <p>a) vykonává svou pravomoc způsobem odporujícím jinému právnímu předpisu,</p>
                <p>b) překročí svou pravomoc nebo</p>
                <p>c) nesplní povinnost vyplývající z její pravomoci,</p>
                <p>bude potrestána odnětím svobody na jeden rok až pět let nebo zákazem činnosti.“</p>
                <p><strong>Uvedený exekutor či exekutoři podle mého názoru v důsledku dále uvedených porušení vykonávali
                    svou pravomoc způsobem odporujícím právnímu předpisu, konkrétně čl. 6 odst. 1 Evropské úmluvy na
                    ochranu lidských práv a svobod, jež je v smyslu čl. 10 Ústavy ČR součástí právního řádu České
                    republiky a má přednost před zákonem či jiným právním předpisem, či nesplnil(i) svou povinnost
                    vyplývající z jim svěřené pravomoci, když v dále uvedených konkrétních případech nezajistil(i)
                    spravedlivý proces, k čemuž je každý exekutor v každém jednotlivém případě povinen na základě čl. 6
                    odst. 1 Evropské úmluvy na ochranu lidských práv a svobod (viz např. rozhodnutí ESLP, ve věci Dombo
                    Beheer B.V. v. Nizozemí, série A, č. 274) a na základě ustanovení §§ 2 odst. 1 a 2 exekučního řádu,
                    jakož i čl. 5 odst. 1 (v případném spojení s čl. 3 odst. 1 a 3) a/nebo čl. 12 odst. 3 Usnesení sněmu
                    Exekutorské komory České republiky ze dne 28. února 2006, kterým byl přijat stavovský předpis,
                    kterým se stanoví Pravidla profesionální etiky a pravidla soutěže soudních exekutorů, v platném
                    znění.</strong></p>
                <p><strong>Tato důvodná podezření mne proto vedou k podání tohoto trestního oznámení pro podezření z
                    naplnění skutkových podstat výše uvedeného trestného činu či případně jiného trestného činu či
                    činů. </strong></p>
                <p><strong>Dále uvedené právní povinnosti týkající se práv spravedlivého procesu vztahující se na soudy
                    a soudce podle dále uvedené judikatury Evropského soudu pro lidská práva, jež podle mého názoru byly
                    porušeny, se použijí vedle soudů a soudců obdobně (mutatis mutandis) i na exekutory, jež vystupují v
                    řízení, na nějž se § 6 odst. 1 EÚLP vztahuje, v obdobném postavení jako soud či soudce.</strong></p>

                {GenerateDocumentSelections(state.doc)}
                <p><strong>Vysvětlení: Očíslované věty či části vět představují právní pravidla týkající se práv na
                    spravedlivý proces vyjádřená v rozsudcích Evropského soudu pro lidská práva. Odkazy ve formátu
                    „jméno v.
                    (proti) název státu, § číslo či §§ čísla“, například „Cipolletta v. Italy §§ 33-37“ představují
                    odkazy
                    na rozsudky (judikáty) Evropského soudu pro lidská práva ve Štrasburku, ve kterých byla jednotlivá
                    právní pravidla formulována. Plný obsah těchto rozsudků lze dohledat zadáním jejich názvu v databázi
                    rozsudků Evropského soudu pro lidská práva ve Štrasburku HUDOC na internetové adrese:
                    https://hudoc.echr.coe.int/</strong></p>

                <h2>4. OSOBA ČI OSOBY, JEŽ SE UVEDENÝCH PORUŠENÍ DOPUSTILY</h2>
                <p><strong>Exekutor či exekutoři, jenž se podle podatele výše uvedených porušení práv na spravedlivý
                    proces dopustil(i):</strong></p>
                <p>{state.doc.generatorSettings.exekutori}</p>
                <p><strong>Exekuční úřad, na kterém tento exekutor či exekutoři působí: </strong></p>
                <p>{state.doc.generatorSettings.exekutorskyUrad}</p>
                <h2>5. IDENTIFIKACE PODATELE A DOTČENÝCH OSOB, JSOU-LI OD PODATELE ODLIŠNÉ</h2>
                <p><strong>Jméno a příjmení podatele:</strong></p>
                <p>{state.doc.generatorSettings.podatel}</p>
                <p><strong>Osoba či osoby dotčené výše uvedenými porušeními, pokud jsou odlišné od podatele:</strong>
                </p>
                <p>{state.doc.generatorSettings.dotceni}</p>
                <h2>6. ADRESÁT A DALŠÍ DOTČENÉ INSTITUCE</h2>
                <p><strong>Adresát:</strong></p>
                <p>
                    Vrchní státní zastupitelství v Praze
                    <br/>nám. Hrdinů 1300,
                    <br/>140 00 Praha 4-Nusle
                    <br/>podatelna@vsz.pha.justice.cz
                </p>
                <p>Vzhledem k tomu, že orgány činné v trestním řízení, zejména pak státní zástupci, jež by byli místně
                    příslušní v případném řízení vyvolaným tímto trestním oznámením, mohou být v důsledku výkonu svých
                    funkcí v častém kontaktu s exekutorem či exekutory, jež jsou tímto trestním oznámením dotčeni, v
                    důsledku čehož by mohlo dojít je koluzi mezi uvedenými orgány činnými v trestním řízení a exekutorem
                    či exekutory, jež jsou tímto trestním oznámením dotčeni, je toto trestní oznámení podáváno na Vrchní
                    státní zastupitelství v Praze s žádostí, aby na základě svých znalostí fungování justiční a
                    policejní soustavy přidělilo vyřízení tohoto trestního oznámení takovému státnímu zástupci a
                    policejnímu orgánu, u nichž podle jeho znalostí a zkušeností nehrozí nebezpečí koluze s uvedeným
                    exekutorem či exekutory.</p>
            </Card.Body>
            <Card.Footer className={"text-end"} id={"download-buttons"}>
                <Button variant={"success"} className={"m-2"}
                        onClick={() => DownloadRTF(downloadContents, state.doc.id)}>Stáhnout jako Word</Button>
            </Card.Footer>
        </Card>
    </>;
}