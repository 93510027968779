import {Alert, Breadcrumb, Button, Card, Col, Container, Form, Nav, Navbar, Row} from "react-bootstrap";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import {useNavigate, useParams} from "react-router-dom";
import DocumentSettingsDialog from "../Utilities/DocumentSettingsDialog";
import React, {useEffect, useState} from "react";
import {PssChoice, PssSection, PssSectionPart} from "../Model/PssDatabase";
import HTMLReactParser from "html-react-parser";
import {PssAction} from "../Model/PssEnums";
import StringHashCode from "../Utilities/StringHashCode";
import GetTypeDatabase from "../Database/FindDatabase";
import DisclaimerPopup from "../Utilities/DisclaimerPopup";

function DocumentDetail() {

    const navigate = useNavigate();

    let {documentId: str_doc_id} = useParams();
    let documentId: number = parseInt(str_doc_id as string);

    const ref_doc_name = React.createRef<HTMLElement>();

    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);
    let storedPssDocument = documents.getDocument(documentId);
    console.log('document-detail', storedPssDocument);

    let [statefulPssDocument, setStatefulPssDocument] = useState({d: storedPssDocument});
    if (statefulPssDocument === undefined || statefulPssDocument.d.id !== storedPssDocument.id) {
        console.log('document-detail update stateful document');
        setStatefulPssDocument({d: storedPssDocument});
    }

    if (![PssAction.classifications, PssAction.selections].includes(statefulPssDocument.d.step)) {
        setStatefulPssDocument({d: statefulPssDocument.d.setCurrentStep(PssAction.classifications)});
    }

    const typeDatabase: PssSection[] = GetTypeDatabase(statefulPssDocument.d.type);

    useEffect(() => {
        let subscriberId = documents.subscribeChange(changedDoc => {
            if (statefulPssDocument.d.id === changedDoc.id) {
                console.log('documents subscribeChange propagate', changedDoc);
                setStatefulPssDocument({d: changedDoc});
                if (ref_doc_name.current) {
                    ref_doc_name.current.textContent = statefulPssDocument.d.name;
                }
            } else {
                console.log('ignore update for', statefulPssDocument, statefulPssDocument.d.id, changedDoc.id);
            }
        });

        return () => {
            documents.unsubscribe(subscriberId);
        };
    });

    const handleChoice = function (choice_key: string, choice_status: boolean) {
        if (statefulPssDocument.d.step === PssAction.classifications) {
            statefulPssDocument.d.handleClassification(choice_key, choice_status);
        } else if (statefulPssDocument.d.step === PssAction.selections) {
            statefulPssDocument.d.handleChoice(choice_key, choice_status);
        }
        documents.setDocument(statefulPssDocument.d);
    }

    const renderChoices = function (c: PssChoice[]): JSX.Element {
        return (
            <>
                {c.map(choice => {
                    return (
                        <Row className={"pss-choice border-bottom g-0"} key={StringHashCode(choice.label)}>
                            <Col sm={1} className={"text-center"}>
                                {choice.selectable && choice.id && <Form.Check.Input
                                    type={"checkbox"}
                                    name={choice.id}
                                    defaultChecked={statefulPssDocument.d.step === PssAction.classifications ? statefulPssDocument.d.classifications.includes(choice.id) : statefulPssDocument.d.choiceStatus(choice.id)}
                                    onChange={(e) => {
                                        handleChoice(choice.id, e.target.checked);
                                    }}/>}
                            </Col>
                            <Col>
                                {choice.label}
                                {choice.description && HTMLReactParser(('<br/>' + choice.description as string).replaceAll('\n', '<br/>'))}
                            </Col>
                        </Row>
                    );
                })}
            </>
        );
    }

    const renderPart = function (p: PssSectionPart): JSX.Element {
        const css_class = "pss-part " + (p.positive_design ? "positive" : "negative");
        return (
            <div key={StringHashCode(p.header)}>
                <Card.Header className={css_class}>{p.header}</Card.Header>
                <Card.Body className={css_class}>
                    {renderChoices(p.choices)}
                </Card.Body>
                {p.children.map(pc => renderPart(pc))}
            </div>
        );
    }

    const renderSection = function (t: PssSection): JSX.Element {
        return (
            <Card className={"pss-section mb-3"} key={StringHashCode(t.header)}
                  id={`section-${StringHashCode(t.header)}`}>
                <Card.Header>
                    <h2>{t.header}</h2>
                </Card.Header>
                <Card.Body>
                    <div className={"lead"}>
                        {HTMLReactParser((t.preamble as string).replaceAll('\n', '<br/>'))}
                    </div>
                    <div className={"small"}>
                        <br/>
                        {HTMLReactParser((t.citations as string).replaceAll('\n', '<br/>'))}
                    </div>
                </Card.Body>
                {t.parts.map(p => renderPart(p))}
            </Card>
        );
    };

    const handleContinue = function (): void {
        window.scrollTo(0, 0);
        console.log("handleContinue", statefulPssDocument.d.step, "canContinue", statefulPssDocument.d.canContinue());
        if (statefulPssDocument.d.step === PssAction.classifications && statefulPssDocument.d.canContinue()) {
            statefulPssDocument.d.setCurrentStep(PssAction.selections);
            documents.setDocument(statefulPssDocument.d);
        } else if (statefulPssDocument.d.step === PssAction.selections && statefulPssDocument.d.canContinue()) {
            statefulPssDocument.d.setCurrentStep(PssAction.descriptions);
            documents.setDocument(statefulPssDocument.d);
            navigate(`/documents/${statefulPssDocument.d.id}/descriptions`, {replace: true});
        }
    };

    const handleBack = function (): void {
        window.scrollTo(0, 0);
        if (statefulPssDocument.d.step === PssAction.selections) {
            statefulPssDocument.d.setCurrentStep(PssAction.classifications);
            documents.setDocument(statefulPssDocument.d);
        } else {
            navigate(`/`, {replace: true});
        }
    }

    return (
        <>
            <Row className={"g-0 border-bottom mb-2"}>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#/">Hlavní stránka</Breadcrumb.Item>
                        <Breadcrumb.Item active>Dokumenty</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            <span ref={ref_doc_name}>{statefulPssDocument.d.name}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col className={"text-end"}>
                    <DocumentSettingsDialog documentId={documentId}/>
                </Col>
            </Row>
            <Navbar sticky={"top"} variant={"dark"} bg={"dark"} className={"mb-2 rounded"}>
                <Container fluid className={"m-2"}>
                    <Navbar.Brand className={"me-auto"}>
                        {statefulPssDocument.d.step === PssAction.classifications ? "Krok 2 - Identifikujte typ procesu" : "Krok 3 - Vyberte jedno nebo více porušení"}
                    </Navbar.Brand>
                    <Nav className={""}>
                        <Nav.Link
                            as={Button}
                            variant={"primary"}
                            className={"me-3 text-light"}
                            disabled={statefulPssDocument.d.step !== PssAction.selections}
                            onClick={() => handleBack()}
                        >
                            Zpět
                        </Nav.Link>
                        <Nav.Item className={"ms-auto text-light pt-2"}>
                            {
                                statefulPssDocument.d.step === PssAction.classifications ?
                                    (`Vybráno ${statefulPssDocument.d.classifications.length}`) :
                                    (`Vybráno ${statefulPssDocument.d.choices.size} porušení`)
                            }
                        </Nav.Item>
                        <Nav.Link
                            as={Button}
                            variant={statefulPssDocument.d.canContinue() ? "success" : "secondary"}
                            className={"ms-3 text-light"}
                            disabled={!statefulPssDocument.d.canContinue()}
                            onClick={() => handleContinue()}
                        >
                            Pokračovat
                        </Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            {statefulPssDocument.d.step === PssAction.selections ? (
                <>
                    <Alert variant={"info"}>
                        <strong>Vysvětlení:</strong>
                        <ul>
                            <li>
                                Očíslované věty či části vět představují právní pravidla týkající se práv na spravedlivý
                                proces vyjádřená v rozsudcích Evropského soudu pro lidská práva.
                            </li>
                            <li>
                                Odkazy ve formátu „jméno v. (proti) název státu, § číslo či §§ čísla“, například
                                „Cipolletta v. Italy §§ 33-37“ představují odkazy na rozsudky (judikáty) Evropského
                                soudu pro lidská práva ve Štrasburku, ve kterých byla jednotlivá právní pravidla
                                formulována. Plný obsah těchto rozsudků lze dohledat zadáním jejich názvu v databázi
                                rozsudků Evropského soudu pro lidská práva ve Štrasburku HUDOC na internetové adrese:
                                https://hudoc.echr.coe.int/
                            </li>
                            <li>
                                „EÚLP“ je zkratkou Evropské úmluvy o lidských právech.
                            </li>
                            <li>
                                „ESLP“ je zkratkou Evropského soudu pro lidská práva.
                            </li>
                            <li>
                                Zkratky v názvech rozsudků představují zkratky typů rozsudků Evropského soudu pro lidská
                                práva, např. zkratka „GC“ odkazuje na anglickou zkratku spojení „Grand Chamber“, tj. v
                                češtině „Velký senát“ Evropského soudu pro lidská práva.
                            </li>
                        </ul>
                    </Alert>
                    <div className={"bg-light border border-1 border-dark p-1 mb-2"}>
                        <h3>Obsah</h3>
                        <ul>
                            {typeDatabase.filter((t) => !t.is_classification).map((t) => {
                                return (
                                    <li key={StringHashCode(t.header)}>
                                        <a href={window.location.href} onClick={(e) => {
                                            document.querySelector(`#section-${StringHashCode(t.header)}`)?.scrollIntoView();
                                            e.preventDefault();
                                            return false;
                                        }}>{t.header}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </>
            ) : (``)}
            {typeDatabase.filter((t) => {
                return t.is_classification === (statefulPssDocument.d.step === PssAction.classifications);
            }).map((t) => {
                return renderSection(t);
            })}
            {statefulPssDocument.d.step === PssAction.selections ? <DisclaimerPopup/> : <></>}
            {
                statefulPssDocument.d.step === PssAction.classifications
                && !statefulPssDocument.d.hasClassifications()
                ? handleContinue()
                    : <></>
            }
        </>
    )
}

export default DocumentDetail;