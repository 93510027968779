import {useEffect, useRef, useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import {useParams} from "react-router-dom";
import GenerateDocumentSelections from "./Utils/GenerateDocumentSelections";
import DownloadRTF from "./Utils/DownloadRTF";

export default function ExekutorKarnyPodnet() {

    let {documentId: str_doc_id} = useParams();
    let documentId: number = parseInt(str_doc_id as string);

    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);
    const storedDocument = documents.getDocument(documentId);
    const [state, setState] = useState({doc: storedDocument});

    useEffect(() => {
        let subscriberId = documents.subscribeChange(changedDoc => {
            if (state.doc.id === changedDoc.id) {
                setState({doc: changedDoc});
            }
        });

        return () => {
            documents.unsubscribe(subscriberId);
        };
    });

    const downloadContents = useRef(null);

    return <>
        <Card className={"mt-3"} id={"form-identifications"}>
            <Card.Header>
                <Card.Title>
                    Vyplňte prosím následující identifikace pro vybraný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Exekutor či exekutoři, jenž se podle podatele výše uvedených porušení práv na
                            spravedlivý proces dopustil(i):</Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.exekutori}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.exekutori = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Exekuční úřad, na kterém tento exekutor či exekutoři působí: </Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.exekutorskyUrad}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.exekutorskyUrad = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení podatele:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.podatel}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.podatel = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení dotčené osoby, pokud se neshodují s podatelem:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.dotceni}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.dotceni = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
        <Card className={"mt-3"} id={"document-preview"}>
            <Card.Header>
                <Card.Title>
                    Náhled na generovaný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body ref={downloadContents}>
                <h1>Podnět ministru spravedlnosti na podání návrhu na zahájení kárného řízení proti exekutorovi či
                    exekutorům</h1>
                <p>Já níže uvedený podávám tímto podnět ministru spravedlnosti na podání návrhu na zahájení kárného
                    řízení proti exekutorovi či exekutorům, a to z níže uvedených důvodů.</p>
                <h2>1. OBSAH PODNĚTU</h2>
                <p>Žádám ministra spravedlnosti, aby níže uvedená mnou namítaná porušení práv spravedlivého procesu níže
                    uvedenému exekutorovi či exekutorům posoudil a na základě tohoto posouzení návrhu na zahájení
                    kárného řízení proti tomuto exekutorovi či exekutorům. </p>
                <p>Dále žádám ministra spravedlnosti, aby mě vyrozuměl o tom, jakým způsobem tento můj podnět
                    posoudil. </p>
                <p>Nedojde-li ze strany ministra spravedlnosti k podání návrhu na zahájení kárného řízení proti níže
                    uvedenému exekutorovi či exekutorům, žádám, aby se ve vyrozumění, jenž mi zašle, argumentačně
                    vypořádal se všemi níže uvedenými porušeními práva na spravedlivý proces, jichž se exekutor dopustil
                    či dopustili. V této souvislosti podotýkám, že podle dále uvedené judikatury Ústavního soudu (např.
                    I. ÚS 403/03, N 160/38 SbNU 277), argumentuje-li účastník názory judikatury, musí se veřejný orgán s
                    těmito názory argumentačně vypořádat (případně i tak, že vysvětlí, proč je nepovažuje pro danou věc
                    relevantní). </p>
                <p>Rovněž žádám, aby zároveň toto vyrozumění kromě mé osoby bylo zasláno rovněž na vědomí níže uvedeným
                    útvarům Evropské komise, Evropského parlamentu a Rady Evropy, jež jsou odpovědné za dohled nad
                    dodržováním práva na spravedlivý proces podle Evropské úmluvy na ochranu lidských práv a svobod v
                    členských státech Rady Evropy a Evropské unie.</p>
                <p>Konečně žádám, aby mi bylo zmíněné vyrozumění zasláno v zákonné lhůtě, tedy nejpozději do 30 dnů od
                    přijetí tohoto mého podnětu, nebo v případě, že se uvedené posouzení bude jevit jako složité, abych
                    byl v této zákonné lhůtě informován o prodloužení lhůty k posouzení na nejvýše 60 dnů.</p>
                <h2>2. PRÁVNÍ ZÁKLAD PODNĚTU</h2>
                <p>Podle čl. 2 odst. 3 Ústavy ČR a čl. 2 odst. 3 Listiny základních práv a svobod „státní moc slouží
                    všem občanům a lze ji uplatňovat jen v případech, v mezích a způsoby, které stanoví zákon.“</p>
                <p>Podle čl. 10 Ústavy ČR „vyhlášené mezinárodní smlouvy, k jejichž ratifikaci dal Parlament souhlas a
                    jimiž je Česká republika vázána, jsou součástí právního řádu; stanoví-li mezinárodní smlouva něco
                    jiného než zákon, použije se mezinárodní smlouva.“</p>
                <p>Podle čl. 6 odst. 1 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou
                    a vyhlášené ve sdělení č. 209/1992 Sb., má „každý právo na to, aby jeho záležitost byla spravedlivě,
                    veřejně a v přiměřené lhůtě projednána nezávislým a nestranným soudem, zřízeným zákonem, který
                    rozhodne o jeho občanských právech nebo závazcích nebo o oprávněnosti jakéhokoli trestního obvinění
                    proti němu.“ Obdobně podle čl. 36 odst. 1 se každý může domáhat stanoveným postupem svého práva u
                    nezávislého a nestranného soudu a ve stanovených případech u jiného orgánu. Podle čl. 81 Ústavy ČR
                    soudní moc vykonávají jménem republiky nezávislé soudy.</p>
                <p>Podle konstantní judikatury Ústavního soudu (např. N 160/38 SbNU 277) se nezávislost rozhodování
                    obecných soudů uskutečňuje v ústavním a zákonném procesněprávním a hmotněprávním rámci, jenž je
                    představován především principy řádného a spravedlivého procesu, jak vyplývají z čl. 36 a násl.
                    Listiny, jakož i z čl. 1 Ústavy České republiky a čl. 6 odst. 1 Evropské úmluvy o ochraně lidských
                    práv a svobod. Soudci i exekutoři proto musí principy řádného a spravedlivého procesu, jak jsou
                    vymezeny v uvedených ustanoveních a na nich navazující judikatuře, při své činnosti dodržovat, aby
                    se vyloučila libovůle při jejich rozhodování.</p>
                <p>Podle konstantní judikatury Evropského soudu pro lidská práva (ESLP) mají vnitrostátní orgány, včetně
                    soudů, v každém jednotlivém případě povinnost zajistit účastníkům sporu spravedlivý proces (např.
                    ESLP, Dombo Beheer B.V. v. Nizozemí); tento závěr je sdílen i českými soudci: „Někdy u soudu nejsme
                    schopni nastolit skutečnou spravedlnost, ale vždycky Vám musíme dát férové zacházení (Kateřina
                    Šimáčková, bývalá soudkyně Ústavního soudu ČR, https://www.youtube.com/watch?v=QDSSW2PokIM)</p>
                <p>Podle § 28 zákona č. 120/2001 Sb. o soudních exekutorech a exekuční činnosti (exekuční řád), v
                    platném znění (věta druhá) se úkony exekutora se považují za úkony exekučního soudu. Podle § 55b
                    odst. 1 exekučního řádu, exekutor při svém rozhodování činí úkony, které v řízení o výkonu
                    rozhodnutí přísluší soudu prvního stupně. Podle § 2 odst. 1 exekučního řádu exekutor vykonává
                    exekuční činnost nezávisle, přičemž při výkonu exekuční činnosti je vázaný jen Ústavou České
                    republiky, zákony, jinými právními předpisy a rozhodnutími soudu vydanými v řízení o výkonu
                    rozhodnutí a exekučním řízení. Podle § 2 odst. 2 je exekutor je povinen vykonávat svědomitě své
                    povolání a při jeho výkonu a v občanském životě se zdržet všeho, co by mohlo narušit důstojnost
                    exekutorského povolání nebo ohrozit důvěru v nezávislý, nestranný a spravedlivý výkon exekuční
                    činnosti.</p>
                <p>Podle čl. 3 odst. 1 Usnesení sněmu Exekutorské komory České republiky ze dne 28. února 2006, kterým
                    byl přijat stavovský předpis, kterým se stanoví Pravidla profesionální etiky a pravidla soutěže
                    soudních exekutorů je exekutor je všeobecně povinen přispívat poctivým, čestným a slušným chováním k
                    důstojnosti a vážnosti exekutorského stavu. Podle odst. 3 téhož článku jsou projevy exekutora v
                    souvislosti s výkonem exekuční činnosti a další činnosti soudního exekutora věcné, střízlivé a
                    nikoliv vědomě nepravdivé. Podle čl. 5 odst. 1 téhož stavovského předpisu ve věcech, v nichž byl
                    soudem pověřen provedením exekuce nebo při výkonu další činnosti postupuje exekutor vždy nezávisle,
                    svědomitě a pečlivě.</p>
                <p>Podle čl. 12 odst. 3 zmíněného stavovského předpisu „Pravidla profesionální etiky a pravidla soutěže
                    soudních exekutorů“ je exekutor povinen v exekučním řízení jednat poctivě, respektovat zákonná práva
                    účastníků tohoto řízení a chovat se k nim i k ostatním osobám zúčastněným na řízení tak, aby nebyla
                    snižována jejich důstojnost ani důstojnost exekutorského stavu. Dále podle čl. 13 (Veřejně prospěšná
                    činnost) je exekutor, byl-li k tomu vyzván Exekutorskou komorou, je povinen podílet se v přiměřeném
                    rozsahu na projektech směřujících k prosazování nebo obhajobě lidských práv a svobod, a to i bez
                    nároku na odměnu, ledaže mu v tom brání vážné důvody, za stejných podmínek je exekutor povinen
                    podílet se na výzvu Exekutorské komory na projektech, jejichž cílem je uskutečňování principů
                    demokratického právního státu nebo zdokonalení právního řádu České republiky.</p>
                <p>Čl. 6 odst. 1 Evropské úmluvy na ochranu lidských práv a svobod se vztahuje na všechny fáze soudního
                    řízení, jimiž se „rozhod[uj]e… o… občanských právech nebo závazcích“, včetně fází následujících po
                    vydání rozsudku ve věci samé. Výkon rozsudku kteréhokoli soudu tedy musí být považován za nedílnou
                    součást „řízení“ ve smyslu článku 6 (Hornsby proti Řecku, § 40; Romańczyk proti Francii, § 53),
                    přičemž použitelnost článku 6 na prvotní řízení není rozhodující, neboť exekuční titul, kterým bylo
                    rozhodnuto o občanských právech, nemusí nutně vzejít z řízení, na které je použitelný článek 6 (Buj
                    proti Chorvatsku, § 19).</p>
                <p>Podle konstantní judikatury Ústavního soudu České republiky je notorietou, tj. skutečností, jíž si
                    musí být vědom každý orgán nadaný veřejnou moci a aplikující právo, včetně soudů, že „mechanická
                    aplikace [práva] abstrahující, resp. neuvědomující si, a to buď úmyslně, nebo v důsledku
                    nevzdělanosti, smysl a účel právní normy, činí z práva nástroj odcizení a absurdity." (I.ÚS 2920/09
                    ze dne 8. 2. 2011, N 14/60 SbNU 141, Pl. ÚS 33/97 (N 163/9 SbNU 399; 30/1998 Sb.)), přičemž takový
                    orgán, včetně soudů i exekutorů, „není absolutně vázán doslovným zněním zákonného ustanovení, nýbrž
                    se od něj smí a musí odchýlit v případě, kdy to vyžaduje ze závažných důvodů […] některý z principů,
                    jež mají svůj základ v ústavně konformním právním řádu jako významovém celku" (I.ÚS 2920/09, N 14/60
                    SbNU 141).</p>
                <p>Podle čl. 13 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou a
                    vyhlášené ve sdělení č. 209/1992 Sb., „každý, jehož práva a svobody přiznané touto Úmluvou byly
                    porušeny, musí mít účinné právní prostředky nápravy před národním orgánem, i když se porušení
                    dopustily osoby při plnění úředních povinností.“</p>
                <p>Podle § 7 odst. 1 státní dohled nad exekuční činností i nad činností exekutora vykonává Ministerstvo
                    spravedlnosti, přičemž toto ministerstvo provádí státní dohled i na základě písemných podnětů
                    právnických nebo fyzických osob. Podle odst. 2 téhož článku uvedené ministerstvo při výkonu státního
                    dohledu prověřuje zákonnost postupu soudního exekutora, jež zahrnuje i dodržování práv spravedlivého
                    procesu čl. 6 odst. 1 Evropské úmluvy pro ochranu lidských práv a svobod, jejíž ustanovení mají
                    přednost před zákonem, dodržování kancelářského řádu a plynulost a délku exekučního řízení.</p>
                <p>Podle § 116 odst. 1 exekučního řádu je exekutor, kandidát a koncipient jsou kárně odpovědni za kárný
                    delikt nebo závažný kárný delikt (dále jen „kárné provinění“). Podle odst. 2 ustanovení téhož
                    paragrafu je kárným deliktem porušení povinností exekutora, kandidáta nebo koncipienta stanovených
                    právním nebo stavovským předpisem anebo usnesením Komory a/nebo jednání, jímž exekutor narušuje
                    důstojnost exekutorského povolání nebo ohrožuje důvěru v nezávislý, nestranný, odborný a spravedlivý
                    výkon exekuční činnosti, případně odborný výkon další činnosti, přičemž podle odst. 4 ustanoví téhož
                    paragrafu se za kárný delikt exekutora se považuje, i když se uvedeného porušení či porušení
                    dopustil zaměstnanec exekutora; pokud je porušení uvedené povinnosti či povinností uvedenou osobou
                    či osobami závažné považuje se podle odst. 3 a 5 ustanovení téhož paragrafu za závažný kárný
                    delikt.</p>
                <p>Podle § 117 odst. 1 exekučního řádu se kárné řízení se zahajuje na návrh, který se nazývá kárnou
                    žalobou. Podle odst. 2 ustanovení téhož paragrafu je kárnou žalobu je oprávněn podat ministr
                    spravedlnosti proti kterémukoli exekutorovi, kandidátovi nebo koncipientovi.</p>
                <h2>3. PORUŠENÍ ODŮVODŇUJÍCÍ PODÁNÍ NÁVRHU NA ZAHÁJENÍ KÁRNÉHO ŘÍZENÍ</h2>
                <p><strong>Níže uvedený exekutor či exekutoři se podle mého názoru dopustili u podatele či u níže
                    uvedené osoby či osob odlišných od podatele následujících porušení práv spravedlivý proces podle čl.
                    6 odst. 1 Evropské úmluvy na ochranu lidských práv a svobod, tak jak jsou vymezeny v příslušné
                    judikatuře Evropského soudu pro lidská práva vykládající toto ustanovení, která je u příslušného
                    porušení vždy zmíněna. Těmito porušeními se níže uvedený exekutor či exekutoři dopustili porušení
                    svých povinností podle §§ 2 odst. 1 a 2 exekučního řádu, jakož i možného porušení čl. 5 odst. 1, v
                    případném spojení a čl. 3 odst. 1 a 3 a/nebo čl. 12 odst. 3 Usnesení sněmu Exekutorské komory České
                    republiky ze dne 28. února 2006, kterým byl přijat stavovský předpis, kterým se stanoví Pravidla
                    profesionální etiky a pravidla soutěže soudních exekutorů, v platném znění, což je podle mého názoru
                    důvodem pro podání na zahájení kárného řízení proti níže uvedenému exekutorovi či exekutorům v
                    důsledku podezření, že se uvedený exekutor či exekutoři dopustili jednoho či více kárných provinění
                    podle § 116 odst. 2 a/nebo 3 a/nebo 4 a/nebo 5 exekučního řádu.</strong></p>
                <p><strong>Dále uvedené právní povinnosti týkající se práv spravedlivého procesu vztahující se na soudy
                    a soudce podle dále uvedené judikatury Evropského soudu pro lidská práva, jež podle mého názoru byly
                    porušeny, se použijí vedle soudů a soudců obdobně (mutatis mutandis) i na exekutory, jež vystupují v
                    řízení, na nějž se § 6 odst. 1 EÚLP vztahuje, v obdobném postavení jako soud či soudce.</strong></p>

                {GenerateDocumentSelections(state.doc)}
                <p><strong>Vysvětlení: Očíslované věty či části vět představují
                    právní pravidla týkající se práv na
                    spravedlivý proces vyjádřená v rozsudcích Evropského soudu pro lidská práva. Odkazy ve formátu
                    „jméno v.
                    (proti) název státu, § číslo či §§ čísla“, například „Cipolletta v. Italy §§ 33-37“ představují
                    odkazy
                    na rozsudky (judikáty) Evropského soudu pro lidská práva ve Štrasburku, ve kterých byla jednotlivá
                    právní pravidla formulována. Plný obsah těchto rozsudků lze dohledat zadáním jejich názvu v databázi
                    rozsudků Evropského soudu pro lidská práva ve Štrasburku HUDOC na internetové adrese:
                    https://hudoc.echr.coe.int/</strong></p>

                <h2>4. OSOBA ČI OSOBY, JEŽ SE UVEDENÝCH PORUŠENÍ DOPUSTILI</h2>
                <p><strong>Exekutor či exekutoři, jenž se podle podatele výše uvedených porušení práv na spravedlivý
                    proces dopustil(i):</strong></p>
                <p>{state.doc.generatorSettings.exekutori}</p>
                <p><strong>Exekuční úřad, na kterém tento exekutor či exekutoři působí: </strong></p>
                <p>{state.doc.generatorSettings.exekutorskyUrad}</p>
                <h2>5. IDENTIFIKACE PODATELE A DOTČENÝCH OSOB, JSOU-LI OD PODATELE ODLIŠNÉ</h2>
                <p><strong>Jméno a příjmení podatele:</strong></p>
                <p>{state.doc.generatorSettings.podatel}</p>
                <p><strong>Osoba či osoby dotčené výše uvedenými porušeními, pokud jsou odlišné od podatele:</strong>
                </p>
                <p>{state.doc.generatorSettings.dotceni}</p>
                <h2>6. ADRESÁT PODNĚTU A DALŠÍ INSTITUCE DOTČENÉ TÍMTO PODNĚTEM</h2>
                <p><strong>Adresát:</strong></p>
                <p>
                    Ministr spravedlnosti
                    <br/>Ministerstvo spravedlnosti ČR
                    <br/>Vyšehradská 16
                    <br/>128 10 Praha 2
                    <br/>posta@msp.justice.cz
                </p>
                <p><strong>Na vědomí</strong></p>
                <p>Evropské komisi, GŘ Spravedlnost a spotřebitelé, Ředitelství D - Základní práva a právní stát, Odbor
                    C2: Politiky v oblasti základních práv: JUST-C-DIR@ec.europa.eu, JUST-C-SEC@ec.europa.eu,,
                    JUST-C2@ec.europa.eu</p>
                <p>Výboru pro občanské svobody, spravedlnost a vnitřní věci Evropského parlamentu,
                    libe-secretariat@ep.europa.eu</p>
                <p>Řídicímu výboru pro lidská práva Výboru ministrů Rady Evropy: DGI-CDDH@coe.int,
                    DGI-CDDH-Reform@coe.int </p>
                <p>Podatel dává tento podnět na vědomí Evropské komisi a Evropskému parlamentu, neboť v podnětu popsané
                    porušení podle jeho názoru představuje rovněž porušení Českou republikou obecných zásad práva
                    Evropské unie tvořených podle ustanovení čl. 6 odst. 3 Smlouvy o Evropské unii základními právy,
                    která jsou zaručena Evropskou úmluvou o ochraně lidských práv a základních svobod: konkrétně pak v
                    tomto ohledu porušení základního práva na právní jistotu ohledně řádného výkonu spravedlnosti, jež
                    je jedním ze základních práv zaručených Evropskou úmluvou o ochraně lidských práv a základních
                    svobod v každé demokratické společnosti (viz např. ESLP, Beian proti Rumunsku (č. 1), § 39), ESLP,
                    Pretto a ostatní proti Itálii, § 21) a rovněž základní hodnotou Evropské unie zmíněnou v čl. 2
                    Smlouvy o Evropské unii; stejně tak představuje toto porušení porušení základních práv, která
                    vyplývají z ústavních tradic společných členským státům EU, včetně ČR: konkrétně pak v tomto ohledu
                    porušení základního práva vyplývajícího z ústavních ČR a ostatních členských států EU, na výslovné a
                    konkrétní zdůvodnění argumentu týkajícího se porušení konkrétního aspektu práva na spravedlivý
                    proces (viz např. Ústavní soud ČR, N 160/38 SbNU 277).</p>
                <p>Podatel dává tento podnět rovněž na vědomí Řídicímu výboru pro lidská práva Výboru ministrů Rady
                    Evropy, aby tento výbor upozornil na nedodržování Evropské úmluvy na ochranu lidských práv a svobod
                    ze strany České republiky jako státu, jenž se přesto k dodržování této Úmluvy zavázal, a umožnil
                    tímto případné spuštění příslušných dozorových či sankčních mechanismů Výboru ministrů Rady Evropy
                    či jiných orgánů Rady Evropy vůči České republice v důsledku popsaného nedodržování uvedené Úmluvy
                    Českou republikou.</p>
                <p>V případě, že ministr spravedlnosti nevyrozumí podatele o tom, jak naložil s touto jeho žádostí,
                    a/nebo toto vyrozumění nedá na vědomí Řídicímu výboru pro lidská práva Výboru ministrů Rady Evropy
                    ve výše uvedené lhůtě 30 dnů či ve složitějších případech 60 dnů, podatel zároveň žádá Řídicí výbor,
                    aby po uplynutí uvedené lhůty vyzval generálního tajemníka Rady Evropy, aby si podle čl. 52 Evropské
                    úmluvy pro lidská práva vyžádal u příslušných orgánů České republiky vysvětlení o způsobu, jakým
                    české právo zajišťuje účinné provádění ustanovení čl. 6 § 1 Úmluvy vzhledem k výše popsaným
                    porušením tohoto ustanovení, jichž se uvedený exekutor či exekutoři dopustil či dopustili; zejména
                    pak žádá Řídicí výbor, aby tlumočil generálnímu tajemníkovi Rady Evropy požadavek, aby si generální
                    tajemník Rady Evropy vyžádal vysvětlení, jaká opatření byla proti výše uvedenému exekutorovi či
                    exekutorům přijata, aby se taková porušení v budoucnu již v České republice neopakovala a bylo
                    zajištěno účinné provádění ustanovení čl. 6 § 1 Úmluvy ve vztahu nejen k dotčeným exekutorům, ale i
                    všem exekutorům působícím v České republice.</p>
            </Card.Body>
            <Card.Footer className={"text-end"} id={"download-buttons"}>
                <Button variant={"success"} className={"m-2"}
                        onClick={() => DownloadRTF(downloadContents, state.doc.id)}>Stáhnout jako Word</Button>
            </Card.Footer>
        </Card>
    </>;
}