import {useEffect, useRef, useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {PssStoreState, useAppSelector} from "../Model/PssStore";
import {useParams} from "react-router-dom";
import GenerateDocumentSelections from "./Utils/GenerateDocumentSelections";
import DownloadRTF from "./Utils/DownloadRTF";

export default function PolicieStiznostGIBS() {

    let {documentId: str_doc_id} = useParams();
    let documentId: number = parseInt(str_doc_id as string);

    const documents = useAppSelector((state: PssStoreState) => state.documents.documents);
    const storedDocument = documents.getDocument(documentId);
    const [state, setState] = useState({doc: storedDocument});

    useEffect(() => {
        let subscriberId = documents.subscribeChange(changedDoc => {
            if (state.doc.id === changedDoc.id) {
                setState({doc: changedDoc});
            }
        });

        return () => {
            documents.unsubscribe(subscriberId);
        };
    });

    const downloadContents = useRef(null);

    return <>
        <Card className={"mt-3"} id={"form-identifications"}>
            <Card.Header>
                <Card.Title>
                    Vyplňte prosím následující identifikace pro vybraný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Příslušník či příslušníci Policie ČR, jenž se podle podatele výše uvedených porušení práv na spravedlivý proces dopustil(i):</Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.policiste}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.policiste = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Útvar Policie ČR, na kterém tento příslušník či příslušníci působí: </Form.Label>
                        <Form.Control as={"textarea"} rows={3}
                                      value={state.doc.generatorSettings.policejniUtvar}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.policejniUtvar = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení podatele:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.podatel}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.podatel = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Jméno a příjmení dotčené osoby, pokud se neshodují s podatelem:</Form.Label>
                        <Form.Control as={"textarea"} rows={2}
                                      value={state.doc.generatorSettings.dotceni}
                                      onChange={(e) => {
                                          state.doc.generatorSettings.dotceni = e.target.value
                                          documents.setDocument(state.doc);
                                      }}
                        />
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
        <Card className={"mt-3"} id={"document-preview"}>
            <Card.Header>
                <Card.Title>
                    Náhled na generovaný dokument
                </Card.Title>
            </Card.Header>
            <Card.Body ref={downloadContents}>
                <h1>Stížnost Generální inspekci bezpečnostních sborů na příslušníka či příslušníky Policie ČR</h1>
                <p>Já níže uvedený podávám tímto stížnost Generální inspekci bezpečnostních sborů na příslušníka či
                    příslušníky Policie ČR, a to z níže uvedených důvodů.</p>
                <h2>1. OBSAH </h2>
                <p>Žádám, aby níže uvedená mnou namítaná porušení zásad spravedlivého procesu níže uvedeným příslušníkem
                    či příslušníky Policie ČR byla posouzena ve světle podezření ze spáchání níže uvedených trestných
                    činů či případně jiných trestných činů. Zároveň žádám, aby se Generální inspekce bezpečnostních
                    sborů argumentačně vypořádala se všemi níže uvedenými porušeními práva na spravedlivý proces, k nimž
                    došlo, jež by mohla založit důvodné podezření z jednoho či více uvedených trestných činů. V této
                    souvislosti podotýkám, že podle dále uvedené judikatury Ústavního soudu (např. I. ÚS 403/03, N
                    160/38 SbNU 277), argumentuje-li podatel názory judikatury, musí se veřejný orgán s těmito názory
                    argumentačně vypořádat (případně i tak, že vysvětlí, proč je nepovažuje pro danou věc
                    relevantní). </p>
                <h2>2. PRÁVNÍ ZÁKLAD</h2>
                <p>Podle čl. 2 odst. 3 Ústavy ČR a čl. 2 odst. 3 Listiny základních práv a svobod „státní moc slouží
                    všem občanům a lze ji uplatňovat jen v případech, v mezích a způsoby, které stanoví zákon.“</p>
                <p>Podle čl. 10 Ústavy ČR „vyhlášené mezinárodní smlouvy, k jejichž ratifikaci dal Parlament souhlas a
                    jimiž je Česká republika vázána, jsou součástí právního řádu; stanoví-li mezinárodní smlouva něco
                    jiného než zákon, použije se mezinárodní smlouva.“</p>
                <p>Podle čl. 6 odst. 1 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou
                    a vyhlášené ve sdělení č. 209/1992 Sb., má „každý právo na to, aby jeho záležitost byla spravedlivě,
                    veřejně a v přiměřené lhůtě projednána nezávislým a nestranným soudem, zřízeným zákonem, který
                    rozhodne o jeho občanských právech nebo závazcích nebo o oprávněnosti jakéhokoli trestního obvinění
                    proti němu.“ </p>
                <p>Podle konstantní judikatury Evropského soudu pro lidská práva (ESLP) mají vnitrostátní orgány, včetně
                    orgánů činných v trestním řízení zahrnujících příslušníky Policie ČR, v každém jednotlivém případě
                    povinnost zajistit účastníkům sporu spravedlivý proces (např. ESLP, Dombo Beheer B.V. v. Nizozemí).
                    Příslušníci Policie ČR proto musí principy řádného a spravedlivého procesu, jak jsou vymezeny v
                    uvedených ustanoveních a na nich navazující judikatuře, při své činnosti dodržovat, aby se vyloučila
                    libovůle při jejich rozhodování.</p>
                <p>Podle § 2 odst. 4 zákona č. 141/1961 Sb., o trestním řízení soudním (trestní řád) projednávají orgány
                    činné v trestním řízení, jež zahrnují státní zástupce i příslušníky policie ČR s plným šetřením práv
                    a svobod zaručených Listinou základních práv a svobod a mezinárodními smlouvami o lidských právech a
                    základních svobodách, jimiž je Česká republika vázána, přičemž při provádění úkonů trestního řízení
                    lze do těchto práv osob, jichž se takové úkony dotýkají, zasahovat jen v odůvodněných případech na
                    základě zákona a v nezbytné míře pro zajištění účelu trestního řízení.</p>
                <p>Čl. 6 odst. 1 Evropské úmluvy na ochranu lidských práv a svobod se vztahuje na všechny fáze soudního
                    řízení, jimiž se „rozhoduje… o oprávněnosti jakéhokoli trestního obvinění proti jednotlivci“. Podle
                    čl. 6 odst. 2 téže Úmluvy každý, kdo je obviněn z trestného činu, se považuje za nevinného, dokud
                    jeho vina nebyla prokázána zákonným způsobem. Podle čl. 6 odst. 3 téže Úmluvy každý, kdo je obviněn
                    z trestného činu, má tato minimální práva:</p>
                <p>a) být neprodleně a v jazyce, jemuž rozumí, podrobně seznámen s povahou a důvodem obvinění proti
                    němu;</p>
                <p>b) mít přiměřený čas a možnosti k přípravě své obhajoby;</p>
                <p>c) obhajovat se osobně nebo za pomoci obhájce podle vlastního výběru nebo, pokud nemá prostředky na
                    zaplacení obhájce, aby mu byl poskytnut bezplatně, jestliže to zájmy spravedlnosti vyžadují;</p>
                <p>d) vyslýchat nebo dát vyslýchat svědky proti sobě a dosáhnout předvolání a výslech svědků ve svůj
                    prospěch za stejných podmínek, jako svědků proti sobě;</p>
                <p>e) mít bezplatnou pomoc tlumočníka, jestliže nerozumí jazyku používanému před soudem nebo tímto
                    jazykem nemluví.</p>
                <p>Podle konstantní judikatury Ústavního soudu České republiky je notorietou, tj. skutečností, jíž si
                    musí být vědom každý orgán nadaný veřejnou moci a aplikující právo, včetně příslušníků Policie ČR,
                    že „mechanická aplikace [práva] abstrahující, resp. neuvědomující si, a to buď úmyslně, nebo v
                    důsledku nevzdělanosti, smysl a účel právní normy, činí z práva nástroj odcizení a absurdity." (I.ÚS
                    2920/09 ze dne 8. 2. 2011, N 14/60 SbNU 141, Pl. ÚS 33/97 (N 163/9 SbNU 399; 30/1998 Sb.)), přičemž
                    takový orgán, včetně příslušníků Policie ČR, „není absolutně vázán doslovným zněním zákonného
                    ustanovení, nýbrž se od něj smí a musí odchýlit v případě, kdy to vyžaduje ze závažných důvodů […]
                    některý z principů, jež mají svůj základ v ústavně konformním právním řádu jako významovém celku"
                    (I.ÚS 2920/09, N 14/60 SbNU 141).</p>
                <p>Podle čl. 13 Evropské úmluvy o ochraně lidských práv a svobod, ratifikované Českou republikou a
                    vyhlášené ve sdělení č. 209/1992 Sb., „každý, jehož práva a svobody přiznané touto Úmluvou byly
                    porušeny, musí mít účinné právní prostředky nápravy před národním orgánem, i když se porušení
                    dopustily osoby při plnění úředních povinností.“</p>
                <p>Podle § 2 odst. 1 zákona č. 273/2008 Sb. o Policii České republiky, v platném znění, policie slouží
                    veřejnosti a jejím úkolem je chránit bezpečnost osob a majetku a veřejný pořádek, předcházet trestné
                    činnosti, plnit úkoly podle trestního řádu a další úkoly na úseku vnitřního pořádku a bezpečnosti
                    svěřené jí zákony, přímo použitelnými předpisy Evropské unie nebo mezinárodními smlouvami, které
                    jsou součástí právního řádu, včetně výše uvedené v Evropské úmluvě o ochraně lidských práv a
                    svobod.</p>
                <p>Podle § 97 odst. 1 zákona č. 273/2008 Sb. o Policii České republiky, v platném znění, každý může
                    upozornit na nedostatky v činnosti policejního útvaru, policisty nebo zaměstnance policie, nebo na
                    skutečnost, že se policista nebo zaměstnanec policie dopustil jednání, které naplňuje znaky
                    trestného činu, přestupku nebo kázeňského přestupku. Podle odstavce 2 téhož zákona policista nebo
                    útvar policie musí upozornění přijmout, přičemž policie do 30 dnů ode dne přijetí upozornění
                    vyrozumí toho, kdo jej učinil, o přijatých opatřeních, pokud o toto vyrozumění osoba požádá, čímž
                    není dotčeno oznámení protiprávního jednání podle trestního řádu.</p>
                <p>Podle § 56 odst. 1 zákona č. 341/2011 Sb., o Generální inspekci bezpečnostních sborů, každý může
                    upozornit inspekci na nedostatky v činnosti bezpečnostního sboru, na nějž se vztahuje působnost
                    inspekce, nebo na skutečnost, že se příslušník nebo zaměstnanec dopustil jednání, které naplňuje
                    znaky trestného činu, přestupku nebo kázeňského přestupku. Podle odst. 2 téhož zákona inspekce musí
                    upozornění přijmout, přičemž inspekce do 30 dnů ode dne přijetí upozornění vyrozumí toho, kdo jej
                    učinil, o přijatých opatřeních, pokud o toto vyrozumění osoba požádá, čímž není dotčeno oznámení
                    protiprávního jednání podle trestního řádu.</p>
                <h2>3. PORUŠENÍ PRÁV SPRAVEDLIVÉHO PROCESU</h2>
                <p><strong>Níže uvedený příslušník či příslušníci Policie ČR se podle mého názoru dopustili
                    následujících porušení mých práv spravedlivý proces podle čl. 6 odst. 1-3 Evropské úmluvy pro lidská
                    práva, tak jak jsou vymezeny v příslušné judikatuře Evropského soudu pro lidská práva vykládající
                    toto ustanovení, která je u příslušného porušení vždy zmíněna. Těmito porušeními se níže uvedený
                    příslušník či příslušníci Policie ČR dopustili porušení svých povinností podle § 2 odst. 4 věta
                    třetí trestní řádu a dále porušení § 2 odst. 1 zákona o Policii České republiky, v platném
                    znění.</strong></p>


                {GenerateDocumentSelections(state.doc)}
                <p><strong>Vysvětlení: Očíslované věty či části vět představují právní pravidla týkající se práv na
                    spravedlivý proces vyjádřená v rozsudcích Evropského soudu pro lidská práva. Odkazy ve formátu
                    „jméno v.
                    (proti) název státu, § číslo či §§ čísla“, například „Cipolletta v. Italy §§ 33-37“ představují
                    odkazy
                    na rozsudky (judikáty) Evropského soudu pro lidská práva ve Štrasburku, ve kterých byla jednotlivá
                    právní pravidla formulována. Plný obsah těchto rozsudků lze dohledat zadáním jejich názvu v databázi
                    rozsudků Evropského soudu pro lidská práva ve Štrasburku HUDOC na internetové adrese:
                    https://hudoc.echr.coe.int/</strong></p>

                <h2>4. OSOBA ČI OSOBY, JEŽ SE UVEDENÝCH PORUŠENÍ DOPUSTILY</h2>
                <p><strong>Příslušník či příslušníci Policie ČR, jenž se podle podatele výše uvedených porušení práv na
                    spravedlivý proces dopustil(i):</strong></p>
                <p>{state.doc.generatorSettings.policiste}</p>
                <p><strong>Útvar Policie ČR, na kterém tento příslušník či příslušníci působí: </strong></p>
                <p>{state.doc.generatorSettings.policejniUtvar}</p>
                <h2>5. IDENTIFIKACE PODATELE A DOTČENÝCH OSOB, JSOU-LI OD PODATELE ODLIŠNÉ</h2>
                <p><strong>Jméno a příjmení podatele:</strong></p>
                <p>{state.doc.generatorSettings.podatel}</p>
                <p><strong>Osoba či osoby dotčené výše uvedenými porušeními, pokud jsou odlišné od podatele:</strong>
                </p>
                <p>{state.doc.generatorSettings.dotceni}</p>
                <h2>6. ADRESÁT A DALŠÍ DOTČENÉ INSTITUCE</h2>
                <p><strong>Adresát:</strong></p>
                <p>
                    Generální inspekce bezpečnostních sborů
                    <br/>podatelna@gibs.cz
                </p>
                <h2>7. CO PODATEL STÍŽNOSTI POŽADUJE?</h2>
                <p>Podatel stížnosti žádá prošetření výše uvedených porušení mých práv na spravedlivý proces podle čl. 6
                    odst. 1 Evropské úmluvy pro lidská práva, tak jak jsou vymezeny v příslušné judikatuře Evropského
                    soudu pro lidská práva vykládající toto ustanovení výše uvedeným příslušníkem či příslušníky Policie
                    ČR za účelem posouzení, zda se tímto jednáním uvedený příslušník či příslušníci Policie ČR dopustili
                    trestného činu či kázeňského přestupku, a případného postoupení v posléze uvedeném případě orgánu
                    příslušnému k projednání takového kázeňského přestupku.</p>
            </Card.Body>
            <Card.Footer className={"text-end"} id={"download-buttons"}>
                <Button variant={"success"} className={"m-2"}
                        onClick={() => DownloadRTF(downloadContents, state.doc.id)}>Stáhnout jako Word</Button>
            </Card.Footer>
        </Card>
    </>;
}