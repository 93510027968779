export default class GeneratorSettings {
    constructor(
        public soudy: string = '',
        public soudci: string = '',
        public podatel: string = '',
        public dotceni: string = '',
        public statniUrednik: string = '',
        public organVerejneMoci: string = '',
        public urednikSamospravy: string = '',
        public uradSamosprava: string = '',
        public exekutori: string = '',
        public exekutorskyUrad: string = '',
        public advokati: string = '',
        public statniZastupce: string = '',
        public statniZastupitelstvi: string = '',
        public policiste: string = '',
        public policejniUtvar: string = '',
    ){}
}