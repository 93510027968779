import {PssChoice, PssSection, PssSectionPart} from "../Model/PssDatabase";

export const CivilSoudceDb: PssSection[] = [
    new PssSection(
        "I. KDY SE PRÁVA NA SPRAVEDLIVÝ PROCES UPLATNÍ A KDY NE?",
        "Právo na spravedlivý proces se uplatní vždy v řízení, ve kterém jde o “nárok” soukromé osoby, tedy typicky nárok," +
        " který je žalovatelný v civilním soudním sporném řízení [1]. Právo na spravedlivý proces se však uplatňuje i v jiných řízení," +
        " než je civilní soudní řízení, jde-li v takovém řízení o soukromý vymahatelný nárok jednotlivce podle práva daného státu, jenž " +
        "je členským státem Rady Evropy [2]. Může jít za určitých okolností o soudní správní řízení nebo správní řízení nebo i disciplinární řízení."+
        "\n" +
        "\n" +
        " Pokud vaše právo není soudně vymahatelné, pak se ohledně tohto vašeho práva, právo na spravedlivý proces neuplatní [3]. " +
        "Pokud naopak vaše právo soudně vymahatelné je, použije se právo na spravedlivý proces nejen ohledně vzniku tohoto vašeho práva," +
        " ale i ohledně otázek týkající se faktických skutečností nebo rozsahu či způsobu uplatňování tohoto vašeho práva [4]. " +
        "Spor o vašem, i jen potenciálně soudně vymahatelném právu, musí být skutečný a opravdový.",
        "[1] (Regner v. the Czech Republic [GC], § 99); [2] (Károly Nagy v. Hungary [GC], §§ 60-61; Roche v. the United Kingdom [GC], " +
        "§ 119; Boulois v. Luxembourg [GC], § 91); [3] (Boulois v. Luxembourg [GC], §§ 96 and 101); [4] (Benthem v. the Netherlands, § 32; " +
        "Cipolletta v. Italy, § 31)",
        [
            new PssSectionPart(
                "A. Řízení, na které lze použít záruky spravedivého procesu podle čl. 6 odst. 1 Úmluvy:",
                [],
                [
                    new PssChoice("cs.1.1", "1. Sporná civilní věc proti jiné fyzické osobě nebo jiné právnické osobě, která se již u soudu projednává nebo se projednávala ","(Airey v. Ireland, § 21)"),
                    new PssChoice("cs.1.2", "2. Exekuční řízení: právo na spravedlivý proces se aplikuje i na řízení vymáhací, po skončení nalézacího řízení bez ohledu na to, zda se právo na spravedlivý proces aplikovalo i v nalézacím řízení ","(Buj v. Croatia, § 19; (Hornsby v. Greece, § 40; Romańczyk v. France, § 53)"),
                    new PssChoice("cs.1.3", "3. Insolvenční řízení ","(Cipolletta v. Italy §§ 33-37)"),
                    new PssChoice("cs.1.4", "4. Řízení o likvidaci právnické osoby ","(Cipolletta v. Italy §§ 33-37)"),
                    new PssChoice("cs.1.5", "5. Řízení týkající se konstrukce přehrady, která měla zaplavit vesnici žadatelů "," (Gorraiz Lizarraga and Others v. Spain, § 46) "),
                    new PssChoice("cs.1.6", "6. Řízení ohledně udělení povolení provozovateli zlatého dolu používající injekce kyanidu, jež ohrožuje obyvatele blízkých vesnic "," (Taşkın and Others v. Turkey, § 133; Zander v. Sweden, §§ 24-25)"),
                    new PssChoice("cs.1.7", "7. Soudní řízení ohledně odvolání vzneseného spolkem pro ochranu přírody za účelem přezkoumání soudního rozhodnutí vzhledem ke zvláštnímu statute asociace i jejich zakldatelů, jež bylo věcně omezené "," (L’Érablière A.S.B.L. v. Belgium, §§ 28-30)"),
                    new PssChoice("cs.1.8", "8. Řízení týkající se navrácení právní způsobilosti fyzické osoby "," (Stanev v. Bulgaria [GC], § 233)"),
                    new PssChoice("cs.1.9", "9. Řízení o navrácení osoby do její soudcovské funkce, do které byla ustanovena na pět let "," Denisov v. Ukraine [GC] (§§ 47-49)"),
                    new PssChoice("cs.1.10", "10. Řízení, jež mohou být veřejnoprávního nebo správního charakteru, avšak jejichž výsledek je rozhodný pro soukromoprávní majetková práva jednotlivců, jako jsou například:\n" +
                        "\n"+
                        "", "- stavební řízení či územní řízení " +
                        "\n"+
                        "(Sporrong and Lönnroth v. Sweden, § 79)\n" +
                        "\n"+
                        "- řízení týkající se povolení prodat pozemek " +
                        "\n"+
                        "(Ringeisen v. Austria, § 94)\n" +
                        "\n"+
                        "- řízení týkající se povolení provozovat soukromou kliniku " +
                        "\n"+
                        "(König v. Germany, §§ 94-95)\n" +
                        "\n"+
                        "- řízení o povolení zřídit místo náboženského kultu " +
                        "\n"+
                        "(Lupeni Greek Catholic Parish and Others v. Romania [GC], §§ 71-73) \n" +
                        "\n"+
                        "- řízení týkající se povolení související s výkonem činnosti " +
                        "\n"+
                        "(Benthem v. the Netherlands, § 36) \n" +
                        "\n"+
                        "- řízení týkající se povolení pro výdej alkoholických nápojů " +
                        "\n"+
                        "(Tre Traktörer Aktiebolag v. Sweden, § 43) \n" +
                        "\n"+
                        "- řízení týkající se platby náhrad za nemoc z povolání nebo pracovní úraz " +
                        "\n"+
                        "(Chaudet v. France, § 30)\n" +
                        "\n"+
                        "- řízení o zákonnosti udělení povolení soutěžiteli v situaci, kdy udělení toto povolení vedlo ke ztrátě zákazníků jiného soutěžitele " +
                        "\n"+
                        "(Sine Tsaggarakis A.E.E. v. Greece, §§ 38-43)\n" +
                        "\n"+
                        "- řízení o udělení výzkumného grantu " +
                        "\n"+
                        "(Mirovni Inštitut v. Slovenia (§§ 28-30)\n" +
                        "\n"+
                        "- řízení o propuštění ze služebního poměru u veřejné instituce v důsledku ztráty bezpečnostní prověrky  " +
                        "\n"+
                        "(Regner v. the Czech Republic [GC], § 105)"),
                    new PssChoice("cs.1.11", "11. Řízení o žalobě o náhradě škody vyplývající z odpovědnosti za nesprávný výkon veřejné moci, jeko je například orgánu činném v trestním řízení nebo orgánu územní samosprávy ","(Osman v. the United Kingdom; Z and Others v. the United Kingdom [GC])"),
                    new PssChoice("cs.1.12", "12. Řízení o náhradě škody ","(Vilho Eskelinen and Others v. Finland [GC], § 41)"),
                    new PssChoice("cs.1.13", "13. Řízení či proces týkající se služebního poměru ve veřejné správě, pokud jde o platy, náhrady či obdobné příspěvky nebo v případě jiných podmínek, pokud není možnost bránit se soudně v daném případě zákonem výslovně vyloučena a toto vyloučení je ve veřřejném zájmu na základě objektivních kritérií (Frezadou v. Greece, § 30; Vilho Eskelinen and Others v. Finland [GC] (§§ 50-62)"),
                    new PssChoice("cs.1.14", "14. Řízení týkající se pracovněprávních sporů, zejména propuštění "," (Buchholz v. Germany)"),
                    new PssChoice("cs.1.15", "15. Řízení ve věcech práv týkajících se sociálního zabezpečení "," (Feldbrugge v. the Netherlands; Salesi v. Italy), "),
                    new PssChoice("cs.1.16", "16. Řízení týkající se odvodů na sociální pojištění ","(Schouten and Meldrum v. the Netherlands)"),
                    new PssChoice("cs.1.17", "17. Řízení ohledně odškodnění za válečné perzekuce "," (Woś v. Poland, § 76)"),
                    new PssChoice("cs.1.18", "18. Řízení týkající se sociálního bydlení a sociální pomoci ","(Fazia Ali v. the United Kingdom, §§ 58-59)"),
                    new PssChoice("cs.1.19", "19. Řízení, které vede k udělení povolení vykonávat určitou regulovanou profesi, například lékařskou profesi, včetně uznání výkonu lékařské profese v jiné zemi, profesi soudního znalce ","(Chevrol v. France, § 55, Kök v. Turkey, § 37; Cimperšek v. Slovenia, §§ 35-36) atp."),
                    new PssChoice("cs.1.20", "20. Řízení o propuštění z pracovního poměru nebo jeho změn, včetně služebního poměru ve veřejném sektoru "," (Regner v. the Czech Republic [GC], § 117), Kövesi v. Romania, § 115)"),
                    new PssChoice("cs.1.21", "21. Řízení o náhradě škody způsobené výkonem veřejné moci "," (X v. France), "),
                    new PssChoice("cs.1.22", "22. Řízení o zrušení povolení, jež poškozuje práva jeho nositele "," (De Geouffre de la Pradelle v. France)"),
                    new PssChoice("cs.1.23", "23. Správní řízení týkající se zákazu rybařit ve vodách patřících stěžovateli "," (Alatulkkila and Others v. Finland, § 49)"),
                    new PssChoice("cs.1.24", "24. Soutěž o veřejnou zakázku, kdy se jednalo o diskriminace na základě náboženské víry a politického názoru "," (Tinnelly & Sons Ltd and Others and McElduff and Others v. the United Kingdom, § 61; srovnej I.T.C. Ltd v. Malta (dec.)) "),
                    new PssChoice("cs.1.25", "25. Řízení týkající se vymazání záznamů osob z policejní databáze, kteréžto záznamy znemožňovaly stěžovatelům žádat o licenci k provozování požárnické činnosti ","(Pocius v. Lithuania, §§ 38-46; Užukauskas v. Lithuania, §§ 34-39)"),
                    new PssChoice("cs.1.26", "26. Řízení o náhradě škody způsobené veřejnými úředníky (Aksoy v. Turkey, § 92)"),
                    new PssChoice("cs.1.27", "27. Disciplinární řízení, jehož výsledkem může být zákaz výkonu určité činnosti, a to jak v soukromém, tak veřejném sektoru ","(Le Compte, Van Leuven and De Meyere v. Belgium; Philis v. Greece (no. 2), § 45, (Marušić v. Croatia (dec.), §§ 72-73), Kamenos v. Cyprus, § 73, Ramos Nunes de Carvalho e Sá v. Portugal [GC], § 120; Grace Gatt v. Malta, §§ 60-63)"),
                    new PssChoice("cs.1.28", "28. Proces podání žádosti poškozeným, který se připojil k trestnímu procesu za účelem získání odškodnění, státnímu zástupci, aby podal procesní prostředek (stížnost) ohledně nesprávného právního posouzení věci ","(Gorou v. Greece (no. 2) [GC], § 35)"),
                    new PssChoice("cs.1.29", "29. Řízení před vojenským soudem "," (Pridatchenko and Others v. Russia, § 47)"),
                    new PssChoice("cs.1.30", "30. Řízení o propuštění zaměstnanců ambasády ","(Cudak v. Lithuania [GC], §§ 44-47"),
                    new PssChoice("cs.1.31", "31. Řízení o nedobrovolném přesunu státního zástupce nebo jeho odvolání ","(Zalli v. Albania (dec.); Kövesi v. Romania, §§ 115 and 121)"),
                    new PssChoice("cs.1.32", "32. Disciplinární řízení se soudcem ","(Olujić v. Croatia; Harabin v. Slovakia)"),
                    new PssChoice("cs.1.33", "33. Řízení o povýšení celního úředníka "," (Fiume v. Italy, §§ 33-36)"),
                    new PssChoice("cs.1.34", "34. Řízení ohledně jmenování soudce předsedou soudu ","(Tsanova-Gecheva v. Bulgaria, §§ 84-85)"),
                    new PssChoice("cs.1.35", "35. Civilní žaloba v rámci trestního řízení či žaloba, ohledně práva, které je nerozlučně spjato s právem poškozeného na jeho odškodnění, i kdyby mělo být pouze symbolické; netýká se žaloby, jejímž cílem je pouhá osobní msta nebo potrestání (Perez v. France [GC], §§ 70-71; Nicolae Virgiliu Tănase v. Romania [GC], §§ 188 and 194; Sigalas v. Greece, § 29; Mihova v. Italy (dec.)) "),
                    new PssChoice("cs.1.36", "36. Řízení týkající se ochrany cti a dobré pověsti, jakož i řízení o pomluvě "," (Helmers v. Sweden, § 27; Tolstoy Miloslavsky v. the United Kingdom, § 58)"),
                    new PssChoice("cs.1.37", "37. Řízení týkající se práva na svobodný přístup k informacím a dokumentům veřejné správy ","(Loiseau v. France (dec.)) "),
                    new PssChoice("cs.1.38", "38. Řízení týkající se záznamu a výmazu v rejstříku trestů "," (Alexandre v. Portugal, §§ 54-55), Pocius v. Lithuania, §§ 38-46; Užukauskas v. Lithuania, §§ 32-40)."),
                    new PssChoice("cs.1.39", "39. Řízení týkající se předběžných opatření "," (De Tommaso v. Italy [GC], § 151)"),
                    new PssChoice("cs.1.40", "40. Řízení týkající se práva být členem spolku či přípustnosti činnosti spolku "," (Sakellaropoulos v. Greece (dec.); Lovrić v. Croatia, §§ 55-56; APEH Üldözötteinek Szövetsége and Others v. Hungary, §§ 34-35)"),
                    new PssChoice("cs.1.41", "41. Řízení týkající se práva na nižší (Oršuš and Others v. Croatia [GC], § 104)  či vyšší vzdělání (Emine Araç v. Turkey, §§ 18-25)"),
                    new PssChoice("cs.1.42", "42. Řízení týkající se otázek životního prostředí, jež souvisí s právem na život, zdraví nebo zdravé životní prostředí "," (Taşkın and Others v. Turkey)"),
                    new PssChoice("cs.1.43", "43. Řízení o určení otcovství ","(Alaverdyan v. Armenia (dec.), § 33)."),
                    new PssChoice("cs.1.44", "44. Řízení či proces týkající se vydání lustračního osvědčení "," (Polyakh and Others v. Ukraine, § 153)"),
                    new PssChoice("cs.1.45", "45. Řízení týkající se výchovy dětí, včetně školní docházky ","(McMichael v. the United Kingdom; Ellès and Others v. Switzerland, §§ 21-23)"),
                    new PssChoice("cs.1.46", "46. Řízení týkající se svobody projevu, včetně procesu, v rámci kterého se novinář snaží získat informace za účelem výkonu svého povolání ","(Kenedi v. Hungary, § 33; Selmani and Others v. the former Yugoslav Republic of Macedonia, § 47; Shapovalov v. Ukraine, § 49)"),
                    //new PssChoice("cs.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Řízení, na které záruky spravedlivého procesu podle čl. 6 odst. 1 Úmluvy použít nelze:",
                [],
                [

                    new PssChoice("", "1. V řízení, jehož základem není právní nárok, ale pouze právo, jehož udělení závisí na volné diskreci příslušného úřadu a jež je zakončeno rozhodnutím, jež daný úřad nemusí zdůvodňovat", "(Masson and Van Zon v. the Netherlands, §§ 49-51; Roche v. the United Kingdom [GC], §§ 122-125; Ankarcrona v. Sweden (dec.))"),
                    new PssChoice("", "2. Proces vedoucí ke zpracování zpráv vyplývajících z šetření, jejichž smyslem je zachytit fakta, jež mají být dále použity jako základ pro úkony dalších kompetentních úřadů, ať už se jedná o orgány činné v trestním řízení, správní řízení, disciplinární řízení nebo i zákonodárné zprávy, a to i když takové zprávy mohou vest k poškození pověsti v nich zmíněné osoby ", "(Fayed v. the United Kingdom, § 61)"),
                    new PssChoice("", "3. Řízení civilního charakteru proti vězeňské správě z důvodu přítomnosti HIV nakažených osob ve věznici ", "(Skorobogatykh v. Russia (dec.))"),
                    new PssChoice("", "4. V situacích, kdy soudní řízení nebylo započato, neboť žaloba byla odmítnuta z důvodu zjevné neopodstatněnosti, ať už věcné či procesní ", "(Astikos Oikodomikos Synetairismos Nea Konstantinoupolis v. Greece (dec.); Arvanitakis and Others v. Greece (dec.); Stavroulakis v. Greece (dec.))"),
                    new PssChoice("", "5. Řízení, jehož smyslem bylo napadení rozhodnutí rozšiřující licenci pro společnost provozující jadernou elektrárnu ", "(Balmer-Schafroth and Others v. Switzerland, § 40; Athanassoglou and Others v. Switzerland [GC], §§ 46-55; Sdružení Jihočeské Matky v. the Czech Republic (dec.)"),
                    new PssChoice("", "6. Disciplinární řízení, jež se přímo netýká práva pokračovat ve výkonu profese, v situaci, kdy takového cíle lze dosáhnout v jiném řízení ", "(Marušić v. Croatia (dec.), §§ 74-75)"),
                    new PssChoice("", "7. Řízení iniciované z důvodu údajného plagiátorství proti autorovi knihy, jež nemá vliv na právo autora na jeho dobrou pověst ", "(Morawska v. Poland, §§ 72 and 73)"),
                    new PssChoice("", "8. Řízení o udělení bezpečnostní prověrky ", "(Regner v. the Czech Republic [GC], § 105)"),
                    new PssChoice("", "9. Nesporné řízení, jež nezahrnuje protistrany a ve kterém se nejedná o spor o subjektivní právo ", "(Alaverdyan v. Armenia (dec.), § 35)"),
                ],
                false
            )
        ],
        true
    ),
    new PssSection(
        "II. PORUŠENÍ NEZÁVISLOSTI SOUDCE A SOUDU",
        "Spravedlnost nemůže být pouze vykonávána, ale veřejnost musí rovněž vidět, že je spravedlnost vykonávána." +
        " Viditelnost výkonu spravedlnosti posiluje důvěru veřejnosti v soudy v demokratické společnosti [1]. " +
        "Soudci proto nesmí být vůči stranám podjatí, a to ani funkčně, tedy v rámci jednotlivých fází procesu, ani osobně [2]." +
        "\n" +
        "\n" +
        "Nezávislost soudu zahrnuje nezávislost vůči ostatním složkám veřejné moci (výkonné i zákonodárné) [3]. \"Nezávislost\" " +
        "tak označuje nezbytnou osobní a institucionální nezávislost, která je nezbytná pro nestranné rozhodování, a je tedy předpokladem nestrannosti. " +
        "Charakterizuje jak stav mysli, který označuje odolnost soudce vůči vnějšímu tlaku jako součást jeho morální integrity [4]. " +
        "Evropský soud pro lidská práva soustavně zdůrazňuje, že závazek státu zajistit projednání před „nezávislým a nestranným soudem“ podle článku 6 odst. " +
        "1 Úmluvy se netýká jen moci soudní, ale má důsledky i pro moc výkonnou, zákonodárnou I vůči všem ostatním státním orgánům na jakékoli úrovni [5].",
        "[1] (Micallef v. Malta [GC]); [2] (Micallef v. Malta [GC], §§ 97-98); " +
        "[3] (De Haan v. the Netherlands, §§ 52-55; Helle v. Finland, § 46; Crompton v. the United Kingdom, § 79; Denisov v. Ukraine [GC], " +
        "§§ 65, 67 and 72); [4] (Guðmundur Andri Ástráðsson v. Island [GC], § 234); [5] (Agrokompleks proti Ukrajině, § 136)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na spravedlivý proces, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("cs.2.1", "1. Soudce, ohledně něhož existuje legitimní důvod se bát, že není nestranný, se musí z projednávání věci vyloučit, což ovšem neučinil, čímž došlo k porušení práva na spravedlivý proces. "," (Micallef v. Malta [GC], § 98)"),
                    new PssChoice("cs.2.2", "2. Soud, jemuž byla doručena námitka podjatosti, se nevyjádřil k argumentům uvedeným v této námitce nebo v souvislosti s ní, porušil právo stran na nezávislý a nestranný soud. "," (Harabin v. Slovakia, § 136)"),
                    new PssChoice("cs.2.3", "3. Je porušením práva na nezávislost a nestrannost soudce, pokud se soud vyhne posouzení námitky podjatosti, ačkoli je zřejmé, že není bezpředmětná či zjevně nedůvodná, a nepřijme v důsledku toho příslušná opatření zajišťující nestrannost a nezávislost soudu. "," (Cosmos Maritime Trading and Shipping Agency v. Ukraine, §§ 78-82)"),
                    new PssChoice("cs.2.4", "4. Soudce porušil svou nestrannost a nezávislost, pokud vyjadřoval nepřátelství vůči jedné ze stran. "," (Buscemi v. Italy, §§ 67-68)"),
                    new PssChoice("cs.2.5", "5. Osobní nesnášenlivost soudce k jedné ze stran je předsvědčivým důvodem pro jeho vyloučení. "," (Rustavi 2 Broadcasting Company Ltd and Others v. Georgia, § 359)"),
                    new PssChoice("cs.2.6", "6. Je porušením nestrannosti soudce a práva na spravedlivý proces, pokud soudce vyjadřoval předsudky či předpojatost vůči některé ze stran nebo vůči určitému způsobu řešení sporu. "," (Wettstein proti Švýcarsku, § 43; Micallef proti Maltě [velký senát]"),
                    new PssChoice("cs.2.7", "7. Soudce porušil svou nestrannost a nezávislost, pokud poskytoval poradenství jedné ze stran v téže věci, ohledně téhož rozhodnutí nebo ohledně analogických záležitostí. "," (Kleyn and Others v. the Netherlands [GC], § 200)"),
                    new PssChoice("cs.2.8", "8. Jakákoli přímá účast na přijetí zákona či prováděcích opatření, včetně například územního či rozvojového plánu určitého území je zpravidla dostatečným důvodem pro vyloučení soudce z důvodu absence jeho nestrannosti a nezávislosti, pokud tento soudce následně daný předpis vykládá v rámci sporu, který soudí. "," (McGonnell v. the United Kingdom, §§ 55-58)"),
                    new PssChoice("cs.2.9", "9. Pokud jsou vedena dvě souběžná řízení mezi stejnými stranami, přičemž v jednom z nich určitá osoba vystupuje jako soudce a v druhé jako protistrana, představuje to porušení práva na nestranného a nezávislého soudce. "," (Wettstein v. Switzerland, §§ 44-47)"),
                    new PssChoice("cs.2.10", "10. Soudce porušil svou nestrannost a nezávislost, pokud v předchozích řízeních, jež vyústily v řízení před tímto soudem, zastupoval jednu ze stran. "," (Mežnarić v. Croatia, § 36, Švarc and Kavnik v. Slovenia, § 44)"),
                    new PssChoice("cs.2.11", "11. Pokud existuje úzký vztah soudce s jeho faktickou přítomností v jiném postavení než v postavení soudce v záležitostech či skutečnostech, jichž se týkají různé fáze řízení, jehož se jako soudce účastní, je to porušením jeho nestrannosti. "," (Toziczka v. Poland, § 36)"),
                    new PssChoice("cs.2.12", "12. Nejasnost ohledně toho, zda určitá osoba vystupuje v civilním sporu v roli soudce či státního zástupce, je porušením nestrannosti takového soudce. "," (Kamenos v. Cyprus, § 104)"),
                    new PssChoice("cs.2.13", "13. Soudce porušuje svou nestrannost a nezávislost, pokud rozhoduje o tom, zda se dopustil nesprávného právního posouzení v rozhodnutí, jehož vydání se v předchozím stupni účastnil. "," (Driza v. Albania, §§ 78-83)"),
                    new PssChoice("cs.2.14", "14. Soudce porušuje svou nestrannost a nezávislost v případě, kdy se účastní dvou různých řízení týkajících se stejných skutkových okolností. "," (Indra proti Slovensku, § 51–53)"),
                    new PssChoice("cs.2.15", "15. Jeden ze soudců rozhodující o právním posouzení věci na odvolacím soudě, rozhodoval dříve o téže věci jako soudce soudu první instance. "," (De Haan proti Nizozemsku, § 51)), čímž se dopustil porušení své nestrannosti a nezávislosti"),
                    new PssChoice("cs.2.16", "16. Představuje porušení nestrannosti a nezávislosti, pokud předseda nejvyššího soudu rozhoduje o věci, jež se týká dřívějšího rozhodnutí správního orgánu, jehož byl v té době předsedou. "," (Ramos Nunes de Carvalho e Sá v. Portugal [GC] (§§ 153-156)"),
                    new PssChoice("cs.2.17", "17. Je porušením nezávislosti a nestrannosti soudu, pokud asistent jednoho z ústavních soudců byl dříve součástí týmu právníků, jež zastupovali jednu ze stran v řízení na nižším stupni, jež poté vyústila v řízení před ústavním soudem. "," (Bellizzi v. Malta (§§ 60-61)"),
                    new PssChoice("cs.2.18", "18. Soud porušuje požadavek nestrannosti a nezávislosti v situaci, kdy čtyři ze sedmi soudců, jež rozhodují o věci ve vyšší instanci rozhodovali o téže věci v nižší instanci. "," (Pereira da Silva v. Portugal, §§ 59-60)"),
                    new PssChoice("cs.2.19", "19. Je porušením nestrannosti soudu, pokud odvolací soud, jehož soudci se dříve zúčastnili nalézacího řízení a nyní jsou povoláni rozhodnout o tom, zda ve svých předchozích rozhodnutích nevyložili nebo nepoužili chybně právní předpis, nestrannosti. "," (San Leonard Band Club proti Maltě, § 64)"),
                    new PssChoice("cs.2.20", "20. Je porušením nestrannosti soudu, pokud jeden ze soudců rozhodujících o dovolání se předtím účastnil řízení v téže věci jako soudce soudu vyšší instance. "," (Peruš proti Slovinsku, § 38–39)"),
                    new PssChoice("cs.2.21", "21. Soudce porušuje svou povinnost nestrannosti, pokud má osobní zájem na výsledku sporu ve věci, již soudí. "," (Langborger v. Sweden, § 35; Gautrin and Others v. France, § 59)"),
                    new PssChoice("cs.2.22", "22. Profesní, majetkové nebo osobní vztahy mezi soudcem a stranou sporu nebo advokátem jedné ze stran sporu jsou důvodem pro porušení nestrannosti a nezávislosti soudce. "," (Pescador Valero v. Spain, § 27; Tocono and Profesorii Prometeişti v. Moldova, § 31; Micallef v. Malta [GC], § 102; Wettstein v. Switzerland, § 47)"),
                    new PssChoice("cs.2.23", "23. Vyjádření či vztahy mezi rodinnými příslušníky soudce na straně jedné, a jedné ze stran sporu nebo jejího právního zástupce či právní kanceláře, jejímž jménem tento právní zástupce ve sporu vystupuje, na straně druhé, mohou být důvodem pro vyloučení soudce z důvodu jeho nestrannosti či nezávislosti v závislosti na jejich intenzitě, úzkému charakteru majetkových či osobních vztahů, vlivu a postavení dané osoby v dotčené právní kanceláři, významu sporu pro danou právní kancelář atd. ","(Rustavi 2 Broadcasting Company Ltd and Others v. Georgia, §§ 342 et seq., § 344, Nicholas v. Cyprus, § 62)"),
                    new PssChoice("cs.2.24", "24. Pokud je soudce nebo jeho asistent podílející se na dané věci zároveň v podřízeném postavení vůči jedné ze stran sporu, představuje taková situace porušení nezávislosti a nestrannosti soudu. "," (Sramek v. Austria, § 42)"),
                    new PssChoice("cs.2.25", "25. Zasáhla-li moc výkonná do případů projednávaných před soudem s cílem ovlivnit jejich výsledek, je tím ohrožena nezávislost soudců, a došlo k porušení práva na spravedlivý proces. "," (Sovtransavto Holding proti Ukrajině, § 80; Mosteanu a ostatní proti Rumunsku, § 42)"),
                    //new PssChoice("cs.2.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Soud i soudci jsou považováni za nestranné a nezávislé, dokud jejich nestrannost či nezávislost není zpochybněna. ", "(Le Compte, Van Leuven and De Meyere v. Belgium, § 58; Driza v. Albania, § 75; Micallef v. Malta [GC], § 94)"),
                    new PssChoice("", "2. Skutečnost, že jsou soudci jmenování představitelem výkonné moci a jsou odvolatelní, není dostatečným důvodem k pochybnostem o jejich nestrannosti či nezávislosti. ", "(Clarke v. the United Kingdom (dec.))"),
                    new PssChoice("", "3. Skutečnost, že se soudce v určité části své procesní dráhy může nacházet v obdobném postavení jako některá ze stran sporu, není sama o sobě bez dalšího skutečností, jež by vedla k porušení nestrannosti a nezávislosti soudce.", "(Ramos Nunes de Carvalho e Sá v. Portugal [GC], § 163)"),
                    new PssChoice("", "4. Není porušením práva na nestranný a nezávislý soud, pokud soud odmítne námitku podjatosti, protože nesplňuje formální náležitosti. ", "(Mikhail Mironov v. Russia, §§ 34-40)"),
                    new PssChoice("", "5. Není porušením práva na nestranný a nezávislý soud, pokud soud posoudí námitku podjatosti „očima nezávislého pozorovatele“ a námitku podjatosti zamítne. ", "(Clarke v. the United Kingdom (dec.))"),
                    new PssChoice("", "6. Není porušením práva na nestranný a nezávislý soud, pokud zamítnutá námitka podjatosti, byla následně posouzena v plném rozsahu soudem vyššího stupně, který se vypořádal s problémem, na nějž tato námitka mířila. ", "(De Haan v. the Netherlands, §§ 52-55; Helle v. Finland, § 46; Crompton v. the United Kingdom, § 79; and Denisov v. Ukraine [GC], §§ 65, 67 and 72)"),
                    new PssChoice("", "7. Není porušením práva na nestranný a nezávislý soud, pokud předchozí vztah soudce k dané věci byl marginální a uplynula od něj již dlouhá doba. ", "(McGonnell v. the United Kingdom, §§ 52-57)"),
                    new PssChoice("", "8. Skutečnost, že soudce má pokrevní pouto se členem advokátní kanceláře, jejíž člen je právním zástupcem v soudním sporu, jehož se účastní rovněž uvedený soudce, neznamená automaticky porušení nezávislosti či nestrannosti tohoto soudce. ", "(Ramljak v. Croatia, §§ 29, Koulias v. Cyprus, §§ 62-64)"),
                    new PssChoice("", "9. Skutečnost, že soudci se znají jako kolegové, mohou se potkávat v budově soudu nebo dokonce sdílejí své kanceláře, není sám o sobě nezvratným důkazem o porušení jejich nezávislosti či nestrannosti. ", "(Steck-Risch and Others v. Liechtenstein, § 48)"),
                    new PssChoice("", "10. Skutečnost, že má soudce detailní znalost projednávané věci i z jiných zdrojů, než jsou tvrzení a důkazy účastníků, není porušením jeho nestrannosti, pokud je výsledné rozhodnutí založeno na tvrzeních a důkazech předložených účastníky.", "(Morel v. France, § 45)"),
                    new PssChoice("", "11. Skutečnost, že se soudce odvolacího soudu nevyloučil z projednávání věci v situaci, kdy se účastnil jiných souvisejících civilních řízení, není porušením jeho nestrannosti. ", "(Golubović v. Croatia, § 52)"),
                    new PssChoice("", "12. Není porušením nestrannosti soudu, pokud předsedové soudů vykonávali pouze správní (manažerské a organizační) funkce, které byly striktně odděleny od funkce soudní. ", "(Parlov-Tkalčić proti Chorvatsku, § 88–95)"),
                    new PssChoice("", "13. Není porušením nestrannosti soudce, a tudíž ani práva na spravedlivý proces to, že se soudce nevyloučil z projednání odvolání v občanskoprávní věci, ačkoli se předtím účastnil řady jiných podobných řízení, nebyla důkazem postačujícím pro vyvrácení domněnky nestrannosti. ", "(Golubović proti Chorvatsku, § 52)"),
                    new PssChoice("", "14. Z požadavku na nestrannost soudu nevyplývá pro nadřízený soud, který zrušil správní nebo soudní rozhodnutí, povinnost vrátit věc k projednání jinému jurisdikčnímu orgánu nebo jinak obsazenému útvaru téhož orgánu, tzn. že není porušením práva na spravedlivý proces, je-li nadřízeným soudem, vrácena zrušená věc témuž soudu, který dříve vydal rozhodnutí, jež bylo nadřízeným soudem zrušeno.", "(Ringeisen proti Rakousku, § 97 in fine)"),
                    new PssChoice("", "15. S požadavkem nestrannosti není neslučitelné, jestliže se tentýž soudce účastní nejprve rozhodování o meritu věci a následně řízení, v němž je posuzována přípustnost opravného prostředku proti tomuto rozhodnutí. ", "(Warsicka proti Polsku, § 38–47)"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "III. ABSENCE FÉROVOSTI PROCESU",
        "Právo na férovost soudního procesu je význačnou vlastností a jedním ze základních principů demokratické " +
        "společnosti [1]. Instituce státu musí zajistit v každém individuálním případě, že požadavky férového procesu byly dodrženy [2]." +
        " Tyto požadavky se vztahují na doručení informace o existenci soudního řízení, zajištění rovnosti zbraní účastníků soudního řízení, " +
        "povinnosti soudu posoudit a zhodnotit argumenty účastníků řízení. " +
        "\n"+
        "\n"+
        "Stížnosti účastníků soudního řízení na to, že tyto požadavky spravedlivého procesu byly dodrženy," +
        "jsou soudy povinny zkoumat se zvláštní pečlivostí a opatrností [3]. Podstata práva na přístup k soudu je narušena, " +
        "pokud pravidla přestanou sloužit cílům „právní jistoty“ [4] nebo „řádného výkonu spravedlnosti“ [5].",
        "[1] (Airey v. Ireland, § 24; Stanev v. Bulgaria [GC], § 231, Pretto and Others v. Italy, § 21); [2] (Dombo Beheer B.V. " +
        "v. the Netherlands, § 33 in fine); [3] (Fabris v. Francie [GC], § 72 in fine; Wagner a JMWL v. Lucembursko, § 96); [4] (Nideröst-Huber " +
        "v. Švýcarsko, § 30); [5] (Beian proti Rumunsku (č. 1), § 39)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("cs.3.1", "1. Soud neumožnil „praktický a účinný“ přístup do soudního řízení. Přístup k soudu byl jen teoretický nebo iluzorní. "," (Airey v. Irsko, § 24; Perez v. Francie [GC], § 80)"),
                    new PssChoice("cs.3.2", "2. Soud neumožnil účastnit se řízení, které bylo zahájeno protistranou a hájit v tomto řízení zájmy účastníka. "," (Dilipak a Karakaya v. Turecko, § 85-88)"),
                    new PssChoice("cs.3.3", "3. Soud vytvořil jednu či více překážek, která zabránila v tom, aby byl případ projednán věcně příslušným soudem. "," (Zubac v. Chorvatsko [GC], § 98)"),
                    new PssChoice("cs.3.4", "4. Soud neposoudil námitky týkající se práv a svobod zaručených Úmluvou a jejími Protokoly, přesto, že je účastník řízení v řízení předložil, a vnitrostátní soudy je nezkoumali obzvláště přísně a pečlivě přesto, že jsou k tomu povinny. "," (Wagner a J.M.W.L. proti Lucembursku, § 96)"),
                    new PssChoice("cs.3.5", "5. Jako členovi mezinárodní organizace imunita této mezinárodní organizace před národním soudem nebyla respektována. "," (Stichting Mothers of Srebrenica and Others v. the Netherlands (dec.), § 139)"),
                    new PssChoice("cs.3.6", "6. Soud neučinil nezbytná opatření, aby informoval účastníka o řízení, která se ho týkají. "," (Dilipak a Karakaya v. Turecko, § 85-88)"),
                    new PssChoice("cs.3.7", "7. Soud znemožnil, účastníkovi, aby měl jasnou a praktickou příležitost napadnout čin, který je zásahem do jeho práv. "," (Bellet v. Francie, § 36; Nunes Dias v. Portugalsko (dec.))"),
                    new PssChoice("cs.3.8", "8. Soud s náležitou péčí nezajistil účastníkovi, aby byl informován o řízení či řízeních, která se ho týkají, aby se mohl k soudu dostavit a bránit se a aby bylo zajištěno jeho právo na přístup k soudu. "," (Schmidt v Latvia, §§ 86-90, 92 a 94-95)"),
                    new PssChoice("cs.3.9", "9. Účastník nebyl s dostatečným předstihem informován o datu a místě soudního jednání, neboť soud před prvním soudním jednání neověřil, že účastník byl s dostatečným předstihem informován o datu a místě jednání soudního jednání. Soud musí jít nad rámec pouhého odkazu na zaslání soudního předvolání a musí co nejlépe využít dostupné důkazy, aby bylo možné zjistit, zda byla nepřítomná strana skutečně o jednání informována v dostatečném předstihu, což však soud neučinil. ","Gankin a další v. Rusko, §§ 34-38"),
                    new PssChoice("cs.3.11", "10. Soud neodročil jednání přesto, že zjistil, že nepřítomný účastník neobdržel předvolání k soudu včas. ","(Gankin a další v. Rusko, § 39 a 42)"),
                    new PssChoice("cs.3.12", "11. Odvolání proti rozhodnutí podané jedním účastníkem řízení nebylo druhému účastníkovi doručeno, takže posléze uvedený účastník neměl možnost se k tomuto odvolání vyjádřit. "," (Beer v. Austria, § 19)"),
                    new PssChoice("cs.3.13", "12. K řádné účasti odvolatele na odvolacím řízení je nezbytné, aby mu soud z vlastního podnětu poskytl dokumenty, které má k dispozici. Není tudíž podstatné, že stěžovatel proti neposkytnutí příslušných dokumentů nic nenamítal nebo že se pokusil získat přístup ke spisu. "," (Kerojärvi proti Finsku, § 42)"),
                    new PssChoice("cs.3.14", "13. Je porušením práva na spravedlivý proces účastníka řízení, pokud zákonodárce za situace, kdy již devět let probíhalo řízení, jehož účastníkem byl stát a stěžovatelé měli proti němu k dispozici pravomocný vykonatelný rozsudek, zasáhl legislativně do probíhajícího řízení, přičemž cílem zásahu bylo ovlivnit blížící se výsledek řízení ve prospěch státu. "," (Stran Greek Refineries a Stratis Andreadis proti Řecku, § 49–50)"),
                    new PssChoice("cs.3.15", "14. Je porušením práva na spravedlivý proces, pokud došlo k přijetí zákona, který pro účely praxe vyřešil hmotněprávní otázky a učinil tak bezpředmětným další pokračování sporu před kasačním soudem, jenž se v té době nacházel v rozhodující fázi. "," (Papageorgiou proti Řecku)"),
                    new PssChoice("cs.3.16", "15. Je porušením práva na spravedlivý proces, pokud stát, ačkoli sám není účastníkem řízení, prostřednictvím svých legislativních pravomocí soudní řízení zmaří. "," (Ducret proti Francii, § 33–42)"),
                    //new PssChoice("cs.3.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Pouhá skutečnost, že výsledek sporu nedopadl ve vás prospěch, neznamená, že vaše práva na spravedlivý proces byla porušena. ", "(Andronicou and Constantinou v. Cyprus, § 201)"),
                    new PssChoice("", "2. Pochybení soudu ohledně férovosti procesu byla napravena týmž soudem nebo soudem vyššího stupně v pozdější fázi řízení. ", "(Helle v. Finland, §§ 46 and 54, Schuler-Zgraggen v. Switzerland, § 52)"),
                    new PssChoice("", "3. Účastník řízení nevyužil možnosti napadnout předchozí procesní pochybení či rozhodnutí před nezávislým soudním orgánem, i když k tomu měl příležitost. "," (Oerlemans v. the Netherlands, §§ 53-58; British-American Tobacco Company Ltd v. the Netherlands, § 78, Air Canada v. the United Kingdom, § 62)"),
                    new PssChoice("", "4. Přijetí obecných právních předpisů se může pro účastníky řízení ukázat jako nepříznivé, aniž by bylo skutečně zacíleno na probíhající soudní řízení a jeho účelem bylo obejití zásady zákonnosti. Právní předpis, na němž je výsledek řízení závislý, může být v průběhu soudního řízení prohlášen za neústavní, aniž by bylo úmyslem jakkoli toto soudní řízení ovlivnit, a bylo tím ovlivněno právo na spravedlivý proces. ", "(Gorraiz Lizarraga a ostatní proti Španělsku, § 72; Dolca proti Rumunsku (rozh.)"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "IV. PORUŠENÍ PRÁVA NA KONTRADIKTORNÍ PROCES",
        "Požadavky vyplývající z práva na kontradiktorní řízení jsou v zásadě shodné jak v občanskoprávních, " +
        "tak v trestních věcech [1]. Snaha ušetřit čas a urychlit řízení ze strany soudu neodůvodňuje nerespektování základní zásady, " +
        "rovnosti zbraní v řízení [2]. Právo na kontradiktorní řízení v zásadě znamená, že účastníci trestního nebo občanskoprávního řízení " +
        "musejí mít možnost seznámit se se všemi důkazy nebo stanovisky předloženými s cílem ovlivnit rozhodnutí soudu a vyjádřit" +
        " se k nim, a to i v případě, že je předložili nezávislí experti, například zaměstnanci vnitrostátní právní služby [3]." +
        "\n"+
        "\n"+
        " Úkolem podle Úmluvy je zajistit, zda řízení jako celek bylo spravedlivé, a to včetně způsobu dokazování [4]. " +
        "Soud musí tudíž zjistit, zda byly důkazy předloženy způsobem, který poskytoval záruku spravedlivého řízení [5]." +
        " Úmluva jako taková ovšem nestanoví žádná pravidla týkající se důkazů jako takových [6]. " +
        "Přípustnost důkazů a způsob jejich hodnocení jsou primárně předmětem vnitrostátní právní úpravy a jejího" +
        " výkladu vnitrostátními soudy [7]. Totéž platí i pro důkazní hodnotu a důkazní břemeno.",
        "[1] (Werner proti Rakousku, § 66); [2] (Nideröst-Huber v. Švýcarsko, § 30); [3] (Ruiz-Mateos proti Španělsku, § 63; " +
        "McMichael proti Spojenému království, § 80); [4] (Elsholz proti Německu [velký senát], § 66); " +
        "[5] (Blücher proti České republice, § 65); [6] (Mantovanelli proti Francii, § 34); " +
        "[7] (García Ruiz proti Španělsku [velký senát] § 28); [8] (Tiemann proti Francii a Německu (rozh.))",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("cs.4.1", "1. Účastníkovi bylo znemožněno předložit soudu argumenty a důkazy s potřebnou účinností. "," (H. v. Belgie, § 53, Dombo Beheer BV v. Nizozemsko, § 33 in fine)"),
                    new PssChoice("cs.4.2", "2. Účastníkovi poskytnuta přiměřená příležitost předložit jeho případ - včetně jeho důkazů - za podmínek, které ho neznevýhodňují vůči druhé straně. "," (Regner v. Česká republika [GC], § 146; Dombo Beheer BV v. Nizozemsko, § 33)"),
                    new PssChoice("cs.4.3", "3. Účastník neměl možnost získat dostatečné znalosti o průběhu soudního řízení a komentovat všechny předložené důkazy nebo vyjádření podaná s cílem ovlivnit rozhodnutí soudu. "," (Ruiz-Mateos v. Španělsko, § 63; McMichael v. Spojené království, § 80; Vermeulen v. Belgie, § 33; Lobo Machado v. Portugalsko, § 31; Kress v. Francie [GC], § 74)"),
                    new PssChoice("cs.4.4", "4. Účastník neměl možnost seznámit se s důkazy před soudem nebo možnost vyjádřit se k jejich existenci, obsahu a autentičnosti vhodnou formou a ve vhodnou dobu. "," (Krčmář a další v. Česká republika, § 42; Immeubles Groupe Kosser v. Francie, § 26)"),
                    new PssChoice("cs.4.5", "5. Jako zranitelnému jednotlivci, například v důsledku zdravotní poruchy, se účastníkovi v průběhu řízení nedostalo potřebné ochrany či pomoci tak, aby byla zachována jeho důstojnost a právo na soukromý život. "," (Evers v. Germany, §§ 82-84)"),
                    new PssChoice("cs.4.6", "6. Zákonná a podzákonná pravidla týkající se přípustnosti znaleckých posudků a dokumentů neposkytla účastníkovi možnost tyto znalecké posudky a důkazy účinně napadnout. "," (Letinčić v. Croatia, § 50)"),
                    new PssChoice("cs.4.7", "7. Pokud byl soudem ustanoven znalec, nebylo účastníkovi umožněno, aby se účastnil výslechů, které soudní znalec uskutečnil nebo, jež uvádí ve svých dokumentech, že je uskutečnil, v důsledku čehož se účastník nemohl účinně účastnit řízení. ","(Letinčić v. Croatia, § 50; Devinar v. Slovenia, § 46)"),
                    new PssChoice("cs.4.8", "8. Soud neodročil soudní jednání, přesto, že účastník o to žádal z důvodu, že neměl možnost seznámit se s důkazy před soudem nebo možnost vyjádřit se k jejich existenci, obsahu a autentičnosti vhodnou formou a ve vhodnou dobu. ","(Yvon v. Francie, § 39)"),
                    new PssChoice("cs.4.9", "9. Účastník nemohl předložit argumenty nebo důkazy, protože nevěděl o existenci práva nebo existenci souvisejících řízeních. "," (Schmidt v. Lotyšsko, bod 96 a citované odkazy na judikaturu)"),
                    new PssChoice("cs.4.10", "10. Účastník soudního řízení neměl možnost seznámit se s důkazy předloženými soudu, ani možnost vyjádřit se přiměřenou formou a v přiměřené lhůtě k jejich existenci, obsahu a pravosti, v případě potřeby i docílením odročení jednání. ","(Krčmář a ostatní proti České republice, § 42; Immeubles Groupe Kosser proti Francii, § 26; Yvon proti Francii § 39)"),
                    new PssChoice("cs.4.11", "11. Účastník řízení neměl příležitost navrhnout důkaz potřebný k tomu, aby se svým nárokem uspěl. "," (Clinique des Acacias a ostatní proti Francii, § 37)"),
                    //new PssChoice("cs.4.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Bylo porušeno procesní právo účastníka řízení, avšak důsledek tohoto porušení neměl reálně možnost ovlivnit výsledek sporu, který byl v souladu s právem. ", "(Stepinska v. France, § 18)"),
                    new PssChoice("", "2. Právo na spravedlivý proces výslovně nezaručuje právo na předvolání svědka a přípustnost svědecké výpovědi je zásadně záležitostí vnitrostátního práva. Zamítnutí návrhu na předvolání svědka stejně jako zamítnutí přípustnosti svědecké výpovědi bylo soudem přiměřeně odůvodněno. ", "(Dombo Beheer B.V. proti Nizozemsku, § 31, H. proti Francii, § 61 a 70)"),
                    new PssChoice("", "3. Procesní pochybení, kterého se dopustil soud, je zcela marginální. Například neobdržel jste od soudu vyjádření protistrany nebo kopii důkazů, které nebylo pro řízení nijak podstatné nebo neobsahovalo žádné nové skutečnosti nebo soud výslovně prohlásil, že takový důkaz protistrany nevzal v potaz. "," (Liga Portuguesa de Futebol Profissional v. Portugal (déc.), §§ 36-40; Kılıç and Others v. Turkey (dec.); Holub v. the Czech Republic (dec); Cavajda v. the Czech Republic (dec.)"),
                    new PssChoice("", "4. Procesní pochybení bylo pouze izolované a nemohlo způsobit neférovost procesu jako takového. "," (Miroļubovs and Others v. Latvia, § 103)"),
                    new PssChoice("", "5. Pokud byl důkaz předložený soudu získán nezákonně, je pouze na soudu, zda určí, zda takový důkaz je v soudním řízení přípustný. ", "(López Ribalda and Others v. Spain [GC], § 150)"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "V. PORUŠENÍ PRÁVA NA ROVNOST PROCESNÍCH ZBRANÍ",
        "Zásada „rovnosti procesních zbraní“ je nedílnou součástí pojmu spravedlivého řízení v širším slova smyslu. " +
        "Požadavek „rovnosti procesních zbraní“ ve smyslu „spravedlivé rovnováhy“ mezi účastníky řízení se v zásadě uplatní v " +
        "civilních i trestních věcech [1]. " +
        "Zachování „spravedlivé rovnováhy“ mezi účastníky řízení znamená," +
        " že každému účastníku řízení musí být poskytnuta přiměřená možnost předestřít svoji věc" +
        " – včetně důkazů – za podmínek, které jej podstatným způsobem neznevýhodňují vůči protistraně [2]. " +
        "\n"+
        "\n"+
        "Vnitrostátní soudy jsou povinny řádně posoudit podání, argumenty a důkazy, předložené účastníky řízení [3]." +
        " Vnitrostátním soudům přísluší také posouzení relevance navrhovaných důkazů [4].",
        "[1] (Feldbrugge proti Nizozemsku, § 44); [2] (Dombo Beheer B.V. proti Nizozemsku, § 33)" +
        "[3] (Van de Hurk proti Nizozemsku, § 59); [4] (Centro Europa 7 S.r.l. a Di Stefano proti Itálii [velký senát], § 198)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("cs.5.1", "1. Soud upřel účastníkovi mít soudem posouzeny jeho tvrzení a důkazy, které byly předloženy soudu před vydáním rozsudku. "," (Kraska v. Switzerland, § 30; Van de Hurk v. the Netherlands, § 59; Perez v. France [GC], § 80)"),
                    new PssChoice("cs.5.2", "2. Soud porušil právo účastníka řízení na to, aby vyjádření, které předložil/a, byla skutečně „vyslechnuta“, tj. aby je soud řádně zvážil. "," (Donadze v. Georgia, § 35)"),
                    new PssChoice("cs.5.3", "3. Účastník řízení neměl příležitost vyjádřit svůj názor na určitý dokument ve spisu, včetně dokumentů, které si soud opatřil z vlastního podnětu. "," (K.S. proti Finsku, § 22; Nideröst-Huber proti Švýcarsku, § 29; Pellegrini proti Itálii, § 45)"),
                    new PssChoice("cs.5.4", "4. Běh lhůt byl přerušen jen jednomu z účastníků řízení, čímž se druhý účastník ocitl v podstatné nevýhodě. "," (Platakou proti Řecku, § 48; Wynen proti Belgii § 32)"),
                    new PssChoice("cs.5.5", "5. Účastník řízení nebyl informován o podání k soudu, jež učinil jiný účastník a nemohl se k němu vyjádřit. "," (APEH Üldözötteinek Szövetsége a ostatní proti Maďarsku, § 42)"),
                    new PssChoice("cs.5.6", "6. Soud se konkrétně a výslovně nevyjádřil k tvrzení účastníka, které bylo rozhodné pro výsledek řízení. "," (Ruiz Torija v. Spain, § 30; Hiro Balani v. Spain, § 28; srovnej Petrović and Others v. Montenegro, § 43)"),
                    new PssChoice("cs.5.7", "7. Zamítnutí soudem návrhu účastníka na předvolání svědků nebylo soudem dostatečně odůvodněno a vykazuje známky svévole, takže účastník řízení byl tímto zamítnutí nepřiměřeně omezen ve svém právu předkládat argumenty na podporu svých tvrzení. "," (Wierzbicki proti Polsku, § 45)"),
                    new PssChoice("cs.5.8", "8. Nedostatek neutrality znalce spolu s jeho postavením a úlohou v řízení vychýlily rovnováhu ve prospěch jednoho účastníka řízení na úkor druhého. "," (Sara Lind Eggertsdottir proti Islandu, § 53)"),
                    new PssChoice("cs.5.9", "9. Ze dvou klíčových svědků byl vyslechnut pouze jeden. "," (Dombo Beheer B.V. v. the Netherlands, §§ 34-35)"),
                    new PssChoice("cs.5.10", "10. Protistrana měla oproti účastníkovi řízení významná zvýhodnění, co se týče přístupu k relevantním informacím, v důsledku čehož měla dominantní postavení v procesu a významně ovlivňovala posouzení soudu. "," (Yvon v. France, § 37)"),
                    new PssChoice("cs.5.11", "11. Protistrana měla v důsledku svého postavení a funkcí významnou výhodu v soudním řízení a soud účastníkovi řízení neumožnil tuto její výhodu napadnout tím, že by umožnil předložení listinných důkazů nebo vyslechnutí svědků uvedenému účastníkovi řízení. "," (De Haes and Gijsels v. Belgium, §§ 54 and 58)"),
                    new PssChoice("cs.5.12", "12. Účastníkovi řízení nebyl soudem umožněn přístup k relevantním dokumentům, jež byly v držení orgánu veřejné moci a jež byly významné pro výsledek řízení. "," (McGinley and Egan v. the United Kingdom, §§ 86 and 90)"),
                    new PssChoice("cs.5.13", "13. Odepření právní pomoci znemožnilo účastníkovi řízení účinně prezentovat jeho případ před soudem v situaci, kdy protistrana disponovala mnohem většími finančními prostředky. "," (Steel and Morris v. the United Kingdom, § 72)"),
                    new PssChoice("cs.5.14", "14. Státní zástupce intervenoval do soudního řízení – jiného než trestního – , aby podpořil argumenty protistrany. "," (Menchinskaya v. Russia, §§ 35-39)"),
                    new PssChoice("cs.5.15", "15. Soudce odmítl odročit jednání, i když účastník řízení musel být z urgentních důvodů převezen do nemocnice, což způsobilo, že ani právní zástupce účastníka řízení nebyl schopen zastupovat tohoto účastníka při soudním jednáním a v důsledku toho reagovat na tvrzení protistrany. "," (Vardanyan and Nanushyan v. Armenia, §§ 88-90)"),
                    new PssChoice("cs.5.16", "16. Soud prvního stupně nezvážil všechny hlavní argumenty a nepřezkoumal hlavní argumenty účastníka řízení, které v řízení předložil. ","(Buzescu v. Rumunsko, § 67; Donadze v. Gruzie, § 35)"),
                    new PssChoice("cs.5.17", "17. Soudem prvního stupně bylo opomenuto obecné skutkové a právní pozadí případu, přesto, že se ho účastník snažil soudu vylíčit. "," (Stankiewicz v. Polsko, § 70)"),
                    new PssChoice("cs.5.18", "18. Soud prvního stupně nevzal v úvahu důkazy předložené oběma stranami, včetně těch od účastníka řízení, a nerespektoval jeho právo na to předložit důkazy takovým způsobem, který zaručuje spravedlivý proces.", "(Blücher v. Česká republika, § 65; Van de Hurk v. Nizozemsko, bod 59)"),
                    new PssChoice("cs.5.19", "19. Stanovisko nezávislého pracovníka právního odboru orgánu veřejné moci v občanskoprávní věci nebyla předem sdělena účastníkovi řízení, čímž mu bylo znemožněno na ně reagovat. "," (Vermeulen proti Belgii, § 33; Lobo Machado proti Portugalsku, § 31; Van Orshoven proti Belgii, § 41; Göç proti Turecku [velký senát], § 55–56; Kress proti Francii, § 76; Immeubles Groupe Kosser proti Francii, § 26)"),
                    new PssChoice("cs.5.20", "20. Přítomnost členů právního odboru orgánu veřejné moci (vnitrostátní právní služby) na poradě soudu, ať již „aktivní“ nebo „pasivní“, poté, co veřejně vyjádřili svůj názor na projednávanou věc, je nepřípustná, přesto k ní však došlo. "," (Vermeulen proti Belgii, § 34; Lobo Machado proti Portugalsku, § 32; Kress proti Francii, § 87)"),
                    //new PssChoice("cs.5.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Soud se vyjádřil k vaším hlavním argumentům, ale nevyjádřil se úplně ke všem vašim argumentům; Perez v. France [GC], § 81).", "(Van de Hurk v. the Netherlands, § 61; García Ruiz v. Spain [GC], § 26; Jahnke and Lenoble v. France (déc.)"),
                    new PssChoice("", "2. Vaše právo na férový proces nemohlo být porušeno, pokud jste nepředložil relevantní a dostatečné důkazy o porušení vašich hmotněprávních nároků. ", "(Fleischner v. Germany, §§ 40-41)"),
                    new PssChoice("", "3. Porušení vašich práv na spravedlivý proces je důsledkem významných chyb v soudním řízení z vaší strany, které jsou vám objektivně přičitatelné. ", "(Zubac v. Croatia [GC], §§ 90 and 121)"),
                    new PssChoice("", "4. Porušení vašich práv na spravedlivý proces je důsledkem významné nečinnosti či nedbalosti z vaší strany. ", "(Avotiņš v. Latvia [GC], §§ 123-24; Barik Edidi v. Spain (dec.), § 45)"),
                    new PssChoice("", "5. Právo na férovost procesu nezajišťuje, že týž soud nebo odlišné soudy nemohou dojít k odlišným závěrům a vydat odlišná rozhodnutí. ", "(Santos Pinto v. Portugal, § 41)"),
                    new PssChoice("", "6. Není pochybením soudu, pokud vydal odlišná rozhodnutí v situacích, jež byly objektivně skutkově odlišné. ", "(Uçar v. Turkey (dec.))"),
                    new PssChoice("", "7. Požadavek, aby byl účastník řízení před kasačním soudem zastupován specializovaným právníkem, sám o sobě není v rozporu s článkem 6. ", "(G.L. a S.L. proti Francii (rozh.); Tabor proti Polsku, § 42)"),
                    new PssChoice("", "8. Rovnost zbraní neznamená, že účastník řízení má právo na to, aby mu ještě před jednáním byla sdělena stanoviska, s nimiž dosud nebyl seznámen druhý účastník řízení, soudce zpravodaj nebo soudci nalézacího soudu. ", "(Kress proti Francii, § 73)"),
                    new PssChoice("", "9. Šlo-li v řízení o položení předběžné otázky Evropskému soudnímu dvoru, právo na férovost procesu vám negarantuje právo na to, aby byla jiným soudem, národním nebo Evropským soudním dvorem, posouzena tato předběžná otázka. ", "(Coëme and Others v. Belgium, § 114; Acar and Others v. Turkey (dec.), § 43)"),
                    new PssChoice("", "10. Šlo-li v řízení o položení předběžné otázky, vznesl jste žádost o posouzení této předběžné otázky u jiného soudu, ale nutnost posouzení této předběžné otázky jste výslovně nezdůvodnil. ", "(John v. Germany (dec.); Somorjai v. Hungary, § 60)"),
                    new PssChoice("", "11. Šlo-li v řízení o položení předběžné otázky, to, proč předběžná otázka nebyla soudem u jiného soudu vznesena, byla prvně uvedeným soudem řádně zdůvodněna, a to ve světle faktických skutečností a i celkového průběhu soudního řízení. ", "(Dotta v. Italy (dec.); Herma v. Germany (dec.); (Harisch v. Germany, § 42; Repcevirág Szövetkezet v. Hungary, § 59; Krikorian v. France (dec.), § 99)"),
                    //new PssChoice("", "", "", false),
                ],
                false
            ),
        ]
    ),
    new PssSection(
        "VI.    PORUŠENÍ POVINNOSTI ŘÁDNÉHO ODŮVODNĚNÍ",
        "Záruky zakotvené v článku 6 odst. 1 zahrnují i povinnost soudů řádně odůvodňovat svá rozhodnutí [1]." +
        " Odůvodněné rozhodnutí je pro účastníky řízení důkazem o tom, že jejich věc byla skutečně projednána. " +
        "Ačkoli má vnitrostátní soud určitý prostor pro uvážení při volbě argumentů a připouštění důkazů, je povinen " +
        "vysvětlit svoji činnost tím, že svá rozhodnutí odůvodní [2]. " +
        "\n" +
        "\n" +
        "Odůvodnění musí účastníkům řízení umožňovat účinné využití případného práva na odvolání [3]. Rozsah, v jakém se tato povinnost " +
        "odůvodnit rozhodnutí uplatní,se může lišit podle povahy soudního rozhodnutí [4] a může být určen pouze ve světle okolností daného " +
        "případu: je nezbytné vzít v úvahu mimo jiné různorodost podání, jež může účastník řízení před soudem činit, a " +
        "existující rozdíly mezi smluvními státy, pokud jde o zákonná ustanovení, obyčejová pravidla, právní názory " +
        "a vyhlašování a vyhotovování rozsudků [5].",
        "[1] (H. proti Belgii, § 53); [2] (Suominen proti Finsku, § 36); [3] (Hirvisaari proti Finsku, § 30 in fine)" +
        "[4] (Ruiz Torija proti Španělsku, § 29; Hiro Balani proti Španělsku, § 27); [5] (Ruiz Torija proti Španělsku, § 29; " +
        "Hiro Balani proti Španělsku, § 27)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("cs.6.1", "1. Odůvodnění poskytnuté správním orgánem ve správním řízení bylo příliš stručné a obecné, takže dotčenému účastníku řízení neumožnilo řádně odůvodněnou žalobou napadnout jeho závěry, a soudy rozhodující o skutkových otázkách účastníku neumožnily předložit argumenty na podporu jeho tvrzení. "," (Hentrich proti Francii, § 56)"),
                    new PssChoice("cs.6.2", "2. Pokud existuje mechanismus pro postoupení věci k rozhodnutí o předběžné otázce a vnitrostátní soud návrh na toto postoupení zamítne, došlo tím k porušení práva na spravedlivý proces účastníka řízení, pokud se tak stalo v jedné z následujících situací (i) pokud byl návrh na podání předběžné otázky zamítnut, ačkoli příslušná pravidla nepřipouštějí žádné výjimky ze zásady postoupení k rozhodnutí o předběžné otázce ani alternativy k ní, nebo (ii) pokud zamítavé rozhodnutí bylo založeno na jiných důvodech, než které stanoví příslušná pravidla, nebo (iii) pokud zamítavé rozhodnutí nebylo řádně odůvodněno v souladu s uvedenými pravidly. ","(Ullens de Schooten a Rezabek proti Belgii, § 57–67)"),
                    new PssChoice("cs.6.3", "3. Odvolací soud při zkoumání přiměřenosti omezení přístupu k soudu nevzal v úvahu procesní chyby, které se udály v průběhu řízení v prvním stupni a které vám bránily v přístupu do řízení a účastník řízení byl proto nucen nést nadměrné břemeno z důvodu těchto chyb soudu. "," (Zubac v. Croatia [GC], [GC], §§ 90-95, § 119)"),
                    new PssChoice("cs.6.4", "4. Odůvodnění rozhodnutí soudu musí účastníkům řízení umožňovat účinné využití případného práva na odvolání, což se však nestalo. "," (Hirvisaari v. Finland, § 30 in fine)"),
                    new PssChoice("cs.6.5", "5. Soud nereagoval výslovným a konkrétním odůvodněním na hlavní argumenty účastníka řízení předložené soudu. "," (Buzescu proti Rumunsku, § 67; Donadze proti Gruzii, § 35)"),
                    new PssChoice("cs.6.6", "6. Dovolací soud odmítl dovolání účastníka jako nepřípustné, aniž by nepřípustnost takového odvolání podrobně zdůvodnil. "," (Kukkonen v. Finland (no. 2), § 24; Bufferne v. France (dec.))"),
                    new PssChoice("cs.6.7", "7. Existuje dlouhodobě ustálená judikatura, avšak nejvyšší soud se od ní odklonil, což však podrobně neodůvodnil, čímž porušil právo účastníka řízení na řádně odůvodněné rozhodnutí. "," (Petko Petkov proti Bulharsku, § 32–34)"),
                    new PssChoice("cs.6.8", "8. Je porušením práva na spravedlivý proces, pokud se rozhodnutí odvolacího soudu opíralo, byť jen podpůrně, o zákon, který byl přijat v průběhu řízení a měl vliv na jeho výsledek. ","(Anagnostopoulos a ostatní proti Řecku, § 20–21)"),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Článek 6 odst. 1 nevyžaduje, aby odvolací soud podrobněji odůvodnil rozhodnutí, jímž na základě konkrétního zákonného ustanovení nepřipustí dovolání pro jeho nedostatečné vyhlídky na úspěch, aniž by podal bližší vysvětlení. ", "(Burg a ostatní proti Francii, (rozh.); Gorou proti Řecku (č. 2) [velký senát], § 41)"),
                    new PssChoice("", "2. V případě návrhu na připuštění odvolání, které je podmínkou pro projednání věci nadřízeným soudem a případné vynesení rozsudku, nelze článek 6 odst. 1 vykládat tak, že vyžaduje, aby se i na samotné zamítnutí návrhu vztahoval požadavek podrobného odůvodnění. ", "(Kukkonen proti Finsku č. 2, § 24; viz též Bufferne proti Francii (rozh.))"),
                    new PssChoice("", "3. Při zamítnutí odvolání může odvolací soud v zásadě jen potvrdit odůvodnění rozhodnutí nižšího soudu, aniž by tím bylo porušeno právo na spravedlivý proces. ", "(Garcia Ruiz proti Španělsku [velký senát], § 26 – naopak viz Tatishvili proti Rusku, § 62)"),
                    new PssChoice("", "4. Požadavky právní jistoty a ochrany legitimních očekávání v sobě nezahrnují právo na ustálenou judikaturu. Vývoj judikatury sám o sobě není v rozporu s řádným výkonem spravedlnosti, neboť nemožnost uplatňovat dynamický a flexibilní přístup by bránila jakýmkoli reformám či zlepšením. Sjednocení judikatury si však může vyžádat jistý čas, a lze tedy tolerovat určitá období, kdy je judikatura rozkolísaná, aniž by tím byla ohrožena právní jistota. ", "(Unédic proti Francii, § 74; Atanasovski proti „Bývalé jugoslávské republice Makedonii“, § 38; (Nejdet Şahin a Perihan Şahin proti Turecku [velký senát], § 83; Albu a ostatní proti Rumunsku, § 36 a 40–43)"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "VII. ABSENCE PRÁVA NA VEŘEJNÉ SLYŠENÍ A NA VEŘEJNÉ VYHLÁŠENÍ SOUDNÍHO ROZHODNUTÍ",
        "Právo na „veřejné slyšení“ znamená nárok na „ústní slyšení“ [1] ohledně zkoumání právních otázek a " +
        "důležitých faktických otázek [2], nebo ohledně posouzení, zda orgány správně zjistily skutkový stav [3]. " +
        "Účastníci sporu mají právo na veřejné, resp. ústní slyšení, protože je to chrání před netransparentním výkonem spravedlnosti, " +
        "jež je skryta veřejnosti." +
        "\n" +
        "\n" +
        "Veřejné, resp. ústní slyšení, stran sporu přispívá k dosažení účelu spravedlivého procesu [4]. " +
        "Veřejné slyšení je zárukou proti svévoli soudního rozhodování a zárukou důvěry v soudy [5]. Pokud byly strany sporu v důsledku" +
        " absence veřejné, resp. ústního slyšení, zbaveny práva účinně prezentovat své argumenty, porušily soudní orgány právo stran na spravedlivý proces" +
        " [6]. " +
        "\n" +
        "\n" +
        "Článek 6 odst. 1 stanoví, že „[r]ozsudek musí být vyhlášen veřejně“, z čehož by na první pohled vyplývalo," +
        " že je nezbytné jeho hlasité přečtení při veřejném jednání. Evropský soud pro lidská práva však dospěl k závěru," +
        " že slučitelný s článkem 6 odst. 1 může být i „jiný způsob zveřejnění rozsudku“ [7]. " +
        "Pro zjištění, zda jsou formy zveřejnění upravené vnitrostátním právem slučitelné s požadavkem na veřejné vyhlášení " +
        "rozsudku ve smyslu článku 6 odst. 1, „musí být způsob, jímž má být rozsudek podle vnitrostátního práva zveřejněn…" +
        " v každém případě posouzen ve světle zvláštních rysů daného řízení a s ohledem na předmět a účel článku 6 odst. 1“ [8].",
        "[1] (Fredin v. Švédsko (č. 2), § 21–22 ; Allan Jacobsson proti Švédsku (č. 2), § 46; Göç proti Turecku [GC], § 47; " +
        "Selmani a další v. Bývalá jugoslávská republika Makedonie, § 37–39)); [2] (Fischer v. Rakousko, § 44); [3] (Malhous v. Česká" +
        "republika [GC], § 60.); [4] (Malhous v. the Czech Republic [GC], §§ 55-56); [5] (Fazliyski v. Bulgaria, § 69, Pretto and Others " +
        "v. Italy, § 21); [6]  Altay v. Turkey (no. 2), §§ 78-81); [7] (Moser proti Rakousku, § 101); [8] (Pretto a ostatní proti Itálii, § 26, " +
        "a Axen proti Německu, § 31)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na spravedlivý proces, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("cs.7.1", "1. Je porušením práva na spravedlivý proces, pokud ústní jednání neproběhlo alespoň v jedné instanci a nebyly dány výjimečné okolnosti, které by upuštění od tohoto jednání odůvodňovaly. "," (Fischer proti Rakousku, § 44; Salomonsson proti Švédsku, § 36)"),
                    new PssChoice("cs.7.2", "2. Právo na ústní slyšení sice bylo přiznáno alespoň v jedné soudní instanci, avšak toto ústní slyšení se nemohlo týkat zkoumání právních otázek a důležitých faktických otázek nebo posouzení, zda orgány správně zjistily skutkový stav. "," (Fischer v. Austria, § 44; Salomonsson v. Sweden, § 36; Ramos Nunes de Carvalho e Sá v. Portugal [GC], § 192)"),
                    new PssChoice("cs.7.3", "3. Je porušením práva na spravedlivý proces, pokud nebylo konání ústní jednání v situaci, kdy do pravomoci soudu spadají otázky právní i významné otázky skutkové, nebo posouzení otázky, zda příslušné orgány správně zjistily skutkový stav. "," (Fischer proti Rakousku, § 44; Malhous proti České republice [velký senát], § 60)"),
                    new PssChoice("cs.7.4", "4. Je porušením práva na spravedlivý proces, pokud nebylo konání ústní jednání v situaci, kdy takové jednání bylo nezbytné, aby si soud mohl mimo jiné i tímto způsobem ujasnit některé skutečnosti. "," (Fredin proti Švédsku (č. 2), § 22; Lundevall proti Švédsku, § 39)"),
                    new PssChoice("cs.7.5", "5. Je porušením práva na spravedlivý proces, pokud nebylo konání ústní jednání v situaci, kdy stěžovatel měl být vyslechnut ohledně skutečností týkajících se osobní újmy významných pro výši náhrady - nebo aby si soud mohl mimo jiné i tímto způsobem ujasnit některé skutečnosti. "," (Göç proti Turecku [velký senát], § 51; Lorenzetti proti Itálii, § 33; Fredin proti Švédsku (č. 2), § 22; Lundevall proti Švédsku, § 39)"),
                    new PssChoice("cs.7.6", "6. Pokud soudní orgány poskytly nepřesné nebo neúplné informace o lhůtách pro ústní slyšení nebo předložení tvrzení či důkazů, což znemožnilo dostatečně zohlednit konkrétní okolnosti případu, nesmí příliš přísně uplatňovat příslušná procesní pravidla a judikatura, což však neučinily a příslušná procesní pravidla a judikaturu přísně uplatňovaly. ","(Clavien v. Švýcarsko (dec.) a Gajtani v. Švýcarsko)"),
                    new PssChoice("cs.7.7", "7. Právo na spravedlivý proces je porušeno, pokud stěžovateli nebylo včas doručeno předvolání na ústní jednání. "," (Yakovlev proti Rusku, § 20–22)"),
                    new PssChoice("cs.7.8", "8. Právo na férový proces v situaci, kdy bylo vydáno rozhodnutí soudu v nepřítomnosti, nelze omezit na pouhou naději, závislou na diskrečním posouzení soudu, na přiznání hmotného práva, o kterém tvrdíte, že vám svědčí, avšak k takovému omezení na pouhou naději došlo. "," (Masson a Van Zon v. Nizozemsko , §§ 49-51; Roche v. Spojené království [GC], §§ 122-125; Ankarcrona v. Švédsko (dec.))"),
                    new PssChoice("cs.7.9", "9. Jako vězni vám bylo upřeno právo účastnit se ústního slyšení před civilním soudem nebo při ústním slyšení vznášet otázky."," (Igranov and Others v. Russia, §§ 34-35; Altay v. Turkey (no. 2), § 77)"),
                    new PssChoice("cs.7.10", "10. Jako vězni vám justiční orgány neumožnili vyjádřit se k tomu, zda se chcete účastnit ústního slyšení v civilním řízení, a pokud jste se toho práva výslovně nevzdal, nezajistily vám účast u civilního řízení. "," (Altay v. Turkey (no. 2), § 78; Yevdokimov and Others v. Russia, §§ 33-47)"),
                    //new PssChoice("cs.7.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Není porušením práva na spravedlivý proces, pokud se dotyčná osoba vzdala práva na veřejné projednání (Le Compte, Van Leuven a De Meyere proti Belgii, § 59) z vlastní svobodné vůle, výslovně nebo konkludentně, avšak jednoznačným způsobem, a nikoli v rozporu s významným veřejným zájmem. ", " (Albert a Le Compte proti Belgii, § 35; Le Compte, Van Leuven a De Meyere proti Belgii, § 59; Håkansson a Sturesson proti Švédsku, § 66; Albert a Le Compte proti Belgii, § 35 a Håkansson a Sturesson proti Švédsku, § 67)"),
                    new PssChoice("", "2. Ústní slyšení před soudem se nemusí konat v situaci, kdy žádná ze stran nepopírá faktické skutečnosti a nejsou pochybnosti o důvěryhodnosti těchto faktických skutečností a soud může rozhodnout danou věc na základě tvrzení a listinných důkazů předložených oběma stranami. ", "(Döry v. Sweden, § 37; Saccoccia v. Austria, § 73; Mirovni Inštitut v. Slovenia, § 37)"),
                    new PssChoice("", "3. Ústní slyšení před soudem se nekonalo, protože soud zdůvodnil, že daná část řízení se týkala pouze právních otázek omezeného rozsahu, které nebyly nijak komplexní. ", "(Allan Jacobsson v. Sweden (no. 2), § 49; Valová, Slezák and Slezák v. Slovakia, §§ 65-68; Varela Assalino v. Portugal (dec.); Speil v. Austria (dec.)"),
                    new PssChoice("", "4. Právo na ústní slyšení před soudem nebylo umožněno ve výjimečném případě v řízení, jež muselo být provedeno rychle v důsledku své naléhavosti nutné k ochraně práv jiných osob. ", "(Eker v. Turkey, § 29)"),
                    new PssChoice("", "5. Právo na veřejné slyšení nevylučuje uplatnění zvláštních pravidel týkajících se projednávání utajovaných skutečností a vyloučení veřejnosti v takových případech. ", "(Fazliyski v. Bulgaria, § 69)"),
                    new PssChoice("", "6. Právo na veřejné slyšení umožňuje vyloučení tisku a veřejnosti vyloučeny buď po dobu celého nebo části procesu „v zájmu mravnosti, veřejného pořádku nebo národní bezpečnosti v demokratické společnosti.", "(B. A P. proti Spojenému království, § 39; Zagorodnikov proti Rusku, § 26)"),
                    new PssChoice("", "7. Právo na veřejné slyšení nevylučuje vyloučení tisku a veřejnosti vyloučeny buď po dobu celého nebo části procesu. ", "(B. A P. proti Spojenému království, § 39; Zagorodnikov proti Rusku, § 26)"),
                    new PssChoice("", "8. Právo na veřejné slyšení umožňuje vyloučení tisku a veřejnosti „když to vyžadují zájmy nezletilých nebo ochrana soukromého života účastníků“: zájmy nezletilých nebo ochrana soukromého života účastníků řízení to vyžadují například v řízeních o pobytu nezletilých po odloučení rodičů ve sporech mezi členy téže rodiny. ", "(B. A P. proti Spojenému království, § 38)"),
                    new PssChoice("", "9. Absence jednání před soudem druhé a třetí instance není porušením práva na spravedlivý proces za předpokladu, že jednání proběhlo v první instanci. Řízení o přípustnosti opravného prostředku a řízení pouze o právních otázkách (na rozdíl od řízení o otázkách skutkových) tedy může vyhovět požadavkům článku 6 odst. 1, i když dotčený účastník neměl možnost být osobně vyslechnut odvolacím nebo kasačním soudem. ", "(Helmers proti Švédsku, § 36, naproti tomu viz § 38–39; Miller proti Švédsku, § 30)"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "VIII.\tNEPŘIMĚŘENÁ DÉLKA ŘÍZENÍ",
        "Každý má právo na konečné rozhodnutí ve sporu, jenž se týká civilních práv a povinností v rozumné době [1]." +
        " Co je “rozumná doba řízení” a co již je “nepřiměřená délka”) není obecně stanoveno, a liší se případ od případu. " +
        "Požadavkem na projednání záležitostí v „přiměřené lhůtě“ Úmluva podtrhuje význam výkonu spravedlnosti bez průtahů, " +
        "které by mohly ohrozit její účinnost a důvěryhodnost [2]. Pouze průtahy přičitatelné státu mohou být důvodem k závěru o" +
        " nesplnění požadavku „přiměřené lhůty“ [3]. Stát je zodpovědný za všechny své orgány: nikoli pouze za soudní orgány, " +
        "nýbrž za všechny veřejné instituce [4]." +
        "\n" +
        "\n" +
        "Pokud jde o počátek doby přiměřené délky řízení, tato doba obvykle začíná běžet okamžikem, kdy je podána žaloba k příslušnému soudu [5], " +
        "ledaže je zahájení soudního řízení podmíněno předchozím podáním ke správnímu orgánu; v takovém případě může rozhodné období" +
        " zahrnovat i povinné předběžné správní řízení [6]. Řízení o postoupení předběžné otázky Evropskému soudnímu dvoru se nezapočítává [7]." +
        "\n" +
        "\n" +
        "Článek 6 odst. 1 zavazuje smluvní státy k tomu, aby své právní systémy uspořádaly způsobem, který umožní soudům plnit různé požadavky " +
        "na ně kladené. Článek 6 odst. 1 může být použitelný také na řízení, jež sice nemá výlučně soudní charakter, " +
        "avšak úzce souvisí s dohledem soudního orgánu. Takovým řízením bylo například nesporné řízení o rozdělení nemovité věci, které bylo vedeno před " +
        "dvěma notáři, avšak nařízeno a schváleno bylo soudem [8]. Délka řízení před notáři může být proto zohledněna při určení přiměřené lhůty." +
        "\n" +
        "\n" +
        "Pro účely výpočtu doby přiměřené délky řízení je proto nutné považovat za nedílnou součást řízení i výkon rozhodnutí vydaného kterýmkoli soudem [9]. " +
        "Lhůta běží, dokud není právo uplatňované v řízení skutečně vykonáno [10]. Konečně, pokud jde přistoupení vedlejších účastníků do " +
        "občanskoprávního řízení, je třeba rozlišovat následující situace: pokud stěžovatel přistoupil do vnitrostátního řízení pouze sám za sebe, " +
        "začíná doba přiměřené délky řízení běžet od tohoto dne, zatímco pokud oznámil úmysl pokračovat v řízení jako dědic, " +
        "může namítat celkovou délku řízení [11].",
        "[1] (Comingersoll S.A. v. Portugal [GC], § 24; Lupeni Greek Catholic Parish and Others v. Romania [GC], § 142); " +
        "[2] (H. proti Francii, § 58; Katte Klitsche de la Grange proti Itálii, § 61); (Buchholz proti Německu, § 49; Papageorgiou proti Řecku, " +
        "§ 40; Humen proti Polsku [velký senát], § 66); [4] (Martins Moreira proti Portugalsku, § 60); " +
        "[5] (Poiss proti Rakousku, § 50; Bock proti Německu, § 35); " +
        "[6] (König proti Německu, § 98; X. proti Francii, § 31; Kress proti Francii [velký senát], § 90); [5] (Pafitis a ostatní proti Řecku, § 95); " +
        "[8] (Siegel proti Francii, § 33–38)" +
        "[9] (Martins Moreira proti Portugalsku, § 44; Silva Pontes proti Portugalsku, § 33; Di Pede proti Itálii, § 24); [10] " +
        "(Estima Jorge proti Portugalsku, § 36–38); [11] (Scordino proti Itálii (č. 1), § 220)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na spravedlivý proces, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [
                    new PssSectionPart(
                        "a. Řízení trvá VÝRAZNĚ déle než je průměrná délka civilního soudního řízení v ČR, jež je cca. 150 dní (Údaj za rok 2019 zahrnující civilní soudní řízení ve všech stupních podle dokumentu Evropské komise – EU Justice Scoreboard 2021.)",
                        [],
                        [
                            new PssChoice("cs.8.1", "1. Řízení VÝRAZNĚ déle než je průměrná délka civilního soudního řízení v ČR, jež je cca 150 dní, proto, že dochází bez legitimního důvodu průtahům na straně orgánu veřejné moci, jež řízení vede, zejména soudu. "," (Frydlender proti Francii [velký senát], § 43)"),
                            new PssChoice("cs.8.2", "2. Řízení VÝRAZNĚ déle než je průměrná délka civilního soudního řízení v ČR, jež je cca 150 dní, proto, dochází bez legitimního důvodu průtahům na straně orgánů, jež se na řízení podílejí, např. u soudního znalce. ","(Capuano proti Itálii, § 30–31; Versini proti Francii, § 29; Sürmeli proti Německu [velký senát], § 129)."),
                            new PssChoice("cs.8.3", "3. Řízení VÝRAZNĚ déle než je průměrná délka civilního soudního řízení v ČR, jež je cca 150 dní, aniž by důvod, proč k průtahům dochází byl známý či vysvětlitelný. ","(Beaumartin proti Francii, § 33)"),
                            new PssChoice("cs.8.4", "4. Řízení trvá dlouho VÝRAZNĚ déle než je průměrná délka civilního soudního řízení v ČR, jež je cca 150 dní proto, že vyšší soudy pro opakované chyby musely rušit rozhodnutí nižších soudů v dané věci. ","(Lupeni Greek Catholic Parish and Others v. Romania [GC], § 147)"),
                            new PssChoice("cs.8.5", "5. Řízení trvá dlouho VÝRAZNĚ déle než je průměrná délka civilního soudního řízení v ČR, jež je cca 150 dní proto, že soud věnoval nepřiměřené úsilí otázce stěžovatelova duševního stavu, ačkoli existovalo pět posudků potvrzujících stěžovatelovo duševní zdraví a byly zamítnuty dvě žádosti o ustanovení opatrovníka; soudní řízení kromě toho trvalo více než devět let. ","(Bock proti Německu, § 47)"),
                            new PssChoice("cs.8.6", "6. Řízení trvá dlouho VÝRAZNĚ déle než je průměrná délka civilního soudního řízení v ČR, jež je cca 150 dní z důvodu opakované změny soudce, který se musel vždy nejprve důkladně seznámit se spisem. ","(Lechner a Hess proti Rakousku, § 58)"),
                            //new PssChoice("cs.8.", ""),
                        ]
                    ),
                    new PssSectionPart(
                        "b. Soudní řízení ve zvláštních případech, které má probíhat rychle, trvá zhruba jako je průměr civilního řízení nebo o něco déle.",
                        [],
                        [
                            new PssChoice("cs.8.7", "7. Zvláštní pozornost rychlosti soudního řízení je třeba věnovat případům, ve kterým se jedná o statusové věci fyzických osob a jejich způsobilosti k právním úkonům. "," (Bock v. Germany, § 49; Laino v. Italy [GC], § 18; Mikulić v. Croatia, § 44)"),
                            new PssChoice("cs.8.8", "8. Případy týkající se opatrovnictví dětí musí být řešeny s obzvláštní rychlosti stejně jako případy týkající se péče o dítě a styků rodičů s dětmi. ","(Hokkanen v. Finland, § 72; Niederböster v. Germany, § 39; Paulsen-Medalen and Svensson v. Sweden, § 39; Laino v. Italy [GC], § 22)"),
                            new PssChoice("cs.8.9", "9. Pracovně právní záležitosti, včetně práv vykonávat profesi vyžadují zrychlené rozhodování, závisí-li na nich obživa dotčené osoby. "," (Vocaturo v. Italy, § 17; Ruotolo v. Italy, § 117; and Frydlender v. France [GC], § 45), Thlimmenos v. Greece [GC], §§ 60 and 62), König v. Germany, § 111), Garcia v. France, § 14), Buchholz v. Germany, § 52; Frydlender v. France [GC], § 45), Obermeier v. Austria, § 72), Sartory v. France, § 34, Doustaly v. France, § 48)"),
                            new PssChoice("cs.8.10", "10. Rychlé rozhodování vyžadují rovněž spory ohledně důchodů a dávek zajišťujících živobytí. ","(Borgese v. Italy, § 18)"),
                            new PssChoice("cs.8.11", "11. Obzvláštní pozornost rychlosti soudního řízení je třeba věnovat případům, kdy žadatel je nevyléčitelně nemocný a má zkrácenou naději dožití. "," (X v. France, § 47; Pailot v. France, § 68; A. and Others v. Denmark, §§ 78-81)"),
                            new PssChoice("cs.8.12", "12. Zvláštní pozornost rychlosti soudního řízení je třeba věnovat případům, kdy si jednotlivec stěžuje na násilné chování policejních příslušníků. "," (Caloc v. France, § 120)"),
                            new PssChoice("cs.8.13", "13. Vyšší rychlost soudního řízení je rovněž nutno vyžadovat tam, kde se jedná o přiznání či zvýšení invalidního důchodu v situaci, kdy tento příjem byl nejvýznamnějším příjmem žadatele a jeho stav se rapidně zhoršoval. ","(Mocié v. France, § 22)"),
                            new PssChoice("cs.8.14", "14. Vyšší rychlost soudního řízení má své místo tam, kde šlo o civilní nárok na odškodnění osoby poškozené z trestného činu, které k okamžiku spáchání trestného činu bylo 65 let. "," (Codarcea v. Romania, § 89)"),
                            new PssChoice("cs.8.15", "15. Soudní řízení by mělo být zrychlené, pokud je jeho předmětem právo na vzdělání. "," (Oršuš and Others v. Croatia [GC], § 109)"),
                            new PssChoice("cs.8.16", "16. Zvlášť svědomitého postupu ze strany příslušných soudních orgánů bylo zapotřebí při šetření stížnosti osoby, která tvrdila, že se stala terčem policejního násilí. ","(Caloc proti Francii, § 120)"),
                            //new PssChoice("cs.8.", ""),
                        ]
                    )
                ]
            ),
            new PssSectionPart(
                "B. Pokud případ trvá dlouho z jednoho či více z následujících důvodů, pak si nelze stěžovat na nepřiměřenou délku řízení.",
                [
                    new PssSectionPart(
                        "a. Soudní řízení trvá dlouho, protože případ je složitý z jednoho či více následujících důvodů:",
                        [],
                        [
                            new PssChoice("", "1. Případ zahrnuje více stran sporu.", "(H. v. the United Kingdom, § 72)"),
                            new PssChoice("", "2. Případ obnáší mnoho důkazů a složité postupy při dokazování. ", "(Humen v. Poland [GC], § 63)"),
                            new PssChoice("", "3. Případ je právně složitý, protože chybí domácí soudní praxe nebo je tato soudní praxe rozporuplná nebo je nutné žádat o odpověď na předběžnou otázku u Evropského soudního dvora. ", "(Satakunnan Markkinapörssi Oy and Satamedia Oy v. Finland [GC], § 212)"),
                            new PssChoice("", "4. Případ je skutkově složitý a vyžaduje si vyhotovení mnoha znaleckých posudků. "," (Nicolae Virgiliu Tănase v. Romania [GC], (§ 210)"),
                        ],
                        false
                    ),
                    new PssSectionPart(
                        "b. Soudní řízení trvá dlouho, protože vy způsobujete či jste způsobili průtahy soudního řízení.",
                        [],
                        [
                            new PssChoice("", "5. Průtahy jsou způsobeny vámi jako účastníkem řízení.  Za průtahy způsobené účastníkem však nelze považovat to, že řádně využívá opravné prostředky nebo pokud jsou způsobeny zdravotním stavem účastníka. "," (Comingersoll S.A. v. Portugal [GC]; Frydlender v. France [GC], § 43; Sürmeli v. Germany [GC], § 128; Lupeni Greek Catholic Parish and Others v. Romania [GC], § 143; Erkner and Hofauer v. Austria, § 68; Nicolae Virgiliu Tănase v. Romania [GC], § 211)"),
                            new PssChoice("", "6. Průtahy jsou způsobeny vámi jako účastníkem řízení v důsledku formálních chyb ve vašich podáních, které je třeba napravovat nebo vám přičitatelné nedbalosti při provádění vašich procesních úkonů. ", "(Vernillo v. France, § 34, Acquaviva v. France, § 61; (Keaney v. Ireland, § 95)"),
                            new PssChoice("", "7. Průtahy jsou způsobeny vámi jako účastníkem řízení v důsledku toho, že používáte zdržovací taktiku. ", "(Unión Alimentaria Sanders S.A. v. Spain, § 35)"),
                            new PssChoice("", "8. Průtahy jsou způsobeny vámi jako účastníkem řízení v důsledku toho, že často měníte právního zástupce. ", "(König v. Germany, § 103)"),
                            new PssChoice("", "9. Průtahy jsou způsobeny vámi jako účastníkem řízení v důsledku toho, že jste v rámci řízení podal velké množství žádostí. ", "(Pereira da Silva v. Portugal, §§ 76-79)"),
                            new PssChoice("", "10. Případ trvá dlouho, protože vaše žaloba byla podána u nepříslušného soudu. ", "(Beaumartin v. France, § 33)"),
                            new PssChoice("", "11. Případ trvá dlouho v důsledku předchozího vyjednávání o smír. ", "(Pizzetti v. Italy, § 18; Laino v. Italy [GC], § 22)"),
                        ],
                        false
                    )
                ],
                [],
                false
            )
        ]
    )
];