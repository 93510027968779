import {Card} from "react-bootstrap";
import {MutableRefObject} from "react";
import HtmlToRtfBrowser from "html-to-rtf-browser";

export default function DownloadRTF(ref: MutableRefObject<typeof Card.Body | null>, id: number): void {
    const rtfFactory = new HtmlToRtfBrowser();
    let htmlString = (ref.current as unknown as HTMLElement).innerHTML;
    // hack no-css paragraphs and headers margins
    htmlString = htmlString.replaceAll('<p>', '<p></p><p>');
    htmlString = htmlString.replace('<h1>', '<p></p><h1>');
    htmlString = htmlString.replace('<h2>', '<p></p><h2>');
    htmlString = htmlString.replace('<h3>', '<p></p><h3>');
    htmlString = htmlString.replace('<h4>', '<p></p><h4>');
    // get html as rtf string
    const rtfString = rtfFactory.convertHtmlToRtf(htmlString);

    const blob = new Blob([rtfString], {type: 'application/rtf;charset=utf-8'});
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `podani-${id}.rtf`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
}