import {PssChoice, PssSection, PssSectionPart} from "../Model/PssDatabase";

export const TrestSoudceDb: PssSection[] = [
    new PssSection(
        "I. Na koho a od kdy se vztahují záruky spravedlivého procesu v trestních věcech podle čl. 6 (1), (2) a (3) Evropské úmluvy o lidských právech?",
        "Východiskem pro posouzení použitelnosti trestního aspektu článku 6 Úmluvy jsou kritéria uvedená ve věci " +
        "Engel a další v. Nizozemsko (§§ 82–83), a to (1) klasifikace daného řízení či opatření podle vnitrostátního (českého)" +
        " práva, (2) povaha trestného činu, přečinu či přestupku, a (3) přísnost trestu, jehož uložení hrozí dotyčné osobě, " +
        "za uvedený trestný čin, přečin či přestupek. " +
        "\n" +
        "\n" +
        "První kritérium je jen orientační a slouží pouze jako výchozí bod: pokud vnitrostátní právo klasifikuje daný čin" +
        " jako trestný, požadavky na spravedlivý proces podle čl. 6 (1) – (3) Úmluvy se použijí vždy. Nejedná-li se o trestní řízení," +
        " je třeba pro závěr o použitelnosti požadavků podle čl. 6 (1) – (3) Úmluvy přezkoumat věcnou podstatu řízení, " +
        "o kterém se jedná [1].  " +
        "\n" +
        "\n" +
        "Druhé kritérium ohledně povahy trestného činu, přečinu či přestupku znamená, že požadavky uvedené čl. 6, odst. 1 – 3" +
        " Úmluvy se použijí, pokud právní norma, jenž takový trestný čin, přečin či přestupek vymezuje je obecného závazného" +
        " charakteru [2] nebo, pokud řízení započaté v důsledku porušení uvedené normy je orgánem veřejné moci [3], nebo," +
        " pokud má uvedená právní norma odrazovat od určitého chování nebo určité chování trestat [4], nebo pokud uvedená právní norma " +
        "chrání obecné společenské zájmy, jež jsou obvykle předmětem trestněprávní regulace [5], nebo pokud je uvalení sankce závislé" +
        " na předchozím uznání viny [6] či konečně, zda je v jiných členských státech Rady Evropy daný čin kvalifikován jako trestný [7]. " +
        "\n" +
        "\n" +
        "Třetí kritérium je doplňující a je určeno odkazem na maximální možnou sankci, kterou vnitrostátní právo za uvedený čin stanoví " +
        "[8]. Čím citelnější je sankce, tím více pravděpodobnější je použití čl. 6, odst. 1 – 3 Úmluvy. Pokud se neaplikují" +
        " požadavky spravedlivého procesu uvedené čl. 6, odst. 1 – 3 Úmluvy vzhledem k trestním aspektům, tj. zejména trestnímu řízení, " +
        "mohou se nicméně aplikovat požadavky spravedlivého procesu podle 6, odst. 1 vzhledem k civilním aspektům, tj. zejména civilního" +
        " řízení či obdobných řízení)." +
        "\n" +
        "\n" +
        "Požadavky spravedlivého procesu uvedené čl. 6, odst. 1 – 3 Úmluvy se vztahují k celému trestnímu " +
        "řízení, včetně přípravného řízení, [9], výkonu trestu, včetně řízení o amnestii [10], na řízení o opravných prostředcích" +
        " [11] či řízení před Ústavním soudem v návaznosti na trestní či obdobné řízení, na něž se vztahují požadavky čl. 6, odst. " +
        "1 – 3 Úmluvy [12].",
        "[1] (Gestur Jónsson and Ragnar Halldór Hall v. Iceland [GC], §§ 85 and 77-78); [2] (Bendenoun v. France, § 47);" +
        "[3] (Benham v. the United Kingdom, § 56); [4] (Öztürk v. Germany, § 53; Bendenoun v. France, § 47);" +
        "[5] (Produkcija Plus Storitveno podjetje d.o.o. v. Slovenia, § 42); [6] (Benham v. the United Kingdom, § 56);" +
        "[7] (Öztürk v. Germany, § 53); [8] (Campbell and Fell v. the United Kingdom, § 72; Demicoli v. Malta, § 34);" +
        "[9] (Dvorski v. Chorvatsko, § 76); [10] (Montcornet de Caumont v. Francie (dec.));" +
        "[11] (Meftah a další v. Francie [GC], § 40); [12] (Gast a Popp v. Německo, §§ 65–66; Caldas Ramírez de Arrellano v. Španělsko (dec.))",
        [
            new PssSectionPart(
                "A. Situace, kdy lze záruky spravedivého procesu podle čl. 6 odst. 1-3 Úmluvy použít:",
                [],
                [
                    new PssChoice("ts.1.1", "1. Požadavky spravedlivého procesu (uvedené v čl. 6, odst. 1 – 3 Úmluvy) se vztahují na trestní řízení bez ohledu na jeho fázi a bez ohledu na typ činu, který je v rámci trestního řízení řešen a bez ohledu, zda za takový čin hrozí trest odnětí svobody. ","(Negulescu v. Romania, §§ 39-42, and Buliga v. Romania, §§ 41-44; Nicoleta Gheorghe v. Romania, § 26)"),
                    new PssChoice("ts.1.2", "2. Požadavky spravedlivého procesu (uvedené v čl. 6, odst. 1 – 3 Úmluvy) se vztahují na úkony učiněné orgánem činným v trestním řízení, a to i před okamžikem než formálně podle českého právního řádu započalo trestní řízení od chvíle, kdy vůči dotčené osobě byl učiněn úkon, jenž naplňuje definici “vznesení obvinění z trestného činu”, tak jak tuto definici vymezuje čl. 6 Úmluvy, například v případě, kdy došlo:", "" +
                        "\n" +
                        "- k obvinění dotčené osoby z trestného činu; \n" +
                        "(Pélissier and Sassi v. France [GC], § 66; Pedersen and Baadsgaard v. Denmark [GC], § 44)\n" +
                        "\n" +
                        "- k úkonům v rámci řízení, jež předcházely trestnímu řízení, avšak v trestní řízení poté vyústily;\n" +
                        "(Dvorski v. Croatia, § 76)\n" +
                        "\n" +
                        "- k zadržení dotčené osoby pro podezření ze spáchání trestného činu;\n" +
                        "(Heaney and McGuinness v. Ireland, § 42; Brusco v. France, §§ 47-50)\n" +
                        "\n" +
                        "- k výslechu dotčené osoby ohledně jejího zapojení do činnosti představující podezření ze spáchání trestného činu, " +
                        "i když v rámci daného výslechu byla označována za svědka;\n" +
                        "(Aleksandr Zaichenko v. Russia, §§ 41-43; Yankov and Others v. Bulgaria, § 23; " +
                        "Schmid-Laffer v. Switzerland, §§ 30-31; Stirmanov v. Russia, § 39, Kalēja v. Latvia, §§ 36-41)\n" +
                        "\n" +
                        "- k řízení ve věci trestné či obdobné činnosti mladistvých.\n" +
                        "(Blokhin v. Russia [GC] (§§ 179-182)"),
                    new PssChoice("ts.1.3", "3. Požadavky spravedlivého procesu uvedené včl. 6, odst. 1 – 3 Úmluvy se vztahují i na úkony spočívající:", "" +
                        "\n" +
                        "- v zaslání dopisu státním zástupcem s výzvou k uzavření provozovny a požadující po něm " +
                        "zaplacení finanční částky jako narovnání umožňující se dané osobě vyhnout obvinění z trestného činu " +
                        "a trestnímu řízení;\n" +
                        "(Deweer v. Belgium, §§ 41-47)\n" +
                        "\n" +
                        "- v obvinění z porušení pravidel silničního procesu, za něž ji hrozí pokuta nebo omezení práva řízení dopravního prostředku, " +
                        "jako například trestné body nebo zákaz řízení;\n" +
                        "(Lutz v. Germany, § 182; Schmautzer v. Austria; Malige v. France; Marčan v. Croatia, § 33; " +
                        "Igor Pascari v. the Republic of Moldova, §§ 20-23)\n" +
                        "\n" +
                        "- v obvinění z porušení právních předpisů v oblasti sociálního zabezpečení, jako například „práce načerno“ při souběžném " +
                        "pobírání sociálních dávek;\n" +
                        "(Hüseyin Turan v. Turkey, §§ 18-21)\n" +
                        "\n" +
                        "- v obvinění ze správního deliktu spočívajícím v šíření materiálů propagující etnickou nenávist, za něž hrozí trest " +
                        "napomenutí a konfiskace zmíněných materiálů;\n" +
                        "(Balsytė-Lideikienė v. Lithuania, § 61)\n" +
                        "\n" +
                        "- v obvinění ze správního deliktu nepovoleného shromáždění;\n" +
                        "(Kasparov and Others v. Russia, § 39-45; Mikhaylova v. Russia, §§ 50-75)\n" +
                        "\n" +
                        "- celním deliktu;\n" +
                        "(Salabiaku v. France) \n" +
                        "\n" +
                        "- v udělení sankcí soudem v rozpočtových a finančních záležitostech;\n" +
                        "(Guisset v. France)\n" +
                        "\n" +
                        "- na řízení, jež může vést k odstranění stavby, jež byla postavena bez stavebního povolení či v rozporu s jinými předpisy;\n" +
                        "(Hamer v. Belgium, § 60)\n" +
                        "\n" +
                        "- na řízení ohledně pohrdání soudem, je-li považováno za trestný čin.\n" +
                        "(Mariusz Lewandowski v. Poland, §§ 29-30)"),
                    //new PssChoice("ts.1.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Situace, kdy záruky spravedivého procesu podle čl. 6 odst. 1-3 Úmluvy použít nelze:",
                [],
                [
                    new PssChoice("", "1. Článek 6 nezaručuje právo na to, aby fyzická osoba či právnická osoba nebyla vyšetřována či stíhána orgány činnými v trestním řízení. ", "(International Bank for Commerce and Development AD and Others v. Bulgaria, § 129)",),
                    new PssChoice("", "2. Článek 6 rovněž nezaručuje právo na vydání rozsudku ohledně trestních obvinění, která byla vůči účastníkovi řízení vznesena a ohledně nichž účastník řízení očekává, že z nich bude rozsudkem soudu zproštěn, pokud absence vydání takového rozsudku nemá nezvratitelné škodlivé důsledky na účastníky řízení.", "(Kart v. Turkey [GC], § 113)"),
                    new PssChoice("", "3. Požadavky spravedlivého procesu uvedené (v čl. 6, odst. 1 – 3 Úmluvy) se nevztahují na osobu obviněného či obžalovaného v situaci, kdy byl v trestním řízení zproštěn viny či trestní řízení přerušeno v jeho prospěch a kdy ke stížnosti na porušení čl. 6 odst. 1 – 3 došlo až po takovém zproštění či přerušení, včetně odložení výroku o výkonu trestu. ", "(Khlyustov v. Russia, § 103; Kerman v. Turkey, §§ 100-106)"),
                    new PssChoice("", "4. Požadavky spravedlivého procesu uvedené (v čl. 6, odst. 1 – 3 Úmluvy) se nevztahují na běžné daňové řízení, které nemá “trestní souvislosti”. ", "(Ferrazzini v. Italy [GC], § 20)"),
                    new PssChoice("", "5. Požadavky spravedlivého procesu uvedené (v čl. 6, odst. 1 – 3 Úmluvy) se nevztahují na poškozené, pokud jde o náhradu škody způsobenou trestným činem. ", "(Perez v. France [GC], § 70; Arlewin v. Sweden, §§ 51-52)"),
                    new PssChoice("", "6. Požadavky spravedlivého procesu uvedené (v čl. 6, odst. 1 – 3 Úmluvy) se nevztahují na:", "" +
                        "\n" +
                        "- drobné přestupky proti pravidlům silničního provozu; \n" +
                        "(Marčan v. Croatia, § 37);\n" +
                        "\n" +
                        "- drobná narušení pokojného sousedského soužití; \n" +
                        "(Lauko v. Slovakia; Nicoleta Gheorghe v. Romania, §§ 25-26; Şimşek, Andiç and Boğatekin v. Turkey (dec.)\n" +
                        "\n" +
                        "- na řízení týkající se správního deliktu spočívajícího v poskytování prostor pro provozování prostituce;\n" +
                        "(Sancaklı v. Turkey, §§ 43-52)\n" +
                        "\n" +
                        "- na disciplinární řízení proti advokátovi;\n" +
                        "(Müller-Hartburg v. Austria (§§ 42-49);\n" +
                        "\n" +
                        "- na disciplinární řízení proti úředníkovi ve veřejné službě;\n" +
                        "(Moullet v. France (dec.); Trubić v. Croatia (dec.), § 26; Pişkin v. Turkey, §§ 105-109; Čivinskaitė v. Lithuania, §§ 98-99)\n" +
                        "\n" +
                        "- na disciplinární řízení proti vojákovi či řízení ohledně vojenského kariérního postupu;\n" +
                        "(Suküt v. Turkey (dec.)); R.S. v. Germany (dec.), § 33)\n" +
                        "\n" +
                        "- na disciplinární řízení se soudním doručovatelem;\n" +
                        "(Bayer v. Germany, § 37);\n" +
                        "\n" +
                        "- na disciplinární řízení se soudcem;\n" +
                        "(Oleksandr Volkov v. Ukraine, §§ 93-95; Kamenos v. Cyprus, §§ 51-53, Ramos Nunes de Carvalho e Sá v. Portugal [GC], §§ 124-128)\n" +
                        "\n" +
                        "- na řízení ve věci pohrdání soudem, není-li považováno za trestný čin;\n" +
                        "(Ravnsborg v. Sweden, § 34; Putz v. Austria, §§ 33-37)\n" +
                        "\n" +
                        "- na řízení týkající se zpřísnění výkonu trestu umístněním do věznice s vyšším stupněm ostrahy;\n" +
                        "(Enea v. Italy [GC], § 98)\n" +
                        "\n" +
                        "- na řízení týkající se porušení volebního zákona, rozpuštění politické strany nebo parlamentní vyšetřovací komise; \n" +
                        "(Pierre-Bloch v. France, §§ 53-60; Refah Partisi (the Welfare Party) and Others v. Turkey (dec.); Montera v. Italy (dec.))\n" +
                        "\n" +
                        "- na porušení právních předpisů ohledně střetu zájmů;\n" +
                        "(Cătăniciu v. Romania (dec.), §§ 38-41)\n" +
                        "\n" +
                        "- na řízení o vyhoštění cizince, i když je výsledkem trestního řízení;\n" +
                        "(Maaouia v. France [GC], § 39)\n" +
                        "\n" +
                        "- na extradiční řízení;\n" +
                        "(Peñafiel Salgado v. Spain (dec.))\n" +
                        "\n" +
                        "- na řízení související s vydáním evropského zatykače;\n" +
                        "(Monedero Angora v. Spain (dec.))\n" +
                        "\n" +
                        "- opatření týkající se předcházení trestné činnosti či nepokojů;\n" +
                        "(Raimondo v. Italy, § 43)\n" +
                        "\n" +
                        "- mrtvého, ale vztahují se na zanikající společnosti, když odpovědnost za spáchaný čin přechází na nástupnickou společnost;\n" +
                        "(Magnitskiy and Others v. Russia, § 281; Grădinar v. Moldova, §§ 90-104; Carrefour France v. France (dec.))\n" +
                        "\n" +
                        "- na řízení o obnově řízení; " +
                        "(Moreira Ferreira v. Portugal (no. 2) [GC], §§ 60-61; Fischer v. Austria (dec.))\n" +
                        "\n" +
                        "- řízení vedoucí ke konfiskaci či zabavení majetkových hodnot, jako je například letadlo, pokud nemá trestněprávní souvislosti;\n" +
                        "(Air Canada v. the United Kingdom, § 54) nebo zlatých mincí (AGOSI v. the United Kingdom, §§ 65-66)", false),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ],
        true
    ),
    new PssSection(
        "II. Nezávislost a nestrannost soudu",
        "Za účelem určení, zda lze soud považovat za \"nezávislý\", jak vyžaduje článek 6 § 1, může být důležité i to, " +
        "jak se soudní řízení jeví navenek. V sázce je důvěra, kterou musí veřejnost chovat vůči soudům v demokratické společnosti," +
        " a pokud jde o trestní řízení, pak jde zejména o důvěru obviněného, resp. obžalovaného [1]. Zásady aplikované za účelem určení," +
        " zda lze soud považovat za \"nezávislý a nestranný\", se vztahují na profesionální soudce, přísedící, ale také porotce, " +
        "kteří však v našem právním řádu v rámci trestního řízení nepůsobí [2]. " +
        "\n" +
        "\n" +
        "Nezávislost soudnictví vyžaduje, aby jednotliví soudci nebyli nepřiměřeně ovlivňováni tlaky mimo soudní soustavu " +
        "či zevnitř. Vnitřní nezávislost soudnictví vyžaduje, aby soudy nebyly vystaveny tlakům ze strany kolegů soudců nebo těch," +
        " kteří mají u soudu manažerskou odpovědnost, jako je předseda soudu nebo předseda senátu soudu. Neexistence dostatečných " +
        "záruk zajišťujících nezávislost soudců v soudnictví, zejména vůči jejich soudním nadřízeným, může vést k závěru, že pochybnosti" +
        " obžalovaného o nezávislosti a nestrannosti soudu lze považovat za objektivně odůvodněné [3].",
        "[1] (Şahiner v. Turecko, § 44), [2] (Holm v. Švédsko, § 30), [3] (Parlov-Tkalčić v. Chorvatsko, §86;" +
        "Daktaras v. Litva, § 36; Moisejev v. Rusko, § 184)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ts.2.1", "1. Soudce, ohledně něhož existuje legitimní důvod mít obavy, že není nestranný, se musí z projednávání věci vyloučit, což ovšem neučinil, čímž došlo k porušení práva na spravedlivý proces. ", " (Micallef v. Malta [GC], § 98)"),
                    new PssChoice("ts.2.2", "2. Soud, jemuž byla doručena námitka podjatosti, se nevyjádřil k argumentům uvedeným v této námitce nebo v souvislosti s ní, porušil právo stran na nezávislý a nestranný soud. ", " (Harabin v. Slovakia, § 136)"),
                    new PssChoice("ts.2.3", "3. Je porušením práva na nezávislost a nestrannost soudce, pokud se soud vyhne posouzení námitky podjatosti, ačkoli je zřejmé, že není bezpředmětná či zjevně nedůvodná, a nepřijme v důsledku toho příslušná opatření zajišťující nestrannost a nezávislost soudu. ", "(Cosmos Maritime Trading and Shipping Agency v. Ukraine, §§ 78-82)"),
                    new PssChoice("ts.2.4", "4. Soudce porušil svou nestrannost a nezávislost, pokud vyjadřoval nepřátelství vůči jedné ze stran. ", " (Buscemi v. Italy, §§ 67-68)"),
                    new PssChoice("ts.2.5", "5. Osobní nesnášenlivost soudce k jedné ze stran je přesvědčivým důvodem pro jeho vyloučení. ", " (Rustavi 2 Broadcasting Company Ltd and Others v. Georgia, § 359)"),
                    new PssChoice("ts.2.6", "6. Je porušením nestrannosti soudce a práva na spravedlivý process, pokud soudce vyjadřoval předsudky či předpojatost vůči některé ze sran nebo vůči určitému způsobu řešení sporu. ", " (Wettstein proti Švýcarsku, § 43; Micallef proti Maltě [velký senát]"),
                    new PssChoice("ts.2.7", "7. Soudce porušil svou nestrannost a nezávislost, pokud poskytoval poradenství jedné ze stran v téže věci, ohledně téhož rozhodnutí nebo ohledně analogických záležitostí. ", " (Kleyn and Others v. the Netherlands [GC], § 200)"),
                    new PssChoice("ts.2.8", "8. Jakákoli přímá účast na přijetí zákona či prováděcích opatření, včetně například územního či rozvojového plánu určitého území je zpravidla dostatečným důvodem pro vyloučení soudce z důvodu absence jeho nestrannosti a nezávislosti, pokud tento soudce následně daný předpis vykládá v rámci sporu, který soudí. ", " (McGonnell v. the United Kingdom, §§ 55-58)"),
                    new PssChoice("ts.2.9", "9. Pokud jsou vedena dvě souběžná řízení mezi stejnými stranami, přičemž v jednom z nich určitá osoba vystupuje jako soudce a v druhé jako protistrana, představuje to porušení práva na nestranného a nezávislého soudce. ", " (Wettstein v. Switzerland, §§ 44-47)"),
                    new PssChoice("ts.2.10", "10. Soudce porušil svou nestrannost a nezávislost, pokud v předchozích řízeních, jež vyústily v řízení před tímto soudem, zastupoval jednu ze stran. ", " (Mežnarić v. Croatia, § 36, Švarc and Kavnik v. Slovenia, § 44)"),
                    new PssChoice("ts.2.11", "11. Pokud existuje úzký vztah soudce s jeho faktickou přítomností v záležitostech či skutečnostech, jichž se týkají různé fáze řízení, jehož se jako soudce účastní, je porušením jeho nestrannosti. "," (Toziczka v. Poland, § 36)"),
                    new PssChoice("ts.2.12", "12. Nejasnost ohledně toho, zda určitá osoba vystupuje v civilním sporu v roli soudce či státního zástupce, je porušením nestrannosti takového soudce. ", " (Kamenos v. Cyprus, § 104)"),
                    new PssChoice("ts.2.13", "13. Soudce porušuje svou nestrannost a nezávislost, pokud rozhoduje o tom, zda se dopustil nesprávného právního posouzení v rozhodnutí, jehož vydání se v předchozím stupni účastnil. ", " (Driza v. Albania, §§ 78-83)"),
                    new PssChoice("ts.2.14", "14. Soudce porušuje svou nestrannost a nezávislost v případě, kdy se účastní dvou různých řízení týkajících se stejných skutkových okolností. ", " (Indra proti Slovensku, § 51–53)"),
                    new PssChoice("ts.2.15", "15. Jeden ze soudců rozhodující o právním posouzení věci na odvolacím soudě, rozhodoval dříve o téže věci jako soudce soudu první instance, čímž se dopustil porušení své nestrannosti a nezávislosti.", "(De Haan proti Nizozemsku, § 51)"),
                    new PssChoice("ts.2.16", "16. Představuje porušení nestrannosti a nezávislosti, pokud předseda nejvyššího soudu rozhoduje o věci, jež se týká dřívějšího rozhodnutí správního orgánu, jehož byl v té době předsedou. ", " (Ramos Nunes de Carvalho e Sá v. Portugal [GC] (§§ 153-156)"),
                    new PssChoice("ts.2.17", "17. Je porušením nezávislosti a nestrannosti soudu, pokud asistent jednoho z ústavních soudců byl dříve součástí týmu právníků, jež zastupovali jednu ze stran v řízení na nižším stupni, jež poté vyústila v řízení před ústavním soudem. ", " (Bellizzi v. Malta (§§ 60-61)"),
                    new PssChoice("ts.2.18", "18. Soud porušuje požadavek nestrannosti a nezávislosti v situaci, kdy čtyři ze sedmi soudců, jež rozhodují o věci ve vyšší instanci rozhodovali o téže věci v nižší instanci. ", " (Pereira da Silva v. Portugal, §§ 59-60)"),
                    new PssChoice("ts.2.19", "19. Je porušením nestrannosti soudu, pokud odvolací soud, jehož soudci se dříve zúčastnili nalézacího řízení a nyní jsou povoláni rozhodnout o tom, zda ve svých předchozích rozhodnutích nevyložili nebo nepoužili chybně právní předpis, nestrannosti. ", "(San Leonard Band Club proti Maltě, § 64)"),
                    new PssChoice("ts.2.20", "20. Je porušením nestrannosti soudu, pokud jeden ze soudců rozhodujících o dovolání se předtím účastnil řízení v téže věci jako soudce soudu vyšší instance. ", " (Peruš proti Slovinsku, § 38–39)"),
                    new PssChoice("ts.2.21", "21. Soudce porušuje svou povinnost nestrannosti, pokud má osobní zájem na výsledku sporu ve věci, již soudí. ", " (Langborger v. Sweden, § 35; Gautrin and Others v. France, § 59)"),
                    new PssChoice("ts.2.22", "22. Profesní, majetkové nebo osobní vztahy mezi soudcem a stranou sporu nebo advokátem jedné ze stran sporu jsou důvodem pro porušení nestrannosti a nezávislosti soudce. ", " (Pescador Valero v. Spain, § 27; Tocono and Profesorii Prometeişti v. Moldova, § 31; Micallef v. Malta [GC], § 102; Wettstein v. Switzerland, § 47)"),
                    new PssChoice("ts.2.23", "23. Vyjádření či vztahy mezi rodinnými příslušníky soudce na straně jedné, a jedné ze stran sporu nebo jejího právního zástupce či právní kanceláře, jejímž jménem tento právní zástupce ve sporu vystupuje, na straně druhé, mohou být důvodem pro vyloučení soudce z důvodu jeho nestrannosti či nezávislosti v závislosti na jejich intenzitě, úzkému charekteru majetkových či osobních vztahů, vlivu a postavení dané osoby v dotčené právní kanceláři, významu sporu pro danou právní kancelář atd. ", " (Rustavi 2 Broadcasting Company Ltd and Others v. Georgia, §§ 342 et seq., § 344, Nicholas v. Cyprus, § 62)"),
                    new PssChoice("ts.2.24", "24. Pokud je soudce nebo jeho asistent podílející se na dané věci zároveň v podřízeném postavení vůči jedné ze stran sporu, představuje taková situace porušení nezávislosti a nestrannosti soudu. ", " (Sramek v. Austria, § 42)"),
                    new PssChoice("ts.2.25", "25. Zasáhla-li moc výkonná do případů projednávaných před soudem s cílem ovlivnit jejich výsledek, je tím ohrožena nezávislost soudců, a došlo k porušení práva na spravedlivý proces. ", " (Sovtransavto Holding proti Ukrajině, § 80; Mosteanu a ostatní proti Rumunsku, § 42)"),
                    new PssChoice("ts.2.26", "26.  Mezi členy soudu jsou či byly osoby, které jsou v podřízeném postavení, pokud jde o jejich povinnosti a organizaci jejich služby, vůči jedné ze stran trestního soudního sporu, čímž došlo k porušení práva na spravedlivý proces. ", " (Şahiner v. Turecko, §45)"),
                    new PssChoice("ts.2.27", "27. Vyšší soud odmítl zrušit rozhodnutí nižšího soudu, který nebyl nestranný, a potvrdil rozhodnutí a trest uložený nižším soudem, čímž došlo k porušení práva na spravedlivý proces. ", "(Kyprianou v. Kypr [GC], § 134; De Cubber v. Belgie, § 33; Findlay v. Spojené království, §§ 78–79)"),
                    new PssChoice("ts.2.28", "28. K porušení práva na spravedlivý proces došlo tím, že byla prokázána podjatost soudce z důvodu předchozí účasti tohoto soudce v řízení, od které neuplynuly ani dva roky. ", "(Dāvidsons a Savins v. Lotyšsko, § 57)"),
                    new PssChoice("ts.2.29", "29. Pokud byl předseda soudu již dříve prohlášen za podjatého vůči účastníkovi řízení v předchozím souboru trestních řízení týkajících se podobných obvinění proti němu, vzniká objektivní a odůvodněná obava z nedostatku nestrannosti jak ve vztahu k žalobci, tak vůči jeho spoluobžalovaným, a došlo tím k porušení práva na spravedlivý proces. ", " (Otegi Mondragon a další v. Španělsko, §§ 58-69)"),
                    new PssChoice("ts.2.30", "30. Je porušením práva na spravedlivý proces z důvodu absence nestrannosti soudu situace, kdy trestní soud učinil závěry na základě důkazů, jež byly učiněny v jiných řízeních, kterých se účastník nemohl účastnit. ", " (Khodorkovskiy and Lebedev v. Russia (no.2), § 522)"),
                    new PssChoice("ts.2.31", "31. Je porušením práva na sprevedlivý proces z důvodu absence nestrannosti soudu situace, kdy členy trestního soudu jsou osoby pracující souběžně v ozbrojených složkách. ", "(Incal v. Turkey, § 72; Iprahim Ülger v. Turkey, § 26)"),
                    new PssChoice("ts.2.32", "32. Až na velmi výjimečné situace je odsouzení civilní osoby vojenským soudem porušením práva na spravedlivý proces. ", "(Martin v. the United Kingdom, § 44; see also Mustafa v. Bulgaria, §§ 28-37)"),
                    new PssChoice("ts.2.33", "33. Je porušením práva na spravedlivý proces z důvodu absence nestrannosti soudu situace, kdy manžel soudkyně byl vedoucím týmu vyšetřovatelů, o výsledky jejichž práce se opírá či opírala obžaloba. (Dorozhko and Pozharskiy v. Estonia, §§ 56-58)"),
                    new PssChoice("ts.2.34", "34. Je porušením práva na spravedlivý proces z důvodu absence nestrannosti soudu situace, kdy syn soudce byl součástí týmu vyšetřovatelů, o výsledky jejichž práce se opírá či opírala obžaloba. ", "(Jhangiryan v. Armenia, §§ 97-103)"),
                    new PssChoice("ts.2.35", "35. Je porušením práva na spravedlivý proces z důvodu absence nestrannosti soudu situace, kdy matka poškozeného byla zároveň soudkyní v daném případě. ", "(Mitrov v. the former Yugoslav Republic of Macedonia, §§ 49-56)."),
                    new PssChoice("ts.2.36", "36. Pokud předseda soudu veřejně použil výrazy, z nichž vyplývá, že si vytvořil nepříznivý názor na účastníka řízení ještě předtím, než soudní řízení započalo, představuje to porušení nestrannosti a zároveň porušení spravedlivého procesu. ", "(Mitrov v. the former Yugoslav Republic of Macedonia, §§ 58, Lavents v. Latvia, § 119)"),
                    new PssChoice("ts.2.37", "37. Je porušením nestrannosti pokud soudce trestního soudu v této věci rovněž rozhodoval v přípravném řízení, včetně rozhodnutí týkajících se vazby, a v této části řízení již vyjádřil názor ohledně viny obviněného, resp. později obžalovaného. ", "(Gómez de Liaño y Botella v. Spain, §§ 67-72)"),
                    new PssChoice("ts.2.38", "38. Je porušením práva na spravedlivý proces z důvodu absence nestrannosti soudu, pokud nadřízený soud vrátí věc zpět témuž podřízenému soudu či týmž soudcům téhož soudu přesto, že je podle zákona povinen poslat danou věc jinému soudu nebo jiným soudcům téhož soudu.", " (Lavents v. Latvia, § 115)"),
                    //new PssChoice("ts.2.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Soud i soudci jsou považováni za nestranné a nezávislé, dokud jejich nestrannost či nezávislost není zpochybněna. ", "(Le Compte, Van Leuven and De Meyere v. Belgium, § 58; Driza v. Albania, § 75; Micallef v. Malta [GC], § 94)"),
                    new PssChoice("", "2. Skutečnost, že jsou soudci jmenováni představitelem výkonné moci a jsou odvolatelní, není dostatečným důvodem k pochybnostem o jejich nestrannosti či nezávislosti. ", "(Clarke v. the United Kingdom (dec.))"),
                    new PssChoice("", "3. Skutečnost, že se soudce v určité části své procesní dráhy může nacházet v obdobném postavení jako některá ze stran sporu, není sama o sobě bez dalšího skutečností, jež by vedla k porušení nestrannosti a nezávislosti soudce. ", "(Ramos Nunes de Carvalho e Sá v. Portugal [GC], § 163)"),
                    new PssChoice("", "4. Není porušením práva na nestranný a nezávislý soud, pokud soud odmítne námitku podjatosti, protože nesplňuje formální náležitosti. ", "(Mikhail Mironov v. Russia, §§ 34-40)"),
                    new PssChoice("", "5. Není porušením práva na nestranný a nezávislý soud, pokud soud posoudí námitku podjatosti „očima nezávislého pozorovatele“ a námitku podjatosti zamítne. ", "(Clarke v. the United Kingdom (dec.))"),
                    new PssChoice("", "6. Není porušením práva na nestranný a nezávislý soud, pokud zamítnutá námitka podjatosti, byla následně posouzena v plném rozsahu soudem vyššího stupně, který se vypořádal s problémem, na nějž tato námitka mířila. ", "(De Haan v. the Netherlands, §§ 52-55; Helle v. Finland, § 46; Crompton v. the United Kingdom, § 79; and Denisov v. Ukraine [GC], §§ 65, 67, 72)"),
                    new PssChoice("", "7. Není porušením práva na nestranný a nezávislý soud, pokud předchozí vztah soudce k dané věci byl marginální a uplynula od něj již dlouhá doba. ", "(McGonnell v. the United Kingdom, §§ 52-57)"),
                    new PssChoice("", "8. Skutečnost, že soudce má pokrevní pouto se členem advokátní kanceláře, jejíž člen je právním zástupcem v soudním sporu, jehož se účastní rovněž uvedený soudce, neznamená automaticky porušení nezávislosti či nestrannosti tohoto soudce. ", "(Ramljak v. Croatia, §§ 29, Koulias v. Cyprus, §§ 62-64)"),
                    new PssChoice("", "9. Skutečnost, že soudci se znají jako kolegové, mohou se potkávat v budově soudu nebo dokonce sdílejí své kanceláře není sám o sobě nezvratným důkazem o porušení jejich nezávislosti či nestrannosti. ", "(Steck-Risch and Others v. Liechtenstein, § 48)"),
                    new PssChoice("", "10. Skutečnost, že má soudce detailní znalost projednávané věci i z jiných zdrojů, než jsou tvrzení a důkazy účastníků, není porušením jeho nestrannosti, pokud je výsledné rozhodnutí založeno na tvrzeních a důkazech předložených účastníky. ", "(Morel v. France, § 45)"),
                    new PssChoice("", "11. Skutečnost, že se soudce odvolacího soudu nevyloučil z projednávání věci v situaci, kdy se účastnil jiných souvisejících civilních řízení, není porušením jeho nestrannosti.", "(Golubović v. Croatia, § 52)"),
                    new PssChoice("", "12. Není porušením nestrannosti soudu, pokud předsedové soudů vykonávali pouze správní (manažerské a organizační) funkce, které byly striktně odděleny od funkce soudní. ", "(Parlov-Tkalčić proti Chorvatsku, § 88–95)"),
                    new PssChoice("", "13. Není porušením nestrannosti soudce, a tudíž ani práva na spravedlivý proces to, že se soudce nevyloučil z projednání odvolání v občanskoprávní věci, ačkoli se předtím účastnil řady jiných podobných řízení, nebyla důkazem postačujícím pro vyvrácení domněnky nestrannosti. ", "(Golubović proti Chorvatsku, § 52)"),
                    new PssChoice("", "14. Z požadavku na nestrannost soudu nevyplývá pro nadřízený soud, který zrušil správní nebo soudní rozhodnutí, povinnost vrátit věc k projednání jinému jurisdikčnímu orgánu nebo jinak obsazenému útvaru téhož orgánu, tzn. že není porušením práva na spravedlivý proces, je-li nadřízeným soudem, vrácena zrušená věc témuž soudu, který dříve vydal rozhodnutí, jež bylo nadřízeným soudem zrušeno. ", "(Ringeisen proti Rakousku, § 97 in fine)"),
                    new PssChoice("", "15. S požadavkem nestrannosti není neslučitelné, jestliže se tentýž soudce účastní nejprve rozhodování o meritu věci a následně řízení, v němž je posuzována přípustnost opravného prostředku proti tomuto rozhodnutí. ", "(Warsicka proti Polsku, § 38–47)"),
                    new PssChoice("", "16. Tvrzení účastníka trestního řízení, že existuje legitimní důvod, že soud není nezávislý nebo nestranný, je důležitý, nicméně není dostatečný. Tento tvrzený důvod musí být navíc objektivně zdůvodněný. ", "(Incal v. Turkey, § 71)"),
                    new PssChoice("", "17. Namítaný důvod absence nezávislosti či nestrannosti u nižšího soudu může být napraven soudem vyšší instance nebo nejvyšším soudem. ", "(Kyprianou v. Cyprus [GC], § 134; De Cubber v. Belgium, § 33; Findlay v. the United Kingdom, §§ 78-79)"),
                    new PssChoice("", "18. Záruky nezávislosti a nestrannosti podle článku 6 § 1 se však týkají pouze orgánu, který má rozhodnout o trestním obvinění proti obžalovanému, a nevztahují se na státního zástupce, který je pouze účastníkem řízení. ", "(Kontalexis v. Greece, § 57; Haarde v. Iceland, § 94; Thiam v. France, § 71)"),
                    new PssChoice("", "19. Účastník řízení nezaložil svou námitku podjatosti na konkrétních a/nebo rozhodných skutečnostech, jež vyvolaly důvodné podezření o absenci nestrannosti soudce, ale pouze na obecných a abstraktních důvodech. ", "(Zahirović v. Croatia, §§ 31-37)"),
                    new PssChoice("", "20. Pouhá skutečnost, že soudce trestního soudu v této věci rovněž rozhodoval v přípravném řízení, včetně rozhodnutí týkajících se vazby, nemůže být sama o sobě považována za ospravedlňující obavy z nedostatku nestrannosti; záleží na rozsahu a povaze těchto rozhodnutí. ", "(Fey v. Austria, § 30; Sainte-Marie v. France, § 32; Nortier v. the Netherlands, § 33)"),
                    new PssChoice("", "21. Skutečnost, že soudce byl v minulosti členem oddělení státního zastupitelství není důvodem pro pochybnosti ohledně jeho nestrannosti a tudíž důvodem pochybovat o spravedlivém procesu. ", "(Paunović v. Serbia, §§ 38-43)"),
                    new PssChoice("", "22. Povinnost nestrannosti neznamená povinnost nadřízeného soudu vrátit věc jinému soudu či jiným soudcům téhož soudu, není-li to jeho zákonná povinnost. ", "(Marguš v. Croatia [GC], §§ 85-89); Thomann v. Switzerland, § 33; Stow and Gai v. Portugal (dec.))"),
                    new PssChoice("", "23. Není porušením práva na nestrannost ani práva na spravedlivý proces, pokud se přísedící soudce vyjádří v novinovém interview poté, co byl vynesen rozsudek. ", "(Bodet v. Belgium (dec.), §§ 24-38; Haarde v. Iceland, § 105)"),
                    new PssChoice("", "24. Skutečnost, že soudce byl v minulosti členem politické strany, nestačí k tomu, aby zpochybnila nestrannost soudce, zejména pokud nic nenasvědčuje tomu, že by členství soudce v politické straně mělo nějakou souvislost s podstatou věci.", "(Otegi Mondragon and Others v. Spain (dec.), §§ 25-29)"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "III. Férovost procesu: možnost efektivně se bránit",
        "Klíčovou zásadou uplatňování článku 6 je férovost [1]. To, co představuje spravedlivý proces, však není " +
        "předmětem jediného neměnného pravidla, ale závisí na okolnostech konkrétního případu [2]. Článek 6 jako celek zaručuje" +
        " právo obviněného účinně se obhajovat a vystupovat v rámci trestního řízení [3]. Obecně to zahrnuje, mimo jiné, " +
        "nejen jeho právo být přítomen, ale také vyslechnout a sledovat řízení [4]. " +
        "\n" +
        "\n" +
        "Rovnost zbraní je neodmyslitelnou součástí spravedlivého procesu. Vyžaduje, aby každá strana měla přiměřenou" +
        " příležitost předložit svůj případ za podmínek, které ho neznevýhodňují vůči jeho oponentovi [5]. " +
        "Rovnost zbraní vyžaduje dosažení spravedlivé rovnováhy, tj., aby se účastníci řízení dozvěděli o všech předložených důkazech" +
        " nebo návrzích či argumentech podaných za účelem ovlivnění rozhodnutí soudu a vyjádřili se k němu za týmž účelem [6]. " +
        "\n" +
        "\n" +
        "V trestních věcech se článek 6 § 1 překrývá se zvláštními zárukami článku 6 § 3, i když se neomezuje na minimální práva " +
        "v něm uvedená. Záruky obsažené v článku 6 § 3 jsou totiž základními prvky, mimo jiné pojmu spravedlivý proces stanovený " +
        "v článku 6 § 1 [7].",
        "[1] (Gregačević v. Chorvatsko, § 49); [2] (Ibrahim a další v. Spojené království [GC], § 250); " +
        "[3] (Murtazaliyeva v. Rusko [GC], § 91); [4] (Stanford v. Spojené království, § 26)" +
        "[5] (Öcalan v. Turecko [GC], § 140; Foucher v. Francie, § 34; Bulut v. Rakousko; Faig Mammadov v. Ázerbájdžán, § 19)" +
        "[6] (Brandstetter v. Rakousko, § 67); [7] (Ibrahim a další v. Spojené království [GC], § 251)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ts.3.1", "1. Špatná akustika v soudní síni a potíže se sluchem u účastníka řízení vedou k absenci férovosti procesu a porušení práva na spravedlivý proces. ", " (Stanford v. the United Kingdom, § 29)"),
                    new PssChoice("ts.3.2", "2. Účinná účast obviněného či obžalovaného na jeho trestním řízení zahrnuje rovněž jeho právo sestavovat si poznámky s cílem usnadnit provádění jeho obhajoby bez ohledu na to, zda má právního zástupce či nikoli. ", "(Pullicino v. Malta  (prosinec); Moisejev v. Rusko, § 214)"),
                    new PssChoice("ts.3.3", "3. Míra omezení osobní svobody účastníka řízení v soudní síni může rovněž ovlivnit férovost jednání tím, že narušuje právo obviněného, resp. obžalovaného účinně se účastnit řízení. Bezpečnostní obavy při jednání trestního soudu však mohou zahrnovat, zejména ve velkém nebo citlivém případě, použití zvláštních opatření v soudní síni. ", " (Svinarenko a Slyadnev v. Rusko  [GC], § 134), § 150,  Jaroslav Belousov v. Rusko  (§§ 151-154)"),
                    new PssChoice("ts.3.4", "4. Pokud účastníkovi řízení bylo zabráněno vyjádřit se k podání státního zástupce, jedná se o porušení práva na spravedlivý proces. ", " (Borgers v. Belgium)"),
                    new PssChoice("ts.3.5", "5. Je porušením férovosti a spravedlivého procesu, pokud vyjádření státního zástupce ohledně odvolání účastníka řízení není doručeno účastníkovi řízení. ", " (Zahirović v. Croatia, §§ 44-50)"),
                    new PssChoice("ts.3.6", "6. Je porušením práva na spravedlivý proces podle článku 6 § 1 v kombinaci s článkem 6 § 3 v trestním řízení, kde byl obhájce nucen čekat patnáct hodin, než konečně v časných ranních hodinách dostal možnost obhajoby svého klienta před soudem. ", " (Makhfi v. France)"),
                    new PssChoice("ts.3.7", "7. Obžalovaný, který byl v odvolacím řízení odsouzen a požádal o přítomnost při soudním jednání, byl vyloučen z předběžného jednání soudu, které se konalo neveřejně, čímž došlo k porušení práva na spravedlivý proces. ", "(Zhuk v. Ukraine, § 35)"),
                    new PssChoice("ts.3.8", "8. Je porušením práva na spravedlivý proces, kdy žadatel nesměl být přítomen jednání před odvolacím soudem, zatímco zástupce státního zastupitelství přítomen byl. ", " (Eftimov v. the former Yugoslav Republic of Macedonia, § 41)"),
                    new PssChoice("ts.3.9", "9. Soud odmítl vyslechnout jakéhokoli svědka nebo provést jakýkoli důkaz navržený účastníkem řízení, zatímco vyslechl všechny svědky obžaloby a provedl všechny důkazy, které navrhovala, čímž se dopustil porušením práva na spravedlivý proces a práva na rovnost zbraní. ", " (Borisova v. Bulgaria, §§ 47-48; Topić v. Croatia, § 48)"),
                    new PssChoice("ts.3.10", "10. Soud odmítl v rámci trestního řízení vyslechnout všechny svědky, které navrhl obžalovaný za účelem vysvětlení nejasných skutečností, které tvořily základ obžaloby. ", " (Kasparov and Others v. Russia, §§ 64-65)"),
                    new PssChoice("ts.3.11", "11. Účastníkovi řízení nebylo umožněno účinně napadnout znalecký posudek, zejména připuštěním revizního znaleckého posudku, čímž došlo k porušení práva na spravedlivý proces. ", " (Stoimenov v. the former Yugoslav Republic of Macedonia, § 38; Matytsina v. Russia, § 169)"),
                    new PssChoice("ts.3.12", "12. Účastníkovi řízení, během něhož byly použity video prostředky, nebylo umožněno, aby se řízení efektivně účastnil, tedy, aby byl slyšen bez technických omezení a aby mu byla umožněna důvěrná komunikace s jeho právním zástupcem. ", " (Sakhnovskiy v. Russia [GC], § 104; Gorbunov and Gorbachev v. Russia, § 37)"),
                    new PssChoice("ts.3.13", "13. Neomezený přístup ke spisu a neomezené používání jakýchkoli poznámek, v případě potřeby včetně možnosti získat kopie příslušných dokumentů, jsou důležitými zárukami spravedlivého procesu. Neposkytnutím takového přístupu byla porušena zásada rovnosti zbraní a právo na spravedlivý proces účastníka řízení. ", " (Beraru v. Romania, § 70)"),
                    new PssChoice("ts.3.14", "14. Článek 6 § 1 Úmluvy vyžaduje, aby orgány státních zastupitelství sdělily obhajobě veškeré podstatné důkazy, které mají k dispozici pro obviněného nebo proti němu, což se nestalo, čímž bylo porušeno právo na spravedlivý proces. ", " (Rowe and Davis v. the United Kingdom [GC], § 60)"),
                    new PssChoice("ts.3.15", "15. Účastníkovi řízení nebylo umožněno, aby se vyjádřil k dodatečné policejní zprávě, čímž bylo porušeno právo na spravedlivý proces. ", "(Kuopila v. Finland, § 38)"),
                    new PssChoice("ts.3.16", "16. Účastníkovi řízení nebylo umožněno, aby se mohl seznámit se zprávou soudce zpravodaje před soudním slyšením a vyjádřit se k němu, stejně jako mu nebylo možno odpovědět na závěry státního zástupce, čímž bylo porušeno právo na spravedlivý proces. ", "(Reinhardt and Slimane-Kaïd v. France, §§ 105-106)"),
                    new PssChoice("ts.3.17", "17. Je porušením práva na spravedlivý proces, pokud účastník řízení namítal porušení práva na spravedlivý proces v důsledku existence procesní vady, avšak soud neposoudil, zda později v rámci řízení došlo k nápravě uvedené procesní vady. ", " (Mehmet Zeki Çelebi v. Turkey, § 51)"),
                    new PssChoice("ts.3.18", "18. Je porušením práva na spravedlivý proces, pokud dojde k více drobným procesním vadám, které všechny dohromady kumulativně narušují férovost procesu, byť jednotlivě tyto vady samy o sobě zásadní z hlediska spravedlivého procesu nejsou. ", " (Mirilashvili v. Russia, § 165)"),
                    //new PssChoice("ts.3.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Podle článku 6 § 1 Úmluvy jsou přípustná opatření omezující práva na obhajobu, která jsou nezbytně nutná z důvodu národní bezpečnosti nebo potřeby chránit ohrožené svědky, kterým hrozí odveta, nebo utajit metody používané policií k vyšetřování trestné činnosti. ", "(Van Mechelen a další v. Nizozemsko, § 58; Paci v. Belgie, § 85)"),
                    new PssChoice("", "2. Omezení práv účastníka řízení a obhajoby byla dostatečně vyvážena jinými opatřeními ve prospěch tohoto účastníka a obhajoby v rámci daného řízení. ", "(Rowe and Davis v. the United Kingdom [GC], § 61; Doorson v. the Netherlands, § 72)"),
                    new PssChoice("", "3. Není porušením práva na spravedlivý proces, pokud informace, se kterými nebylo umožněno účastníkovi řízení se seznámit, neměly žádný vliv na práva obhajoby a účastníka řízení. ", "(M v. the Netherlands, § 69)"),
                    new PssChoice("", "4. Obhajobě sice nebylo umožněno učinit výslech svědka, nicméně žádost o výslech svědka nebyla ze strany obhajoby dostatečně odůvodněná a relevantní pro předmět obvinění nebo soud poskytl dostatečné důvody pro své rozhodnutí nevyslechnout svědka u soudu nebo rozhodnutí soudu nevyslechnout svědka nenarušilo celkovou spravedlnost řízení. ", "(Abdullayev v. Azerbaijan, §§ 59-60)"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "IV. Odůvodnění rozhodnutí",
        "Rozsudky soudů musí odpovídajícím způsobem uvádět důvody, na nichž jsou založeny [1]. Vnitrostátní soudy" +
        " by měly s dostatečnou jasností uvést důvody, na nichž své rozhodnutí zakládají. Odůvodněné rozhodnutí je důležité," +
        " aby žadatel mohl reálně uplatnit jakékoli dostupné opravné prostředky [2]. Z rozhodnutí musí vyplývat, že byly řešeny" +
        " základní otázky řešené v dané věci [3].",
        "[1] (Moreira Ferreira v. Portugalsko (č. 2) [GC], § 84; Papon v. Francie (dec)); [2] (Hadjianastassiou v. Řecko);" +
        "[3] (Boldea v. Rumunsko, § 30; Lobzhanidze a Peradze v. Gruzie, § 66)",
        [
            new PssSectionPart(
                "POZITIVNÍ SEZNAM",
                [],
                [
                    new PssChoice("ts.4.1", "1. Právo na spravedlivý proces bylo porušeno, neboť nebyla poskytnuta konkrétní a výslovná odpověď na argumenty, které jsou rozhodující pro výsledek věci. ", " (Moreira Ferreira v. Portugalsko (č. 2) [GC], § 84)"),
                    new PssChoice("ts.4.2", "2. Soud ignoroval v odůvodnění svého rozhodnutí konkrétní, relevantní a důležitý bod vznesený účastníkem řízení, čímž došlo k porušení práva na spravedlivý proces podle čl. 6 odst. 1 Úmluvy. ", " (Nechiporuk a Yonkalo v. Ukrajina, § 280; Rostomashvili v. Gruzie, § 59; Čang v. Ukrajina, § 73)"),
                    new PssChoice("ts.4.3", "3. Zdůvodnění rozhodnutí soudu spočívá na automatických či stereotypních argumentech, čímž došlo k porušení práva na spravedlivý proces. ", " (Moreira Ferreira v. Portugalsko (č. 2) [GC], § 84)"),
                    new PssChoice("ts.4.4", "4. Rozhodnutí soudu neobsahuje žádné důvody nebo jsou uvedené důvody založeny na zjevném skutkovém nebo právním omylu, kterého se dopustil soud, což vedlo k \"odepření spravedlnosti\", a tím i práva na spravedlivý proces. ", " (Moreira Ferreira v. Portugalsko (č. 2) [GC], § 85; Navalnyj a Ofitserov v. Rusko, § 119)"),
                    new PssChoice("ts.4.5", "5. V rozhodnutí soudu nižšího stupně vyvstala problém neexistence jakéhokoli skutkového a/nebo právního základu, avšak vyšší soud v rozhodnutí revidující rozhodnutí soudu nižšího stupně neuvedl vlastní řádné důvody, čímž porušil právo na spravedlivý proces. ", "(Tatishvili v. Rusko, § 62)"),
                    new PssChoice("ts.4.6", "6. Účastník řízení uvedl výslovnou námitku proti přípustnosti důkazů, přesto soud vyššího stupně založil své rozhodnutí na takovém důkazu, aniž by na námitku přípustnosti odpověděl. ", "(Šabelnik v. Ukrajina (č. 2), §§ 50-55)"),
                    //new PssChoice("ts.4.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Soudy nejsou povinny poskytnout podrobnou odpověď na každý argument vznesený účastníkem řízení. ", " (Van de Hurk v. the Netherlands, § 61)"),
                    new PssChoice("", "2. V zamítnutí odvolání může odvolací soud v zásadě pouze potvrdit důvody rozhodnutí nižšího soudu. ", " (García Ruiz v. Spain [GC], § 26; Stepanyan v. Armenia, § 35)"),
                    new PssChoice("", "3. Pokud jde o rozhodnutí odvolacího soudu o tom, zda odvolání připustit, nevyžaduje se, aby se na zamítnutí přípustnosti takového odvolání bylo podrobně odůvodněno. ", "(Sawoniuk v. the United Kingdom (dec.))"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "V. Právo nevypovídat a neobviňovat sebe samého",
        "Každý, kdo je obviněn z trestného činu, má právo nevypovídat a nesmí být nucen k obviňování sebe sama [1]." +
        " Ačkoli to není výslovně uvedeno v článku 6 Úmluvy, právo nevypovídat a zásada spočívající v nepřispívání k vlastnímu " +
        "obvinění jsou obecně uznávány mezinárodními normami, které jsou jádrem pojmu spravedlivého řízení podle článku 6 Úmluvy." +
        " Tím, že poskytují obviněnému ochranu před nepřípustným nátlakem ze strany orgánů činných v trestním řízení," +
        " přispívají tyto zásady také k předcházení justičním omylům a k zajištění cílů článku 6 [2]. " +
        "\n" +
        "\n" +
        "Právo neobviňovat sebe samého nechrání před podáním usvědčujícího prohlášení jako takového, ale " +
        "před získáním důkazů donucením nebo nátlakem. Právě existence donucení vyvolává obavy, zda bylo respektováno " +
        "právo neobviňovat sebe samého. Z tohoto důvodu musí být nejprve posouzena povaha a stupeň donucení použitého" +
        "k získání důkazů [3]. Právo neobviňování sebe samého se vztahuje na trestní řízení ve vztahu ke" +
        "všem druhům trestných činů, od těch méně závažných až po ty nejzávažnější [4]. Právo nevypovídat platí od okamžiku," +
        "kdy je podezřelý vyslýchán policií [5].  Právo nevypovídat není absolutní [6].",
        "[1] (O'Halloran a Francis v. Spojené království [GC], § 45; Funke v. Francie, § 44); [2] (John Murray v. Spojené království [GC], § 45; " +
        "Bykov v. Rusko [GC], § 92); [3] (Ibrahim a další v. Spojené království [GC], § 267); [4] (Saunders v. Spojené království [GC] § 74)" +
        "[5] (John Murray v. Spojené království [GC], § 45); [6] (John Murray v. Spojené království [GC], § 47; " +
        "Ibrahim a další v. Spojené království [GC], § 269)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ts.5.1", "1. Zejména osoba \"obviněná z trestného činu\" ve smyslu článku 6 Úmluvy má právo být informována o právu neobviňovat sama sebe, k čemuž však nedošlo, čímž bylo porušeno jeho právo na spravedlivý proces. ", " (Ibrahim and Others v. the United Kingdom [GC], § 272)"),
                    new PssChoice("ts.5.2", "2. Podezřelý byl povinen vypovídat pod hrozbou sankcí, a skutečně tak učinil nebo tak neučinil, a byl za to sankcionován a nebyl předtím informována o právu neobviňovat sama sebe, čímž bylo porušeno jeho právo na spravedlivý proces. ", " (Saunders v. the United Kingdom [GC], Brusco v. France)"),
                    new PssChoice("ts.5.3", "3. Právo účastníka řízení nevypovídat bylo porušeno v důsledku fyzického nebo psychologického nátlaku, jež porušuje ustanovení článku 3 Úmluvy o zákazu mučení a nelidského zacházení, a tento nátlak byl použit za účelem získání důkazů či výpovědi. ", " (Jalloh v. Germany [GC]; Gäfgen v. Germany [GC])"),
                    new PssChoice("ts.5.4", "4. Právo účastníka řízení nevypovídat, a tudíž jeho právo na spravedlivý proces bylo porušeno v důsledku jednání orgánů činných v trestním řízení, které se pokusily získat informace od účastníka řízení lstí poté, co se jim nepodařilo získat dotčenou informaci výslechem. ", " (Allan v. the United Kingdom)"),
                    new PssChoice("ts.5.5", "5. Policií zadržené osoby nemohou být v žádném případě zbaveny práva na právní pomoc, pokud nebyly informovány o jejich právu nevypovídat, k čemuž však došlo, čímž byly zbaveny práva na spravedlivý proces. ", " (Brusco v. France, § 54; Navone and Others v. Monaco, § 74)"),
                    new PssChoice("ts.5.6", "6. Osobám, jež jsou “obviněné z trestného činu“ ve smyslu Úmluvy, nesmí být dáno na výběr mezi jejich právem nevypovídat a neobviňovat sebe samého, na straně jedné, a právem na právní pomoc na straně druhé, k čemuž však došlo, čímž bylo porušeno jeho právo na spravedlivý proces. ", "(Ibrahim and Others v. the United Kingdom [GC], § 272)"),
                    new PssChoice("ts.5.7", "7. Sdělení poučení o právu nevypovídat a neobviňovat sebe samého dotčené osobě musí orgány činné v trestním řízení být schopny dokumentovat, přičemž případné vzdání se tohoto práva, pokud k tomu došlo, musí být učiněno v přítomnosti právního zástupce dotčené osoby, jinak se jedná o porušení práva na spravedlivý proces. ", "(Stojkovic v. France and Belgium, § 54)"),
                    new PssChoice("ts.5.8", "8. Odsouzení obžalovaného nesmí být založeno pouze na skutečnosti, že obžalovaný odmítl vypovídat nebo poskytnout důkazy o jeho nevině. ", " (John Murray v. the United Kingdom [GC], § 47)"),
                    //new PssChoice("ts.5.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Svědectví získané na základě jinak nepřípustného nátlaku, které nepřispívají k zesílení obvinění dotčeného účastníka řízení, jako jsou poznámky ve prospěch této osoby nebo pouhé informace o skutkových otázkách, mohou být použity v trestním řízení obžalobou například za účelem rozporování nebo zpochybnění jiných prohlášení obviněného nebo důkazů, které poskytl během hlavního líčení, nebo ke zpochybnění jeho důvěryhodnosti. Takový postup je přípustný a není porušením spravedlivého procesu. ", "(Ibrahim and Others v. the United Kingdom [GC], § 268)"),
                    new PssChoice("", "2. Právo neobviňovat sebe samého není porušeno získáním od dotčené osoby vzorku krve, DNA nebo jiných tělesných vzorků nebo získáním dokumentů, které mohou orgány činné v trestním řízení získat ohledně dotčené osoby na základě svých pravomocí z jiných zdrojů. ", "(Saunders v. the United Kingdom [GC], § 69; O’Halloran and Francis v. the United Kingdom [GC], § 47)"),
                    new PssChoice("", "3. Konfrontace obviněného či obžalovaného v trestním řízení s jeho vyjádřeními, které učinil v jiných typech řízení, například azylovém řízení, nepředstavuje porušení práva nevypovídat či neobviňovat sebe samého. ", "(H. and J. v. the Netherlands (dec.))"),
                    //new PssChoice("","","",false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "VI. Právo na to, aby předložené důkazy byly získány řádně a férově",
        "Článek 6 sice zaručuje právo na spravedlivý proces, ale nestanoví žádná pravidla pro přípustnost důkazů " +
        "jako takových, což je především předmětem úpravy vnitrostátních norem [1]. Zejména je třeba přezkoumat, zda účastníkovi " +
        "řízená byla dána příležitost zpochybnit věrohodnost důkazů a namítnout jejich nepřípustnost [2]. " +
        "\n" +
        "\n" +
        "Kromě toho je třeba vzít v úvahu kvalitu důkazů, stejně jako okolnosti, za kterých byly získány, a zda tyto okolnosti" +
        " zpochybňují jejich spolehlivost nebo přesnost [2]. Právo na to, aby předložené důkazy byly získány řádně a férově " +
        "se neomezuje na důkazy, které jsou rozhodné pro výsledek trestního řízení [3].",
        "[1] (Schenk v. Švýcarsko, §§ 45–46; Moreira Ferreira v. Portugalsko (č. 2) [GC], § 83; Heglas v. Česká republika, § 84);" +
        "[2] (Bykov v. Rusko  [GC], § 89; Jalloh v. Německo  [GC], § 96); [3] (Gäfgen v. Germany [GC], § 164)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ts.6.1", "1. Právo na to, aby předložené důkazy byly získány, je vždy porušeno, pokud předložené důkazy v trestním řízení byly získány jako výsledek porušení zákazu mučení či nelidského či ponižujícího zacházení podle čl. 3 Úmluvy, k čemuž v daném případě došlo. ", " (Jalloh v. Germany [GC], §§ 99 and 105; Harutyunyan v. Armenia, § 63, Gäfgen v. Germany [GC], § 166; Ibrahim and Others v. the United Kingdom [GC], § 254; El Haski v. Belgium, § 85; Cēsnieks v. Latvia, §§ 67-70)"),
                    new PssChoice("ts.6.2", "2. Právo na to, aby předložené důkazy byly získány, je porušeno, pokud předložené důkazy v trestním řízení byly získány jako výsledek porušení zákazu mučení či nelidského či ponižujícího zacházení podle čl. 3 Úmluvy, i když se posléze uvedeného porušení dopustila fyzická osoba, k čemuž v daném případě došlo. ", " (Ćwik v. Poland)"),
                    new PssChoice("ts.6.3", "3. Právo na to, aby předložené důkazy byly získány řádně a férově, je porušeno, pokud předložené důkazy v trestním řízení byly získány v přímé souvislosti s porušením zákazu mučení či nelidského či ponižujícího zacházení podle čl. 3 Úmluvy, k čemuž v daném případě došlo. ", "(Gäfgen v. Germany [GC], § 167; Jalloh v. Germany [GC], § 105)"),
                    new PssChoice("ts.6.4", "4. Právo na to, aby předložené důkazy byly získány řádně a férově, je porušeno i v případě, pokud předložené důkazy v trestním řízení byly získány jako výsledek porušení zákazu mučení či nelidského či ponižujícího zacházení podle čl. 3 Úmluvy uplatněného vůči třetí osobě a nikoli účastníkovi řízení, proti kterému je takový předložený důkaz použit, k čemuž v daném případě došlo. ", " (Urazbayev v. Russia, § 73)"),
                    new PssChoice("ts.6.5", "5. Právo na to, aby předložené důkazy byly získány řádně a férově, je porušeno v případě, že tyto důkazy byly získány jako výsledek vyjednávání o vině a trestu nebo o snížení trestu spoluobviněného, resp. spoluobžalovaného, a byly naplněny všechny následující podmínky:\n" +
                        "(i) prohlášení spoluobviněného, resp. spoluobžalovaného, představovalo, rozhodující důkaz proti obžalovanému, \n" +
                        "(ii) soud nezkoumal širší kontext toho, za jakých okolností bylo uvedené prohlášení získáno, \n" +
                        "(iii) skutečnost, že dohoda o vině a trestu spoluobžalovaného byla uzavřena bez účasti soudu, \n" +
                        "(iv) soud nezkoumal námitky dotčeného účastníka řízení ohledně zmíněných tří skutečností. \n" +
                        "Všechny uvedené podmínky byly naplněny a k popsanému porušení proto došlo. \n" +
                        "\n" +
                        "(Adamčo v. Slovakia, §§ 56-71)"),
                    //new PssChoice("ts.6.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Důkazy proti obviněnému, resp. obžalovanému, byly získány jako výsledek nelidského či ponižujícího zacházení, jež však nedosáhlo intenzity mučení ve smyslu čl. 3 Úmluvy. Pokud takto získané důkazy neměly žádný vliv na výsledek řízení, nejedná se o porušení práva na spravedlivý proces. ", "(Gäfgen v. Germany [GC], § 178; El Haski v. Belgium, § 85; Zličić v. Serbia, § 119)"),
                    new PssChoice("", "2. Právo na to, aby předložené důkazy byly získány řádně a férově, není porušeno v případě, že tyto důkazy byly získány jako výsledek vyjednávání o dohodě o vině a trestu nebo o snížení trestu v souladu se zákonem a za účasti soudu v podobě dostatečného dohledu soudu nad tímto vyjednáváním. ", "(Kadagishvili v. Georgia, §§ 156-157)"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "VII. Právo na veřejné slyšení a ústní slyšení",
        "Veřejná povaha řízení chrání účastníky řízení před výkonem spravedlnosti v tajnosti bez veřejné kontroly; " +
        "je také jedním z prostředků, jak lze zachovat důvěru v soudy. Zviditelněním výkonu spravedlnosti přispívá publicita " +
        "k dosažení cíle článku 6 § 1, a sice spravedlivého procesu, jehož záruka je jednou ze základních zásad každé demokratické společnosti" +
        " [1]. \n" +
        "\n" +
        "Zásada veřejné povahy soudního řízení zahrnuje dva aspekty: konání veřejných jednání a veřejné vyhlášení rozsudků " +
        "[2]. Povinnost zaručit právo obžalovaného z trestného činu být přítomen v soudní síni je tedy jedním ze základních požadavků článku 6" +
        " [3]. Kromě toho právo být přítomen na jednání soudu umožňuje obviněnému ověřit správnost či účinnost své obhajoby " +
        "a porovnat ji s výpověďmi poškozených (obětí) a svědků [4]. Nárok na \"veřejné slyšení\" v článku 6 § 1 nutně " +
        "znamená právo na \"ústní slyšení\" [5].",
        "[1] (Riepan v. Rakousko, § 27; Krestovskiy v. Rusko, § 24; Sutter v. Švýcarsko, § 26); [2] (Tierce a další v. San Marino, § 93);" +
        " [3] (Hermi v. Itálie [GC], §§ 58–59; Sejdovic v. Itálie [GC], §§ 81 a 84; Arps v. Chorvatsko, § 28); [4] (Medenica v. Švýcarsko, § 54);" +
        " [5] (Döry v. Sweden, § 37)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ts.7.1", "1. Je porušením práva na spravedlivý proces, pokud ústní jednání neproběhlo alespoň v jedné instanci a nebyly dány výjimečné okolnosti, které by upuštění od tohoto jednání odůvodňovaly. ", " (Fischer proti Rakousku, § 44; Salomonsson proti Švédsku, § 36)"),
                    new PssChoice("ts.7.2", "2. Právo na ústní slyšení sice bylo přiznáno alespoň v jedné soudní instanci (Fischer v. Austria, § 44; Salomonsson v. Sweden, § 36), avšak toto ústní slyšení se nemohlo týkat zkoumání právních otázek a důležitých faktických otázek nebo posouzení, zda orgány správně zjistily skutkový stav. ", " (Ramos Nunes de Carvalho e Sá v. Portugal [GC], § 192)"),
                    new PssChoice("ts.7.3", "3. Je porušením práva na spravedlivý proces, pokud nebylo konáno ústní jednání v situaci, kdy do pravomoci soudu spadají otázky právní i významné otázky skutkové, nebo posouzení otázky, zda příslušné orgány správně zjistily skutkový stav. ", " ((Fischer proti Rakousku, § 44; Malhous proti České republice [velký senát], § 60)"),
                    new PssChoice("ts.7.4", "4. Je porušením práva na spravedlivý proces, pokud nebylo konáno ústní jednání v situaci, kdy takové jednání bylo nezbytné, aby si soud mohl mimo jiné i tímto způsobem ujasnit některé skutečnosti. ", " (Fredin proti Švédsku (č. 2), § 22; Lundevall proti Švédsku, § 39)"),
                    new PssChoice("ts.7.5", "5. Je porušením práva na spravedlivý proces, pokud nebylo konáno ústní jednání v situaci, kdy stěžovatel měl být vyslechnut ohledně skutečností týkajících se osobní újmy významných pro určení výše náhrady nebo, aby si soud mohl, mimo jiné, i tímto způsobem ujasnit některé skutečnosti. ", " (Göç proti Turecku [velký senát], § 51; Lorenzetti proti Itálii, § 33; Fredin proti Švédsku (č. 2), § 22; Lundevall proti Švédsku, § 39)"),
                    new PssChoice("ts.7.6", "6. Pokud soudní orgány poskytly nepřesné nebo neúplné informace o lhůtách pro ústní slyšení nebo předložení tvrzení či důkazů, což znemožnilo dostatečně zohlednit konkrétní okolnosti případu, nesmí příliš přísně uplatňovat příslušná procesní pravidla a judikaturu, což však neučinily a příslušná procesní pravidla a judikaturu přísně uplatňovaly. ", " (Clavien v. Švýcarsko (dec.) a Gajtani v. Švýcarsko)"),
                    new PssChoice("ts.7.7", "7. Právo na spravedlivý proces je porušeno, pokud stěžovateli nebylo včas doručeno předvolání na ústní jednání. ", " (Yakovlev proti Rusku, § 20–22)"),
                    new PssChoice("ts.7.8", "8. Právo na férový proces v situaci, kdy bylo vydáno rozhodnutí soudu v nepřítomnosti nelze omezit na pouhou naději, závislou na diskrečním posouzení soudu, na přiznání hmotného práva, o kterém se tvrdí, že účastníkovi svědčí, avšak k takovému omezení na pouhou naději došlo. ", " (Masson a Van Zon v. Nizozemsko, §§ 49-51; Roche v. Spojené království [GC], §§ 122-125; Ankarcrona v. Švédsko (dec.))"),
                    new PssChoice("ts.7.9", "9. I když článek 6 § 1 nelze vykládat tak, že přiznává žalobci právo na získání konkrétní formy doručení soudních písemností, například doporučenou poštou, v zájmu výkonu spravedlnosti by měl být účastník řízení vyrozuměn o soudním jednání tak, aby nejen znal datum, čas a místo jednání, ale také aby měl dostatek času na přípravu svého případu a na účast na soudním jednání, což se však nestalo. ", "(Vyacheslav Korchagin v. Russia, § 65)"),
                    new PssChoice("ts.7.10", "10. Absence ústního slyšení v odvolacím řízení v situaci, kdy bylo nutné přímo osobně v přítomnosti účastníka řízení posoudit předložené důkazy, je porušením spravedlivého procesu. ", " (Mtchedlishvili v. Georgia, § 39)"),
                    new PssChoice("ts.7.11", "11. Došlo k porušení práva na spravedlivý proces, pokud soud v trestním řízení nezkoumal, zda byl účastník řízení řádně informován o konání ústního slyšení a zda byla přijata opatření k zajištění přítomnosti obžalovaného na ústním slyšení. ", " (Colozza v. Italy, § 32; M.T.B. v. Turkey, §§ 49-53; Medenica v. Switzerland, § 54)"),
                    new PssChoice("ts.7.12", "12. Je porušením práva na spravedlivý proces, pokud soud řádně nezdůvodnil, proč nebylo důležité, aby se ústní slyšení v dané věci konalo. ", " (Mtchedlishvili v. Georgia, § 39)"),
                    //new PssChoice("ts.7.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Absence ústního slyšení přichází v úvahu, aniž by došlo k porušení práva na spravedlivý proces, pouze ve vyjímečných případech a za předpokladu řádného zdůvodnění ze strany soudu, proč nebylo důležité, aby ústní slyšení v dané věci, konalo. ", "(Grande Stevens and Others v. Italy, §§ 121-122; Mtchedlishvili v. Georgia, § 39)"),
                    new PssChoice("", "2. Není porušením práva na spravedlivý proces, pokud se dotyčná osoba vzdala práva na veřejné projednání výslovně nebo konkludentně, například, když se snaží vyhnout se projednávání případu před soudem útěkem. ", "(Lena Atanasova v. Bulgaria, § 52; see, for instance, Chong Coronado v. Andorra, §§ 42-45)"),
                    new PssChoice("", "3. Ústní slyšení se nemusí konat, pokud neexistují žádné otázky ohledně důvěryhodnosti nebo ohledně sporných skutečností, které vyžadují ústní předložení důkazů nebo křížový výslech svědků, a pokud obviněnému byla poskytnuta přiměřená příležitost předložit svůj případ písemně a zpochybnit důkazy proti němu. V této souvislosti je legitimní, aby vnitrostátní orgány upřesnily požadavky účinnosti a hospodárnosti. ", "(Jussila v. Finland [GC], §§ 41-43 and 47-48)"),
                    new PssChoice("", "4. Ústní slyšení před soudem se nekonalo, protože soud zdůvodnil, že daná část řízení se týkala pouze právních či technických otázek omezeného rozsahu, které nebyly nijak komplexní a mohly být posouzeny bez ústního řízení. ", "(Özmurat İnşaat Elektrik Nakliyat Temizlik San. ve Tic. Ltd. Şti. v. Turkey, § 37)"),
                    new PssChoice("", "5. Právo na veřejné slyšení nevylučuje uplatnění zvláštních pravidel týkajících se projednávání utajovaných skutečností a vyloučení veřejnosti v takových případech. ", "(Fazliyski v. Bulgaria, § 69)"),
                    new PssChoice("", "6. Právo na veřejné slyšení umožňuje vyloučení tisku a veřejnosti, a to buď na dobu celého nebo části procesu „v zájmu mravnosti, veřejného pořádku nebo národní bezpečnosti v demokratické společnosti“. ", "(B. A P. proti Spojenému království, § 39; Zagorodnikov proti Rusku, § 26)"),
                    new PssChoice("", "7. Právo na veřejné slyšení nevylučuje vyloučení tisku a veřejnosti na dobu celého nebo části procesu. ", "(B. A P. proti Spojenému království, § 39; Zagorodnikov proti Rusku, § 26)"),
                    new PssChoice("", "8. Právo na veřejné slyšení umožňuje vyloučení tisku a veřejnosti „když to vyžadují zájmy nezletilých nebo ochrana soukromého života účastníků“: zájmy nezletilých nebo ochrana soukromého života účastníků řízení to vyžadují například v řízeních o pobytu nezletilých po odloučení rodičů ve sporech mezi členy téže rodiny. ", "(B. A P. proti Spojenému království, § 38)"),
                    new PssChoice("", "9. Absence jednání před soudem druhé a třetí instance není porušením práva na spravedlivý proces za předpokladu, že takové jednání proběhlo v první instanci. Řízení o přípustnosti opravného prostředku a řízení pouze o právních otázkách (na rozdíl od řízení o otázkách skutkových) tedy může vyhovět požadavkům článku 6 odst. 1, i když dotčený účastník neměl možnost být osobně vyslechnut odvolacím nebo kasačním soudem.", "(Helmers proti Švédsku, § 36, naproti tomu viz § 38–39, Miller proti Švédsku, § 30)"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "VIII. Průtahy v trestním řízení",
        "V trestních věcech je cílem článku 6 § 1 Úmluvy, díky kterému má každý právo na slyšení v přiměřené lhůtě zajistit," +
        " aby dotčený účastník řízení nebyl nepřiměřeně dlouho v pozici obviněného či obžalovaného a aby bylo rozhodnuto o obvinění" +
        " či obžalobě v přiměřené lhůtě bez průtahů [1]. Pojem obvinění a obžaloby musí být chápán v autonomním smyslu " +
        "podle článku 6 § 1 Úmluvy a s ním související judikatury Evropského soudu pro lidská práva [2], nikoli pouze ve smyslu českého práva. " +
        "Přiměřenost lhůty řízení je třeba určit s ohledem na okolnosti případu, které vyžadují" +
        " celkové posouzení [3]. Článek 6 Úmluvy vyžaduje, aby soudní řízení bylo rychlé, ale rovněž stanoví obecnější zásadu " +
        "řádného výkonu spravedlnosti, kdy je třeba nalézt spravedlivou rovnováhu mezi různými aspekty tohoto základního požadavku [4].  " +
        "\n" +
        "\n" +
        "\"Přiměřená lhůta\" může začít běžet před tím, než se věc dostala před soud [5], například od okamžiku zatčení [6]," +
        " nebo od okamžiku, kdy je osoba obviněna [7], nebo od momentu započetí předběžného vyšetřování [8] nebo od okamžiku výslechu " +
        "žalobce jako svědka podezřelého ze spáchání trestného činu [9]. V každém případě je však relevantním okamžikem ten okamžik, " +
        "kdy se obviněný dozvěděl o svém obvinění nebo když byl podstatně dotčen opatřeními přijatými v rámci vyšetřování vedoucímu" +
        "k zahájení trestního řízení [10]. „Přiměřená lhůta“ podle čl. 6 Úmluvy se vztahuje na celé trestní řízení [11]," +
        "včetně odvolacího řízení [12]. Tato lhůta, která má být vzata v úvahu, trvá nejméně do zproštění obžaloby nebo do vydání" +
        "odsuzujícího rozsudku [13]. " +
        "\n" +
        "\n" +
        "Výkon rozhodnutí vydaného kterýmkoli soudem v trestní věci je považován za nedílnou součást hlavního líčení ve " +
        "smyslu čl. 6 Úmluvy [14]. Osoba přestává být takto dotčena pouze od okamžiku, kdy je jí rozhodnutí sděleno" +
        "[15] nebo pokud nejistota ohledně jeho statusu v souvislosti s trestním řízení je odstraněna jinými " +
        "procesními prostředky [16]. Ve vztahu k vymezení „přiměřené lhůty řízení“ článek 6 nevyžaduje, " +
        "aby žadatelé aktivně spolupracovali se soudními orgány, a ani jim nelze vyčítat to, že plně využívají opravných prostředků," +
        "které mají k dispozici podle vnitrostátního práva.",
        "[1] (Wemhoff v. Německo, § 18; Kart v. Turecko [GC], § 68); [2] (McFarlane v. Irsko [GC], § 143);" +
        " [3] (Boddaert v. Belgie, §36); [4] (Boddaert v. Belgie, §39); [5] (Deweer v. Belgie, § 42);" +
        " [6] (Wemhoff v. Německo, § 19); [7] (Neumeister v. Rakousko, § 18); [8] (Ringeisen v. Rakousko, § 110; Šubinski v. Slovinsko, §§ 65-68);" +
        " [9] (Kalēja v. Lotyšsko, § 40); [10] (Mamič v. Slovinsko (č. 2), §§ 23-24); Liblík a další v. Estonsko, § 94);" +
        " [11] (König v. Německo, § 98); [12] (Delcourt v. Belgie, §§ 25–26; König v. Německo, § 98; V. v. Spojené království [GC], § 109);" +
        " [13] (Wemhoff v. Německo, § 18); [14] (Assanidze v. Georgia [GC], § 181); [15] (Boržhonov v. Rusko, §38; Niedermeier v. Německo (dec.));" +
        " [16] (Gröning v. Německo (dec.))",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ts.8.1", "1. I když určité části trestního řízení byly samy o sobě vedeny přiměřenou rychlostí, celková délka trestního řízení přesáhla přiměřenou dobu. Bez ohledu na to, zda je projednávaná věc komplexní či nikoli, došlo ke zdlouhavým obdobím neaktivity, jež nejsou souladu s požadavkem přiměřenosti délky řízení a právem na spravedlivý proces. Skutečnost, že věc je složitá, neomlouvá nepřiměřenou délku řízení. ", " (Dobbertin v. France, § 44, Adiletta v. Italy, § 17; Rutkowski and Others v. Poland, § 137)"),
                    new PssChoice("ts.8.2", "2. Zaneprázdněnost soudů není nikdy způsobilým důvodem pro omluvu nepřiměřenosti délky řízení a porušení práva na spravedlivý proces v tomto ohledu, neboť čl. 6 Úmluvy vyžaduje, aby si její členské státy zorganizovaly své soudní systémy tak, aby dostály požadavku přiměřené délky řízení. ", " (Eckle v. Germany, § 92; Abdoella v. The Netherlands, § 24; Dobbertin v. France, § 44)"),
                    new PssChoice("ts.8.3", "3. Je porušením přiměřenosti délky řízení a práva na spravedlivý proces, pokud soud nezajistil přítomnost relevantních účastníků řízení (svědci, spoluobžalovaní a jejich zástupci), v důsledku čehož došlo k opakovaným odkladům projednávání věci před soudem. ", " (Tychko v. Russia, § 68)"),
                    new PssChoice("ts.8.4", "4. Je porušením přiměřenosti délky řízení a práva na spravedlivý proces, pokud věc sama o sobě nebyla složitá s výjimkou počtu účastníků řízení (35), avšak řízení trvalo 9 let a 7 měsíců, a to i přes vysokou zaneprázdněnost soudů v důsledku souběžně probíhajících nepokojů. ", " (Milasi v. Italy, §§ 14-20)"),
                    new PssChoice("ts.8.5", "5. Je porušením přiměřenosti délky řízení a práva na spravedlivý proces, pokud řízení trvalo 13 let a 4 měsíce, a to i přes vysokou zaneprázdněnost soudů a politické nepokoje v regionu. ", " (Baggetta v. Italy, §§ 20-25)"),
                    new PssChoice("ts.8.6", "6. Je porušením přiměřenosti délky řízení a práva na spravedlivý proces, pokud řízení trvalo 5 let, 5 měsíců a 18 dní, včetně 33 měsíců mezi vynesením rozsudku a doručením jeho písemného vyhotovení, aniž by posléze uvedené vedlo k uplatnění disciplinárních opatření vůči odpovědnému soudci. ", " (B. v. Austria, §§ 48-55). "),
                    new PssChoice("ts.8.7", "7. Je porušením přiměřenosti délky řízení a práva na spravedlivý proces, pokud řízení trvalo 12 let, 7 měsíců, a 10 dní, aniž by věc byla zásadně složitá či aniž by účastník řízení uplatňoval zdržovací taktiku. ", " (Clinique Mozart SARL v. France, §§ 34-36)"),
                    //new PssChoice("ts.8.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Není porušením přiměřenosti délky řízení a práva na spravedlivý proces, pokud je dlouhá délka řízení důsledkem složitosti dané věci, jež může vyplývat z jednoho z následujících faktorů: vysoký počet obvinění, vysoký počet osob, jež jsou součástí projednávané věci, jako například vysoký počet obžalovaných nebo svědků, mezinárodní rozměr věci, například v situaci, kdy se dotčené komplexní transakce odehrávaly v řadě zemí mezi velkým množstvím právnických osob, což vyžadovalo využití pomoci Interpolu a úmluv o mezinárodní soudní spolupráci nebo, v situaci, kdy se jednalo o otázky mezinárodní legalizace výnosů z trestné činnosti. ", "(Neumeister v. Austria, § 20; C.P. and Others v. France, § 30)"),
                    new PssChoice("", "2. Není porušením přiměřenosti délky řízení a práva na spravedlivý proces, pokud je dlouhá délka řízení důsledkem chování účastníka řízení dané věci, jež může vyplývat z jednoho z následujících faktorů: účastník řízení úmyslně zdržoval vyšetřování, což je objektivně zřejmé ze spisu (I.A. v. France, § 121); účastník řízení zdržoval soudní řízení systematicky podávanými námitkami ohledně podjatosti soudců (Eckle v. Germany, § 82); účastník řízení se vyhýbal trestnímu řízení pobytem mimo zemi, kde proti němu bylo trestní řízení vedeno; zdravotní stav účastníka řízení vedl k průtahům v řízení. ", "(Vayiç v. Turkey, § 44; Yaikov v. Russia, § 76)"),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "IX. Presumpce neviny – čl. 6 odst. 2 Úmluvy",
        "Odstavec 2 článku 6 Úmluvy obsahuje zásadu presumpce neviny. Ta vyžaduje mimo jiné, aby: " +
        "(1) členové soudu při plnění svých povinností nezačali soudit s předsudkem, že obžalovaný spáchal trestný čin či činy, " +
        "ze kterých je obžalován; (2) vycházeli z principu, že důkazní břemeno je na obžalobě, a (3) jakékoli pochybnosti svědčily " +
        "ve prospěch obžalovaného [1]. " +
        "\n" +
        "\n" +
        "Presumpce neviny se vztahuje na celé trestní řízení, nikoli však na jiná než trestní řízení, které trestnímu řízení mohou předcházet. " +
        "Presumpce nepůsobí již poté, co nabyl právní moci odsuzující rozsudek. Presumpce neviny se vztahuje se pouze na trestné činy, " +
        "za které byla osoba odsouzena a z důvodů, za které byla odsouzena. Presumpce neviny se vztahuje i na odvolací či dovolací řízení " +
        "a platí i posmrtně. Konečně, presumpce neviny se vztahuje i na osobu, která se k trestnému činu přiznala [2]." +
        "\n" +
        "\n" +
        "Presumpce neviny se použije vedle trestního řízení jako takového i na věcně související řízení (souběžná řízení) " +
        "a časově související řízení (následná řízení). Aby se presumpce neviny použila i na řízení související s trestním řízením, " +
        "je nutné prokázat souvislost takového řízení s trestním řízením, například když proti osobě bylo zahájeno trestní i disciplinární " +
        "řízení pro podezření, že se dopustila trestných činů a kde disciplinární sankce významně přispěla ke zjištění, zda se tato osoba " +
        "skutečně dopustila trestných činů, z nichž byl obviněna v trestním řízení [3]." +
        "\n" +
        "\n" +
        "Presumpce neviny rovněž chrání osoby, které byly zproštěny obžaloby nebo v souvislosti s nimiž bylo trestní " +
        "řízení zastaveno před tím, aby s nimi veřejní činitelé a orgány v následných jiných řízeních zacházelyi, jako by se ve " +
        "skutečnosti dopustily trestného činu, z něhož byly obviněny. Bez ochrany, která by zajistila respektování osvobozujícího " +
        "rozsudku nebo rozhodnutí o zastavení trestního řízení, by se záruky spravedlivého procesu podle článku 6 § 2 mohly stát jen " +
        "teoretickými a iluzorními. Po skončení trestního řízení může mít absence uvedených záruk spravedlivého procesu " +
        "negativní dopad na pověst osoby a způsob, jakým je osoba dříve dotčená trestním řízením vnímána veřejností [4]." +
        "\n" +
        "\n" +
        "Presumpce neviny může být porušena nejen soudcem nebo soudem, ale i jinými orgány veřejné moci [5]." +
        "To platí například pro policisty a policejní úředníky [6]; prezidenta republiky [7]; předsedu vlády nebo ministra vnitra [8];" +
        "ministra spravedlnosti [9]; předsedu parlamentu [10]; státního zástupce [11] a další osoby, jež jsou orgány činnými v trestním řízení, " +
        "nebo pro ně pracují [12]. Rovněž to platí pro soudce, s tím, že vyjádření soudců " +
        "podléhají přísnější kontrole než výroky orgánů činných v trestním řízení [13].",
        "[1] (Barberà, Messegué a Jabardo v. Španělsko, §77); [2] (Axel Springer SE and RTL Television GmbH v. Germany, § 40); " +
        "[3] (Kemal Coşkun v. Turecko, § 44; viz také Istrate v. Rumunsko, §§ 63-66); [4] (Allen v. The United Kingdom [GC], § 94); " +
        "[5] (Allenet de Ribemont v. Francie, §36; Daktaras v. Litva, § 42; Petyo Petkov v. Bulharsko, § 91); " +
        "[6]  (Allenet de Ribemont v. Francie, §§ 37 a 41); [7] (Peša v. Chorvatsko, §149); [8] (Gutsanovi v. Bulharsko,§§ 194-198); " +
        "[9]  (Konstas v. Řecko,§§ 43 a 45); [10] (Butkevičius v. Litva, § 53); " +
        "[11] (Daktaras v. Litva, § 42); [12] (Khuzhin a další v. Rusko, § 96); [13] (Pandy v. Belgie, § 43)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ts.9.1", "1. Jasné prohlášení soudce nebo orgánu činného v trestním řízení či osoby vykonávající veřejnou moc před okamžikem nabytí právní moci odsuzujícího rozsudku, že dotyčná osoba spáchala určitý trestný čin, je porušením presumpce neviny a zároveň porušením práva na spravedlivý proces podle Úmluvy (Ismoilov and Others v. Russia, § 166; Nešťák v. Slovakia, § 89), a to i v případě, že: ", "\n" +
                        "- takové prohlášení bylo učiněno neúmyslně; \n" +
                        "(Avaz Zeynalov v. Azerbaijan, § 69)\n" +
                        "\n" +
                        "- bylo učiněno neobratně či nešťastně; \n" +
                        "(Allen v. The United Kingdom [GC], § 126; Lähteenmäki v. Estonia, § 45)\n" +
                        "\n" +
                        "- je-li takové prohlášení v doslovném znění v pořádku, ale jeho skutečný význam presumpci neviny porušuje.\n" +
                        "(Lavents v. Latvia, § 126)\n" +
                        "\n" +
                        "K takovému porušení v daném případě došlo."),
                    new PssChoice("ts.9.2", "2. Vyjádření „pevného přesvědčení, že se určitá osoba opět dopustila trestného činu“ v řízení o pozastavení trestu odnětí svobody ve zkušební době porušilo článek 6 § 2 Úmluvy, tedy je porušením práva na spravedlivý proces. ", "(El Kaada v. Germany, §§ 61-63)"),
                    new PssChoice("ts.9.3", "3. Je porušením presumpce neviny a zároveň porušením práva na spravedlivý proces podle Úmluvy, pokud soudce nebo orgán činný v trestním řízení či osoby vykonávající veřejnou moc učiní jasné prohlášení, že dotyčná osoba spáchala daný trestný čin poté, co nabylo právní moci rozhodnutí, jímž se daná osoba zprošťuje viny. ", " (Rushiti v. Austria, § 31; O. V. Norway, § 39; Geerings v. The Netherlands, § 49; Paraponiaris v. Greece, § 32)"),
                    new PssChoice("ts.9.4", "4. Je porušením presumpce neviny a zároveň porušením práva na spravedlivý proces podle Úmluvy, pokud soudce nebo orgán činný v trestním řízení či osoby vykonávající veřejnou moc učiní jasné prohlášení, že dotyčná osoba spáchala daný trestný čin předtím, než nabylo právní moci rozhodnutí, jímž se daná osoba uznává vinnou z trestného činu, za který byla odsouzena. ", "(Matijašević v. Serbia, § 49; Nešťák v. Slovakia, § 90)"),
                    new PssChoice("ts.9.5", "5. Je porušením presumpce neviny a zároveň porušením práva na spravedlivý proces podle článku 6 § 2 Úmluvy, pokud ministr vnitra po zatčení dotčené osoby, ale před jejím slyšením před soudcem, učiní prohlášení publikované v časopise, ve kterém zdůrazňuje, že to, co dotčená osoba udělala, představovalo propracovaný systém machinací fungující po řadu let. ", "(Gutsanovi v. Bulgaria (§§ 195-201)"),
                    //new PssChoice("ts.9.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Není porušením presumpce neviny a zároveň není porušením práva na spravedlivý proces, že určitá osoba je pouze podezřelá z toho, že se dopustila trestného činu. ", "(Ismoilov and Others v. Russia, § 166; Nešťák v. Slovakia, § 89)"),
                    new PssChoice("", "2. Prohlášení porušující presumpci neviny neporušuje právo na spravedlivý proces, pokud se netýká stejného trestného činu, u něhož se uplatňuje ochrana presumpce neviny v rámci trestního řízení ohledně uvedeného trestného činu. ", "(Larrañaga Arando and Others v. Spain (dec.), § 48)"),
                    new PssChoice("", "3. Právo na presumpci neviny se neuplatní v situaci, kdy ohledně dotčené osoby doposud nebylo vzneseno “obvinění z trestného činu” ve smyslu čl. 6 Úmluvy a je učiněno soukromou osobou, například novináři. ", "(Gogitidze and Others v. Georgia, §§ 125-126; Larrañaga Arando and Others v. Spain (dec.), §§ 45-46; Khodorkovskiy and Lebedev v. Russia (no.2), § 543)"),
                    new PssChoice("", "4. Právo na presumpci neviny a spravedlivý proces nebrání organům veřejné moci, aby informovaly veřejnost obezřetně a citlivě, jakož i v souladu s vnitrostátními pravidly o probíhajících trestních vyšetřováních a řízeních. ", "(Fatullayev v. Azerbaijan, § 159; Allenet de Ribemont v. France, § 38; Garycki v. Poland, § 69)"),
                    new PssChoice("", "5. Porušení práva presumpce neviny nemusí soud posuzovat z úřední povinnosti, tedy v situaci, kdy námitka porušení práva presumpce neviny nebyla dotčeným účastníkem trestního řízení vznesena řádným způsobem v rámci takového řízení, ať už v první instanci či odvolacím řízení. ", "(Czajkowski v. Poland (dec.))"),
                    new PssChoice("", "6. Není porušením presumpce neviny práva na spravedlivý proces, pokud předseda vlády oznámí v televizi bez dalšího fakt, že určitá osoba byla zadržena či vzata do vazby. ", "(Gutsanovi v. Bulgaria (§§ 195-201)"),
                    new PssChoice("", "7. Prohlášení předsedy politické strany, která je právně a finančně nezávislá na státu v kontextu vzrušené politické debaty, nelze považovat za prohlášení veřejného činitele jednajícího ve veřejném zájmu podle článku 6 § 2 Úmluvy, a tudíž ani za porušení práva na spravedlivý proces. ", "(Mulosmani v. Albania, § 141)"),
                    //new PssChoice("","","",false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "X. Práva obhajoby – čl. 6, odst. 3, písm. (a) Úmluvy: právo na včasné seznámení se s povahou a důvodem obvinění v jazyce, kterému obviněný rozumí",
        "V trestních věcech je poskytnutí úplných a podrobných informací týkajících se obvinění proti obžalovanému," +
        " a tedy právní kvalifikace činu, kterou by soud mohl v této věci přijmout, základním předpokladem pro zajištění " +
        "spravedlivého řízení [1]. Odstavce a) a b) článku 6 § 3 jsou spojeny v tom, že právo být informován o povaze a " +
        "příčině obvinění musí být posuzováno s ohledem na právo obviněného připravit svou obhajobu [2]. ",
        "[1] (Pélissier a Sassi v. Francie [GC], § 52; Sejdovic v. Itálie [GC], § 90; Varela Geis v. Španělsko, § 42)" +
        "[2] (Pélissier a Sassi v. Francie [GC], § 54; Dallos v. Maďarsko, § 47)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ts.10.1", "1. Obvinění z trestného činu, které bylo účastníkovi řízení sděleno, neobsahuje podrobné informace o trestných činech, z nichž je obžalovaný obviněn, nebo neobsahuje dostatečnou identifikaci skutku uvedením místa a data spáchání trestného činu, případně identifikaci oběti, pokud existuje. Tím došlo k porušení práva na spravedlivý proces. ", " (Brozicek v. Itálie, § 42)"),
                    new PssChoice("ts.10.2", "2. Obvinění z trestného činu, které bylo účastníkovi řízení sděleno, neobsahuje odkaz na příslušná ustanovení trestního zákoníku či jiného zákonného předpisu vymezující skutkovou podstatu trestného činu, a ani neobsahuje právní kvalifikaci skutků, jež jsou obviněném kladeny za vinu ve světle uvedených ustanovení trestní zákonných předpisů. ", " (Mattoccia v. Italy, § 59; Penev v. Bulgaria, §§ 33 and 42)"),
                    new PssChoice("ts.10.3", "3. Povinnost informovat obviněného spočívá výhradně na obžalobě a nelze ji pasivně dodržet zpřístupněním informací, aniž by na to byla upozorněna obhajoba, k čemuž však došlo, čímž zároveň došlo k porušení práva na spravedlivý proces. ", "(Mattoccia v. Itálie, §65; Chichlian a Ekindjian v. Francie, Zpráva Komise, § 71)"),
                    new PssChoice("ts.10.4", "4. O tom, že obviněný skutečně obdržel informace o povaze a důvodu obvinění v jazyce, kterému obviněný rozumí neexistuje důkaz; doručení informace o povaze a důvodu obviněnému je založeno pouze na fikci doručení či obdobné právní domněnce doručení, což se v daném případě stalo, čímž zároveň došlo k porušení práva na spravedlivý proces. ", "(C. V. Italy, Commission decision)"),
                    new PssChoice("ts.10.5", "5. V případě osoby s duševními obtížemi jsou orgány povinny přijmout další opatření, aby byla osoba podrobně informována o povaze a příčině obvinění proti němu, což se v daném případě nestalo, čímž zároveň došlo k porušení práva na spravedlivý proces. ", "(Vaudelle v. France, § 65)"),
                    new PssChoice("ts.10.6", "6. Obviněný nebyl řádně a plně informován o změně v jeho obvinění, včetně důvodu a právní kvalifikace, tak aby měl dostatečný čas a dostatečnou možnost připravit svou obhajobu, čímž došlo k porušení práva na spravedlivý proces. ", " (Mattoccia v. Italy, §§ 60 nebo 61; Bäckström and Andersson v. Sweden (dec.); Varela Geis v. Spain, § 54)"),
                    new PssChoice("ts.10.7", "7. Je-li prokázáno nebo existují-li důvody se domnívat, že obviněný nemá dostatečné znalosti jazyka, v němž jsou informace o jeho obvinění poskytnuty, musí mu orgány poskytnout překlad obvinění, a to zdarma. ", "(Brozicek v. Italy, § 41; Tabaï v. France (dec.); Luedicke, Belkacem and Koç v. Germany, § 45)"),
                    //new PssChoice("ts.10.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Informace o obvinění nemusí nutně uvádět důkazy, na nichž je obvinění založeno.  ", "(X. V. Belgie, rozhodnutí Komise; Collozza a Rubinat v. Itálie, zpráva Komise)"),
                    new PssChoice("", "2. Není porušením práva na spravedlivý proces, pokud se skutečnost, že se obviněný nemohl včas seznámit s povahou a důvodem obvinění v jazyce, kterému obviněný rozumí, je přičitatelná chování obviněného. ", " (Erdogan v. Turkey, Commission decision; Campbell and Fell v. The United Kingdom, § 96)"),
                    new PssChoice("", "3. Skutkové podrobnosti trestného činu mohou být doplněny po sdělení obvinění během následného řízení.", "(Sampech v. Italy (dec.), § 110; Pereira Cruz and Others v. Portugal, § 198)"),
                    new PssChoice("", "4. Nedochází k porušení práva na včasné seznámení se s povahou a důvodem obvinění v jazyce, kterému obviněný rozumí, a tedy ani k porušení práva na spravedlivý proces, pokud vady ve sdělení obvinění či obžaloby byly v odvolacím řízení napraveny, pokud měl obžalovaný před vyššími soudy předložit svou obhajobu ve vztahu k přeformulovanému obvinění a zpochybnit jeho odsouzení ve všech relevantních právních a skutkových aspektech. ", " (Dallos v. Hungary, §§ 49-52; Sipavičius v. Lithuania, §§ 30-33; Zhupnik v. Ukraine, §§ 39-43; I.H. and Others v. Austria, §§ 36-38; Gelenidze v. Georgia, § 30)"),
                    new PssChoice("", "5. Dostatečné informace o obvinění však mohou být rovněž poskytnuty ústním překladem obžaloby, pokud to obviněnému, resp. obžalovanému umožní připravit svou obhajobu.", "(Husain v. Italy (dec.))"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "XI. Práva obhajoby – Čl. 6, odst. 3, písm. (b) Úmluvy: právo na přiměřený čas na přípravu obhajoby",
        "Článek 6 § 3 (b) Úmluvy chrání obviněného před nepřiměřeně rychlým soudním řízením [1]." +
        "Ačkoli je důležité vést řízení přiměřenou rychlostí, nemělo by to být provedeno na úkor procesních práv jedné ze" +
        "stran [2]. Článek 6 § 3 (b) Úmluvy se týká dvou prvků řádné obhajoby, a sice otázky, zda měl obviněný dostatečné " +
        "možnosti k tomu, aby si připravil svou obhajobu, a zda měl k této přípravě přiměřený čas." +
        "Otázka přiměřenosti času a možností se hájit poskytnutých obviněnému musí být posouzena s ohledem na okolnosti " +
        "každého konkrétního případu [3]. " +
        "\n" +
        "\n" +
        "Obhajoba jménem obviněného může zahrnovat vše, co je „nezbytné“ pro přípravu soudního řízení. Obviněný musí " +
        "mít možnost zorganizovat svou obhajobu vhodným způsobem a bez omezení, pokud jde o schopnost předložit soudu" +
        "v trestním řízení všechny relevantní argumenty obhajoby, a ovlivnit tak výsledek tohoto řízení [4]; každý obviněný" +
        "z trestného činu musí mít rovněž možnost seznámit se pro účely přípravy své obhajoby s výsledky vyšetřování " +
        "prováděných v průběhu trestního řízení [5]. Při posuzování, zda měl obviněný dostatek času na přípravu své obhajoby," +
        "je třeba vzít zvláštní ohled na povahu řízení, jakož i na složitost věci a fázi řízení [6]. ",
        "[1] (Kröcher a Möller v. Švýcarsko, rozhodnutí Komise; Bonzi v. Švýcarsko, rozhodnutí Komise; Borisova v. Bulharsko, § 40; " +
        "Malofeyeva v. Rusko, § 115; Gafgaz Mammadov v. Ázerbájdžán, § 76–82); [2] (OAO Neftyanaya Kompaniya Yukos v. Rusko, § 540); " +
        "[3] (Iglin v. Ukrajina, § 65; Galstyan v. Arménie, § 84); [4] (Can v. Rakousko, zpráva Komise, § 53; Gregačević v. Chorvatsko, § 51); " +
        "[5] (Huseyn a další v. Ázerbájdžán, §175; OAO Neftyanaya Kompaniya Yukos v. Rusko, § 538); [6] (Gregačević v. Chorvatsko, § 51)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ts.11.", "1. Právo na přípravu obhajoby v přiměřené době, a též právo na spravedlivý proces je porušeno, když je obviněnému bráněno v získávání kopií příslušných dokumentů ze spisu a sestavování a používání jakýchkoli pořízených poznámek či návrhů, k čemuž v daném případě došlo. ", "(Rasmussen v. Polsko, §§ 48-49; Moisejev v. Rusko, §§ 213-218; Matyjek v. Polsko, § 59; Seleznev v. Rusko, §§ 64-69)"),
                    new PssChoice("ts.11.", "2. Právo na přípravu obhajoby v přiměřené době, a též právo na spravedlivý proces bylo porušeno, pokud účastník řízení a jeho obhájce měli pouhých 5 dní na přípravu obhajoby, kdy spis čítal cca 1500 stran. ", " (Lambin v. Russia, §§ 43-48)"),
                    new PssChoice("ts.11.", "3. Právo na přípravu obhajoby v přiměřené době, a též právo na spravedlivý proces bylo porušeno, pokud obhájce a účastník řízení měl pouze velmi krátký čas ke studiu spisu nebo mezi sdělením obvinění a ústním jednáním u soudu uplynula pouze velmi krátká doba. ", " (Huseyn and Others v. Azerbaijan, § 174-178; Iglin v. Ukraine, §§ 70-73; Vyerentsov v. Ukraine, §§ 75-77)"),
                    new PssChoice("ts.11.", "4. Obhajobě musí být poskytnuta dodatečná lhůta poté, co nastaly určité události v řízení, aby obhajoba mohla reagovat na takové události, například úpravou obhajoby, přípravy žádostí, podání odvolání atd. Tyto „události“ mohou zahrnovat změnu v obžalobě, předložení nových důkazů obžalobou nebo náhlou a drastickou změnou názoru znalce během hlavního líčení. K takovému poskytnutí dodatečné lhůty ovšem nedošlo, čímž došlo k porušením práva na spravedlivý proces.", "((Miminoshvili v. Rusko, § 141; Pélissier a Sassi v. Francie [GC], § 62; G.B. v. Francie,§§ 60-62, 69-70)"),
                    new PssChoice("ts.11.", "5. Není-li plně odůvodněný rozsudek k dispozici před uplynutím lhůty pro podání opravného prostředku, musí být obviněnému poskytnuty dodatečně veškeré informace a čas, aby mohl podat informované odvolání. Takové informace nebo čas však poskytnuty nebyly, čímž došlo k porušení práva na spravedlivý proces. ", " (Zoon v. the Netherlands, §§ 40-50; Baucher v. France, §§ 46-51)"),
                    new PssChoice("ts.11.", "6. Je zásadní, aby se obviněný i jeho obhájce mohli účastnit řízení a přednést obhajobu, aniž by trpěli nadměrnou únavou, k čemuž však došlo a čímž bylo porušeno jeho právo na spravedlivý proces.", " (Barberà, Messegué and Jabardo v. Spain, § 70; Makhfi v. France, § 40; Fakailo (Safoka) and Others v. France, § 50)"),
                    new PssChoice("ts.11.", "7. Schopnost obviněného či obžalovaného soustředit se a uplatňovat mentální dovednosti při své obhajobě byly narušeny, nikoli jeho vinou, čímž bylo porušeno jeho právo na spravedlivý proces. ", " (Mayzit v. Russia, § 81)"),
                    new PssChoice("ts.11.", "8. Podmínky zadržení neumožnily obviněnému či obžalovanému číst a psát s přiměřeným stupněm koncentrace, včetně instruování svého právního zástupce.", " (Moiseyev v. Russia, § 221)"),
                    new PssChoice("ts.11.", "9. Kumulativní účinek vyčerpání způsobeného zdlouhavými převozy mezi věznicemi – ve špatných podmínkách a s méně než osmi hodinami odpočinku, opakovaných po dobu čtyř dnů v týdnu po dobu delší než čtyři měsíce – vážně narušil schopnost obviněného, resp. obžalovaného sledovat řízení, předkládat tvrzení, dělat si poznámky a instruovat své právníky Tím bylo porušeno právo na spravedlivý proces.", " (Razvozzhayev v. Russia and Ukraine and Udaltsov v. Russia, §§ 253-254)"),
                    new PssChoice("ts.11.", "10. Umístění obžalovaného do skleněné kabiny během soudního jednání brání jeho účinné konzultaci s advokátem, čímž došlo k porušení práva na spravedlivý proces. ", "(Yaroslav Belousov v. Russia, §§ 148-153)"),
                    new PssChoice("ts.11.", "11. Soud z vlastní iniciativy neodložil soudní jednání, aby umožnil přípravu obhajoby v přiměřené době, v případě, že ho o to účastník řízení požádal a měl k tomu reálné důvody. ", "(Sadak and Others v. Turkey (no. 1), § 57; Sakhnovskiy v. Russia [GC], §§ 103 and 106)"),
                    //new PssChoice("ts.11.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Neměl-li účastník dostatečný čas na přípravu obhajoby, musí tento účastník požádat soud o odložení či odročení soudního jednání. Pokud tak neučinil, nejedná se o porušení práva na spravedlivý proces.", "(Campbell and Fell v. the United Kingdom, § 98; Bäckström and Andersson v. Sweden (dec.); Craxi v. Italy (no. 1), § 72)"),
                    new PssChoice("", "2. Obviněnému nemusí být umožněn přímý přístup ke spisu, stačí, aby byl svými zástupci informován o materiálu ve spise, pokud obviněnému nebylo umožněno vést vlastní obhajobu, odepření přístupu ke spisu představuje porušení práva na obhajobu, a též porušení práva na spravedlivý proces.", "(Kremzow v. Rakousko, § 52; Foucher v. Francie, §§ 33-36)"),
                    //new PssChoice("","","", false)
                ],
                false
            )
        ]
    ),
    new PssSection(
        "XII. Práva obhajoby – Čl. 6, odst. 3, písm. (c) Úmluvy: právo hájit se osobně nebo prostřednictvím obhájce vlastního výběru ",
        "Právo každého obviněného z trestného činu účinně se hájit prostřednictvím advokáta – obhájce, je jedním " +
        "ze základních rysů spravedlivého procesu [1]. Každá osoba, vůči níž bylo vzneseno trestní obvinění, musí být chráněna" +
        " článkem 6 § 3 písm. c) Úmluvy v každé fázi řízení [2]. Zatímco článek 6 § 3 b) je vázán na přípravu hlavního líčení, " +
        "článek 6 § 3 písm. c) Úmluvy dává obviněnému obecnější právo na pomoc a podporu obhájcem po celou dobu trestního" +
        " řízení [3]. Stejně tak způsob, jakým má být článek 6 § 3 písm. c) Úmluvy uplatňován ve vztahu k odvolacím nebo" +
        " dovolacím soudům, závisí na zvláštních rysech řízení před těmito soudy [4]. Předmět a účel článku 6 Úmluvy jako" +
        " celku ukazuje, že osoba obviněná z trestného činu je oprávněna účastnit se soudního jednání [5]. Právo na právní " +
        "pomoc zahrnuje celou řadu služeb spojených s poskytování právní pomocí: prodiskutování případu, " +
        "organizace obhajoby, shromažďování důkazů ve prospěch obviněného či obžalovaného, příprava " +
        "k výslechu, podpora obviněného v tísni a ověření podmínek zadržení [6]." +
        "\n" +
        "\n" +
        "Podezřelému by měl být umožněn přístup k právní pomoci od okamžiku, kdy je proti němu podáno „trestní obvinění“" +
        "v autonomním významu Úmluvy [7]. V této souvislosti osoba nabývá postavení podezřelé osoby, což vyvolává použití" +
        "záruk podle článku 6 Úmluvy, nikoli tehdy, je-li jí tento status podezřelé osoby formálně přidělen," +
        "ale pokud mají vnitrostátní orgány hodnověrné důvody pro podezření, že se tato osoba podílela na trestném činu" +
        "[8]. Právo na přístup k obhájci tak například vzniká nejen tehdy, je-li osoba vzata do vazby nebo vyslýchána policií" +
        "[9], ale i při procesních úkonech, jako jsou identifikace, rekognice či rekonstrukce událostí na místě" +
        "[10], jakož i při pátracích a zajišťovacích operacích [11]. " +
        "\n" +
        "\n" +
        "Kromě toho právo obviněného účinně se účastnit trestního řízení zahrnuje obecně nejen právo být přítomen průběhu tohoto řízení, " +
        "ale také právo na právní pomoc, v případě potřeby [12] zahrnuje právo na „praktickou a účinnou“ právní pomoc. " +
        "Pouhé ustanovení obhájce za účelem poskytnutí právní pomoci ještě nezajišťuje, že právní pomoc bude účinně poskytnuta, " +
        "protože ustanovený obhájce může zemřít, vážně onemocnět, či mu může být znemožněno právní pomoc poskytovat nebo " +
        "se sám může vyhýbat svým povinnostem [13]. Jakékoli omezení ovlivňující vztah mezi klientem a obhájcem, ať už " +
        "faktické nebo výslovné, nesmí popřít charakter účinnosti právní pomoci, na kterou má obhajovaný nárok [14].",
        "[1] (Salduz v. Turecko  [GC], § 51; Ibrahim a další v. Spojené království  [GC], § 255; Simeonovi v. Bulharsko  [GC], § 112; " +
        "Beuze v. Belgie  [GC], § 123); [2] (Imbrioscia v. Švýcarsko, § 37);[3] (Can v. Rakousko, Zpráva Komise, § 54)" +
        " [4] (Meftah a další v. Francie  [GC], § 41); [5] (Zana v. Turecko  [GC], § 68; Monnell a Morris v. Spojené království, § 58)" +
        " [6] (Ayetullah Ay v. Turecko, § 136); [7] (Simeonovi v. Bulharsko [GC], § 110); [8] (Truten v. Ukrajina, § 66; Knox v. Itálie, § 152);" +
        " [9] (Simeonovi v. Bulharsko [GC], § 111; Sîrghi v. Rumunsko, § 44); [10] (İbrahim Öztürk v. Turecko, §§ 48-49; Türk v. Turecko, § 47; " +
        "Mehmet Duman v. Turecko, § 41); [11] (Ayetullah Ay v. Turecko, §§ 135 a 163); [12] (Lagerblom v. Švédsko, § 49; Galstyan v. Arménie, § 89);" +
        " [13] (Artico v. Itálie, §33; Vamvakas v. Řecko (č. 2), § 36); [14] (Sakhnovskij v. Rusko [GC], § 102)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ts.12.1", "1. Přístup k obhájci by měl být zpravidla poskytován od prvního výslechu podezřelého policií, pokud není s ohledem na konkrétní okolnosti každého případu prokázáno, že existují přesvědčivé důvody k omezení tohoto práva, což se však nestalo a existence žádných přesvědčivých důvodů nebyla prokázána. "," (Salduz v. Turkey [GC], §§ 54-55; Ibrahim and Others v. The United Kingdom [GC], § 256)"),
                    new PssChoice("ts.12.2", "2. Stejně tak pouhá přítomnost obhájce nemůže vykompenzovat nepřítomnost obviněného (Zana v. Turecko [GC], § 72). Není-li právní zástupce schopen se poradit se svým klientem a obdržet od něj důvěrné pokyny bez dohledu, ztrácí jeho pomoc velkou část své užitečnosti. Užitečná pomoc obhájce tak nebyla jeho klientovi poskytnuta, tudíž došlo k porušení práva na spravedlivý proces. ","(S. V. Švýcarsko, §48; Brennanová v. Spojené království, § 58)"),
                    new PssChoice("ts.12.3", "3. Dotčená osoba neměla právo poradit se přímo se svým právním zástupcem před úvodním policejním výslechem nebo kdykoli poté, čímž došlo k porušení práva na spravedlivý proces. ","(Beuze v. Belgium, §§ 133-134)"),
                    new PssChoice("ts.12.4", "4. Dotčené osobě nebylo zajištěno právo, aby její právní zástupce byl přítomen úvodnímu policejnímu výslechu nebo kdykoli poté, čímž došlo k porušení práva na spravedlivý proces. ","(Beuze v. Belgium, §§ 133-134)"),
                    new PssChoice("ts.12.5", "5. Dotčené osobě nebylo zajištěno právo, aby její právní zástupce při úvodním policejním výslechu jí mohl aktivně asistovat nebo kdykoli poté, čímž došlo k porušení práva na spravedlivý proces. (Beuze v. Belgium, §§ 133-134; Soytemiz v. Turkey, (§§ 44-46, 27)"),
                    new PssChoice("ts.12.6", "6. Policejní orgán neodložil výslech přesto, že dotčená vyslýchaná osoba požádala před podpisem výslechového protokolu o to, aby mohla být zastupována právním zástupcem. "," (Soytemiz v. Turkey, (§§ 44-46, 27), čímž došlo k porušení práva na spravedlivý proces"),
                    new PssChoice("ts.12.7", "7. Právní zástupce účastníka řízení měl obtíže při přístupu do spisu, a to kdykoli během soudní části trestního řízení nebo předtím od okamžiku prvního kontaktu s orgány činnými v trestním řízení. "," (Beuze v. Belgium [GC], (§ 135)"),
                    new PssChoice("ts.12.8", "8. Právní zástupce účastníka řízení měl obtíže účastnit se úkonů trestního řízení týkajících se účastníka, kterého zastupuje či zastupoval, například při identifikaci, rekonstrukci nebo rekognici. "," (Beuze v. Belgium [GC], (§ 135)"),
                    new PssChoice("ts.12.9", "9. Právo na právní zastoupení nezávisí na přítomnosti obviněného. Skutečnost, že obviněný, přestože byl řádně předvolán, se nedostaví, nemůže – ani při neexistenci omluvy – odůvodnit zbavení jeho práva na právního zástupce. "," (Van Geyseghem v. Belgie  [GC], § 34; Campbell a Fell v. Spojené království, § 99; Poitrimol v. Francie, § 34; Pelladoah v. Nizozemsko, § 40; Krombach v. Francie, § 89; Galstyan v. Arménie, § 89), k čemuž však přesto došlo, čímž bylo porušeno právo na spravedlivý proces"),
                    new PssChoice("ts.12.10", "10. Aby bylo právo na právní pomoc praktické a účinné, a nikoli pouze teoretické, nesmí být jeho výkon podmiňován splněním nepřiměřeně formalistických podmínek: je na soudech, aby zajistily spravedlivé řízení, a tedy i obhájce, který se účastní soudního řízení za zjevným účelem obhajoby obviněného v jeho nepřítomnosti k tomu měl příležitost. "," (Van Geyseghem v. Belgium [GC], § 33; Pelladoah v. The Netherlands, § 41). K uvedenému praktickému a účinnému zajištění práva na právní pomoc však nedošlo, čímž došlo k porušení práva na spravedlivý proces"),
                    new PssChoice("ts.12.11", "11. Je porušením práva na spravedlivý proces, pokud právo na právní pomoc bylo účastníkovi řízení omezeno z důvodu obavy o vynášení informací účastníkem řízení či jeho právním zástupcem na veřejnost nebo v důsledku toho, že omezení práva účastníka řízení na právní pomoc je běžnou zvyklostí orgánů veřejné moci. "," (Ibrahim and Others v. The United Kingdom [GC], § 259; Simeonovi v. Bulgaria [GC], § 130)"),
                    new PssChoice("ts.12.12", "12. Nebyl-li účastník trestního řízení informován o svém právu na právní zastoupení, nemůže se tohoto práva vzdát. Účastník trestního řízení nicméně informován nebyl, ale přesto se tohoto práva vzdal, čímž bylo porušeno právo na spravedlivý proces. "," (Simeonovi v. Bulgaria [GC], § 118)"),
                    new PssChoice("ts.12.13", "13. Vzdání se práva na právní zastoupení bylo později dotčenou osobou, která tak učinila, výslovně odvoláno jejím vyjádřením, ve kterém žádala o právní zastoupení, což jí však bylo odepřeno, čímž došlo k porušení práva na spravedlivý proces. ","(Artur Parkhomenko v. Ukraine, § 81)"),
                    new PssChoice("ts.12.14", "14. Právo na právního zástupce, kterého si dotčená osoba žádá, by mělo být až na výjimečné situace respektováno, k čemuž však nedošlo a uvedená výjimečná situace nenastala, čímž došlo k porušení práva na spravedlivý proces. ","(Lagerblom v. Sweden, § 54)"),
                    new PssChoice("ts.12.15", "15. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces, pokud byla jeho komunikace s jeho právním zástupcem odposlouchávána, k čemuž v daném případě došlo. "," (Zagaria v. Italy, § 36)"),
                    new PssChoice("ts.12.16", "16. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces, pokud bylo dohlíženo na jeho komunikaci s jeho právním zástupcem, k čemuž v daném případě došlo. "," (Rybacki v. Poland, § 58, Lanz v. Austria, § 52)"),
                    new PssChoice("ts.12.17", "17. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces, pokud byly soustavně omezovány návštěvy právního zástupce u daného účastníka. "," (Öcalan v. Turkey [GC], § 135)"),
                    new PssChoice("ts.12.18", "18. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces, pokud mu nebyla umožněna soukromá komunikace s jeho právním zástupcem v rámci videokonference. "," (Sakhnovskiy v. Russia [GC], § 104; Gorbunov and Gorbachev v. Russia, § 37)"),
                    new PssChoice("ts.12.19", "19. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces, pokud mu nebyla umožněna komunikace s jeho právním zástupcem v soudní síni. "," (Khodorkovskiy and Lebedev v. Russia, §§ 642-647)"),
                    new PssChoice("ts.12.20", "20. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces, pokud mu bylo vyhrožováno sankcí v případě, že bude vyžadovat soukromou komunikaci s jeho právním zástupcem. "," (M v. The Netherlands, § 92)"),
                    new PssChoice("ts.12.21", "21. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces v situaci, kdy “ex offo” ustanovený obhájce k zastupování tohoto účastníka v trestním řízení pro tohoto účastníka řízení nečinil žádné úkony. "," (Artico v. Italy, §§ 33 and 36)"),
                    new PssChoice("ts.12.22", "22. Došlo k porušení práva účastníka trestního řízení na spravedlivý process v situaci, kdy “ex offo” ustanovený obhájce k zastupování tohoto účastníka v trestním řízení svou nečinností zavinil nesplnění zásadní procesní povinnosti tohoto účastníka řízení, přičemž takové nesplnění není pouhou vadou v argumentaci nebo chybou ve vedení obhajoby. (Czekalla v. Portugal, §§ 65 and 71)"),
                    new PssChoice("ts.12.23", "23. Došlo k porušení práva účastníka trestního řízení na spravedlivý proces, pokud “ex offo” ustanovený obhájce k zastupování tohoto účastníka v trestním řízení se opakovaně nedostavil na soudní jednání a soud nezajistil takovému účastníkovi řízení jiným způsobem účinné právní zastoupení v tomto trestním řízení. "," (Güveç v. Turkey (§ 131)"),
                    new PssChoice("ts.12.24", "24. Pokud se účastník trestního řízení vzdal práva na to být zastupován právním zástupcem, má soud povinnost zkoumat okolnosti, za jakých k takovému vzdání se došlo. Pokud tak neučinil jako v daném případě, došlo k porušení práva účastníka na spravedlivý proces. "," (Türk v. Turkey, §§ 53-54; Rodionov v. Russia, § 167)"),
                    //new PssChoice("ts.12.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Není porušením práva na spravedlivý proces, pokud se účastník trestního řízení rozhodne hájit sám a zájmy spravedlnosti nevyžadují, aby mu byl ustanoven obhájce “ex-offo”. ", "(Galstyan v. Armenia, § 91)"),
                    new PssChoice("", "2. Není porušením práva na spravedlivý proces, pokud se účastník trestního řízení rozhodne hájit sám a při své obhajobě nepostupuje s dostatečnou procesní pečlivostí.", "(Melin v. France, § 25)"),
                    new PssChoice("", "3. Není porušením práva na spravedlivý proces, pokud je právo na právního zástupce odepřeno účastníkovi řízení v postavení svědka, jenž učinil dobrovolnou výpověď.", "(Bandaletov v. Ukraine, §§ 61-66)"),
                    new PssChoice("", "4. Není porušením práva na spravedlivý proces, pokud je právo na právního zástupce odepřeno účastníkovi dopravní nehody při odběru krevního vzorku. ", "(Sršen v. Croatia (dec.), §§ 43-45)"),
                    new PssChoice("", "5. Není porušením práva na spravedlivý proces, pokud je právo na právního zástupce ve výjimečných případech poskytnuto se zpožděním. Takovém omezení na včasný přístup účastníka trestního řízení ke svému právnímu zástupci musí být založeno na “přesvědčivých důvodech”: existence přesvědčivých důvodů se může zakládat na individuálních důvodech daného případu a musí být časově omezené. I pokud splňují tyto podmínky, nesmí tyto přesvědčivé důvody vest k celkové “neférovosti” trestního řízení, zejména nesmí vést k nenapravitelným poškozením práva na spravedlivý proces dotčeného účastníka trestního řízení. ","(Ibrahim and Others v. The United Kingdom [GC], §§ 257, 258, 264-265; Dimitar Mitev v. Bulgaria, § 71)"),
                    new PssChoice("", "6. Byla prokázána existence přesvědčivých důvodů, proč by právo na důvěrnou komunikaci účastníka trestního řízení s advokátem mohlo být omezeno, konkrétně riziko ovlivňování svědků nebo porušování etických pravidel advokátem nebo protiprávní jednání advokáta (S. V. Switzerland, § 49; Rybacki v. Poland, § 59) nebo zneužití důvěrnosti či bezpečnostní riziko. V takovém případě nejde o porušení práva na spravedlivý proces.", "(Khodorkovskiy and Lebedev v. Russia, § 641)"),
                    new PssChoice("", "7. Účastník trestního řízení se vzdal práva na přístup k právnímu zástupci a toto vzdání se splňuje podmínky “vědomého a s rozmyslem učiněného rozhodnutí”, přičemž soud přezkoumal okolnosti učinění takového “vědomého a s rozmyslem učiněného rozhodnutí. Pokud vzdání se práva na přístup k právnímu zástupci splňuje podmínky “vědomého a s rozmyslem učiněného rozhodnutí” a soud to přezkoumal, není možné zpochybňovat spravedlivý proces. "," (Ibrahim and Others v. The United Kingdom [GC], § 272; Pishchalnikov v. Russia, § 77, Türk v. Turkey, §§ 53-54; Rodionov v. Russia, § 167; Šarkienė v. Lithuania (dec.), § 38; Sklyar v. Russia, § 26)"),
                    new PssChoice("", "8. Omezení možnosti zastupování vybranými advokáty v určitých typech řízení v situaci, kdy si účastník trestního řízení přeje být zastupován jiným advokátem, jenž není některým z uvedených vybraných advokátů, není porušením práva na spravedlivý proces. ", "(Meftah and Others v. France [GC], § 47)"),
                    new PssChoice("", "9. V případě, že bylo omezeno právo účastníka trestního řízení na výběr právního zástupce podle jeho vlastního uvážení, není porušením práva na spravedlivý proces, pokud účastník trestního řízení ve své stížnosti proti takovému omezení nezdůvodní, jakým způsobem toto omezení mohlo mít vliv na celkovou férovost tohoto projevu. ", "(Stevan Petrović v. Serbia, §§ 171-172)"),
                    //new PssChoice("","","",false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "XIII. Práva obhajoby – Čl. 6, odst. 3, písm. (c) Úmluvy: právo na bezplatnou obhajobu",
        "Každá osoba, jež byla obviněna z trestného činu, musí být chráněna článkem 6 § 3 písm. C)" +
        " Úmluvy v každé fázi řízení [1]. Právo na právní pomoc podle článku 6 § 3 písm. C) " +
        "Úmluvy podléhá dvěma kumulativním podmínkám, jež je nutno splnit [2]. Za prvé, musí obviněný prokázat, že nemá" +
        " dostatečné prostředky na zaplacení právní pomoci [3]. Za druhé, smluvní státy Úmluvy jsou povinny poskytovat" +
        " právní pomoc pouze „pokud to vyžadují zájmy spravedlnosti“ [4]. " +
        "\n" +
        "\n" +
        "Splnění obou podmínek je třeba posoudit s přihlédnutím ke skutkovým okolnostem věci jako celku, a " +
        "to nejen k okamžiku, vydání rozhodnutí o žádosti o právní pomoc, ale také k okamžiku, kdy soud rozhoduje " +
        "o meritu věci [5]. Tam, kde byla uvalena vazba, existuje z hlediska spravedlnosti vyšší zájem na" +
        " poskytnutí bezplatné obhajoby [6]. Právo na právní pomoc je rovněž relevantní v odvolacím řízení [7].",
        "[1] (Imbrioscia v. Švýcarsko, § 37); [2] (Quaranta v. Švýcarsko, §27); [3] (Caresana v. Spojené království (dec.));" +
        "[4] (Quaranta v. Švýcarsko, § 27); [5] (Granger v. Spojené království, § 46); [6] (Benham v. The United Kingdom [GC], § 61; " +
        "Quaranta v. Switzerland, § 33; Zdravko Stanev v. Bulgaria, § 38); [7] (Škov v. Rusko, §46; Volkov a Adamskiy v. Rusko, §§ 56-61).",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ts.13.1", "1. K tomu, aby účastníkovi řízení byla poskytnuta bezplatná obhajoba, nemusí svou potřebu prokázat “nade vší pochybnost”; postačí, když existují alespoň určité informace ohledně toho, že jsou přítomny skutečnosti, jež by odůvodňovaly jeho právo na bezplatnou obhajobu, a neexistují významné informace o opaku, přesto však bezplatná obhajoba nebyla poskytnuta, čímž došlo k porušení práva na spravedlivý proces. ","(Pakelli v. Germany, Commission report, § 34; Tsonyo Tsonev v. Bulgaria (no. 2), § 39)"),
                    new PssChoice("ts.13.2", "2. K tomu, aby účastníkovi řízení byla poskytnuta bezplatná obhajoba, musí soud posoudit vedle osobní finanční situace složitost případu, avšak k posouzení jednoho či druhého či obou kritérií nedošlo, čímž bylo porušeno právo na spravedlivý proces. ","((Zdravko Stanev v. Bulgaria, § 38; Quaranta v. Switzerland, § 34; Pham Hoang v. France, § 40; Twalib v. Greece, § 53)"),
                    new PssChoice("ts.13.3", "3. K tomu, aby účastníkovi řízení byla poskytnuta bezplatná obhajoba v odvolacím řízení, musí soud posoudit tři kritéria: (1) rozsah pravomocí odvolacího soudu, (2) závažnost obvinění, a (3) výši trestu. Soud však takové posouzení neprovedl, čímž došlo k porušení práva na spravedlivý proces. ","(Mikhaylova v. Russia, § 80)"),
                    //new PssChoice("ts.13.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Článek 6 odst. 3 písm. (c) Úmluvy neposkytuje právo na bezplatnou obhajobu, pouze povinuje soud k přezkoumání tvrzené potřeby účastníka řízení na poskytnutí takové pomoci podle kritérií stanovených v judikatuře Evropského soudu pro lidská práva uvedeného výše.", "(R.D. v. Poland, § 45)"),
                    new PssChoice("", "2. Článek 6 odst. 3 písm. (c) Úmluvy vyžadují automatické poskytnutí právní pomoci vždy, když se odsouzená osoba bez objektivní pravděpodobnosti úspěchu chce odvolat poté, co jí byl zajištěn spravedlivý proces u soudu prvního stupně v souladu s článkem 6.", "(Monnell and Morris v. The United Kingdom, § 67)"),
                    //new PssChoice("", "","", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "XIV. Práva obhajoby – Čl. 6, odst. 3, písm. (d) Úmluvy: právo vyslýchat nebo dát vyslýchat svědky proti sobě a dosáhnout předvolání a výslechu svědků ve svůj prospěch za stejných podmínek jako svědků proti sobě",
        "Před tím, než může být obžalovaný odsouzen, musí být všechny důkazy proti němu předloženy v jeho přítomnosti" +
        " na veřejném slyšení, aby se k nim mohl vyjádřit a případně je rozporovat. Článek 6 § 3 písm. (d) Úmluvy vyžaduje," +
        " aby obviněnému byla poskytnuta přiměřená a řádná příležitost napadnout a vyslechnout svědka proti němu," +
        " a to buď v okamžiku, kdy svědek učiní svou svědeckou výpověď, nebo v pozdější fázi řízení [1]. " +
        "Pojem „svědek“ má autonomní význam ve smyslu Úmluvy bez ohledu na klasifikaci podle vnitrostátního práva [2]. " +
        "\n" +
        "\n" +
        "Pokud výpověď svědka může sloužit v podstatné míře jako základ pro odsouzení, představuje důkaz pro trestní stíhání," +
        " na které se vztahují záruky stanovené článkem 6 § 1 a § 3 písm. (d) Úmluvy [3], přičemž takové důkazy mohou zahrnovat rovněž " +
        "důkazy vyplývající z rekognice nebo osobní konfrontace s podezřelým [4], jež se týkají spoluobžalovaného [5], oběti [6], znalce [7] či policisty [8]. " +
        "Článek 6 § 3 (d) lze použít také na listinné důkazy [9], včetně zpráv vypracovaných orgánem činným v trestním řízení," +
        " jež provedl zatčení [10].",
        "[1] (Al-Khawaja a Tahery v. Spojené království [GC], § 118; Hümmer v. Německo, § 38; Lucà v. Itálie, § 39; " +
        "Solakov v. Bývalá jugoslávská republika Makedonie, § 57); [2] (Damir Sibgatullin v. Rusko, § 45; S.N. v. Švédsko, § 45);" +
        "[3] (Kaste a Mathisen v. Norsko, § 53; Lucà v. Itálie, § 41); [4] (Vanfuli v. Rusko, § 110); " +
        " [5] (Trofimov v. Rusko, § 37; Oddone a Pecci v. San Marino, §§ 94-95); [6] (Vladimir Romanov v. Rusko, § 97) ;" +
        "[7] (Doorson v. Nizozemsko, §§ 81-82); [8] (Ürek a Ürek v. Turecko, § 50); [9] (Mirilashvili v. Rusko, §§ 158-159; Chap Ltd v. " +
        "Arménie, § 48); [10] (Butkevich v. Rusko, §§ 98-99)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ts.14.1", "1. Účastníkovi řízení nebylo umožněno zpochybnit bezúhonnost a věrohodnost výpovědí svědka, včetně její pravdivosti a spolehlivosti tím, že by svědek byl ústně vyslechnut v jeho přítomnosti, a to ani v době, kdy svědek činil výpověď, nebo v jiné fázi řízení, nebo pokud se svědek dostavil k soudu, avšak účastníkovi řízení nebylo umožněno z důvodu procesních nesrovnalostí účastníka řízení vyslechnout. Tím došlo k porušení práva účastníka řízení na výslech svědků a zároveň k porušení práva na spravedlivý proces. ","(Chernika v. Ukraine, § 46)"),
                    new PssChoice("ts.14.2", "2. Je porušením zásadního aspektu férovosti procesu a tím i práva na spravedlivý proces, pokud není účastníkovi řízení umožněno vyslechnout hlavního svědka v přítomnosti soudce. "," (Tarău v. Romania, § 74; Graviano v. Italy, § 38)"),
                    new PssChoice("ts.14.3", "3. Soud má povinnost zkoumat, zda důvody nepřítomnosti svědka při soudním slyšení, jehož výpověď je relevantní pro obhajobu, jsou oprávněné či nikoli.  Soud však tak neučinil, čímž došlo k porušení práva na spravedlivý proces. ","(Al-Khawaja and Tahery v. The United Kingdom [GC], § 120; Gabrielyan v. Armenia, §§ 78, 81-84)"),
                    new PssChoice("ts.14.4", "4. Absence zdůvodnění, proč soud odmítl vyslechnout svědka, jehož výpověď je relevantní pro obhajobu, k čemuž došlo, je porušením práva na spravedlivý proces. ","(Bocos-Cuesta v. The Netherlands, § 72)"),
                    new PssChoice("ts.14.5", "5. Účastník řízení v postavení obžalovaného není povinen uvádět důvody pro to, aby se dostavil k soudnímu jednání svědek a obžalovanému bylo umožněno takovému svědkovi klást otázky, pokud je takový svědek pro obžalobu relevantním zdrojem informací a obžaloba se na jeho svědectví zakládá a zároveň pokud je uznání obžalovaného vinným soudem rozhodným způsobem založeno na té části obžaloby, která se opírá o uvedené svědectví. "," (Süleyman v. Turkey, § 92, Keskin v. The Netherlands, §§ 45, 55-56)"),
                    new PssChoice("ts.14.6", "6. Článek 6 § 1 společně s čl. 6 § 3 Úmluvy požaduje, aby soud či jiné orgány veřejné moci učinily aktivní kroky k tomu, aby umožnily účastníkovi řízení vyslechnout svědky, jejichž svědectví svědčí proti němu. Takové aktivní kroky však učiněny nebyly, čímž došlo k porušení práva na spravedlivý proces. ","(Trofimov v. Russia, § 33; Sadak and Others v. Turkey (no. 1), § 67; Cafagna v. Italy, § 42)"),
                    new PssChoice("ts.14.7", "7. Je porušením práva na spravedlivý proces, pokud nebyly ze strany orgánů veřejné moci učiněny přiměřené kroky k tomu, aby zajistily při soudním jednání osobní účast svědků, jejichž svědectví svědčí proti účastníkovi řízení v postavení obviněného či obžalovaného, například v případě svědka pobývajícího v zahraničí nedošlo k vyžádání si jeho výslechu prostřednictvím mechanismů mezinárodní právní pomoci. Žádné takové kroky v daném případě však nebyly učiněny. "," (Karpenko v. Russia, § 62; Damir Sibgatullin v. Russia, § 51; Pello v. Estonia, § 35; Bonev v. Bulgaria, § 43; Tseber v. The Czech Republic, § 48; (Lučić v. Croatia, §§ 79-80; Schatschaschwili v. Germany [GC], §§ 121-122)"),
                    new PssChoice("ts.14.8", "8. Je porušením práva na spravedlivý proces, pokud u svědka, jehož totožnost není účastníkovi řízení známa, nejsou prokázány relevantní a dostatečné důvody pro utajení jeho totožnosti, tedy pokud není důkazně prokázána existence objektivně důvodných obav na straně svědka s utajenou totožností. Takové důvody však v daném případě nebyly prokázány. ","(Doorson v. The Netherlands, § 69; Van Mechelen and Others v. The Netherlands, § 52; Krasniki v. The Czech Republic, § 76; Visser v. The Netherlands, § 47; Sapunarescu v. Germany (dec.); Dzelili v. Germany (dec.); Scholer v. Germany, §§ 53-56); (Al-Khawaja and Tahery v. The United Kingdom [GC], § 124; Balta and Demir v. Turkey, § 44)"),
                    new PssChoice("ts.14.9", "9. Pokud soud umožní výslech svědka, má povinnost přijmout účinná opatření k zajištění jeho přítomnosti při soudním jednání, například nařízením jeho předvedení orgány policie. Taková opatření však přijata nebyla, čímž došlo k porušení práv na spravedlivý proces. ","(Murtazaliyeva v. Russia [GC], § 147; Polufakin and Chernyshev v. Russia, § 207)"),
                    new PssChoice("ts.14.10", "10. Soud musí buď umožnit výslech svědka nebo uvést relevantní důvody pro odmítnutí žádosti o výslech svědka, pokud jsou splněny všechny následující podmínky: (i) žádost o výslech svědka nemá zneužívající účel, (ii) je řádně zdůvodněná a (iii) svědectví uvedeného svědka by mohlo pomoci obhajobě či přímo vést ke zproštění viny účastníka řízení v postavení obviněného či obžalovaného. "," (Vidal v. Belgium, § 34; Polyakov v. Russia, §§ 34-35; Sergey Afanasyev v. Ukraine, § 70; Topić v. Croatia, § 42)"),
                    new PssChoice("ts.14.11", "11. Je porušením práva na spravedlivý proces, pokud soud připustí pouze písemné vyjádření svědka místo jeho osobního výslechu při soudním jednání v situaci, kdy existovala reálná možnost, jak umožnit osobní výslech svědka, avšak nebyla využita, a takový svědek nebyl vyslechnut dříve v rámci daného trestního řízení. K takovému porušení v daném případě došlo. ","(Seton v. the United Kingdom, §§ 58-59; Dimović v. Serbia, §§ 36-40; T.K. v. Lithuania, §§ 95-96)"),
                    new PssChoice("ts.14.12", "12. Pokud je odsuzující rozsudek založen pouze a jen na vyjádření svědka, jemuž účastník řízení v postavení obviněného či obžalovaného nemohl, kdykoli během trestního řízení či předtím klást otázky, jedná se o porušení práva na spravedlivý proces, ke kterému v daném případě došlo. "," (Seton v. the United Kingdom, §§ 58-59; Dimović v. Serbia, §§ 36-40; T.K. v. Lithuania, §§ 95-96)"),
                    new PssChoice("ts.14.13", "13. Je porušením práva na spravedlivý proces, pokud výpověď svědka je zprostředkována prostřednictvím videa a obhajobě není umožněno, aby takovému svědkovi kladla otázky. "," (D. v. Finland, § 50; A.L. v. Finland, § 41)"),
                    //new PssChoice("ts.14.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Není porušením práva na spravedlivý proces, pokud žádost o výslech svědka není dostatečně zdůvodněna, a proto je soudem zamítnuta.", "(Al-Khawaja and Tahery v. the United Kingdom [GC] (§§ 119-147)"),
                    new PssChoice("", "2. Není porušením práva na spravedlivý proces, pokud žádost o výslech svědka je dostatečně zdůvodněna, avšak je přesto zamítnuta v případě, že je její zamítnutí řádně odůvodněno a uvedeno, proč není pro obhajobu relevantní, a zároveň je zamítnutí výslechu svědka navrženého obhajobou nenarušuje celkovou férovost procesu.", "(Al-Khawaja and Tahery v. the United Kingdom [GC] (§§ 119-147)"),
                    new PssChoice("", "3. Nejedná se o porušení práva na spravedlivý proces, pokud je odsuzující rozsudek založen pouze a jen na vyjádření svědka, jehož totožnost byla účastníkovi řízení v postavení obviněného či obžalovaného utajeno, a soud s maximální obezřetností ověřil, že nebylo možné pro obžalobu získat důkazy proti uvedenému účastníkovi řízení jiným způsobem.", "(Seton v. the United Kingdom, §§ 58-59; Dimović v. Serbia, §§ 36-40; T.K. v. Lithuania, §§ 95-96)"),
                    new PssChoice("", "4. Není porušením práva na spravedlivý proces, pokud soud nepřeruší projednávání věci v situaci, kdy se zjistilo, že není možné zajistit přítomnost požadovaného svědka a byla za tím účelem přijata aktivní opatření ovšem bez výsledku. ", "(Gossa v. Poland, § 55; Haas v. Germany (dec.); Calabrò v. Italy and Germany (dec.); Ubach Mortes v. Andorra (dec.); Gani v. Spain, § 39)"),
                    new PssChoice("", "5. Není porušením práva na spravedlivý proces, pokud je místo pobytu svědka neznámé, protože se skrývá před spravedlností a účastník řízení dostl příležitost vyjádřit se k aktivním opatřením, které byy přijaty k zajištění jeho přítoknosti, byť bez výsledku. ", "(Lobarev and Others v. Russia, §§ 33-34)"),
                    new PssChoice("", "6. Není porušením práva na spravedlivý proces připuštění písemné výpovědi svědka místo jeho osobní účasti na soudním projednávání věci, pokud je místo pobytu takového svědka neznámé z důvodu, že se skrývá před spravedlností. ", "(Schatschaschwili v. Germany [GC], § 119)"),
                    new PssChoice("", "7. Není porušením práva na spravedlivý proces, pokud se svědek nedostavil k soudnímu projednání věci z některého z následujících důvodů: smrt svědka nebo důvodné obavy o jeho bezpečnost), zdravotní důvody nebo jeho zadržení v jiné zemi. ", "(Mika v. Sweden (dec.), § 37; Ferrantelli and Santangelo v. Italy, § 52; Al-Khawaja and Tahery v. the United Kingdom [GC], §§ 120-125; Bobeş v. Romania, §§ 39-40; Vronchenko v. Estonia, § 58); (Štefančič v. Slovenia, § 39)"),
                    new PssChoice("", "8. Není porušením práva na spravedlivý proces, pokud není připuštěn svědek, jehož svědecká výpověď se týká pouze způsobu, jakým bylo prováděno vyšetřování, a ve vztahu k meritu věci je taková jeho svědecká výpověď redundantní. ", "(Murtazaliyeva v. Russia [GC], § 136)"),
                    new PssChoice("", "9. Není porušením práva na spravedlivý proces, pokud účastník řízení v postavení obviněného či obžalovaného si stěžuje, že nemohl vyslýchat některé svědky, aniž by zdůvodnil, proč bylo důležité, aby takový svědek byl vyslechnut a proč svědecká výpověď takového svědka je důležitá pro jeho obhajobu. ", "(Perna v. Italy [GC], § 29; Băcanu and SC « R » S.A. v. Romania, § 75)"),
                    new PssChoice("", "10. Není porušením práva na spravedlivý proces, pokud svědecká výpověď svědka navrženého účastníkem řízení v postavení obviněného či obžalovaného byla zamítnuta, protože nemohla obvinit výsledek trestního řízení, což bylo soudem řádně zdůvodněno. ", "(Kapustyak v. Ukraine, §§ 94-95)"),
                    //new PssChoice("","","",false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "XV. Práva obhajoby – Čl. 6, odst. 3, písm. (e) Úmluvy: právo na bezplatnou právní pomoc a tlumočníka",
        "Požadavky odstavce 3 (e) článku 6 Úmluvy je třeba považovat za zvláštní aspekty práva na " +
        "spravedlivý proces zaručeného odstavcem 1 téhož článku [1]. Skutečnost, že žalovaný má základní znalost jazyka" +
        " řízení nebo, jak je tomu ve věci, třetího jazyka, překlad do nějž je snadno dostupný, by sama o sobě neměla bránit této " +
        "osobě v tom, aby měl prospěch z tlumočení do jazyka, kterému rozumí dostatečně dobře, aby mohla plně uplatnit" +
        " své právo na obhajobu [2]. Článek 6 § 3 (e) Úmluvy se nevztahuje na vztahy mezi obviněným a jeho právním zástupcem; " +
        "vztahuje se pouze na vztahy mezi obviněným, resp. obžalovaným a soudcem [3]. " +
        "\n" +
        "\n" +
        "Článek 6 § 3 (e) zaručuje právo na bezplatnou pomoc tlumočníka při překladu nebo výkladu všech dokumentů nebo prohlášení" +
        "v řízení, kde je nezbytné, aby obviněný či obžalovaný rozuměl jeho obsahu a mohl využít svého práva" +
        "na spravedlivý proces [4]. Článek 6 § 3 písm. (e) Úmluvy se vztahuje nejen na ústní prohlášení učiněná při " +
        "hlavním líčení, ale také na listinné materiály a přípravné řízení [5]. Stručně řečeno, poskytnutá tlumočnická pomoc " +
        "by měla být taková, aby žalovanému umožnila seznámit se s případem, který je proti němu veden, a bránit" +
        "se zejména tím, že bude moci předložit soudu svou verzi událostí [6].",
        "[1] (Baytar v. Turecko, § 48); [2] (Vizgirda v. Slovinsko, § 83); [3] (X. V. Rakousko, rozhodnutí Komise); " +
        "[4] (Luedicke, Belkacem a Koç v. Německo, § 48; Ucak v. Spojené království (dec.); Hermi v. Itálie [GC], § 69; Lagerblom v. Švédsko, § 61); " +
        "[5] (Kamasinski v. Rakousko, §74; Hermi v. Itálie [GC], § 70; Baytar v. Turecko, § 49); [6] (Hermi v. Itálie [GC]; " +
        "Kamasinski v. Rakousko, § 74; Güngör v. Německo (prosinec); Protopapa v. Turecko, § 80; Vizgirda v. Slovinsko, § 79)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ts.15.1", "1. Povinnost zajistit tlumočníka není omezena na situace, kdy podezřelý či obviněný či obžalovaný je cizím státním příslušníkem a učiní výslovnou žádost o tlumočníka. Je důležité, aby si podezřelý byl vědom práva na tlumočníka, což znamená, že o takovém právu musí být informován, pokud je ve smyslu Úmluvy „obviněn z trestného činu, přičemž oznámení podezřelému o právu na tlumočníka mu musí být sděleno v jazyce, kterému rozumí, k čemuž nedošlo. "," (Vizgirda v. Slovenia, §§ 86-87)"),
                    new PssChoice("ts.15.2", "2. Počáteční vady v tlumočení proto mohou mít dopad na jiná práva a mohou ohrozit férovost řízení jako celku. Stejně jako pomoc právního zástupce musí být pomoc tlumočníka poskytována již od prvotní fáze vyšetřování, k čemuž nedošlo, pokud není prokázáno, že existují přesvědčivé důvody k omezení tohoto práva, jež nebyly přítomny. "," (Baytar v. Turkey, §§ 50, 54-55)"),
                    new PssChoice("ts.15.3", "3. Obviněný či obžalovaný nerozuměl jednacímu jazyku soudu, což je v rozporu s právem na spravedlivý proces, a to i kdyby jeho právní zástupce jednací jazyk soudu ovládal. "," (Kamasinski v. Austria, § 74; Cuscani v. The United Kingdom, § 38)"),
                    new PssChoice("ts.15.4", "4. Náklady tlumočení nemohou být zpětně požadovány po obviněném či obžalovaném. "," (Luedicke, Belkacem and Koç v. Germany, § 46), což se však stalo"),
                    new PssChoice("ts.15.5", "5. Soud neprověřil tvrzení obviněného či obžalovaného, že služby poskytnuté tlumočníkem byly nekvalitní, čímž došlo k porušení práva na spravedlivý proces podle čl. 6 § 3 (e) Úmluvy. "," (Knox v. Italy, §§ 182-187)"),
                    new PssChoice("ts.15.6", "6. Soud má povinnost I bez návrhu se přesvědčit, že obviněný či obžalovaný nepotřebuje tlumočníka, pokud se od právního zástupce dozví, že má potíže s obviněným či obžalovaným komunikovat v daném jazyce. O takových potížích se soud dozvěděl, nicméně potřebu tlumočníka pro obviněného či obžalovaného z úřední povinnosti nepřezkoumal, čímž se dopustil porušení práva na spravedlivý proces. "," (Cuscani v. The United Kingdom, § 38)"),
                    //new PssChoice("ts.15.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Právo na bezplatnou pomoc tlumočníka platí výlučně v situacích, kdy obviněný či obžalovaný nerozumí jazyku používanému u soudu nebo jím nemluví.", "(K. v. France, Commission decision Baytar v. Turkey, § 49)"),
                    new PssChoice("", "2. Obviněný či obžalovaný, který rozumí jednací řeči soudu, nemůže trvat na službách tlumočníka, aby mu umožnil vést svou obhajobu v jiném jazyce, včetně jazyka etnické menšiny, jejíž je členem. "," (Bideault v. France, Commission decision; Lagerblom v. Sweden, § 62)"),
                    new PssChoice("", "3. Práva na tlumočníka se lze vzdát, avšak musí to být rozhodnutí obviněného, resp. obžalovaného, nikoli rozhodnutí jeho právního zástupce.", "(Kamasinski v. Austria, § 80)"),
                    new PssChoice("", "4. Po obviněném, resp. obžalovaném, mohou být požadovány náklady za tlumočení za slyšení, jehož se měl zúčastnit, avšak se nezúčastnil.", "(Fedele v. Germany (dec.)"),
                    //new PssChoice("","","",false),
                ],
                false
            )
        ]
    )
];