export class PssChoice {
    constructor(
        public id: string,
        public label: string,
        public description: string = "",
        public selectable: boolean = true
    ) {
    }
}

export class PssSectionPart {
    constructor(
        public header: string,
        public children: PssSectionPart[] = [],
        public choices: PssChoice[] = [],
        public positive_design: boolean = true
    ) {
    }

    FindChoiceById(choice_id: string): (PssChoice | null) {
        let found: (PssChoice | null) = null;
        this.choices.forEach((choice) => {
            if (found === null && choice.id === choice_id) {
                found = choice;
            }
        });
        // recursive for each part in each part
        this.children.forEach((child) => {
            if (found === null) {
                found = child.FindChoiceById(choice_id);
            }
        });
        return found;
    }
}

export class PssSection {
    constructor(
        public header: string,
        public preamble: string = "",
        public citations: string = "",
        public parts: PssSectionPart[] = [],
        public is_classification: boolean = false,
        public is_classification_required: boolean = true,
    ) {
    }

    FindChoiceById(choice_id: string): (PssChoice | null) {
        let found: (PssChoice | null) = null;
        this.parts.forEach((part) => {
            if (found === null) {
                found = part.FindChoiceById(choice_id);
            }
        });
        return found;
    }
}

export function FindChoiceById(choice_id: string, sections: PssSection[]): (PssChoice | null) {
    let found: (PssChoice | null) = null;
    sections.forEach(section => {
        if (found === null) {
            found = section.FindChoiceById(choice_id);
        }
    });
    return found;
}