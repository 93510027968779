import {createSlice} from "@reduxjs/toolkit";
import PssDocumentsStorage from "./PssDocumentsStorage";

const PssDocumentsSlice = createSlice({
    name: 'documents',
    initialState: {
        documents: new PssDocumentsStorage()
    },
    reducers: {

    }
});

export default PssDocumentsSlice.reducer;