import PssDocument from "./PssDocument";
import {PssDefaultDocumentNames, PssDocumentType} from "./PssEnums";
import assert from "assert";
import GeneratorSettings from "./GeneratorSettings";

const STORAGE_PREFIX: string = 'pss-';

type PssDocumentStorageCallback = (changedDoc: PssDocument) => any;

export default class PssDocumentsStorage {

    private subscribers: Map<number, PssDocumentStorageCallback> = new Map<number, PssDocumentStorageCallback>();

    nextFreeStorageKey(): number {
        let counter: number = 100;
        while (true) {
            if (localStorage.getItem(STORAGE_PREFIX + counter) === null) {
                return counter;
            }
            counter++;
        }
    }

    getAllDocuments(): PssDocument[] {
        let docs: PssDocument[] = [];
        for (let lsKeyIndex = 0; lsKeyIndex < localStorage.length; lsKeyIndex++) {
            let lsKey = localStorage.key(lsKeyIndex);
            if (lsKey == null) {
                continue;
            }
            if (lsKey.startsWith(STORAGE_PREFIX)) {
                let id: number = parseInt(lsKey.substring(STORAGE_PREFIX.length));
                docs[id] = this.getDocument(id);
            }
        }
        return docs;
    }

    getNow(): string {
        let yourDate = new Date();
        const offset = yourDate.getTimezoneOffset()
        yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000))
        return yourDate.toISOString().replace('T', ' ').substring(0, 19);
    }

    createNewDocument(type: PssDocumentType): PssDocument {
        let key: number = this.nextFreeStorageKey();
        let document: PssDocument = new PssDocument(key, type);
        document.name = `${PssDefaultDocumentNames[type]} (${this.getNow()})`;

        localStorage.setItem(STORAGE_PREFIX + key, this.documentToJson(document));

        this.publishChange(document);

        return this.getDocument(key);
    }

    getDocument(key: number, type: PssDocumentType = 0): PssDocument {
        let doc: PssDocument = Object.assign(new PssDocument(key, type), JSON.parse(localStorage.getItem(STORAGE_PREFIX + key) ?? "{}"));
        // first version of PssDocument was just array of choice keys, this is to avoid issues when de-serializing from persistence
        if (Array.isArray(doc.choices)) {
            doc.choices = new Map<string, string>(doc.choices);
        } else if (typeof doc.choices === 'object') {
            // ugly deserialize map from json object
            let map = new Map<string, string>();
            let o: { [key: string]: string; } = (Object)(doc.choices);
            for (let val in Object.keys(o)) {
                map.set(val, o[val]);
            }
            doc.choices = map;
        } else {
            console.log("unexpected doc.choices type", doc.choices, typeof doc.choices);
        }
        if (!Array.isArray(doc.classifications)) {
            doc.classifications = [];
        }
        if (typeof doc.generatorSettings !== 'object') {
            doc.generatorSettings = new GeneratorSettings();
        }
        return doc;
    }

    getDocumentsByType(type: PssDocumentType): PssDocument[] {
        return this.getAllDocuments().filter(d => d.type === type);
    }

    documentToJson(doc: PssDocument): string {
        return JSON.stringify(doc, (key, value) => {
            if (value instanceof Map) {
                return Array.from(value.entries());
            }
            return value;
        });
    }

    setDocument(doc: PssDocument): boolean {
        assert(doc.id > 0, "Document ID must be > 0");
        localStorage.setItem(STORAGE_PREFIX + doc.id, this.documentToJson(doc));
        this.publishChange(doc);
        return true;
    }

    publishChange(doc: PssDocument) {
        this.subscribers.forEach(subscriber => subscriber(doc));
    }

    subscribeChange(subscriber: PssDocumentStorageCallback): number {
        let key: number = 0;
        while (true) {
            if (this.subscribers.has(key)) {
                key++;
                continue;
            }
            break;
        }
        this.subscribers.set(key, subscriber);
        return key;
    }

    unsubscribe(subId: number): boolean {
        if (this.subscribers.has(subId)) {
            this.subscribers.delete(subId);
            return true;
        }
        return false;
    }

}