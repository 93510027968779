import {PssChoice, PssSection, PssSectionPart} from "../Model/PssDatabase";

export const CivilAdvokatDb: PssSection[] = [
    new PssSection(
        "I.\tKDY SE PRÁVA NA SPRAVEDLIVÝ PROCES UPLATNÍ A KDY NE?",
        "Advokát protistrany učinil konkrétní kroky k tomu, aby vás jako účastníka řízení zbavil práv na " +
        "spravedlivý proces, například navrhl soudu, aby došlo k některé ze situací či aby došlo k některému z porušení " +
        "uvedených v sekcích II. či III. nebo IV. Aby bylo možnost si na konkrétní kroky advokáta protistrany stěžovat," +
        " je nutné, aby kroky tohoto advokáta protistrany byly učiněny v rámci některého z řízení uvedených v této " +
        "sekci I. v části A. a zároveň aby se nejednalo o žádné z řízení uvedených v části" +
        "B. této sekce I." +
        "\n" +
        "\n" +
        "Právo na spravedlivý proces se uplatní vždy v řízení, ve kterém jde o “nárok” soukromé osoby, tedy typicky nárok," +
        " který je žalovatelný v civilním soudním sporném řízení [1]. Právo na spravedlivý proces se však uplatňuje i v " +
        "jiných řízení, než je civilní soudní řízení, jde-li v takovém řízení o soukromý vymahatelný nárok jednotlivce" +
        " podle práva daného státu, jenž je členským státem Rady Evropy [2]. Může jít za určitých okolností o soudní správní" +
        " řízení nebo správní řízení nebo i disciplinární řízení. " +
        "\n" +
        "\n" +
        "Pokud však vaše právo není soudně vymahatelné, pak se ohledně tohto vašeho práva, právo na spravedlivý proces neuplatní [3]. " +
        "Pokud naopak vaše právo je soudně vymahatelné, použije se právo na spravedlivý proces nejen ohledně vzniku tohoto vašeho práva, " +
        "ale i ohledně otázek týkající se faktických skutečností t nebo rozsahu či způsobu uplatňování tohoto vašeho práva [4]. " +
        "Spor o vašem, i jen potenciálně soudně vymahatelném právu, musí být skutečný a opravdový.",
        "[1] (Regner v. the Czech Republic [GC], § 99); [2] (Károly Nagy v. Hungary [GC], §§ 60-61; Roche v. the United Kingdom [GC], § 119; " +
        "Boulois v. Luxembourg [GC], § 91); [3] (Boulois v. Luxembourg [GC], §§ 96 and 101); [4] (Benthem v. the Netherlands, § 32; Cipolletta v. Italy, § 31)",
        [
            new PssSectionPart(
                "A. Řízení, na které lze použít záruky spravedivého procesu podle čl. 6 odst. 1 Úmluvy.",
                [
                    new PssSectionPart(
                        "a. Sporná civilní věc",
                        [],
                        [
                            new PssChoice("ca.1.1", "1. Jedná se o spornou civilní věc proti jiné fyzické osobě nebo jiné právnické osobě, která se již u soudu projednává se nebo se projednávala. "," (Airey v. Ireland, § 21)"),
                        ]
                    ),
                    new PssSectionPart(
                        "b. Jiná než sporná civilní věc, soudní správní řízení, správní řízení, civilní řízení atp.",
                        [],
                        [
                            new PssChoice("ca.1.2", "2. Exekuční řízení [1]. Právo na spravedlivý proces se aplikuje i na řízení vymáhací, po skončení nalézacího řízení bez ohledu na to, zda se právo na spravedlivý proces aplikovalo i v nalézacím řízení ", "Hornsby v. Greece, § 40; Romańczyk v. France, § 53; Buj v. Croatia, § 19"),
                            new PssChoice("ca.1.3", "3. Insolvenční řízení ","(Cipolletta v. Italy §§ 33-37)"),
                            new PssChoice("ca.1.4", "4. Řízení o likvidaci právnické osoby "," (Cipolletta v. Italy §§ 33-37)"),
                            new PssChoice("ca.1.5", "5. Řízení týkající se konstrukce přehrady, která měla zaplavit vesnici žadatelů ","(Gorraiz Lizarraga and Others v. Spain, § 46)"),
                            new PssChoice("ca.1.6", "6. Řízení ohledně udělení povolení provozovateli zlatého dolu používající injekce kyanidu, jež ohrožuje obyvatele blízkých vesnic ","(Taşkın and Others v. Turkey, § 133; Zander v. Sweden, §§ 24-25)."),
                            new PssChoice("ca.1.7", "7. Soudní řízení ohledně odvolání vzneseného spolkem pro ochranu přírody za účelem přezkoumání soudního rozhodnutí vzhledem ke zvláštnímu statute asociace i jejich zakldatelů, jež bylo věcně omezené ","(L’Érablière A.S.B.L. v. Belgium, §§ 28-30)."),
                            new PssChoice("ca.1.8", "8. Řízení týkající se navrácení právní způsobilosti fyzické osoby ","(Stanev v. Bulgaria [GC], § 233)"),
                            new PssChoice("ca.1.9", "9. Řízení o navrácení osoby do její soudcovské funkce, do které byla ustanovena na pět let ","(Denisov v. Ukraine [GC] (§§ 47-49)"),
                            new PssChoice("ca.1.10", "10. Řízení, jež mohou být veřejnoprávního nebo správního charakteru, avšak jejichž výsledek je rozhodný pro soukromoprávní majetková práva jednotlivců, jako jsou například:", "- stavební řízení či územní řízení (Sporrong and Lönnroth v. Sweden, § 79),\n" +
                                "- řízení týkající se povolení prodat pozemek, \n (Ringeisen v. Austria, § 94)\n\n" +
                                "- řízení týkající se povolení provozovat soukromou kliniku, \n (König v. Germany, §§ 94-95)\n\n" +
                                "- řízení o povolení zřídit místo náboženského kultu, \n (Lupeni Greek Catholic Parish and Others v. Romania [GC], §§ 71-73) \n\n" +
                                "- řízení týkající se povolení související s výkonem činnosti \n (Benthem v. the Netherlands, § 36) \n\n" +
                                "- řízení týkající se povolení pro výdej alkoholických nápojů \n (Tre Traktörer Aktiebolag v. Sweden, § 43) \n\n" +
                                "- řízení týkající se platby náhrad za nemoc z povolání nebo pracovní úraz \n (Chaudet v. France, § 30) \n\n" +
                                "- řízení o zákonnosti udělení povolení soutěžiteli v situaci, kdy udělení toto povolení vedlo ke ztrátě zákazníků jiného soutěžitele \n (Sine Tsaggarakis A.E.E. v. Greece, §§ 38-43) \n\n" +
                                "- řízení o udělení výzkumného grantu \n (Mirovni Inštitut v. Slovenia (§§ 28-30)\n\n " +
                                "- řízení o propuštění ze služebního poměru u veřejné instituce v důsledku ztráty bezpečnostní prověrky \n (Regner v. the Czech Republic [GC], § 105);"),
                            new PssChoice("ca.1.11", "11. Řízení o žalobě o náhradě škody vyplývající z odpovědnosti za nesprávný výkon veřejné moci, jeko je například orgánu činném v trestním řízení ","(Osman v. the United Kingdom) nebo orgánu územní samosprávy (Z and Others v. the United Kingdom [GC])"),
                            new PssChoice("ca.1.12", "12. Řízení o náhradě škody ","(Vilho Eskelinen and Others v. Finland [GC], § 41)"),
                            new PssChoice("ca.1.13", "13. Řízení či proces týkající se služebního poměru ve veřejné správě, pokud jde o platy, náhrady či obdobné příspěvky nebo v případě jiných podmínek, pokud není možnost bránit se soudně v daném případě zákonem výslovně vyloučena a toto vyloučení je ve veřřejném zájmu na základě objektivních kritérií ","(Frezadou v. Greece, § 30, Vilho Eskelinen and Others v. Finland [GC] (§§ 50-62)"),
                            new PssChoice("ca.1.14", "14. Řízení týkající se pracovněprávních sporů, zejména propuštění ","(Buchholz v. Germany)"),
                            new PssChoice("ca.1.15", "15. Řízení ve věcech práv týkajících se sociálního zabezpečení "," (Feldbrugge v. the Netherlands, Salesi v. Italy)"),
                            new PssChoice("ca.1.16", "16. Řízení týkající se odvodů na sociální pojištění ", "(Schouten and Meldrum v. the Netherlands)"),
                            new PssChoice("ca.1.17", "17. Řízení ohledně odškodnění za válečné perzekuce ", "(Woś v. Poland, § 76)"),
                            new PssChoice("ca.1.18", "18. Řízení týkající se sociálního bydlení a sociální pomoci ", "(Fazia Ali v. the United Kingdom, §§ 58-59)"),
                            new PssChoice("ca.1.19", "19. Řízení, které vede k udělení povolení vykonávat určitou regulovanou profesi, například lékařskou profesi, včetně uznání výkonu lékařské profese v jiné zemi, profesi soudního znalce atp.", "(Chevrol v. France, § 55, Kök v. Turkey, § 37, Cimperšek v. Slovenia, §§ 35-36)"),
                            new PssChoice("ca.1.20", "20. Řízení o propuštění z pracovního poměru nebo jeho změn, včetně služebního poměru ve veřejném sektoru ", "(Regner v. the Czech Republic [GC], § 117), Kövesi v. Romania, § 115)"),
                            new PssChoice("ca.1.21", "21. Řízení o náhradě škody způsobené výkonem veřejné moci ","(X v. France)"),
                            new PssChoice("ca.1.22", "22. Řízení o zrušení povolení, jež poškozuje práva jeho nositele ","(De Geouffre de la Pradelle v. France)"),
                            new PssChoice("ca.1.23", "23. Správní řízení týkající se zákazu rybařit ve vodách patřících stěžovateli ","(Alatulkkila and Others v. Finland, § 49)"),
                            new PssChoice("ca.1.24", "24. Soutěž o veřejnou zakázku, kdy se jednalo o diskriminace na základě náboženské víry a politického názoru ","(Tinnelly & Sons Ltd and Others and McElduff and Others v. the United Kingdom, § 61; srovnej I.T.C. Ltd v. Malta (dec.))"),
                            new PssChoice("ca.1.25", "25. Řízení týkající se vymazání záznamů osob z policejní databáze, kteréžto záznamy znemožňovaly stěžovatelům žádat o licenci k provozování požárnické činnosti ","(Pocius v. Lithuania, §§ 38-46; Užukauskas v. Lithuania, §§ 34-39)"),
                            new PssChoice("ca.1.26", "26. Řízení o náhradě škody způsobené veřejnými úředníky ","(Aksoy v. Turkey, § 92)"),
                            new PssChoice("ca.1.27", "27. Disciplinární řízení, jehož výsledkem může být zákaz výkonu určité činnosti, a to jak v soukromém, tak veřejném sektoru ","(Le Compte, Van Leuven and De Meyere v. Belgium; Philis v. Greece (no. 2), § 45, (Marušić v. Croatia (dec.), §§ 72-73), Kamenos v. Cyprus, § 73, Ramos Nunes de Carvalho e Sá v. Portugal [GC], § 120; Grace Gatt v. Malta, §§ 60-63)"),
                            new PssChoice("ca.1.28", "28. Proces podání žádosti poškozeným, který se připojil k trestnímu procesu za účelem získání odškodnění, státnímu zástupci, aby podal procesní prostředek (stížnost) ohledně nesprávného právního posouzení věci ","(Gorou v. Greece (no. 2) [GC], § 35)"),
                            new PssChoice("ca.1.29", "29. Řízení před vojenským soudem ","(Pridatchenko and Others v. Russia, § 47)"),
                            new PssChoice("ca.1.30", "30. Řízení o propuštění zaměstnanců ambasády ","(Cudak v. Lithuania [GC], §§ 44-47)"),
                            new PssChoice("ca.1.31", "31. Řízení o nedobrovolném přesunu státního zástupce ","(Zalli v. Albania (dec.) nebo jeho odvolání (Kövesi v. Romania, §§ 115 and 121)"),
                            new PssChoice("ca.1.32", "32. Disciplinární řízení se soudcem ","(Olujić v. Croatia; Harabin v. Slovakia)"),
                            new PssChoice("ca.1.33", "33. Řízení o povýšení celního úředníka ","(Fiume v. Italy, §§ 33-36)"),
                            new PssChoice("ca.1.34", "34. Řízení ohledně jmenování soudce předsedou soudu ","(Tsanova-Gecheva v. Bulgaria, §§ 84-85)"),
                            new PssChoice("ca.1.35", "35. Civilní žaloba v rámci trestního řízení či žaloba, ohledně práva, které je nerozlučně spjato s právem poškozeného na jeho odškodnění, i kdyby mělo být pouze symbolické; netýká se žaloby, jejímž cílem je pouhá osobní msta nebo potrestání "," (Perez v. France [GC], §§ 70-71, (Nicolae Virgiliu Tănase v. Romania [GC], §§ 188 and 194, Sigalas v. Greece, § 29; Mihova v. Italy (dec.)). "),
                            new PssChoice("ca.1.36", "36. Řízení týkající se ochrany cti a dobré pověsti, jakož i řízení o pomluvě ","((Helmers v. Sweden, § 27, Tolstoy Miloslavsky v. the United Kingdom, § 58)"),
                            new PssChoice("ca.1.37", "37. Řízení týkající se práva na svobodný přístup k informacím a dokumentům veřejné správy ","(Loiseau v. France (dec.))"),
                            new PssChoice("ca.1.38", "38. Řízení týkající se záznamu a výmazu v rejstříku trestů "," (Alexandre v. Portugal, §§ 54-55), Pocius v. Lithuania, §§ 38-46; Užukauskas v. Lithuania, §§ 32-40)"),
                            new PssChoice("ca.1.39", "39. Řízení týkající se předběžných opatření "," (De Tommaso v. Italy [GC], § 151)"),
                            new PssChoice("ca.1.40", "40. Řízení týkající se práva být členem spolku či přípustnosti činnosti spolku. Řízení týkající se svobody projevu, včetně procesu, v rámci kterého se novinář snaží získat informace za účelem výkonu svého povolání ((Sakellaropoulos v. Greece (dec.); Lovrić v. Croatia, §§ 55-56, ((APEH Üldözötteinek Szövets, Kenedi v. Hungary, § 33, Selmani and Others v. the former Yugoslav Republic of Macedonia, § 47; Shapovalov v. Ukraine, § 49).ége and Others v. Hungary, §§ 34-35)"),
                            new PssChoice("ca.1.41", "41. Řízení týkající se práva na nižší či vyšší vzdělání ","(Oršuš and Others v. Croatia [GC], § 104, Emine Araç v. Turkey, §§ 18-25)"),
                            new PssChoice("ca.1.42", "42. Řízení týkající se otázek životního prostředí, jež souvisí s právem na život, zdraví nebo zdravé životní prostředí ","(Taşkın and Others v. Turkey)."),
                            new PssChoice("ca.1.43", "43. Řízení o určení otcovství ","(Alaverdyan v. Armenia (dec.), § 33)"),
                            new PssChoice("ca.1.44", "44. Řízení či proces týkající se vydání lustračního osvědčení ","(Polyakh and Others v. Ukraine, § 153)"),
                            new PssChoice("ca.1.45", "45. Řízení týkající se výchovy dětí, včetně školní docházky ","((McMichael v. the United Kingdom, Ellès and Others v. Switzerland, §§ 21-23)"),
                            new PssChoice("ca.1.46", "46. Řízení týkající se svobody projevu, včetně procesu, v rámci kterého se novinář snaží získat informace za účelem výkonu svého povolání ","((Kenedi v. Hungary, § 33, Selmani and Others v. the former Yugoslav Republic of Macedonia, § 47; Shapovalov v. Ukraine, § 49)"),
                            //new PssChoice("ca.1.", ""),
                        ]
                    )
                ]
            ),
            new PssSectionPart(
                "B. Řízení, na které záruky spravedlivého procesu podle čl. 6 odst. 1 Úmluvy použít nelze:",
                [],
                [
                    new PssChoice("", "1. Nesporné řízení, jež nezahrnuje protistrany a ve kterém se nejedná o spor o vaše subjektivní právo. ", "(Alaverdyan v. Armenia (dec.), § 35)"),
                    new PssChoice("", "2. V řízení, jehož základem není právní nárok, ale pouze právo, jehož udělení závisí na volné diskreci příslušného úřadu a jež je zakončeno rozhonutím, jež daný úřad nemusí zdůvodňovat ", "(Masson and Van Zon v. the Netherlands, §§ 49-51; Roche v. the United Kingdom [GC], §§ 122-125; Ankarcrona v. Sweden (dec.))"),
                    new PssChoice("", "3. Proces vedoucí ke zpracování zpráv vyplývajících z šetření, jejichž smyslem je zachytit fakta, jež mají být dále použity jako základ pro úkony dalších kompetentních úřadů, ať už se jedná o orgány činné v trestním řízení, správní řízení, disciplinární řízení nebo i zákonodárné zprávy, a to i když takové zprávy mohou vest k poškození pověsti v nich zmíněné osoby. ", "(Fayed v. the United Kingdom, § 61)"),
                    new PssChoice("", "4. Řízení civilního charakteru proti vězeňské správě z důvodu přítomnosti HIV nakažených osob ve věznici. ", "(Skorobogatykh v. Russia (dec.))"),
                    new PssChoice("", "5. V situacích, kdy soudní řízení nebylo započato, neboť žaloba byla odmítnuta z důvodu zjevné neopodstatněnosti, ať už věcné či procesní. ", "(Astikos Oikodomikos Synetairismos Nea Konstantinoupolis v. Greece (dec.); Arvanitakis and Others v. Greece (dec.); Stavroulakis v. Greece (dec.))"),
                    new PssChoice("", "6. Řízení, jehož smyslem bylo napadení rozhodnutí rozšiřující licenci pro společnost provozující jadernou elektrárnu. "," (Balmer-Schafroth and Others v. Switzerland, § 40; Athanassoglou and Others v. Switzerland [GC], §§ 46-55; Sdružení Jihočeské Matky v. the Czech Republic (dec.)"),
                    new PssChoice("", "7. Disciplinární řízení, jež se přímo netýká práva pokračovat ve výkonu profese, v situaci, kdy takového cíle lze dosáhnout v jiném řízení. ", "(Marušić v. Croatia (dec.), §§ 74-75)"),
                    new PssChoice("", "8. Řízení iniciované z důvodu údajného plagiátorství proti autorovi knihy, jež nemá vliv na právo autora na jeho dobrou pověst. ", "(Morawska v. Poland, §§ 72 and 73)"),
                    new PssChoice("", "9. Řízení o udělení bezpečnostní prověrky. ", "(Regner v. the Czech Republic [GC], § 105)"),
                    new PssChoice("", "10. Podání trestního oznámení či žádosti o zahájení trestního řízení. ", "(Mustafa Tunç and Fecire Tunç v. Turkey [GC], § 218)"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ],
        true
    ),
    new PssSection(
        "II.\tABSENCE FÉROVOSTI PROCESU",
        "Právo na férovost soudního procesu je význačnou vlastností a jedním ze základních principů demokratické" +
        " společnosti [1]. Instituce státu musí zajistit v každém individuálním případě, že požadavky férového procesu " +
        "byly dodrženy [2]. " +
        "\n" +
        "\n" +
        "Tyto požadavky se vztahují na doručení informace o existenci soudního řízení, zajištění rovnosti " +
        "zbraní účastníků soudního řízení, povinnosti soudu posoudit a zhodnotit argumenty účastníků řízení. Stížnosti účastníků" +
        " soudního řízení na to, že tyto požadavky spravedlivého procesu byly dodrženy, jsou soudy povinny zkoumat se " +
        "zvláštní pečlivostí a opatrností [3]. " +
        "\n" +
        "\n" +
        "Podstata práva na přístup k soudu je narušena, když pravidla přestanou " +
        "sloužit cílům „právní jistoty“ [4] a „řádného výkonu spravedlnosti“ [5]." +
        "To se může projevit tím, že advokát protistrany učinil konkrétní kroky k tomu, aby vás jako účastníka řízení zbavil práv na spravedlivý" +
        " proces, například navrhl soudu, aby došlo k některé z následujících situací či aby došlo k některému z následujících porušení.",
        "[1] (Airey v. Ireland, § 24; Stanev v. Bulgaria [GC], § 231, Pretto and Others v. Italy, § 21); [2] (Dombo Beheer B.V. v. " +
        "the Netherlands, § 33 in fine); [3] (Fabris v. Francie [GC], § 72 in fine; Wagner a JMWL v. Lucembursko, § 96); " +
        "[4] (Nideröst-Huber v. Švýcarsko, § 30); [5] (Beian proti Rumunsku (č. 1), § 39)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ca.2.1", "1. Soud neumožnil „praktický a účinný“ přístup do soudního řízení. Přístup k soudu byl jen teoretický nebo iluzorní. "," (Airey v. Irsko, § 24; Perez v. Francie [GC], § 80)"),
                    new PssChoice("ca.2.2", "2. Soud neumožnil účastnit se řízení, které bylo zahájeno protistranou a hájit v tomto řízení zájmy účastníka. "," (Dilipak a Karakaya v. Turecko, § 85-88)"),
                    new PssChoice("ca.2.3", "3. Soud vytvořil jednu či více překážek, která zabránila v tom, aby byl případ projednán věcně příslušným soudem. "," (Zubac v. Chorvatsko [GC], § 98)"),
                    new PssChoice("ca.2.4", "4. Soud neposoudil námitky týkající se práv a svobod zaručených Úmluvou a jejími Protokoly, přesto, že je účastník řízení v řízení předložil, a vnitrostátní soudy je nezkoumali obzvláště přísně a pečlivě přesto, že jsou k tomu povinny (Wagner a J.M.W.L. proti Lucembursku, § 96)."),
                    new PssChoice("ca.2.5", "5. Jako příslušníkovi mezinárodní organizace imunita této mezinárodní organizace před národním soudem nebyla respektována. "," (Stichting Mothers of Srebrenica and Others v. the Netherlands (dec.), § 139)"),
                    new PssChoice("ca.2.6", "6. Soud neučinil nezbytná opatření, aby informoval účastníka o řízení, která se ho týkají. "," (Dilipak a Karakaya v. Turecko, § 85-88)"),
                    new PssChoice("ca.2.7", "7. Soud znemožnil, účastníkovi, aby měl jasnou a praktickou příležitost napadnout čin, který je zásahem do jeho práv. ","Bellet v. Francie, § 36; Nunes Dias v. Portugalsko (dec.)"),
                    new PssChoice("ca.2.8", "8. Soud s náležitou péčí nezajistil účastníkovi, aby byl informován o řízení či řízeních, která se ho týkají, aby se mohl k soudu dostavit a bránit se a aby bylo zajištěno jeho právo na přístup k soudu. ","Schmidt v Latvia, §§ 86-90, 92 a 94-95"),
                    new PssChoice("ca.2.9", "9. Účastník nebyl s dostatečným předstihem informován o datu a místě soudního jednání.","Gankin a další v. Rusko, § 39 a 42)"),
                    new PssChoice("ca.2.10", "10. Soud před prvním soudním jednání neověřil, že účastník byl s dostatečným předstihem informován o datu a místě jednání soudního jednání. Soud musí jít nad rámec pouhého odkazu na zaslání soudního předvolání a musí co nejlépe využít dostupné důkazy, aby bylo možné zjistit, zda byla nepřítomná strana skutečně o jednání informována v dostatečném předstihu, což však soud neučinil. ","Gankin a další v. Rusko, § 39 a 42"),
                    new PssChoice("ca.2.11", "11. Soud neodročil jednání přesto, že zjistil, že nepřítomný účastník neobdržel předvolání k soudu včas. ","Gankin a další v. Rusko, §§ 34–38"),
                    new PssChoice("ca.2.12", "12. Odvolání proti rozhodnutí podané jedním účastníkem řízení nebylo druhému účastníkovi doručeno, takže posléze uvedený účastník neměl možnost se k tomuto odvolání vyjádřit. "," (Beer v. Austria, § 19)"),
                    new PssChoice("ca.2.13", "13. K řádné účasti odvolatele na odvolacím řízení je nezbytné, aby mu soud z vlastního podnětu poskytl dokumenty, které má k dispozici. Není tudíž podstatné, že stěžovatel proti neposkytnutí příslušných dokumentů nic nenamítal nebo že se pokusil získat přístup ke spisu. "," (Kerojärvi proti Finsku, § 42)"),
                    new PssChoice("ca.2.14", "14. Je porušením práva na spravedlivý proces účastníka řízení, pokud zákonodárce za situace, kdy již devět let probíhalo řízení, jehož účastníkem byl stát a stěžovatelé měli proti němu k dispozici pravomocný vykonatelný rozsudek, zasáhl legislativně do probíhajícího řízení, přičemž cílem zásahu bylo ovlivnit blížící se výsledek řízení ve prospěch státu. ","(Stran Greek Refineries a Stratis Andreadis proti Řecku, § 49–50)"),
                    new PssChoice("ca.2.15", "15. Je porušením práva na spravedlivý proces, pokud došlo k přijetí zákona, který pro účely praxe vyřešil hmotněprávní otázky a učinil tak bezpředmětným další pokračování sporu před kasačním soudem, jenž se v té době nacházel v rozhodující fázi. "," (Papageorgiou proti Řecku)"),
                    new PssChoice("ca.2.16", "16. Je porušením práva na spravedlivý proces, pokud stát, ačkoli sám není účastníkem řízení, prostřednictvím svých legislativních pravomocí soudní řízení zmaří. "," (Ducret proti Francii, § 33–42)"),
                    //new PssChoice("ca.2.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Pouhá skutečnost, že výsledek sporu nedopadl ve vás prospěch, neznamená, že vaše práva na spravedlivý proces byla porušena. ", "(Andronicou and Constantinou v. Cyprus, § 201)"),
                    new PssChoice("", "2. Pochybení soudu ohledně férovosti procesu byla napravena týmž soudem nebo soudem vyššího stupně v pozdější fázi řízení. ", "(Helle v. Finland, §§ 46 and 54, Schuler-Zgraggen v. Switzerland, § 52)"),
                    new PssChoice("", "3. Nevyužil jste možnosti napadnout předchozí procesní pochybení či rozhodnutí před nezávislým soudním orgánem, i když jste k tomu měl příležitost. ", "(Oerlemans v. the Netherlands, §§ 53-58; British-American Tobacco Company Ltd v. the Netherlands, § 78, Air Canada v. the United Kingdom, § 62)"),
                    new PssChoice("", "4. Přijetí obecných právních předpisů se může pro účastníky řízení ukázat jako nepříznivé, aniž by bylo skutečně zacíleno na probíhající soudní řízení a jeho účelem bylo obejití zásady zákonnosti. Právní předpis, na němž je výsledek řízení závislý, může být v průběhu soudního řízení prohlášen za neústavní, aniž by bylo úmyslem jakkoli toto soudní řízení ovlivnit, a bylo tím ovlivněno právo na spravedlivý proces. ", "(Gorraiz Lizarraga a ostatní proti Španělsku, § 72, Dolca proti Rumunsku (rozh.))"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "III.\tPORUŠENÍ PRÁVA NA KONTRADIKTORNÍ PROCES",
        "Požadavky vyplývající z práva na kontradiktorní řízení jsou v zásadě shodné jak v občanskoprávních, " +
        "tak v trestních věcech [1]. Snaha ušetřit čas a urychlit řízení ze strany soudu neodůvodňuje" +
        " nerespektování základní zásady, rovnosti procesních zbraní v řízení [2]. " +
        "\n" +
        "\n" +
        "Právo na kontradiktorní řízení v zásadě znamená, že účastníci trestního nebo občanskoprávního řízení musejí mít možnost seznámit se " +
        "se všemi důkazy nebo stanovisky předloženými s cílem ovlivnit rozhodnutí soudu a vyjádřit se k nim, a to i v případě, že " +
        "je předložili nezávislí zaměstnanci vnitrostátní právní služby [3]. Úkolem podle Úmluvy je zajistit, zda řízení jako celek " +
        "bylo spravedlivé, a to včetně způsobu dokazování [4]. " +
        "\n" +
        "\n" +
        "Soud musí tudíž zjistit, zda byly důkazy předloženy způsobem, který poskytoval záruku spravedlivého řízení [5]. " +
        "Úmluva jako taková ovšem nestanoví žádná pravidla týkající se důkazů jako takových [6]. Přípustnost důkazů a způsob " +
        "jejich hodnocení jsou primárně předmětem vnitrostátní právní úpravy a jejího výkladu vnitrostátními soudy [7]. " +
        "Totéž platí i pro důkazní hodnotu a důkazní břemeno [8]. " +
        "\n" +
        "\n" +
        "To se může projevit tím, že advokát protistrany učinil konkrétní kroky k tomu, aby Vás jako účastníka řízení zbavil práv " +
        "na spravedlivý proces, například navrhl soudu, aby došlo k některé z následujících situací či aby došlo k " +
        "některému z následujících porušení vašeho práva na kontradiktorní řízení.",
        "[1] (Werner proti Rakousku, § 66); [2] (Nideröst-Huber v. Švýcarsko, § 30); [3] (Ruiz-Mateos proti Španělsku, § 63; " +
        "McMichael proti Spojenému království, § 80); [4] (Elsholz proti Německu [velký senát], § 66); [5] (Blücher proti České republice, § 65); " +
        "[6] (Mantovanelli proti Francii, § 34); [7] (García Ruiz proti Španělsku [velký senát], § 28); [8] (Tiemann proti Francii a Německu (rozh.))",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ca.3.1", "1. Účastníkovi bylo znemožněno předložit soudu argumenty a důkazy s potřebnou účinností. "," (H. v. Belgie, § 53, Dombo Beheer BV v. Nizozemsko, § 33 in fine)"),
                    new PssChoice("ca.3.2", "2. Účastníkovi poskytnuta přiměřená příležitost předložit jeho případ - včetně jeho důkazů - za podmínek, které ho neznevýhodňují vůči druhé straně. ","Regner v. Česká republika [GC], § 146; Dombo Beheer BV v. Nizozemsko, § 33"),
                    new PssChoice("ca.3.3", "3. Účastník neměl možnost získat dostatečné znalosti o průběhu soudního řízení a komentovat všechny předložené důkazy nebo vyjádření podaná s cílem ovlivnit rozhodnutí soudu. ","Ruiz-Mateos v. Španělsko, § 63; McMichael v. Spojené království, § 80; Vermeulen v. Belgie, § 33; Lobo Machado v. Portugalsko, § 31; Kress v. Francie [GC], § 74"),
                    new PssChoice("ca.3.4", "4. Účastník neměl možnost seznámit se s důkazy před soudem nebo možnost vyjádřit se k jejich existenci, obsahu a autentičnosti vhodnou formou a ve vhodnou dobu. ","Krčmář a další v. Česká republika, § 42; Immeubles Groupe Kosser v. Francie, § 26"),
                    new PssChoice("ca.3.5", "5. Jako zranitelnému jednotlivci, například v důsledku zdravotní poruchy, se účastníkovi v průběhu řízení nedostalo potřebné ochrany či pomoci tak, aby byla zachována jeho důstojnost a právo na soukromý život. "," (Evers v. Germany, §§ 82-84)"),
                    new PssChoice("ca.3.6", "6. Zákonná a podzákonná pravidla týkající se přípustnosti znaleckých posudků a dokumentů neposkytla účastníkovi možnost tyto znalecké posudky a důkazy účinně napadnout. "," (Letinčić v. Croatia, § 50)"),
                    new PssChoice("ca.3.7", "7. Pokud byl soudem ustanoven znalec, nebylo účastníkovi umožněno, aby se účastnil výslechů, které soudní znalec uskutečnil nebo, jež uvádí ve svých dokumentech, že je uskutečnil, v důsledku čehož se účastník nemohl účinně účastnit řízení. "," (Letinčić v. Croatia, § 50; Devinar v. Slovenia, § 46)"),
                    new PssChoice("ca.3.8", "8. Soud neodročil soudní jednání, přesto, že účastník o to žádal z důvodu, že neměl možnost seznámit se s důkazy před soudem nebo možnost vyjádřit se k jejich existenci, obsahu a autentičnosti vhodnou formou a ve vhodnou dobu. ","Yvon v. Francie, § 39"),
                    new PssChoice("ca.3.9", "9. Účastník nemohl předložit argumenty nebo důkazy, protože nevěděl o existenci práva nebo existenci souvisejících řízeních. ",""),
                    new PssChoice("ca.3.10", "10. Účastník soudního řízení neměl možnost seznámit se s důkazy předloženými soudu, ani možnost vyjádřit se přiměřenou formou a v přiměřené lhůtě k jejich existenci, obsahu a pravosti, v případě potřeby i docílením odročení jednání. "," (Yvon proti Francii § 39; (Krčmář a ostatní proti České republice, § 42; Immeubles Groupe Kosser proti Francii, § 26)"),
                    new PssChoice("ca.3.11", "11. Účastník řízení neměl příležitost navrhnout důkaz potřebný k tomu, aby se svým nárokem uspěl. "," (Clinique des Acacias a ostatní proti Francii, § 37)"),
                    //new PssChoice("ca.3.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Bylo porušeno vaše procesní právo, avšak důsledek tohoto porušení neměl reálně možnost ovlivnit výsledek sporu, který byl v souladu s právem. ", "(Stepinska v. France, § 18)"),
                    new PssChoice("", "2. Právo na spravedlivý proces výslovně nezaručuje právo na předvolání svědka a přípustnost svědecké výpovědi je zásadně záležitostí vnitrostátního práva. Zamítnutí návrhu na předvolání svědka stejně jako zamítnutí přípustnosti svědecké výpovědi bylo soudem přiměřeně odůvodněno. ", "(Dombo Beheer B.V. proti Nizozemsku, § 31, H. proti Francii, § 61 a 70)"),
                    new PssChoice("", "3. Procesní pochybení, kterého se dopustil soud, je zcela marginální. Například neobdržel jste od soudu vyjádření protistrany nebo kopii důkazů, které nebylo pro řízení nijak podstatné nebo neobsahovalo žádné nové skutečnosti nebo soud výslovně prohlásil, že takový důkaz protistrany nevzal v potaz . ", "(Liga Portuguesa de Futebol Profissional v. Portugal (déc.), §§ 36-40; Kılıç and Others v. Turkey (dec.); Holub v. the Czech Republic (dec); (Cavajda v. the Czech Republic (dec.)"),
                    new PssChoice("", "4. Procesní pochybení bylo pouze izolované a nemohlo způsobit neférovost procesu jako takového. ", "(Miroļubovs and Others v. Latvia, § 103)"),
                    new PssChoice("", "5. Pokud byl důkaz předložený soudu získán nezákonně, je pouze na soudu, zda určí, zda takový důkaz je v soudním řízení přípustný. ", "(López Ribalda and Others v. Spain [GC], § 150)"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    ),
    new PssSection(
        "IV.\tPORUŠENÍ PRÁVA NA ROVNOST PROCESNÍCH ZBRANÍ",
        "Zásada „rovnosti procesních zbraní“ je nedílnou součástí pojmu spravedlivého řízení v širším slova smyslu." +
        " Požadavek „rovnosti procesních zbraní“ ve smyslu „spravedlivé rovnováhy“ mezi účastníky řízení se v zásadě uplatní v civilních " +
        "i trestních věcech [1]. " +
        "\n" +
        "\n" +
        "Zachování „spravedlivé rovnováhy“ mezi účastníky řízení znamená, že každému" +
        " účastníku řízení musí být poskytnuta přiměřená možnost předestřít svoji věc – včetně důkazů – za podmínek," +
        " které jej podstatným způsobem neznevýhodňují vůči protistraně [2]. Vnitrostátní soudy jsou povinny řádně posoudit" +
        " podání, argumenty a důkazy, předložené účastníky řízení [3]. Vnitrostátním soudům přísluší také posouzení relevance " +
        "navrhovaných důkazů [4]." +
        "\n" +
        "\n" +
        "To se může projevit tím, advokát protistrany učinil konkrétní kroky k tomu, aby Vás jako účastníka řízení zbavil práv na spravedlivý proces," +
        " například navrhl soudu, aby došlo k některé z následujících situací či aby došlo k některému z následujících porušení " +
        "vašeho práva na rovnost procesních zbraní.",
        "[1] (Feldbrugge proti Nizozemsku, § 44); [2] Dombo Beheer B.V. proti Nizozemsku, § 33; [3] (Van de Hurk proti Nizozemsku, § 59)" +
        "[4] (Centro Europa 7 S.r.l. a Di Stefano proti Itálii [velký senát], § 198)",
        [
            new PssSectionPart(
                "A. Aby došlo k porušení práva na férovost procesu, muselo dojít alespoň k jedné z následujících situací. Pokud se domníváte, že k jedné či více následujících situací došlo, zaškrtněte ji/je.",
                [],
                [
                    new PssChoice("ca.4.1", "1. Soud upřel účastníkovi mít soudem posouzeny jeho tvrzení a důkazy, které byly předloženy soudu před vydáním rozsudku. "," (Kraska v. Switzerland, § 30; Van de Hurk v. the Netherlands, § 59; Perez v. France [GC], § 80)"),
                    new PssChoice("ca.4.2", "2. Soud porušil právo účastníka řízení na to, aby vyjádření, které předložil/a, byla skutečně „vyslechnuta“, tj. aby je soud řádně zvážil1. ","(Donadze v. Georgia, § 35)"),
                    new PssChoice("ca.4.3", "3. Účastník řízení neměl příležitost vyjádřit svůj názor na určitý dokument ve spisu, včetně dokumentů, které si soud opatřil z vlastního podnětu. "," (K.S. proti Finsku, § 22; Nideröst-Huber proti Švýcarsku, § 29; Pellegrini proti Itálii, § 45)"),
                    new PssChoice("ca.4.4", "4. Běh lhůt byl přerušen jen jednomu z účastníků řízení, čímž se druhý účastník ocitl v podstatné nevýhodě. "," (Platakou proti Řecku, § 48; Wynen proti Belgii § 32)"),
                    new PssChoice("ca.4.5", "5. Účastník řízení nebyl informován o podání k soudu, jež učinil jiný účastník a nemohl se k němu vyjádřit. ","(APEH Üldözötteinek Szövetsége a ostatní proti Maďarsku, § 42)"),
                    new PssChoice("ca.4.6", "6. Soud se konkrétně a výslovně nevyjádřil k tvrzení účastníka, které bylo rozhodné pro výsledek řízení. "," (Ruiz Torija v. Spain, § 30; Hiro Balani v. Spain, § 28; and compare Petrović and Others v. Montenegro, § 43)"),
                    new PssChoice("ca.4.7", "7. Zamítnutí soudem návrhu účastníka na předvolání svědků nebylo soudem dostatečně odůvodněno a vykazuje známky svévole, takže účastník řízení byl tímto zamítnutí nepřiměřeně omezen ve svém právu předkládat argumenty na podporu svých tvrzení. "," (Wierzbicki proti Polsku, § 45)"),
                    new PssChoice("ca.4.8", "8. Nedostatek neutrality znalce spolu s jeho postavením a úlohou v řízení vychýlily rovnováhu ve prospěch jednoho účastníka řízení na úkor druhého. "," (Sara Lind Eggertsdottir proti Islandu, § 53)"),
                    new PssChoice("ca.4.9", "9. Ze dvou klíčových svědků byl vyslechnut pouze jeden. "," (Dombo Beheer B.V. v. the Netherlands, §§ 34-35)"),
                    new PssChoice("ca.4.10", "10. Protistrana měla oproti účastníkovi řízení významná zvýhodnění, co se týče přístupu k relevantním informacím, v důsledku čehož měla dominantní postavení v procesu a významně ovlivňovala posouzení soudu. "," (Yvon v. France, § 37)"),
                    new PssChoice("ca.4.11", "11. Protistrana měla v důsledku svého postavení a funkcí významnou výhodu v soudním řízení a soud účastníkovi řízení neumožnil tuto její výhodu napadnout tím, že by umožnil předložení listinných důkazů nebo vyslechnutí svědků uvedenému účastníkovi řízení. "," (De Haes and Gijsels v. Belgium, §§ 54 and 58)"),
                    new PssChoice("ca.4.12", "12. Účastníkovi řízení nebyl soudem umožněn přístup k relevantním dokumentům, jež byly v držení orgánu veřejné moci a jež byly významné pro výsledek řízení. "," (McGinley and Egan v. the United Kingdom, §§ 86 and 90)"),
                    new PssChoice("ca.4.13", "13. Odepření právní pomoci znemožnilo účastníkovi řízení účinně prezentovat jeho případ před soudem v situaci, kdy protistrana disponovala mnohem většími finančními prostředky. ","(Steel and Morris v. the United Kingdom, § 72)"),
                    new PssChoice("ca.4.14", "14. Státní zástupce intervenoval do soudního řízení – jiného než trestního – , aby podpořil argumenty protistrany. "," (Menchinskaya v. Russia, §§ 35-39)"),
                    new PssChoice("ca.4.15", "15. Soudce odmítl odročit jednání, i když účastník řízení musel být z urgentních důvodů převezen do nemocnice, což způsobilo, že ani právní zástupce účastníka řízení nebyl schopen zastupovat tohoto účastníka při soudním jednáním a v důsledku toho reagovat na tvrzení protistrany. "," (Vardanyan and Nanushyan v. Armenia, §§ 88-90)"),
                    new PssChoice("ca.4.16", "16. Soud prvního stupně nezvážil všechny hlavní argumenty a nepřezkoumal hlavní argumenty účastníka řízení, které v řízení předložil.","(Buzescu v. Rumunsko, § 67; Donadze v. Gruzie, § 35"),
                    new PssChoice("ca.4.17", "17. Soudem prvního stupně bylo opomenuto obecné skutkové a právní pozadí případu, přesto, že se ho účastník snažil soudu vylíčit.","(Stankiewicz v. Polsko, § 70"),
                    new PssChoice("ca.4.18", "18. Soud prvního stupně nevzal v úvahu důkazy předložené oběma stranami, včetně těch od účastníka řízení, a nerespektoval jeho právo na to předložit důkazy takovým způsobem, který zaručuje spravedlivý proces. "," (Blücher v. Česká republika, § 65; Van de Hurk v. Nizozemsko, bod 59"),
                    new PssChoice("ca.4.19", "19. Stanovisko nezávislého pracovníka právního odboru orgánu veřejné moci v občanskoprávní věci nebyla předem sdělena účastníkovi řízení, čímž mu bylo znemožněno na ně reagovat. "," (Vermeulen proti Belgii, § 33; Lobo Machado proti Portugalsku, § 31; Van Orshoven proti Belgii, § 41; Göç proti Turecku [velký senát], § 55–56; Kress proti Francii, § 76; Immeubles Groupe Kosser proti Francii, § 26)"),
                    new PssChoice("ca.4.20", "20. Přítomnost členů právního odboru orgánu veřejné moci vnitrostátní právní služby na poradě soudu, ať již „aktivní“ nebo „pasivní“, poté, co veřejně vyjádřili svůj názor na projednávanou věc, je nepřípustná, přesto k ní však došlo. "," (Vermeulen proti Belgii, § 34; Lobo Machado proti Portugalsku, § 32; Kress proti Francii, § 87)"),
                    //new PssChoice("ca.4.", ""),
                ]
            ),
            new PssSectionPart(
                "B. Aby došlo k porušení práva na spravedlivý proces, nesmí ZÁROVEŇ dojít k žádné z následujících situací. Pokud jste zaškrtli alespoň jednu situaci z výše uvedeného seznamu, ale zároveň došlo k alespoň jedné z následujících situací, pak práva na spravedlivý proces nebyla porušena.",
                [],
                [
                    new PssChoice("", "1. Soud se vyjádřil k vaším hlavním argumentům, ale nevyjádřil se úplně ke všem vašim argumentům. ", "(Van de Hurk v. the Netherlands, § 61; García Ruiz v. Spain [GC], § 26; Jahnke and Lenoble v. France (déc.); Perez v. France [GC], § 81)"),
                    new PssChoice("", "2. Vaše právo na férový proces nemohlo být porušeno, pokud jste nepředložil relevantní a dostatečné důkazy o porušení vašich hmotněprávních nároků. ", "(Fleischner v. Germany, §§ 40-41)"),
                    new PssChoice("", "3. Porušení vašich práv na spravedlivý proces je důsledkem významných chyb v soudním řízení z vaší strany, které jsou vám objektivně přičitatelné. ", "(Zubac v. Croatia [GC], §§ 90 and 121)"),
                    new PssChoice("", "4. Porušení vašich práv na spravedlivý proces je důsledkem významné nečinnosti či nedbalosti z vaší strany. ", "(Avotiņš v. Latvia [GC], §§ 123-24; Barik Edidi v. Spain (dec.), § 45)"),
                    new PssChoice("", "5. Právo na férovost procesu nezajišťuje, že týž soud nebo odlišné soudy nemohou dojít k odlišným závěrům a vydat odlišná rozhodnutí. ", "(Santos Pinto v. Portugal, § 41)"),
                    new PssChoice("", "6. Není pochybením soudu, pokud vydal odlišná rozhodnutí v situacích, jež byly objektivně skutkově odlišné. ", "(Uçar v. Turkey (dec.))"),
                    new PssChoice("", "7. Požadavek, aby byl účastník řízení před kasačním soudem zastupován specializovaným právníkem, sám o sobě není v rozporu s článkem 6. ", "(G.L. a S.L. proti Francii (rozh.); Tabor proti Polsku, § 42)"),
                    new PssChoice("", "8. Rovnost zbraní neznamená, že účastník řízení má právo na to, aby mu ještě před jednáním byla sdělena stanoviska, s nimiž dosud nebyl seznámen druhý účastník řízení, soudce zpravodaj nebo soudci nalézacího soudu. ", "(Kress proti Francii, § 73)"),
                    new PssChoice("", "9. Šlo-li v řízení o položení předběžné otázky Evropskému soudnímu dvoru, právo na férovost procesu vám negarantuje právo na to, aby byla jiným soudem, národním nebo Evropským soudním dvorem, posouzena tato předběžná otázka. ", "(Coëme and Others v. Belgium, § 114; Acar and Others v. Turkey (dec.), § 43)"),
                    new PssChoice("", "10. Šlo-li v řízení o položení předběžné otázky, vznesl jste žádost o posouzení této předběžné otázky u jiného soudu, ale nutnost posouzení této předběžné otázky jste výslovně nezdůvodnil. ", "(John v. Germany (dec.); Somorjai v. Hungary, § 60)"),
                    new PssChoice("", "11. Šlo-li v řízení o položení předběžné otázky, to, proč předběžná otázka nebyla soudem u jiného soudu vznesena, byla prvně uvedeným soudem řádně zdůvodněna, a to ve světle faktických skutečností a i celkového průběhu soudního řízení. "," (Dotta v. Italy (dec.); Herma v. Germany (dec.), Harisch v. Germany, § 42; Repcevirág Szövetkezet v. Hungary, § 59; Krikorian v. France (dec.), § 99)"),
                    //new PssChoice("", "", "", false),
                ],
                false
            )
        ]
    )
];

