import {PssDocumentType} from "../Model/PssEnums";
import {CivilSoudceDb} from "./CivilSoudceDb";
import {CivilExekuceDb} from "./CivilExekuceDb";
import {CivilAdvokatDb} from "./CivilAdvokatDb";
import {TrestOCTRDb} from "./TrestOCTRDb";
import {TrestSoudceDb} from "./TrestSoudceDb";
import {PssSection} from "../Model/PssDatabase";


export default function GetTypeDatabase(type: PssDocumentType): PssSection[] {
    switch (type) {
        default:
            throw new Error("Unknown document type: " + type);
        case PssDocumentType.CivilSoudce:
            return CivilSoudceDb;
        case PssDocumentType.CivilExekuce:
            return CivilExekuceDb;
        case PssDocumentType.CivilAdvokat:
            return CivilAdvokatDb;
        case PssDocumentType.TrestOrgany:
            return TrestOCTRDb;
        case PssDocumentType.TrestSoudce:
            return TrestSoudceDb;
    }
}